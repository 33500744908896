import { createSelector } from 'reselect';

import { RootState } from 'store/root.reducer';

export const selectCountryCodes = ({
  registration
}: RootState): Record<string, string> => registration.countryCodes.data;

export const selectCountryCodesLoading = ({
  registration
}: RootState): boolean => registration.countryCodes.isLoading;

export const selectProductCountries = ({
  registration
}: RootState): DTO.Country[] => registration.productCountries.data;

export const selectProductCountriesLoading = ({
  registration
}: RootState): boolean => registration.productCountries.isLoading;

export const selectTermsOfUse = ({ registration }: RootState): DTO.TermsOfUse =>
  registration.termsOfUse.data;

export const selectTermsOfUseLoading = ({ registration }: RootState): boolean =>
  registration.termsOfUse.isLoading;

export const selectTermsOfUseAgreement = ({
  registration
}: RootState): boolean => registration.termsOfUse.isAgreed;

export const selectTermsOfUseData = ({
  registration
}: RootState): DTO.TermsOfUse => registration.termsOfUse.data;

export const reselectTermsOfUseInfo = createSelector(
  selectTermsOfUseData,
  (data: DTO.TermsOfUse): Record<string, string | number> => {
    return {
      id: data.id,
      version: data.version
    };
  }
);

export const selectRegistrationCountryCode = ({
  registration
}: RootState): string => registration.data.country;

const selectRegistrationData = ({
  registration
}: RootState): AP.RegistrationData => registration.data;

export const reselectRegistrationPlansEntity = createSelector(
  selectRegistrationData,
  (data: AP.RegistrationData): AP.RegistrationPlansEntity =>
    data.plans.reduce(
      (
        entity: AP.RegistrationPlansEntity,
        { code, purchasedLicenses, availableLicenses }: DTO.BasePlan
      ) => ({
        ...entity,
        [code]: {
          code,
          purchasedLicenses,
          availableLicenses
        }
      }),
      {}
    )
);

export const reselectRegistrationCountryCode = createSelector(
  selectRegistrationData,
  (data: AP.RegistrationData): string => data.country
);

export const reselectRegistrationData = createSelector(
  selectRegistrationData,
  (data: AP.RegistrationData) => data
);

export const selectCountryStatesData = ({
  registration
}: RootState): DTO.State[] => registration.states.data;

export const selectCountryStatesLoading = ({
  registration
}: RootState): boolean => registration.states.isLoading;

export const selectProductPlans = ({
  registration
}: RootState): DTO.RegistrationPlan[] => registration.productPlans.data;

export const selectProductPlansLoading = ({
  registration
}: RootState): boolean => registration.productPlans.isLoading;
