import { string, object } from 'yup';
import { withFormik } from 'formik';

import { noop } from 'shared/utils';

export interface CancelSubmissionForm {
  comment: string;
}

export const initialFormValues: CancelSubmissionForm = {
  comment: ''
};

export const commentLengthLimit = 256;

export interface CancelSubmissionDialogProps {
  onSubmit: (comment: string) => void;
  onCancel: () => void;
  show?: boolean;
  datas: any;
}

export const cancelSubmissionDialogEnhancer = withFormik<
  CancelSubmissionDialogProps,
  CancelSubmissionForm
>({
  mapPropsToValues: () => initialFormValues,
  validationSchema: object().shape({
    comment: string()
      .required()
      .trim()
      .max(commentLengthLimit)
  }),
  handleSubmit: noop
});
