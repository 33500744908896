import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'components';
import styles from './DeleteAdminModal.module.css';
import { UserRoles } from '../OnboardingFilters/OnboardingFilters';

interface Props {
  show: boolean;
  user: DTO.AdminUser;
  handleDelete: (email: string) => void;
  onHide: () => void;
}

export const DeleteAdminModal: FC<Props> = ({
  show,
  user,
  handleDelete,
  onHide
}) => {
  const { t } = useTranslation();
  return (
    <Modal centered={true} show={show} onHide={onHide}>
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title className={styles.modalTitle}>
          {t('onboarding.deletePopup.remove')}
        </Modal.Title>
        <span className={styles.cross} role='presentation' onClick={onHide}>
          <svg
            fill='none'
            height='12'
            viewBox='0 0 12 12'
            width='12'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              clipRule='evenodd'
              d='M0.0885912 0.215695L0.146447 0.146447C0.320013 -0.0271197 0.589437 -0.0464049 0.784306 0.0885912L0.853553 0.146447L6 5.293L11.1464 0.146447C11.32 -0.0271197 11.5894 -0.0464049 11.7843 0.0885912L11.8536 0.146447C12.0271 0.320013 12.0464 0.589437 11.9114 0.784306L11.8536 0.853553L6.707 6L11.8536 11.1464C12.0271 11.32 12.0464 11.5894 11.9114 11.7843L11.8536 11.8536C11.68 12.0271 11.4106 12.0464 11.2157 11.9114L11.1464 11.8536L6 6.707L0.853553 11.8536C0.679987 12.0271 0.410563 12.0464 0.215695 11.9114L0.146447 11.8536C-0.0271197 11.68 -0.0464049 11.4106 0.0885912 11.2157L0.146447 11.1464L5.293 6L0.146447 0.853553C-0.0271197 0.679987 -0.0464049 0.410563 0.0885912 0.215695Z'
              fill='black'
              fillRule='evenodd'
            />
          </svg>
        </span>
      </Modal.Header>
      <Modal.Body>
        <Trans i18nKey='onboarding.deletePopup.areYouSure'>
        {{ name: user && user.displayName }}
        </Trans>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button variant='outline-info' onClick={onHide}>
          {t('actions.cancel')}
        </Button>
        <Button variant='danger' onClick={() => handleDelete(user.email)}>
          {t('actions.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
