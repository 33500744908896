export function exportCSVFile(byteCharacters: string, fileTitle: string): void {
  const exportedFilename: string = `${fileTitle}.csv` || 'export.csv';
  const blob = new Blob([byteCharacters], {
    type: 'text/csv;'
  });

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    const link: any = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      //const url: any = URL.createObjectURL(blob);
      // link.setAttribute('href', url);
      
      // setting sencoding for CSV
      link.setAttribute('href', `data:text/csv; charset=utf-8,%EF%BB%BF${encodeURIComponent(byteCharacters)}`);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
