import { Reducer } from 'redux';

import {
  //   asyncActionPending,
  //   asyncActionRejected,
  asyncActionFulfilled
} from 'shared/utils';
import {
  AppLocales,
  defaultLocale
} from '../../shared/constants/common.consts';
import {
  SET_LOCALE,
  FETCH_LOCALE_LIST,
  LocaleList,
  LocaleState,
  LocalizeActionTypes,
  FetchLocaleList
} from './localize.types';

const initialState: LocaleState = {
  defaultLocale,
  localeCode: AppLocales.enUS,
  localeList: [{ ...defaultLocale }]
};

export const localizeReducer: Reducer<LocaleState, LocalizeActionTypes> = (
  state: LocaleState = initialState,
  action: LocalizeActionTypes
): LocaleState => {
  switch (action.type) {
    case SET_LOCALE: {
      return {
        ...state,
        localeCode: action.payload
      };
    }

    case asyncActionFulfilled(FETCH_LOCALE_LIST): {
      const localeList: LocaleList = (action as FetchLocaleList).payload;
      return {
        ...state,
        localeList: [...localeList]
      };
    }

    default:
      return state;
  }
};
