export const validationEn = {
  errors: {
    required: 'This field is required to be entered.',
    email:
      'Please enter a valid email to continue the registration process.',
    additionalEmailNotUnique: 'This user currently has access to this subscription',
    userEmail: 'Please enter a valid non-Deloitte email to continue.',
    isDeloitteAuditClient:
      'We are not offering this product to Audit Clients at this time.',
    userFirstName: 'Please enter valid characters in your First Name.',
    userLastName: 'Please enter valid characters in your Last Name.'
  }
};
