import React from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import cs from 'classnames';

import { noop } from 'shared/utils';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import styles from './Dialog.module.css';
import Cross from '../../assets/images/Cross.png';

interface Props extends ModalProps {
  title: string;
  noCaps?:boolean;
  actions?: AP.DialogAction[];
  className?: string;
  onClose?: () => void;
  onClosed?: () => void;
}

export function AdminDialog({
  title,
  children,
  show = false,
  size,
  actions = [],
  className,
  noCaps = true,
  onHide = noop,
  onClose = noop,
}: React.PropsWithChildren<Props>): JSX.Element {
  const footer = React.useMemo(
    () =>
      actions.length ? (
        <Modal.Footer className={cs(styles.footer, className === 'noBorderAndPadding' ? cs(styles.noBorder, styles.paddingTop_0) : '') }>
          {actions.map(
            ({
              label,
              onClick,
              variant = 'primary',
              disabled = false
            }: AP.DialogAction): JSX.Element => (
              <Button
                key={label}
                className={styles.action}
                disabled={disabled}
                thin={true}
                variant={variant}
                onClick={onClick}
              >
                {label}
              </Button>
            )
          )}
        </Modal.Footer>
      ) : null,
    [actions, className]
  );

  return (
    <Modal
      centered={true}
      className={cs(styles.modal, className)}
      show={show}
      size={size}
      onExited={onClose}
      onHide={onHide}
    >
      <Modal.Header
        className={cs(
          styles.header,
          className === 'noBorderAndPadding'
            ? cs(styles.noBorder, styles.paddingBottom_0)
            : ''
        )}
      >
        <Modal.Title className={styles.titleAdminDialog}>
          <span>{title}</span>
          <div
            className={styles.crossImage}
            role='button'
            tabIndex={0}
            onClick={onClose}
            onKeyPress={onClose}
          >
            <img alt='cross' src={Cross} />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {footer}
    </Modal>
  );
}
