import React from 'react';
import cs from 'classnames';

import { noop } from 'shared/utils';
import styles from './Button.module.css';

interface Props {
  type: 'button' | 'submit' | 'reset';
  className: string;
  onClick: () => void;
}

export const TransparentButton: React.FC<
  React.PropsWithChildren<Partial<Props>>
> = ({ children, className = '', type = 'button', onClick = noop }) => (
  // actually, it has a valid button type
  // eslint-disable-next-line react/button-has-type
  <button
    className={cs(styles.transparentButton, className)}
    type={type}
    onClick={onClick}
  >
    {children}
  </button>
);
