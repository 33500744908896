import React from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import styles from './SubmissionPlansFooter.module.css';

interface Props {
  columnsCount: number;
  rowClassName: string;
  rowStyles: Record<string, string>;
  isSubmitAvailable: boolean;
  onOpenSubmissionDialog: () => void;
  shouldShowText: boolean;
}

export const SubmissionPlansFooter: React.FC<Props> = ({
  columnsCount,
  rowClassName,
  rowStyles,
  isSubmitAvailable,
  onOpenSubmissionDialog,
  shouldShowText
}) => {
  const { t } = useTranslation();

  return (
    <div className={cs(styles.footer, rowClassName)} style={rowStyles}>
      <p
        className={styles.info}
        style={{
          gridColumnStart: 1,
          gridColumnEnd: columnsCount
        }}
      >
        {shouldShowText && t('admin.plans.check')}
      </p>
      <div className={styles.centered}>
        <Button
          className={styles.submitButton}
          disabled={!isSubmitAvailable}
          size='sm'
          variant='link'
          onClick={onOpenSubmissionDialog}
        >
          {t('admin.plans.submit')}
        </Button>
      </div>
    </div>
  );
};
