import React from 'react';
import { useTranslation } from 'react-i18next';

import { BlankLink } from 'components';
import { BaseLayout } from 'features/BaseLayout/BaseLayout';
import styles from './AccessDeniedPage.module.css';

export const AccessDeniedPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BaseLayout className={styles.adminContainer}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h2 className={styles.title}>{t('admin.accessDenied.title')}</h2>
        </div>
        <div className={styles.text}>
          <p className={styles.message}>{t('admin.accessDenied.message')}</p>
          <span className={styles.message}>{t('admin.accessDenied.contactAdmin')}</span>
          <BlankLink href='mailto:assurancesupport@deloitte.com'><u>{t('admin.accessDenied.support')}</u></BlankLink>
         
        </div>
      </div>
    </BaseLayout>
  );
};
