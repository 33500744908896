import { Dispatch, SetStateAction, useCallback } from 'react';

export enum UPDATE_SUB_STEP {
  SELECT_PLANS = 'selectPlans',
  ACCOUNT_DETAILS = 'accountDetails'
}

export interface Step {
  name: UPDATE_SUB_STEP;
  label: string;
  children: JSX.Element[] | JSX.Element;
}

export const useUpdateSubSteps = (
  setStep: Dispatch<SetStateAction<UPDATE_SUB_STEP>>
): Record<UPDATE_SUB_STEP, () => void> => ({
  [UPDATE_SUB_STEP.SELECT_PLANS]: useCallback(
    (): void => setStep(UPDATE_SUB_STEP.SELECT_PLANS),
    [setStep]
  ),

  [UPDATE_SUB_STEP.ACCOUNT_DETAILS]: useCallback(
    (): void => setStep(UPDATE_SUB_STEP.ACCOUNT_DETAILS),
    [setStep]
  )
});

export enum UPDATE_SUBSCRIPTIONS_ACTIONS {
  ADD_NEW_PLAN = 'Add New Plan',
  RENEW = 'Renew',
  RE_ACTIVATE = 'Re-Activate',
  CANCEL = 'Cancel'
}
