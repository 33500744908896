import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutate } from 'restful-react';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'components';
import { selectHttpHeaders, selectSourceSubjectId } from 'store/auth';
import {
  selectCountryStatesData,
  reselectAction,
  setSubscriptionDetails
} from 'store/updateSubscription';
import { selectProductCode } from 'store/product';
import { CancelSubmissionDialog } from 'features/UpdateSubscription/CancelSubmissionDialog/CancelSubmissionDialog';
import { errorException } from 'shared/utils/AppInsights';
import { API_PATH } from 'shared/constants/api.consts';
import { APP_PATH } from '../../../Routes/Routes.helpers';
import { AccountDetailsForm } from './AccountDetailsForm';

export const AccountDetails: React.FC<AP.DefaultRegistrationStepProps> = ({
  onNext,
  onBack,
  showBackButton
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const sourceSubjectId = useSelector(selectSourceSubjectId);
  const productCode = useSelector(selectProductCode);
  const redirectUrl = `${APP_PATH.ADMIN_SUBMISSIONS_ROOT}/${productCode}`;
  const action = useSelector(reselectAction);
  const dispatch = useDispatch();
  const httpHeaders = useSelector(selectHttpHeaders);
  const [fValues, setFValues] = React.useState<any>({});
  const [formErrors, setFormErrors] = React.useState<any>();
  const states = useSelector(selectCountryStatesData);
  const [isOpenSubmissionDialog, setOpenSubmissionDialog] = React.useState<
    boolean
  >(false);
  const {
    mutate: saveSubscription,
    loading: isLoading,
    error: submitError
  } = useMutate<any>({
    verb: 'POST',
    path: API_PATH.SAVE_SUBSCRIPTION(productCode, sourceSubjectId),
    requestOptions: () => ({ headers: httpHeaders })
  });

  //   const {
  //     mutate: submitAccountDetails,
  //     loading: submitLoading,
  //     error: submitError
  //   } = useMutate<AP.RegistrationData>({
  //     verb: 'POST',
  //     path: API_PATH.SUBMIT_REGISTRATION(productCode),
  //     requestOptions: () => ({ headers: httpHeaders }),
  //     onMutate: React.useCallback(
  //       (response: AP.RegistrationData): void => {
  //         onNext();
  //         dispatch(submitRegistrationAccountDetails(response));
  //       },
  //       [onNext, dispatch]
  //     )
  //   });

  // const isDataLoading = areStatesLoading || submitLoading;

  React.useEffect((): void => {
    let errors = {};

    if(submitError && submitError.status === 409){
      
      // AppInsights Error Exception
      errorException('Error bad request to .../product/<product>/subscription/save, File reference: AccountDetailS.tsx in React.useEffect function');

      errors = { 'user.email': { key: 'emailNotUnique' } };
    }
    setFormErrors(errors);
  }, [submitError]);

  const handleOnClose = () => {
    setOpenSubmissionDialog(false);
  };

  // const openDialog = () => {
  //   setOpenSubmissionDialog(true);
  // };

  const updatePlanStatus = (plans: any, status: string) =>
    plans.map((plan: any) => ({
      ...plan,
      status: plan.checked ? status : plan.status
    }));

  const handleOnSubmit = React.useCallback(
    (comment: string): void => {
      saveSubscription({
        ...fValues,
        comment,
        plans: updatePlanStatus(fValues.plans, 'cancelled')
      }).finally(() => {
        history.replace(redirectUrl);
      });
      setOpenSubmissionDialog(false);
    },
    [saveSubscription, fValues, history, redirectUrl]
  );

  const savePreviousStepValues = (val: any): any => {
    formatAndUpdateSubscriptionData(val);
    setFValues(val);
    onBack(val);
  };
  const formatAndUpdateSubscriptionData = (val: any) => {
    const subscription: {
      plans: DTO.BasePlan[];
      userDetails: DTO.UserDetails;
      states: DTO.State[];
    } = {
      plans: val.plans,
      userDetails: {
        displayId: val.displayId,
        referral: val.referral,
        performedBy: val.performedBy,
        firstName: val.user.firstName,
        lastName: val.user.lastName,
        isPending: val.isPending,
        country: val.country,
        user: val.user,
        comment: val.comment,
        organization: {
          legalName: val.organization.legalName,
          parentLegalName: val.organization.parentLegalName || '',
          department: val.organization.department || '',
          address: val.organization.address,
          city: val.organization.city,
          stateL10NKey:  val.organization.stateL10NKey,
          state: val.organization.state,
          stateId: val.organization.stateId,
          postalCode: val.organization.postalCode,
          deloitteAuditClientType: val.organization.deloitteAuditClientType,
          isDeloitteAuditClient: +val.organization.isDeloitteAuditClient
        },
      },
      states: val.states
    };
    dispatch(setSubscriptionDetails(subscription));
  };
  const submitAccountDetails = (val: any) => {
    if (action.toLocaleLowerCase() === t('registration.plans.actions.cancel').toLocaleLowerCase()) {
      setFValues(val);
      setOpenSubmissionDialog(true);
    } else if (action.toLocaleLowerCase() ===t('registration.plans.actions.renew').toLocaleLowerCase()) {
      saveSubscription(val).finally(() => {
        history.replace(redirectUrl);
      });
    } else {
      const passVal = {
        ...val,
        plans: updatePlanStatus(val.plans, 'PendingReActivation')
      };
      saveSubscription(passVal).finally(() => {
        history.replace(redirectUrl);
      });
    }
  };

  return (
    <React.Fragment>
      <Spinner shouldShow={isLoading} />
      <AccountDetailsForm
        formErrors={formErrors}
        loading={false}
        showBackButton={showBackButton}
        states={states}
        onBack={savePreviousStepValues}
        onNext={onNext}
        onSubmit={submitAccountDetails}
      />
      {isOpenSubmissionDialog && (
        <CancelSubmissionDialog
          datas={fValues.plans}
          onCancel={handleOnClose}
          onSubmit={handleOnSubmit}
        />
      )}
    </React.Fragment>
  );
};
