export const onboardingEn = {
    manage: "Manage Admins / Whitelisted Internal Users",
    addAdmin: "Add Admin / Whitelisted Internal User",    
    productManage: "Manage Product Admins",
    addProductAdmin: "Add Product Admin",
    configureRetention: "Configure Retention",
    adminTypes: {
        all: "All Admins",
        tenant: "Tenant Admins",
        country: "Country",
        product: "Product Admins",
        memberFirm: "Member Firm",
        productGroup: "Product Group",
        whitelistedInternalUser: "Whitelisted Internal Users"
    },
    sortBy: "Sorted By",
    fieldSort: {
        userName: "User name",
        email: "Email",
        country: "Country",
        role: "Role"
    },
    table: {
        userName: "User name",
        role: "Role",
        email: "Email",
        country: "Country",
        actions: "Actions",
        noFound: "No users found",
        product: "Product"
    },
    deletePopup: {
        remove: "Remove user",
        areYouSure:"Are you sure you want to remove <1>{{name}}</1>?",
    },
    deletetenantadmin: 'You cannot remove yourself',
    adminCreation: {
        role: "Role",
        adminrole: 'Admin Role / Whitelisted User Role',
        email: "Email",
        exists: "A user with this email already exists.",
        WLUexists: "The team member is already added as a whitelisted user.",
        invalidMemberFirmUser: 'The user needs to be from the same member firm/country',
        tweakedResponseCode: 'Something wrong with the response',
        resourceNotFound: 'Resource not found',
        comments: "Comment",
        commentPlaceholder: 'Leave a comment here...',
        application: "Application",
        country: "Country",
        memberFirm: "Member Firm",
        addAdmin: "Add Admin / Whitelisted Internal User",
        addProductAdmin: "Add Product Admin",
        emailtext:"Select the team member you want to add. Search by first or last name (e.g., Smith or John) or by email ID (e.g., jsmith)",
        cancel: "Cancel",
        item: "Select Item",
        selectEmail: "Add user",
        selectApplication: "Choose Application",
        selectRole: "Select Role",
        info: "User Information",
        toastText: {
        1: 'Tenant Admin has been successfully added',
        3: 'Product Admin has been successfully added',
        7: 'Whitelisted Internal User has been successfully added .'
        },
        roles: {
            SuperAdmin: "Tenant Admin",
            Productgroupadmin: "Product Group Admin",
            ProductAdmin: "Product Admin",
            Memberfirmadmin: "Member Firm Admin",
            Countryadmin: "Country Admin",
            User: "User",
            WhitelistedInternalUser: "Whitelisted Internal User"
        },
        tabs: {
            submission:'Submissions',
            admin: 'Admin'
          }

    },
    cancelRetentionPopup: {
        title: "Confirm Cancellation",
        message: "Are you sure you want to cancel out of this process?"
    },

};