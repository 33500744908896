import axios from "axios";

import { smartFetch, getApiHost } from 'shared/utils';
import { BASE_HTTP_HEADERS } from 'shared/constants/api.consts';
import { USER_ROLES } from 'features/AdminPage/OnboardingPage/OnboardingFilters/OnboardingFilters';
import { LOCAL_STORAGE_EXTERNAL_USER_PRODUCT_KEY } from 'shared/constants/common.consts';
import { i18nService as i18n } from './i18n.service';


export class ApiService {
  private token: string = null;

  private request<T>(url: string, options: RequestInit = {}): Promise<T> {
    const headers: Record<string, string> = { ...BASE_HTTP_HEADERS };

    if (this.token !== null) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    return smartFetch<T>(url, {
      headers,
      ...options
    }) as Promise<T>;
  }

  setToken(token: string): void {
    this.token = token;
  }

  getCurrentUser(): Promise<DTO.User> {
    const product = localStorage.getItem(
      LOCAL_STORAGE_EXTERNAL_USER_PRODUCT_KEY
    )
      ? localStorage.getItem(LOCAL_STORAGE_EXTERNAL_USER_PRODUCT_KEY)
      : null;
    return this.request<DTO.User>(
      `${getApiHost('account')}/product/${product}/user/current`
    );
  }

  fetchLocaleList(): Promise<AP.DefaultLocale[]> {
    return this.request<AP.DefaultLocale[]>(`${getApiHost('system')}/locales`);
  }

  getDictionary(localeCode: string): Promise<any> {
    return this.request<any>(
      `${getApiHost('system')}/locale/${localeCode}/dictionary`
    );
  }

  fetchCountryCodes(): Promise<DTO.CountryCode[]> {
    return this.request<DTO.CountryCode[]>(`${getApiHost('system')}/countries`);
  }

  getProductInfo(code: string): Promise<DTO.Product> {
    return this.request<DTO.Product>(
      `${getApiHost('tenant')}/product/${code}/locale/${i18n.language()}`
    );
  }

  fetchProductCountries(code: string): Promise<DTO.Country[]> {
    return this.request<DTO.Country[]>(
      `${getApiHost(
        'tenant'
      )}/product/${code}/countries/locale/${i18n.language()}`
    );
  }

  fetchTermsOfUse(
    product: string,
    countryCode: string
  ): Promise<DTO.TermsOfUse> {
    return this.request<DTO.TermsOfUse>(
      `${getApiHost(
        'tenant'
      )}/product/${product}/country/${countryCode}/terms-of-use/locale/${i18n.language()}`
    );
  }

  fetchInternalTermsOfUse(
    product: string,
    countryCode: string
  ): Promise<DTO.TermsOfUse> {
    return this.request<DTO.TermsOfUse>(
      `${getApiHost(
        'tenant'
      )}/product/${product}/country/${countryCode}/internal-terms-of-use/locale/${i18n.language()}`
    );
  }

  fetchCountryStates(countryCode: string): Promise<DTO.State[]> {
    return this.request<DTO.State[]>(
      `${getApiHost(
        'system'
      )}/country/${countryCode}/states/locale/${i18n.language()}`
    );
  }

  fetchProductPlans(product: string,countryCode:string): Promise<DTO.RegistrationPlan[]> {

    return this.request<DTO.RegistrationPlan[]>(

      `${getApiHost(

        'tenant'

      )}/product/${product}/plans/locale/${countryCode}/${i18n.language()}`

    );

  }

  // eslint-disable-next-line class-methods-use-this
  getAssetUrl(doc: string): string {
    return `${getApiHost('system')}/locale/asset/${doc}`;
  }
  
  deleteAdmin = (email: string,productCode: string) => {
    return this.request<string>(
      `${getApiHost(
        'account'
      )}/product/${productCode}/admin/users/delete?email=${email}`,
      {
        method: 'DELETE'
      }
    );
  };

  fetchOnboardingUsers(
    product: string,
    pageSize: number,
    pageNumber: number,
    userFilters: DTO.UserFilters,
    sorter: DTO.Sorter,
    saveFilters: boolean,
    role: string
  ) {
    const requestBody: DTO.AdminPaginationRequest = {
      pageNumber,
      pageSize,
      filter: {
        ...userFilters,
        emails:
          // userFilters.emails &&
          // userFilters.emails.length &&
          // typeof userFilters.emails[0] !== 'string'
          //   ? userFilters.emails.map(userEmail => userEmail.email)
          //   : []
          userFilters.emails &&
            userFilters.emails.length
            ? userFilters.emails
            : []
      },
      saveFilters,
      sorter
    };
    const setLanguage = JSON.parse(localStorage.getItem('storeLanguage')) || i18n.language();
    return this.request<DTO.AdminPaginationResponse>(
      `${getApiHost('account')}/product/${role === USER_ROLES.SUPER_ADMIN ? 'all' : product}/admin/users/${setLanguage}`,
      {
        method: 'POST',
        body: JSON.stringify(requestBody)
      }
    );
  }

  fetchAdminRoles(productCode: string): Promise<DTO.AdminRole[]> {
    return this.request(
      `${getApiHost('account')}/product/admin/userroles`
    );
  }

  fetchProducts(): Promise<DTO.AppProduct[]> {
    return this.request(`${getApiHost('tenant')}/products/locale/${i18n.language()}`);
  }

  fetchAvaiableProducts(email: string): Promise<DTO.AppProduct[]> {
    return this.request(`${getApiHost('tenant')}/products/locale/${i18n.language()}/${email}`);
  }

  fetchMemberFirms(): Promise<DTO.ProductMemberFirm[]> {
    return this.request(`${getApiHost('tenant')}/memberfirms`);
  }

  fetchEmailAvailability( productCode: string, requestBody: DTO.ParamsSearchAdminsDto) {
    
    //product = null;
    return this.request(
      `${getApiHost('account')}/product/${productCode}/admin/users/checkbyemail`,
      {
        method : 'POST',
        body: JSON.stringify(requestBody)
      }
    );
  }

  createAdmin(productCode: string, requestBody: DTO.CreateAdminRequest) {
    return this.request(
      `${getApiHost('account')}/product/${productCode}/admin/users/create`,
      {
        method: 'POST',
        body: JSON.stringify(requestBody)
      }
    );
  }

  // eslint-disable-next-line class-methods-use-this
  fetchAdminsByEmail(productCode: string): string {
    return `/product/admin/search`;
  }

  getAuthToken = (adfsToken: string) => {
    return this.request<DTO.AuthToken>(`${getApiHost('account')}/auth/token`, {
      method: 'POST',
      body: JSON.stringify({ token: adfsToken })
    });
  };

  fetchSubscriptionDetails(
    product: string,
    email: string
  ): Promise<{
    plans: DTO.BasePlan[];
    userDetails: DTO.UserDetails;
  }> {
    return this.request<{
      plans: DTO.BasePlan[];
      userDetails: DTO.UserDetails;
    }>(`${getApiHost('account')}/product/${product}/accountdetails`, {
      body: JSON.stringify({ email }),
      method: 'POST'
    });
  }

  getRetentionDetails(requestBody: DTO.RetentionRequestDto) {
    return this.request<DTO.RetentionRequestDto>(`${getApiHost('account')}/getRetentionDetails`, {
      method: 'POST',
      body: JSON.stringify(requestBody)
    });
  };

  uploadAdditionUsers(data: FormData) { 
    return axios({
      method: 'POST', // or 'POST', 'PUT', etc.
      url: `${getApiHost('account')}/UploadBulkAdditionalUser`, data,
      headers: {
        'Authorization': `Bearer ${this.token}`,
        'Api-Version': 'v1'
      }
     });
  };

  deleteAdditionalUserExcel(id: string) {
    return this.request(
      `${getApiHost('account')}/UpdateCancelTaskStatus/${id}`,
      {
        method: 'POST',
      });
  }

  addingBulkAdditionalUsers(id: string) {
    return this.request(
      `${getApiHost('account')}/AddBulkAdditionalUsers/${id}`,
      {
        method: 'POST',
      });
  }

  getStatusForBulkAdditionalUsers(id: string) {
    return this.request(
      `${getApiHost('account')}/GetUploadStatus/${id}`,
      {
        method: 'POST',
      });
  }

  updateRetentionDetails(requestBody: DTO.UpdateRetentionDto) {
    return this.request<DTO.UpdateRetentionDto>(`${getApiHost('account')}/updateRetentionDetails`, {
      method: 'POST',
      body: JSON.stringify(requestBody)
    });
  };

  keepAlive = () => {
    return this.request<DTO.AuthToken>(
      `${getApiHost('account')}/auth/keepalive`,
      {
        method: 'POST'
      }
    );
  };

}

export const apiService = new ApiService();
