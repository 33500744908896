import React, { useMemo, useEffect } from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components';
import { getFromLocalStorage } from 'shared/utils/storage.utils';
import { SORT_DIRECTION } from 'shared/constants/common.consts';
import { DirectionButton } from 'components/DirectionButton/DirectionButton';
import { Option, getIconName } from '../Sorting/Sorting.helpers';
import styles from './AdminGrid.module.css';
import { USER_ROLES } from '../OnboardingPage/OnboardingFilters/OnboardingFilters';

interface Props<T> {
  sortBy?: string;
  direction?: AP.SortDirection;
  onSortChange?: (sortBy: string, sort: AP.SortDirection) => void;
  gridHeaders: any[];
  gridData: T[];
  rowDataRenderer: (data: T) => React.ReactElement;
  dataKey: keyof T;
  className?: string;
  offset?: number;
  footerRenderer?: ({
    columnsCount,
    rowStyles,
    rowClassName
  }: {
    columnsCount: number;
    rowStyles: Record<string, string>;
    rowClassName: string;
  }) => AP.Nullable<JSX.Element>;
  mainGrid?: boolean;
  isDetailsDialog?: boolean;
  productRoles: Array<any>;
  ifPageMIP?: boolean;
  userRole: string;
  isSubmissionRecord?:boolean;
}

export function AdminGrid<T>({
  direction,
  onSortChange,
  sortBy,
  gridHeaders,
  gridData,
  rowDataRenderer,
  dataKey,
  className = '',
  offset = null,
  footerRenderer = null,
  mainGrid,
  isDetailsDialog = false,
  ifPageMIP = false,
  userRole,
  isSubmissionRecord = false
}: Props<T>): JSX.Element {
  const { t } = useTranslation();

  const columnsCount = gridHeaders.length;
  const gridStyles = React.useMemo(
    () => ({
      gridTemplateColumns: `${
        offset > 0 ? `${offset}px` : ''
      } repeat(${columnsCount}, ${Math.floor(100 / columnsCount)}%)`
    }),
    [offset, columnsCount]
  );
  const detailsGridStyles = useMemo(
    () => ({
      gridTemplateColumns: 'repeat(6, 1fr)',
      columnGap: 15,
      alignItems: 'flex-start'
    }),
    []
  );

  const [flexTableStyles, setFlexTableStyles] = React.useState(gridStyles);

  React.useEffect((): void => {
    if (ifPageMIP) {
      setFlexTableStyles(gridStyles);
    }
    else if (isDetailsDialog) {
      setFlexTableStyles(detailsGridStyles);
      // return;
    } else {
      setFlexTableStyles(gridStyles);
    }
  }, [isDetailsDialog, detailsGridStyles, gridStyles, ifPageMIP]);

  const getHighLightStyles = (show: boolean, id: string) => {
    let newStyles = '';
    if(show) {
      newStyles =  styles.highlight;
    } 
    return newStyles;
  };

  const onSortingChangeCb = React.useCallback(
    (value: string, sortdirection) => {
      let sortingOrder: SORT_DIRECTION;
      if (value === sortBy) {
        switch (direction) {
          case '':
            sortingOrder = SORT_DIRECTION.DESC;
            break;
          case SORT_DIRECTION.DESC:
            sortingOrder = SORT_DIRECTION.ASC;
            break;
          case SORT_DIRECTION.ASC:
            sortingOrder = SORT_DIRECTION.DESC;
            break;
          default:
            sortingOrder = SORT_DIRECTION.ASC;
            break;
        }
      } else {
        switch (sortdirection) {
          case '':
            sortingOrder = SORT_DIRECTION.DESC;
            break;
          case SORT_DIRECTION.DESC:
            sortingOrder = SORT_DIRECTION.ASC;
            break;
          case SORT_DIRECTION.ASC:
            sortingOrder = SORT_DIRECTION.DESC;
            break;
          default:
            sortingOrder = SORT_DIRECTION.ASC;
            break;
        }
      }
      onSortChange(value, sortingOrder);
    },
    [sortBy, direction, onSortChange]
  );

  const showColumnForTenantAdmin = (label: any) => {
    if (
      (userRole === USER_ROLES.PRODUCT_ADMIN) ||
      (userRole === USER_ROLES.SUPER_ADMIN &&
        label === t('admin.grid.country') ||
        label === t('admin.grid.submissionDate')
      )
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={cs(styles.container, className)}>
      <div
        className={cs(
          styles.flexTable,
          styles.header,
          mainGrid && styles.mainHeader,
          mainGrid || isDetailsDialog ? styles.bgWhite : styles.submissionPlans,
          isDetailsDialog && styles.detailsGridHeader
        )}
        role='rowgroup'
        style={flexTableStyles}
      >
        {offset !== null && (
          <div key='offset' className={styles.flexRow} role='columnheader' />
        )}
        {gridHeaders.map((header: any, index: number) => (
          <div
            // because repeated headers "" / null are available to make some space
            // eslint-disable-next-line react/no-array-index-key
            key={isSubmissionRecord ? `${header?.label}-${index}` : `${header}-${index}`}
            aria-hidden={true}
            className={cs(
              mainGrid ? styles.mainGridHeader : styles.alignBottom,
              styles.flexRow,
              styles.columnheaders,
            )}
            role='columnheader'
            onClick={() => showColumnForTenantAdmin(t(header?.label))
              && isSubmissionRecord ?
              onSortingChangeCb(header?.value, header?.sortOrder)
              : {}}
          >
            {isSubmissionRecord ? t(header?.label) : header}
            {isSubmissionRecord
              && header
              && header?.sortOrder === ''
              && t(header?.label) !== t('admin.grid.submissionDetails')
              && showColumnForTenantAdmin(t(header?.label)) ? (
              <DirectionButton
                fill='black'
                isDisable={!header?.sortOrder}
                isReversed={header?.sortOrder === SORT_DIRECTION.ASC || header?.sortOrder === ''}
                name='firstArrow'
              />
            )
              : (
                isSubmissionRecord
                && header
                && t(header?.label) !== t('admin.grid.submissionDetails')
                && showColumnForTenantAdmin(t(header?.label)) && (
                  <DirectionButton
                    fill='black'
                    isDisable={!header?.sortOrder}
                    isReversed={header?.sortOrder === SORT_DIRECTION.ASC}
                    name={header?.sortOrder === SORT_DIRECTION.ASC ? 'firstArrow' : 'secondArrow'}
                  />
                )
              )
            }
          </div>
        ))}
      </div>

      {gridData.map(
        React.useCallback(
          (dataProps: T, index) => (
            <div
              key={`${String(dataProps[dataKey])} - ${index}`}
              className={cs(
                ifPageMIP ? styles.flexTableRowHeightMIP : styles.flexTableRowHeightAllProduct,
                styles.flexTable,
                styles.dataRow,
                isDetailsDialog && styles.detailsDataRow,
                (dataProps as unknown as DTO.SubmissionRecord)?.user?.email
                 === getFromLocalStorage('recentUserEmail') 
                 ? styles.highlight
                 : null
              )}
              id={(dataProps as unknown as DTO.SubmissionRecord)?.user?.email.toString()}
              role='rowgroup'
              style={flexTableStyles}
            >
              {rowDataRenderer(dataProps)}
            </div>
          ),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [dataKey, flexTableStyles, rowDataRenderer, isDetailsDialog, ifPageMIP]
        )
      )}

      {footerRenderer !== null &&
        footerRenderer({
          columnsCount,
          rowStyles: flexTableStyles,
          rowClassName: styles.flexTable
        })}
    </div>
  );
}
