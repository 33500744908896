import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useMutate } from 'restful-react';

import { selectHttpHeaders, getCurrentUser } from 'store/auth';
import { API_PATH } from 'shared/constants/api.consts';
import { reselectLocaleList, changeLanguage } from 'store/localize';
import {
  defaultLocale,
  defaultDateFormat,
  defaultNumberFormat
} from 'shared/constants/common.consts';
import { Button } from 'components/Button/Button';
import { Select } from 'components';
import dateFormatList from './DateFormat';
import numberFormatList from './NumberFormat';
import './ConfirmDialog.css';

interface Props {
  title: string;
  subTitle: string;
  subTitle2: string;
  subTitle3: string;
  okBtnName: string;
  cancelBtnName: string;
  showModal: boolean;
  close: any;
  firstLogin?: boolean;
  description?: string;
  userLanguage?: string;
  dateFormat?: string;
  numberFormat?: string;
}

export function ConfirmDialog({
  title,
  subTitle,
  subTitle2,
  subTitle3,
  description,
  okBtnName,
  cancelBtnName,
  showModal,
  close,
  firstLogin,
  userLanguage,
  dateFormat,
  numberFormat
}: Props): JSX.Element {
  const modalClass = ' confirm-dialog';
  const dispatch = useDispatch();
  const httpHeaders = useSelector(selectHttpHeaders);
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLocale);
  const [selectedDateFormat, setSelectedDateFormat] = useState(
    defaultDateFormat
  );
  const [selectedNumberFormat, setSelectedNumberFormat] = useState(
    defaultNumberFormat
  );
  const languageLists = useSelector(reselectLocaleList);
  const [fallbackScenario, setFallbackScenario] = useState(false);

  const renderSelectLanguages = useCallback(
    ({ language }: any): JSX.Element => (
      <div>
        <span>{language}</span>
      </div>
    ),
    []
  );

  useEffect((): void => {
    if (dateFormat) setSelectedDateFormat(dateFormat);
    if (numberFormat) setSelectedNumberFormat(numberFormat);
  }, [dateFormat, numberFormat]);

  useEffect((): void => {
    if (firstLogin && !languageLists.length) {
      setFallbackScenario(true);
    }
  }, [firstLogin, fallbackScenario, languageLists]);

  const handleLanguageChange = (language: any): any => {
    setSelectedLanguage(language);
  };

  const dateChange = (value: any): any => {
    setSelectedDateFormat(value);
  };

  const numberChange = (value: any): any => {
    setSelectedNumberFormat(value);
  };

  const { mutate: SaveSettings } = useMutate<DTO.UserSettings>({
    verb: 'POST',
    path: API_PATH.SAVE_USER_PREFERENCE(),
    requestOptions: () => ({ headers: httpHeaders })
  });

  const handleConfirm = () => {
    SaveSettings({
      language: userLanguage || selectedLanguage.localeCode,
      dateFormat: selectedDateFormat,
      numberFormat: selectedNumberFormat
    }).then(() => dispatch(getCurrentUser()));

    dispatch(changeLanguage(userLanguage || selectedLanguage.localeCode));
    close();
  };

  return (
    <Modal
      backdrop='static'
      centered={true}
      className={modalClass}
      keyboard={false}
      show={showModal}
    >
      <img
        alt='modal illustration'
        className='illustration'
        src='../../images/modal-illustration-big.svg'
      />
      <Modal.Header>
        <div className='d-flex align-items-center justify-content-between w-100 modal-title'>
          <Modal.Title>{title}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex'>
          <div>
            {firstLogin && (
              <Fragment>
                <h4>{subTitle}</h4>
                <div className='d-flex mb-3'>
                  <Select<AP.DefaultLocale>
                    className='first-language mb-3'
                    isClearable={false}
                    isSearchable={false}
                    options={languageLists}
                    renderOption={renderSelectLanguages}
                    value={selectedLanguage}
                    withDropdownIndicator={true}
                    onChange={handleLanguageChange}
                  />
                  {fallbackScenario && (
                    <img
                      alt='warning'
                      className='warning-style mt-1 ml-3'
                      src='../../images/icon_warning_triangle.png'
                      title='Language settings are temporarily unavailable. Please, try again later.'
                    />
                  )}
                </div>
              </Fragment>
            )}

            <h4>{subTitle2}</h4>

            <div className='form-check form-check-inline'>
              <div className='check-control'>
                {dateFormatList.map(format => {
                  return (
                    <div key={format.format} className='mt-2'>
                      <input
                        className='form-check-input '
                        defaultChecked={
                          !firstLogin
                            ? format.format === selectedDateFormat
                            : format.format === defaultDateFormat
                        }
                        id={format.id}
                        name='dateFormat'
                        type='radio'
                        value={format.format}
                        onChange={() => dateChange(format.format)}
                      />
                      <label
                        className='form-check-label label-text ml-1'
                        htmlFor={format.id}
                      >
                        {format.label}
                        {/* {format.id == "df1" && <span> ({default})</span>} */}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>

            <h4 className='mt-3'>{subTitle3}</h4>

            <div className='form-check form-check-inline'>
              <div className='check-control'>
                {numberFormatList.map(format => {
                  return (
                    <div key={format.format} className='mt-2'>
                      <input
                        className='form-check-input '
                        defaultChecked={
                          !firstLogin
                            ? format.format === selectedNumberFormat
                            : format.format === defaultNumberFormat
                        }
                        id={format.id}
                        name='numberFormat'
                        type='radio'
                        value={format.format}
                        onChange={() => numberChange(format.format)}
                      />
                      <label
                        className='form-check-label label-text ml-1'
                        htmlFor={format.id}
                      >
                        {format.label}
                        {/* {format.id == "nf1" && <span> ({default})</span>} */}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            {firstLogin && (
              <div className='d-flex justify-content-between mt-2'>
                <span className='icon-lightbulb-special mt-4 mr-2' />
                <span className='change-settings-txt mt-3'>{description}</span>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        {!firstLogin && (
          <Button className='cancel-btn' onClick={close}>
            {cancelBtnName}
          </Button>
        )}

        <Button className='confirm-btn ml-auto' onClick={handleConfirm}>
          {okBtnName}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmDialog;
