import { useState, useEffect } from 'react';

export const useTimeoutBeforeLoader = (
  loadingControl: boolean,
  timeout?: number
) => {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    if (loadingControl) {
      setTimeout(() => setShowLoader(true), timeout || 400);
    } else if (!loadingControl && showLoader) {
      setShowLoader(false);
    }
  }, [loadingControl, showLoader, timeout]);

  return showLoader;
};
