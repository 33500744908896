/* eslint-disable import/no-default-export */
import accounting from 'accounting';

const formatNumber = (
  value: number,
  format: string,
  precision = 0
): number | string => {
  if (value === 0) {
    return value;
  }
  const thousandSeparator = format.charAt(1);
  const decimalSeparator = format.charAt(format.length - 3);
  return accounting.formatNumber(
    value,
    precision,
    thousandSeparator,
    decimalSeparator
  );
};

export default formatNumber;
