import { format } from 'date-fns';

import { DateLocale } from 'features/AdminPage/DateLocale';
import {
  SUBMISSION_PLAN_STATUS,
  PLAN_TYPE
} from 'shared/constants/submission.consts';
// import { DATE_FORMAT } from 'shared/constants/common.consts';

export const formatDuration = ({
  status,
  model,
  duration,
  performedAt,
  expiredAt,
  translation,
  DATE_FORMAT
}: Pick<
  DTO.SubmissionPlan,
  'status' | 'model' | 'duration' | 'performedAt' | 'expiredAt' | 'DATE_FORMAT'
> & { translation: (keyword: string) => string }): string => {
  switch (true) {
    case model === PLAN_TYPE.LICENSE ||
    (model === PLAN_TYPE.SUBSCRIPTION &&
      status === SUBMISSION_PLAN_STATUS.REJECTED):
    return '-';
    
    case status === SUBMISSION_PLAN_STATUS.PENDING &&
      duration !== null &&
      model === PLAN_TYPE.SUBSCRIPTION: {
      const isPlural = duration > 1;
      const monthsKeyword = isPlural
        ? translation('admin.plans.months')
        : translation('admin.plans.month');
      return `${duration} ${monthsKeyword}`;
    }

    case model === PLAN_TYPE.SUBSCRIPTION && duration === null:
      return translation('admin.plans.noEndDate');

    default:
      return performedAt && expiredAt
        ? `${
            DATE_FORMAT !== 'dd MMMM yyyy'
              ? format(new Date(performedAt), DATE_FORMAT)
              : DateLocale(format(new Date(performedAt), DATE_FORMAT))
          } - ${
            DATE_FORMAT !== 'dd MMMM yyyy'
              ? format(new Date(expiredAt), DATE_FORMAT)
              : DateLocale(format(new Date(expiredAt), DATE_FORMAT))
          }`
        : '-';
  }
};
