import React from 'react';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';

import { Button, Icon } from 'components';
import styles from './FiltersActions.module.css';

interface Props {
  onClose: () => void;
  onApply: () => void;
  addNewFilter: () => any;
  disableFilter: boolean;
}

export function FiltersActions({
  onClose,
  onApply,
  addNewFilter,
  disableFilter,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <div>
        <Button
          className={cs(styles.button, styles.addFilter)}
          disabled={disableFilter}
          size='sm'
          variant='link'
          onClick={addNewFilter}
        >
          <Icon
            className={styles.selectedOption}
            name='plus-special'
            size={16}
          />
          {t('actions.addFilter')}
        </Button>
      </div>
      <div>
        <Button
          className={styles.button}
          size='sm'
          variant='link'
          onClick={onClose}
        >
          {t('actions.cancel')}
        </Button>
        <Button
          className={cs(styles.button, styles.apply)}
          size='sm'
          onClick={onApply}>
          {t('actions.apply')}
        </Button>
      </div>
    </div>
  );
}
