import React from 'react';
import cs from 'classnames';

import { Footer } from 'features/Footer/Footer';
import styles from './BaseLayout.module.css';

interface Props {
  className?: string;
}

export const BaseLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className = ''
}) => (
  <React.Fragment>
    <div className={cs(styles.wrapper, className)}>
      <main className={styles.content}>{children}</main>
    </div>
    <Footer />
  </React.Fragment>
);
