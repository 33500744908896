import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Badge } from 'components';
import { FiltersForm } from './FiltersForm/FiltersForm';
import styles from './SubmissionsFilters.module.css';

interface Props<T> {
  appliedFilters: number;
  filters: any;
  onChange: (filters: T) => void;
  appendTo: React.RefObject<HTMLElement>;
  onClose?: (toggle: boolean) => void;
  countryOptionValue: AP.CountryOption;
  fetchAdminCountries?: boolean;
  showFilters: DTO.FilterFields[];
  onApply: VoidFunction;
}

export function SubmissionsFilters<T>({
  appliedFilters,
  filters,
  onChange,
  appendTo,
  onApply,
  showFilters,
  fetchAdminCountries,
  countryOptionValue,
}: Props<T>): JSX.Element {
  const { t } = useTranslation();
  const [opened, setOpened] = React.useState<boolean>(false);

  const handleOnChange = React.useCallback(
    (updatedValue: any, toggle?: boolean): void => {
      onChange({ ...filters, ...updatedValue });
      if (toggle) {
        setOpened((prev: boolean) => !prev);
      }
    },
    [filters, onChange]
  );

  const handleOnclose = React.useCallback((toggle: boolean): void => {
    setOpened(toggle);
  }, []);

  React.useEffect(()=>{
  setOpened(false);
   }, [filters.role]); 

  const panel = opened ? (
    <FiltersForm
      appendTo={appendTo}
      countryOptionValue={countryOptionValue}
      showFilters={showFilters}
      value={filters}
      onChange={handleOnChange}
      onClose={handleOnclose}
    />
  ) : null;

  return (
    <React.Fragment>
      <Button
        className={styles.toggle}
        size='sm'
        variant='link'
        onClick={React.useCallback(
          (): any => setOpened((prev: boolean) => !prev),
          []
        )}
      >
        {appliedFilters > 0 && (
          <Badge className={styles.badge} pill={true} variant='danger'>
            {appliedFilters}
          </Badge>
        )}
        <Icon name='filter-special' size={22} />
        {t('actions.filters')}
      </Button>
      {panel}
    </React.Fragment>
  );
}
