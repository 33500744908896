import React, { useEffect, FC } from 'react';
import { createPortal } from 'react-dom';

import { SpinnerImage } from './SpinnerImage/SpinnerImage';
import { SpinnerBackdrop } from './SpinnerBackdrop/SpinnerBackdrop';
import { SpinnerSize } from './Spinner.helpers';
import styles from './Spinner.module.css';

interface Props {
  shouldShow?: boolean;
  shouldFill?: boolean;
  size?: SpinnerSize;
  className?: string;
}

export const Spinner: FC<Props> = ({
  shouldShow = true,
  shouldFill = true,
  size = SpinnerSize.Md,
  className = ''
}) => {
  useEffect(() => {
    if (shouldShow && shouldFill) {
      document.body.classList.add(styles.overflowHidden);
    } else {
      document.body.classList.remove(styles.overflowHidden);
    }

    return (): void => {
      document.body.classList.remove(styles.overflowHidden);
    };
  }, [shouldShow, shouldFill]);

  if (!shouldShow) {
    return null;
  }

  if (shouldFill) {
    return createPortal(
      <SpinnerBackdrop className={className}>
        <SpinnerImage size={size} />
      </SpinnerBackdrop>,
      document.body
    );
  }

  return (
    <div className={className}>
      <SpinnerImage size={size} />
    </div>
  );
};
