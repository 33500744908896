import React, { FC } from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectProductCode } from 'store/product';
import styles from './SidebarFooter.module.css';
import { ADDITIONAL_USER_STATE, UserState } from '../UserList/UserList.helpers';
import stylesButton from '../../../../../../components/Button/Button.module.css';
import { Sidebar } from '../../../../../../components';
import ExcelIcon from '../../../../../../assets/images/ExcelIcon.svg';

interface Props {
  userState: UserState;
  onChangePanel: (a:any) => void;
  onClearState: () => void;
  onRemoveUser: () => void;
  onUpdateUser: () => void;
  addUserText: string;
  canAddAdditionalUsers: boolean;
}

export const SidebarFooter: FC<Props> = ({
  userState,
  onChangePanel,
  onClearState,
  onRemoveUser,
  onUpdateUser,
  addUserText,
  canAddAdditionalUsers
}) => {
  const { t } = useTranslation();
  const productCode = useSelector(selectProductCode);

  return (
    <Sidebar.Footer>
      <div className={styles.footer}>
        {userState.type === ADDITIONAL_USER_STATE.MAIN && (
          <React.Fragment>
           { productCode === 'sbp' && (      
           <div className={styles.footerButton}>
             <button
                className={cs(
                  'btn btn-outline-primary',
                  stylesButton.new_btn,
                  stylesButton.new_primary_btn
                )}
                disabled={!canAddAdditionalUsers}
                type='button'
                onClick={()=>{onChangePanel("X");}}
              >
                <img alt="Excel" className={styles.excelIcon} src={ExcelIcon}/>
                {t('admin.adduser.buttonMul')}
              </button>
              </div>
           )}

            <div>{addUserText}</div>
            <div>
              <button
                className={cs(
                  'btn btn-primary',
                  stylesButton.new_btn,
                  stylesButton.new_primary_btn
                )}
                disabled={!canAddAdditionalUsers}
                type='button'
                onClick={onChangePanel}
              >
             {productCode==='dra'?  t('admin.adduser.buttonAddUser'): t('admin.adduser.button')}
              </button>
            </div>
          </React.Fragment>
        )}
        {userState.type === ADDITIONAL_USER_STATE.REMOVE && (
          <React.Fragment>
            <div>
              <button
                className={cs(
                  'btn',
                  stylesButton.new_btn,
                  stylesButton.new_cancel_btn
                )}
                type='button'
                onClick={onClearState}
              >
                {t('actions.cancel')}
              </button>
            </div>
            <div>
              <button
                className={cs('btn btn-danger', stylesButton.new_btn)}
                type='button'
                onClick={onRemoveUser}
              >
                {t('actions.remove')}
              </button>
            </div>
          </React.Fragment>
        )}
        {userState.type === ADDITIONAL_USER_STATE.UPDATE && (
          <div className={styles.update_footer_div}>
            <React.Fragment>
              <div>
                <button
                  className={cs(
                    'btn',
                    stylesButton.new_btn,
                    stylesButton.new_cancel_btn
                  )}
                  type='button'
                  onClick={onClearState}
                >
                  {t('actions.cancel')}
                </button>
              </div>
              <div>
                <button
                  className={cs(
                    'btn btn-primary',
                    stylesButton.new_btn,
                    stylesButton.new_confirm_btn
                  )}
                  type='button'
                  onClick={onUpdateUser}
                >
                  {t('actions.confirm')}
                </button>
              </div>
            </React.Fragment>
          </div>
        )}
      </div>
    </Sidebar.Footer>
  );
};
