export const SET_LOCALE = '@localize/SET_LOCALE';
export const FETCH_LOCALE_LIST = '@localize/FETCH_LOCALE_LIST';

export type LocaleCode = AP.LocaleCode;

export type LocaleList = Array<AP.DefaultLocale>;

export type LocaleState = Readonly<{
  defaultLocale: AP.DefaultLocale;
  localeCode: AP.LocaleCode;
  localeList: LocaleList;
}>;

export type FetchLocaleList = Store.PaylodedAction<LocaleList>;
export type FetchLocaleList$ = Store.PaylodedAction<
  Promise<AP.DefaultLocale[]>
>;
export type FetchLocaleCode = Store.PaylodedAction<any>;
export type FetchLocaleCode$ = Store.PaylodedAction<Promise<any>>;

export type SetLocaleAction = Store.PaylodedAction<any>;
export type SetLocaleAction$ = Store.PaylodedAction<Promise<any>>;

export type LocalizeActionTypes = SetLocaleAction | FetchLocaleList;
