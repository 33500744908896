import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutate } from 'restful-react';

import { removeFromLocalStorage, setToLocalStorage } from 'shared/utils/storage.utils';
import { useOnMount } from 'shared/hooks';
import { setSubscriptionDetails } from 'store/updateSubscription';
import { selectUpdatedPrimaryId, setUpdatedPrimaryUser } from 'store/global';
import { selectProductCode } from 'store/product';
import { API_PATH } from 'shared/constants/api.consts';
import { reselectUser, selectHttpHeaders } from 'store/auth';
import { SUBMISSION_TYPE } from 'shared/constants/submission.consts';
import { AdminContext } from 'features/AdminPage/AdminPageContent/AdminPageContent.helpers';
import { errorException } from 'shared/utils/AppInsights';
import { Submission } from './Submission';
import { SubmissionPlanToggler } from './SubmissionPlanToggler/SubmissionPlanToggler';
import { DetailedInformationDialog } from '../dialogs/DetailedInformationDialog/DetailedInformationDialog';
import { RegistrationCommentDialog } from '../dialogs/RegistrationCommentDialog/RegistrationCommentDialog';
import { SubmissionPlansGrid } from './SubmissionPlansGrid/SubmissionPlansGrid';
import { AdditionalUsersSidebar } from './AdditionalUsersSidebar/AdditionalUsersSidebar';
import styles from './Submission.module.css';
import { CompanyEditDialog } from '../dialogs/CompanyEditDialog/CompanyEditDialog';

interface Props {
  submission: DTO.SubmissionRecord;
  productRoles: Array<any>;
  showUserIcon: boolean;
  handleGetGridData: () => Promise<void>;
}

export const SubmissionContainer: React.FC<Props> = ({
  submission,
  productRoles,
  showUserIcon,
  handleGetGridData,
}) => {
  const { type } = React.useContext(AdminContext);
  const { organization } = submission;
  const user = useSelector(reselectUser);
  const httpHeaders = useSelector(selectHttpHeaders);
  const productCode = useSelector(selectProductCode);
  const dispatch = useDispatch();

  const { mutate: UpdateCompanyName } = useMutate({
    verb: 'POST',
    path: API_PATH.UPDATE_COMPANY_NAME(productCode),
    requestOptions: () => ({ headers: httpHeaders })
  });

  const [isOpenPlanManagement, togglePlanManagement] = React.useState<boolean>(
    false
  );
  const [isOpenCommentDialog, setOpenCommentDialog] = React.useState<boolean>(
    false
  );
  const [isOpenDetailsDialog, setOpenDetailsDialog] = React.useState<boolean>(
    false
  );
  const [isOpenUsersSidebar, setOpenUsersSidebar] = React.useState<boolean>(
    false
  );

  const [isEnableEdit, setisEnableEdit] = React.useState<boolean>(false);  
  const [editName, seteditName] = React.useState<string>(organization.legalName);
  const [isOpenEditCompanyDialog, setOpenEditCompanyDialog] = React.useState<
  boolean
  >(false);

  const updatedPrimaryId = useSelector(selectUpdatedPrimaryId);
  useOnMount(() => {
    if (updatedPrimaryId === +submission.displayId) {
      setOpenUsersSidebar(true);
      dispatch(setUpdatedPrimaryUser(null));
    }
  });

  const handleOpenCommentDialog = React.useCallback((): void => {
    setOpenCommentDialog(true);
  }, []);
  const handleCloseCommentDialog = React.useCallback((): void => {
    setOpenCommentDialog(false);
  }, []);

  const handleOpenDetailsDialog = React.useCallback((): void => {
    setOpenDetailsDialog(true);
  }, []);
  const handleCloseDetailsDialog = React.useCallback((): void => {
    setOpenDetailsDialog(false);
  }, []);

  const handleOpenUsersSidebar = React.useCallback((): void => {
    setOpenUsersSidebar(true);
    // created blank subscrition detail object and dispatching so that 
    // we can use existing redux store to get organization 
    // information from subscription object in UserForm.helper.tsx
    const subscription: {
      plans: DTO.BasePlan[];
      userDetails: DTO.UserDetails;
      states: DTO.State[];
    } = {
      plans: [],
      userDetails: {
        displayId: null,
        referral: null,
        performedBy: null,
        firstName: null,
        lastName: null,
        isPending: false,
        country: {
          code: '',
          memberFirmId: '',
          name: '',
          l10NKey: '',
        },
        user: null,
        comment: null,
        organization: {
        legalName: organization.legalName,
        parentLegalName: organization.parentLegalName || '',
        department: organization.department || '',
        address: organization.address,
        city: organization.city,
        stateL10NKey:  organization.stateL10NKey,
        state: organization.state,
        stateId: organization.stateId,
        postalCode: organization.postalCode,
        deloitteAuditClientType: organization.deloitteAuditClientType,
        isDeloitteAuditClient: 1
        },
      },
      states: [
        {
          id: 0, 
          code: '',
          name: ''
        }
      ],
    };
    dispatch(setSubscriptionDetails(subscription));
  }, [organization, dispatch]);

  const handleCloseUsersSidebar = React.useCallback((): void => {
    setOpenUsersSidebar(false);
  }, []);

  const handleCompanyEditMode = React.useCallback((): void => {
    setisEnableEdit(true);
  }, []);

  const handleEditChange = (e : any) : void => {
    seteditName(e.target.value);
  };

  const handleConfirmEdit = React.useCallback((): Promise<void> => {
    setisEnableEdit(false);
    if (editName.trim()) {
      return UpdateCompanyName({
        ProductCode: productCode,
        CompanyId: organization.companyId,
        LegalName: editName,
        Status: type === SUBMISSION_TYPE.PENDING ? 'Pending' : ''
      })
        .then(res => {
          if (res.companyId === organization.companyId) {
            handleGetGridData().catch((err) => {
              // AppInsights Error Exception
              errorException(`${err}, File reference: Submission.container.tsx in handleConfirmEdit function`);

              console.error(err);
            });
          };
        }).catch(err => {
          // AppInsights Error Exception
          errorException(`${err}, File reference: Submission.container.tsx in handleConfirmEdit function catch`);

          console.error(err);
        });
    }
    return null;
  }, [editName, handleGetGridData, UpdateCompanyName, organization.companyId, productCode, type]);

  const handleCancelEdit = React.useCallback((): void => {
    setisEnableEdit(false);
    seteditName(organization.legalName);
  }, [organization.legalName]);

  useEffect(() => {
    seteditName(organization.legalName);
  }, [organization.legalName]);

  const handleOpenEditCompanyDialog = React.useCallback((): void => {
    if (organization.legalName !== editName.trim()) {
      setOpenEditCompanyDialog(true);
    } else {
      handleCancelEdit();
    }
  }, [editName, handleCancelEdit, organization.legalName]);

  const handleCloseEditCompanyDialog = React.useCallback((): void => {
    setOpenEditCompanyDialog(false);
    handleCancelEdit();
  }, [handleCancelEdit]);

  // on toggler click when submission details are open
  // remove highlight css from active plan
  // find exact css classname with partially matched class
  // then remove that element with exact classname
  useEffect(() => {
    if(isOpenPlanManagement && document.querySelectorAll('[class*="AdminGrid_highlight"]')[0]) {
      const classlists = document.querySelectorAll('[class*="AdminGrid_highlight"]')[0].classList;
      let fullClassName = '';
      /*eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }]*/
      for(let i = 0; i < classlists.length; i++) {
        if(classlists[i].indexOf('AdminGrid_highlight') > -1) {
          fullClassName = classlists[i];
          break;
        }
      }
      if(document.getElementsByClassName(fullClassName)[0]) {
        document.getElementsByClassName(fullClassName)[0].classList.remove(fullClassName);
      }
    }
  }, [isOpenPlanManagement]);
  
  return (
    <React.Fragment>
      <SubmissionPlanToggler
        isOpen={isOpenPlanManagement}
        onToggle={React.useCallback((): void => {
          togglePlanManagement((prevOpenState: boolean) => !prevOpenState);
          removeFromLocalStorage('recentUserEmail');
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])}
      />

      <Submission
        DATE_FORMAT={user.profileSettings.dateFormat}
        editLegalName={editName}
        isEnableEdit={isEnableEdit}
        showUserIcon={showUserIcon}
        submission={submission}
        onCancelEdit={handleCancelEdit}
        onChangeEditMode={handleCompanyEditMode}
        onCommentClick={handleOpenCommentDialog}
        onConfirmEdit={handleOpenEditCompanyDialog}
        onDetailsClick={handleOpenDetailsDialog}
        onStartEditing={handleEditChange}
        onUsersclick={handleOpenUsersSidebar}
      />

      {isOpenPlanManagement && (
        <SubmissionPlansGrid
          className={styles.plansGrid}
          email={submission.user.email}
          enablePlanControl={type === SUBMISSION_TYPE.PENDING}
          plans={submission.plans}
          productRoles={productRoles}
          updatePlanControl={!(type === SUBMISSION_TYPE.PENDING)}
        />
      )}

      {isOpenDetailsDialog && (
        <DetailedInformationDialog
          productRoles={productRoles}
          submission={submission}
          updatePlanControl={false}
          onClose={handleCloseDetailsDialog}
        />
      )}

      {isOpenCommentDialog && (
        <RegistrationCommentDialog
          DATE_FORMAT={user.profileSettings.dateFormat}
          submission={submission}
          onClose={handleCloseCommentDialog}
        />
      )}
      {isOpenUsersSidebar && (
        <AdditionalUsersSidebar
          productRoles={productRoles}
          submission={submission}
          onCloseSidebar={handleCloseUsersSidebar}
        />
      )}
      {isOpenEditCompanyDialog && (
        <CompanyEditDialog
          onCancel={handleCloseEditCompanyDialog}
          onSubmit={handleConfirmEdit}
        />
      )}
    </React.Fragment>
  );
};
