import { withFormik } from 'formik';

import { noop } from 'shared/utils';

export interface AddAdminFormValues {
  role: DTO.AdminRole;
  email: DTO.UserEmail;
  products: string[];
  memberFirm: DTO.ProductMemberFirm;
  comments: string;
  countries: string[];
}

export interface AddAdminFormProps {
  adminRoles: DTO.AdminRole[];
  comments: string;
  onCancel?: () => void;
}

export const AddAdminFormEnhancer = withFormik<
  AddAdminFormProps,
  AddAdminFormValues
>({
  mapPropsToValues: () => ({
    role: null,
    email: null,
    products: null,
    memberFirm: null,
    comments: null,
    countries:null
  }),
  handleSubmit: noop
});
