import { Reducer } from 'redux';

import {
  GlobalActionTypes,
  GlobalState,
  SET_UPDATED_PRIMARY_USER,
  setUpdatedPrimaryUserAction
} from './global.types';

const initialState: GlobalState = {
  updatedPrimaryId: null
};

export const globalReducer: Reducer<GlobalState, GlobalActionTypes> = (
  state: GlobalState = initialState,
  action: GlobalActionTypes
): GlobalState => {
  switch (action.type) {
    case SET_UPDATED_PRIMARY_USER: {
      const updatedPrimaryId = (action as setUpdatedPrimaryUserAction).payload;
      return {
        ...state,
        updatedPrimaryId
      };
    }

    default:
      return state;
  }
};
