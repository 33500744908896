import React from 'react';
import { useTranslation } from 'react-i18next';

import { noop } from 'shared/utils';
import { Button } from 'components';
import styles from './AdminPageHeader.module.css';
import { UserProductsSelect } from '../UserProductsSelect/UserProductsSelect';

interface Props {
  disabledExport?: boolean;
  onExport?: () => void;
}
export const AdminHeader: React.FC<Props> = ({
  disabledExport = false,
  onExport = noop
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h1>{t('admin.tabs.submission')}</h1>
      <UserProductsSelect />
      <Button disabled={disabledExport} thin={true} onClick={onExport}>
        {t('actions.csvExport')}
      </Button>
    </div>
  );
};
