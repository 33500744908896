import React, { PropsWithChildren, FC } from 'react';
import { Card } from 'react-bootstrap';

import styles from './CardHeader.module.css';

export interface Props {
  children: React.ReactChild | React.ReactChild[];
}

export const CardHeader: FC<PropsWithChildren<Props>> = ({ children }) => {
  return (
    <Card.Title className={styles.title}>
      <h2>{children}</h2>
    </Card.Title>
  );
};
