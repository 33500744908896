import React from 'react';
import ReactSelect from 'react-select';
import { ValueType, Theme } from 'react-select/src/types';
import get from 'lodash/get';
import cs from 'classnames';
import { SelectComponents } from 'react-select/src/components';

import { noop } from 'shared/utils';
import { Input } from './Input/Input';
import { ClearIndicator } from './ClearIndicator/ClearIndicator';
import { Placeholder } from './Placeholder/Placeholder';
import { ControlComponent } from './Control/Control';
import { DropdownIndicator } from './DropdownIndicator/DropdownIndicator';
import { Option } from './Option/Option';
import { DefaultSelectOption } from './Select.helpers';
import styles from './Select.module.css';

interface Props<T> {
  value?: T;
  options: T[];
  placeholder?: string;
  placeholderPrefix?: JSX.Element;
  renderOption: (item: T) => JSX.Element;
  onChange?: (value: T) => void;
  getOptionValue?: (option: T) => string;
  getOptionLabel?: (option: T) => string;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isOptionDisabled?: (option: T) => boolean;
  withDropdownIndicator?: boolean;
  className?: string;
  additionalThemeProps?: Theme;
  OptionComponent?: typeof Option;
  isMulti?: any;
  onMenuClose?: () => void;
}

export function Select<T = DefaultSelectOption>({
  value,
  onChange = noop,
  options,
  renderOption,
  placeholder,
  placeholderPrefix,
  getOptionValue = (option: T): string => get(option, 'value'),
  getOptionLabel = (option: T): string => get(option, 'label'),
  isClearable = true,
  isSearchable = true,
  isDisabled = false,
  isOptionDisabled = () => false,
  withDropdownIndicator = false,
  className,
  additionalThemeProps = {} as Theme,
  OptionComponent = Option,
  isMulti,
  onMenuClose = noop
}: Props<T>): JSX.Element {
  const handleOnChange = React.useCallback(
    (item: ValueType<T, any>) => onChange(item as T),
    [onChange]
  );

  const [components, setComponents] = React.useState<Partial<SelectComponents<T, any>>>({
    Placeholder: Placeholder<T>(placeholder, placeholderPrefix),
    ClearIndicator: ClearIndicator(),
    DropdownIndicator: withDropdownIndicator ? DropdownIndicator : null,
    IndicatorSeparator: null,
    Input: Input(placeholderPrefix),
    Control: ControlComponent<T>(),
    Option: OptionComponent
  });

  React.useEffect(() => {
    setComponents(state => {
      return {
        ...state,
        Placeholder: Placeholder<T>(placeholder, placeholderPrefix),
        Input: Input(placeholderPrefix)
      };
    });
  }, [placeholder, placeholderPrefix]);
  
  return (
    <ReactSelect<T>
      className={cs(styles.customSelect, className)}
      components={components}
      formatOptionLabel={renderOption}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isMulti={isMulti}
      isOptionDisabled={isOptionDisabled}
      isSearchable={isSearchable}
      menuPlacement='auto'
      options={options}
      theme={React.useCallback(
        (theme: Theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...get(theme, 'colors'),
            primary25: '#EBF8FD',
            primary: '#007CB0'
          },
          ...additionalThemeProps
        }),
        [additionalThemeProps]
      )}
      value={value}
      onChange={handleOnChange}
      onMenuClose={onMenuClose}
    />
  );
}
