import React, { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';

import { Footer } from 'features/Footer/Footer';
import { ConfirmDialog } from 'components';
import { reselectUser, selectIsFirstLogin } from 'store/auth';
import { AppUserNav } from 'features/AppLayout';
import { confirmDialogConfig } from '../AdminPage';
import { OnboardingPage } from './OnboardingPage';
import { UserMenu } from '../UserMenu/UserMenu';
import { UserProductsSelect } from '../UserProductsSelect/UserProductsSelect';
import { USER_ROLES } from './OnboardingFilters/OnboardingFilters';
import styles from './OnboardingPage.module.css';

export const OnboardingPageWithNav: FC = () => {
  const user = useSelector(reselectUser);
  const isFirstLogin = useSelector(selectIsFirstLogin);
  const [showSettings, setShowSettings] = React.useState<boolean>(true);

  const closeModal = () => {
    setShowSettings(false);
  };
  return (
    <Fragment>
      {isFirstLogin && (
        <ConfirmDialog
          cancelBtnName={confirmDialogConfig.closeButtonName}
          close={closeModal}
          description={confirmDialogConfig.description}
          firstLogin={true}
          okBtnName={confirmDialogConfig.okBtnName}
          showModal={showSettings}
          subTitle={confirmDialogConfig.subTitle}
          subTitle2={confirmDialogConfig.subTitle2}
          subTitle3={confirmDialogConfig.subTitle3}
          title={confirmDialogConfig.title}
        />
      )}
      {user.profileSettings && (
        <React.Fragment>
          <AppUserNav>
            {/* {user.role !== USER_ROLES.SUPER_ADMIN && <UserProductsSelect />} */}
            <UserMenu />
          </AppUserNav>
          <OnboardingPage />
          <div className={styles.footer_wrapper}>
            <Footer />
          </div>
          {/* <Footer /> */}
        </React.Fragment>
      )}
    </Fragment>
  );
};
