import React from 'react';
import cs from 'classnames';

import styles from '../Spinner.module.css';

interface Props {
  className?: string;
}

export const SpinnerBackdrop: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className
}) => <div className={cs(styles.spinnerBackdrop, className)}>{children}</div>;
