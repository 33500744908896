import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { selectHasUser, selectHasUserExpiredToken } from 'store/auth';
import {
  getProductCodeFromExternalPath,
  getCountryCodeFromPath
} from 'shared/utils';
import { LOCAL_STORAGE_EXTERNAL_USER_PRODUCT_KEY } from 'shared/constants/common.consts';
import { Spinner } from 'components';
import { APP_PATH } from '../Routes.helpers';

const TermsOfUseExternalPage = React.lazy(() =>
  import('features/RegistrationPage/steps/TermsOfUse/TermsOfUseExternal').then(
    ({ TermsOfUseExternal: TermsOfUseComponent }) => ({
      default: TermsOfUseComponent
    })
  )
);

const TermsOfUseInternalPage = React.lazy(() =>
  import('features/RegistrationPage/steps/TermsOfUse/TermsOfUseInternal').then(
    ({ TermsOfUseInternal: TermsOfUseComponent }) => ({
      default: TermsOfUseComponent
    })
  )
);

export const TermsOfUseRoutes = (): JSX.Element => {
  const { pathname } = useLocation();
  const hasUserExpiredToken = useSelector(selectHasUserExpiredToken);
  const hasUser = useSelector(selectHasUser);
  const country = getCountryCodeFromPath(pathname);
  const product = getProductCodeFromExternalPath(pathname);
  const redirectUrlByPermission = React.useMemo((): string => {
    localStorage.setItem(LOCAL_STORAGE_EXTERNAL_USER_PRODUCT_KEY, product);
    //TODO: move the logic with expired token to getCurrentUser()
    if (!hasUser || hasUserExpiredToken) {
      // TODO: logout here
      return APP_PATH.ACCESS_DENIED;
    }
    return `${APP_PATH.TERMS_OF_USE_ROOT}/${product}/${country}`;
  }, [product, hasUser, hasUserExpiredToken, country]);

  return (
    <Switch>
      <Route
        exact={true}
        path={APP_PATH.EXTERNAL_TERMS_OF_USE}
        render={React.useCallback(() => {
          const shouldRedirect = !hasUser;

          if (shouldRedirect) {
            return <Redirect to={redirectUrlByPermission} />;
          }
          return (
            <React.Suspense fallback={<Spinner />}>
              <TermsOfUseExternalPage />
            </React.Suspense>
          );
        }, [redirectUrlByPermission, hasUser])}
      />
      <Route
        exact={true}
        path={APP_PATH.INTERNAL_TERMS_OF_USE}
        render={React.useCallback(() => {
          const shouldRedirect = !hasUser;

          if (shouldRedirect) {
            return <Redirect to={redirectUrlByPermission} />;
          }
          return (
            <React.Suspense fallback={<Spinner />}>
              <TermsOfUseInternalPage />
            </React.Suspense>
          );
        }, [redirectUrlByPermission, hasUser])}
      />

      <Redirect from={APP_PATH.WILDCARD} to={APP_PATH.ROOT} />
    </Switch>
  );
};
