import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import { reselectUser } from 'store/auth';
import { DateLocale } from 'features/AdminPage/DateLocale';
import { Detail } from '../Detail/Detail';

interface Props {
  submission: DTO.SubmissionRecord;
  className?: string;
}

export const Details: FC<Props> = ({
  className = '',
  submission: {
    displayId,
    country: { name: countryName },
    organization: { legalName, state, address, city, postalCode },
    user: { firstName, lastName, email, submissionDate }
  }
}) => {
  const { t } = useTranslation();
  const user = useSelector(reselectUser);
  const { dateFormat } = user.profileSettings;

  const rows = [
    { title: t('admin.grid.id'), value: displayId },
    { title: t('admin.grid.companyName'), value: legalName },
    {
      title: t('admin.grid.subscriberName'),
      value: `${firstName} ${lastName}`
    },
    { title: t('admin.grid.subscriberEmail'), value: email },
    {
      title: t('admin.grid.submissionDate'),
      value:
        dateFormat !== 'dd MMMM yyyy'
          ? format(new Date(submissionDate), dateFormat)
          : DateLocale(format(new Date(submissionDate), dateFormat))
    },
    { title: t('admin.grid.country'), value: countryName },
    { title: t('forms.stateProvinceCounty'), value: state },
    { title: t('forms.townCity'), value: city },
    { title: t('forms.address'), value: address },
    { title: t('forms.zipPostalCode'), value: postalCode }
  ];

  return (
    <article className={cs(className)}>
      {rows.map(({ title, value }) => (
        <Detail key={title} title={`${title}:`} value={value} />
      ))}
    </article>
  );
};
