export const dialogsEn = {
  detailedInformation: 'Detailed Information',
  registrationComment: 'Registration comment',
  processRegistration: 'Process Registration',
  termsofuse:'Terms of Use',
  termsofusesub:'Select the Terms of Use to view:',
  updateCompanyName: 'Update Company Name',
  cancelDialogItems: {
    title: 'Are you sure you wish to cancel the selected plan(s)?',
    notes: 'Cancellation Notes',
    placeholder: 'comments',
    actions: {
      cancel: 'CANCEL',
      cancelPlan: 'CANCEL PLAN'
    },
    parts: {
      goingToCancel: 'You are going to cancel',
      plans: 'plan(s)'
    }
  },
  minLicenseWarning: 'Available License cannot go below ‘0’.',
  minTokenLimit: 'Token Limit',
  maxLicenseWarning: 'The count of Available License should not exceed more than',
  exceededTokenLimit: 'Exceeded Token Limit',
  addLicense: 'Add License',
  purchasedLicenseZero: 'For one or all the Selected plans, the Purchased  License is ‘0’.  Please increment the count to proceed further.',
  requestedLicenseZero: 'For one or all the Selected plans, the Requested License is ‘0’.  Please increment the count to proceed further.',
  availableLicenseZero: 'For one or all the Selected plans, the Available License is ‘0’. The plan will move to inactive state. Are you sure you want to proceed further ?',
  configureSettings: 'Configure Settings',
  retentionFailureMsg: {
    title: 'Retention Date Alert',
    message: `Retention Date should be more than 30 days from today's date to trigger`
    },
    updateManageSubscription:
    {
        title: 'Select Action',
        message: 'To proceed manage your plans please select action '
    }
};
