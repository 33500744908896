import React from 'react';
import { Form } from 'react-bootstrap';
import get from 'lodash/get';

import styles from '../Form.module.css';

type useOptionCallback<T> = (option: T) => string;
export interface SelectProps {
  name: string;
  multiple?: boolean;
  placeholder?: string;
  span?: number;
  label?: string;
  optionLabel?: string;
  optionValue?: string;
  onChange?: (selection: any) => void;
  onInputChange?: (q: string) => void;
  validationErrMsg?: (err: string) => string;
}

export function useOptionProp<T>(label: string): useOptionCallback<T> {
  return React.useCallback((option): string => get(option, label), [label]);
}

export function useSelectLabel(label: string): JSX.Element {
  return React.useMemo(
    (): JSX.Element =>
      label ? <Form.Label className={styles.label}>{label}</Form.Label> : null,
    [label]
  );
}
