import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import cs from 'classnames';
import { Badge, Button } from 'react-bootstrap';

import { reselectUser } from 'store/auth';
import { Form, Icon, Select } from 'components';
import styles from './FilterBadge.module.css';
import circleCrossOmnia from '../../../../assets/images/circleCrossOmnia.svg';

interface Props {
    filterArray: Array<any>;
    removeFilter: (key: any, item: any) => void;
}

export function FilterBadge({
    filterArray,
    removeFilter
}: Props): JSX.Element {
    const { t } = useTranslation();
    const user = useSelector(reselectUser);


    return (
        <div className={styles.selectedFilter}>
            {filterArray.map((eachFilter: any, index: number) => {
                return (
                    <React.Fragment key={`${index.toString()}`}>
                        {eachFilter?.value.length > 0 &&
                            (eachFilter?.value.map((filterItem: any, i: any) => {
                                return (
                                    <Badge key={`${filterItem-i}`} className={styles.badge} pill={true} title={filterItem} variant='primary'>
                                        <Icon name='filter-special' size={22} />
                                        {filterItem}
                                        <button className={styles.removeFilterBtn} onClick={() => removeFilter(eachFilter.key, filterItem)}>
                                            <img
                                                alt='remove'
                                                src={circleCrossOmnia}
                                            />
                                        </button>
                                    </Badge>
                                );
                            })
                            )
                        }
                    </React.Fragment>
                );
            })}
        </div>
    );
}
