import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';

import { Dialog, Form } from 'components';
import {
  commentLengthLimit,
  registrationSubmissionDialogEnhancer,
  RegistrationSubmissionDialogProps,
  RegistrationSubmissionForm
} from './RegistrationSubmissionDialog.helpers';
import styles from './RegistrationSubmissionDialog.module.css';

const RegistrationSubmissionDialogComponent = (
  props: RegistrationSubmissionDialogProps &
    FormikProps<RegistrationSubmissionForm>
) => {
  const { onSubmit, onCancel, isValid, values } = props;
  const { t } = useTranslation();
  const [show, setShow] = React.useState<boolean>(true);
  const [sendValues, setSendValues] = React.useState<boolean>(false);

  const handleOnClose = React.useCallback(() => {
    setShow(false);
  }, []);

  const handleOnSubmit = React.useCallback(() => {
    setSendValues(true);
    setShow(false);
  }, []);

  const handleOnClosed = React.useCallback(() => {
    if (sendValues) {
      onSubmit(values.comment);
    } else {
      onCancel();
    }
  }, [onSubmit, onCancel, sendValues, values]);

  const renderFooter = React.useCallback(
    () => (
      <div className={styles.symbolsCounter}>
        {values.comment.length}/{commentLengthLimit}
      </div>
    ),
    [values]
  );
  return (
    <React.Fragment>
      <Dialog
        actions={[
          {
            label: t('actions.cancel'),
            variant: 'outline-secondary',
            onClick: handleOnClose
          },
          {
            label: t('actions.submit'),
            onClick: handleOnSubmit,
            disabled: values.comment.length < 1
          }
        ]}
        show={show}
        title={t('dialogs.processRegistration')}
        onClose={handleOnClose}
        onClosed={handleOnClosed}
      >
        <Form className={styles.container}>
          <p>{t('forms.registrationSubmission.text')}</p>

          <Form.Row>
            <Form.Textarea
              label={t('forms.registrationSubmission.commentLabel')}
              maxLength={commentLengthLimit}
              name='comment'
              placeholder={t('forms.registrationSubmission.commentPlaceholder')}
              renderFooter={renderFooter}
            />
          </Form.Row>
        </Form>
      </Dialog>
    </React.Fragment>
  );
};

export const RegistrationSubmissionDialog = registrationSubmissionDialogEnhancer(
  RegistrationSubmissionDialogComponent
);
