export const GET_ONBOARDING_USERS = '@onboarding/GET_ONBOARDING_USERS';
export const GET_ADMIN_ROLES = '@onboarding/GET_ADMIN_ROLES';
export const GET_PRODUCTS = '@onboarding/GET_PRODUCTS';
export const GET_COUNTRIES = '@onboarding/GET_COUNTRIES';
export const GET_MEMBER_FIRMS = '@onboarding/GET_MEMBER_FIRMS';
export const CREATE_ADMIN = '@onboarding/CREATE_ADMIN';
export const RESET_CREATED_ADMIN = '@onboarding/RESET_CREATED_ADMIN';
export const UPDATE_COLUMN_SORTING = '@onboarding/UPDATE_COLUMN_SORTING';

export type OnboardingState = Readonly<{
  hasSuccessData: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  usersPagination: DTO.AdminPaginationResponse;
  userRoles: DTO.AdminRole[];
  memberFirms: DTO.ProductMemberFirm[];
  products: DTO.AppProduct[];
  hadCreatedAdmin: boolean;
  countries: DTO.Country[];
  sorter: DTO.Sorter;
  headerAllTile: any[];
  headerTenantTile: any[];
  headerProductTile: any[];
}>;

export type SetAdminRoles = Store.PaylodedAction<DTO.AdminRole[]>;
export type SetMemberFirms$ = Store.PaylodedAction<
  Promise<DTO.ProductMemberFirm[]>
>;
export type SetProducts = Store.PaylodedAction<DTO.AppProduct[]>;
export type SetCountries = Store.PaylodedAction<DTO.Country[]>;

export type SetAdminUsers = Store.PaylodedAction<DTO.AdminPaginationResponse>;
export type SetAdminUsers$ = Store.PaylodedAction<
  Promise<DTO.AdminPaginationResponse>
>;
export type SetSorting = Store.PaylodedAction<any>;

export type OnboardingActionTypes =
  | SetAdminUsers
  | SetAdminRoles
  | SetMemberFirms$
  | SetProducts
  | SetCountries
  | SetSorting
