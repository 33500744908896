import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Icon, Select } from 'components';
import { reselectAdminProducts, setCurrentUserAdminFilters } from 'store/auth';
import { reselectProductInfo } from 'store/product';
import { OptionUserProduct } from './OptionUserProduct/OptionUserProduct';
import styles from './UserProductsSelect.module.css';

export const UserProductsSelect: React.FC = () => {
  const dispatch = useDispatch();
  const productInfo = useSelector(reselectProductInfo);
  const adminProducts = useSelector(reselectAdminProducts);
  const history = useHistory();

  const [selectedProduct, setSelectedProduct] = React.useState<DTO.ProductInfo>(
    productInfo
  );

  React.useEffect((): void => {
    setSelectedProduct(productInfo);
  }, [productInfo]);

  const setFilters = useCallback(
    (filters: DTO.UserFilters) => {
      dispatch(setCurrentUserAdminFilters(filters));
    },
    [dispatch]
  );

  const handleProductChange = React.useCallback(
    (product: DTO.ProductInfo): void => {
      if (productInfo.code !== product.code) {
        history.push(product.code);
        setFilters(
          {
            'countryIds': [],
            'emails': [],
            'displayName': [],
            'countryCode': []
          });
      }
    },
    [history, productInfo, setFilters]
  );
  const renderOption = React.useCallback(
    ({ title }: DTO.ProductInfo): JSX.Element => (
      <div className={styles.option}>
        <span>{title}</span>
        <Icon
          className={styles.selectedOption}
          name='checkbox-tick-special'
          size={24}
        />
      </div>
    ),
    []
  );
  const getOptionValue = React.useCallback(
    ({ code }: DTO.ProductInfo): string => code,
    []
  );

  const getOptionLabel = React.useCallback(({ title }): string => title, []);
  const additionalThemeProps = {
    borderRadius: 0,
    colors: {
      neutral0: '#FFFFFF',
      primary25: '#EBF8FD',
      primary: '#007CB0'
    },
    spacing: {
      baseUnit: 4,
      controlHeight: 4,
      menuGutter: 2
    }
  };

  return (
    <div className={styles.selectContainer}>
      <Select<DTO.ProductInfo>
        additionalThemeProps={additionalThemeProps}
        className={styles.userProducts}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isClearable={false}
        isSearchable={false}
        OptionComponent={OptionUserProduct}
        options={adminProducts}
        renderOption={renderOption}
        value={selectedProduct}
        withDropdownIndicator={true}
        onChange={handleProductChange}
      />
    </div>
  );
};
