import React, { FC } from 'react';
import cs from 'classnames';

interface Props {
  name: string;
  onClick?: () => void;
  isActive?: boolean;
  isReversed?: boolean;
  fill?: string;
  isDisable?: boolean;
}

export const DirectionButton: FC<Props> = ({
  isActive,
  isReversed,
  name,
  onClick,
  fill,
  isDisable
}) => {
  const styles = `
  .${name} {
    cursor: pointer;
  }
  .isActiveBlack {
    fill: #007CB0;
  }
  .isActiveWhite {
    fill: #fff;
  }
  .${name}.reversed {
    transform: rotateX(180deg);
  }
  .disableSortIcon {
    color: #75787B;
    opacity: .5;
  }
  `;
  return (
    <svg
      className={cs(isReversed ? `${name} reversed` : name, isDisable ? 'disableSortIcon' : '')}
      fill='none'
      height='24'
      viewBox='0 0 32 32'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <style>{styles}</style>
      <path
        className={isActive ? 'isActiveBlack' : ''}
        d='M0 0H28.8C29.9201 0 30.4802 0 30.908 0.217987C31.2843 0.409734 31.5903 0.715695 31.782 1.09202C32 1.51984 32 2.0799 32 3.2V28.8C32 29.9201 32 30.4802 31.782 30.908C31.5903 31.2843 31.2843 31.5903 30.908 31.782C30.4802 32 29.9201 32 28.8 32H0V0Z'
        fill='white'
      />
      <path
        className={isActive ? 'isActiveWhite' : ''}
        clipRule='evenodd'
        d='M11.4101 8.50806L11.5 8.5C11.7455 8.5 11.9496 8.67688 11.9919 8.91012L12 9V21.792L14.1464 19.6464C14.32 19.4729 14.5894 19.4536 14.7843 19.5886L14.8536 19.6464C15.0271 19.82 15.0464 20.0894 14.9114 20.2843L14.8536 20.3536L11.8536 23.3536C11.68 23.5271 11.4106 23.5464 11.2157 23.4114L11.1464 23.3536L8.14645 20.3536C7.95118 20.1583 7.95118 19.8417 8.14645 19.6464C8.32001 19.4729 8.58944 19.4536 8.78431 19.5886L8.85355 19.6464L11 21.792V9C11 8.75454 11.1769 8.55039 11.4101 8.50806ZM18.5 21C18.7761 21 19 21.2239 19 21.5C19 21.7761 18.7761 22 18.5 22H17.5C17.2239 22 17 21.7761 17 21.5C17 21.2239 17.2239 21 17.5 21H18.5ZM19.5 18C19.7761 18 20 18.2239 20 18.5C20 18.7761 19.7761 19 19.5 19H17.5C17.2239 19 17 18.7761 17 18.5C17 18.2239 17.2239 18 17.5 18H19.5ZM20.5 15C20.7761 15 21 15.2239 21 15.5C21 15.7761 20.7761 16 20.5 16H17.5C17.2239 16 17 15.7761 17 15.5C17 15.2239 17.2239 15 17.5 15H20.5ZM22.5 12C22.7761 12 23 12.2239 23 12.5C23 12.7761 22.7761 13 22.5 13H17.5C17.2239 13 17 12.7761 17 12.5C17 12.2239 17.2239 12 17.5 12H22.5ZM24.5 9C24.7761 9 25 9.22386 25 9.5C25 9.77614 24.7761 10 24.5 10H17.5C17.2239 10 17 9.77614 17 9.5C17 9.22386 17.2239 9 17.5 9H24.5Z'
        fill= {fill || 'blue'}
        fillRule='evenodd'
      />
    </svg>
  );
};
