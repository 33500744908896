import { CONFIG } from 'shared/constants/config.consts';
import { errorException } from 'shared/utils/AppInsights';

type ApiFunctionName = 'tenant' | 'system' | 'account' | 'integration';

const resolveLocalUrls = (func: string): string => {
  switch (func) {
    case 'tenant':
      return 'http://localhost:7071/api';
    case 'system':
      return 'http://localhost:7072/api';
    case 'account':
      return 'http://localhost:7073/api';
    case 'integration':
      return 'http://localhost:7074/api';
    default:
      // AppInsights Error Exception
      errorException(`Error Function ${func} is unknown, File reference: api-host-resolver.utils.tsx in resolveLocalUrls function`);
      throw new Error(`Function ${func} is unknown`);
  }
};

export const getApiHost = (func: ApiFunctionName): string => {
  switch (CONFIG.ENV) {
    case 'LOCAL':
      return resolveLocalUrls(func);
    default:
      return CONFIG.API_URL;
  }
};
