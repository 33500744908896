import React from 'react';
import { components } from 'react-select';
import { SingleValueProps } from 'react-select/src/components/SingleValue';

import styles from './SingleValue.module.css';

export function SingleValue<T = AP.SelectOption>(
  props: SingleValueProps<T>
): JSX.Element {
  return <components.SingleValue {...props} className={styles.container} />;
}
