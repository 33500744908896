import { combineReducers } from 'redux';

import { productReducer as product } from './product/product.reducer';
import { authReducer as auth } from './auth/auth.reducer';
import { registrationReducer as registration } from './registration/registration.reducer';
import { onboardingReducer as onboarding } from './onboarding/onboarding.reducer';
import { localizeReducer as localize } from './localize/localize.reducer';
import { subscriptionReducer as updateSubscription } from './updateSubscription/subscription.reducer';
import { globalReducer as global } from './global/global.reducer';

export const rootReducer = combineReducers({
  product,
  auth,
  registration,
  localize,
  onboarding,
  updateSubscription,
  global
});

export type RootState = ReturnType<typeof rootReducer>;
