import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutate } from 'restful-react';
import { useTranslation } from 'react-i18next';

import { selectHttpHeaders } from 'store/auth';
import { reselectLocaleCode } from 'store/localize';
import { selectProductCode } from 'store/product';
import { Icon, Spinner } from 'components';
import { API_PATH } from 'shared/constants/api.consts';
import styles from './UserList.module.css';
import { ADDITIONAL_USER_STATE, User, UserState } from './UserList.helpers';
import { UserSelect } from '../UserSelect/UserSelect';
import { UserCard } from '../UserCard/UserCard';
import { SidebarFooter } from '../SidebarFooter/SidebarFooter';
import { EditIcon } from '../../../../../../icons/EditIcon';
import { setUpdatedPrimaryUser } from '../../../../../../store/global';
import Guidance from '../../../../../../assets/images/Guidance.svg';
import Close from '../../../../../../assets/images/Close.svg';

type Props = {
  canAddAdditionalUsers: boolean;
  users: User[];
  changePanel: (a: any) => void;
  passData: User & { displayId: string; organization: DTO.Organization };
  reload: () => void;
  onShowSubheading: (value: boolean) => void;
};

export const UserList = ({
  canAddAdditionalUsers,
  users,
  passData,
  changePanel,
  reload,
  onShowSubheading
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const productCode = useSelector(selectProductCode);
  const httpHeaders = useSelector(selectHttpHeaders);
  const localeCode = useSelector(reselectLocaleCode);

  const {
    displayId,
    firstName: primaryFirstName,
    lastName: primaryLastName,
    email: primaryEmail
  } = passData;
  const primaryUser = {
    email: primaryEmail,
    firstName: primaryFirstName,
    lastName: primaryLastName
  };
  const allUsers = [...users, { ...primaryUser }];
  const [secondryUser, setSecondryUser] = useState('');
  const [userState, setUserState] = React.useState<UserState>({
    type: ADDITIONAL_USER_STATE.MAIN,
    user: null
  });
  const [addUserText, setAddUserText] = React.useState<string>();
  const [showSelect, setShowSelect] = React.useState(false);
  const [close, setClose] = React.useState(false);

  useEffect((): void => {
    if (canAddAdditionalUsers && productCode=== 'dra') setAddUserText(t('admin.sidebar.activeText'));
    else if (!canAddAdditionalUsers) setAddUserText(t('admin.sidebar.disableText'));
  }, [canAddAdditionalUsers, t, productCode]); 

  const clearState = React.useCallback((): void => {
    onShowSubheading(false);
    setUserState({ type: ADDITIONAL_USER_STATE.MAIN, user: null });
  }, [onShowSubheading, setUserState]);

  const { mutate: removeUserCall, loading } = useMutate<any>({
    verb: 'POST',
    path: API_PATH.REMOVE_ADDITIONAL_USER(productCode),
    requestOptions: () => ({ headers: httpHeaders }),
    onMutate: React.useCallback((): void => {}, [])
  });

  const { mutate: updateUserCall, loading: updateUserLoading } = useMutate<any>(
    {
      verb: 'POST',
      path: API_PATH.UPDATE_PRIMARY_USER(productCode, localeCode),
      requestOptions: () => ({ headers: httpHeaders })
    }
  );

  const removeUser = React.useCallback(
    (user: any): void => {
      onShowSubheading(true);
      setUserState({ type: ADDITIONAL_USER_STATE.REMOVE, user });
    },
    [onShowSubheading]
  );

  const removeUserFun = React.useCallback(() => {
    removeUserCall({ AccountId: displayId, Email: userState.user.email }).then(
      () => {
        clearState();
        reload();
      }
    );
  }, [clearState, displayId, reload, userState, removeUserCall]);

  const updateUser = React.useCallback(
    (user: User): void => {
      onShowSubheading(true);
      setUserState({ type: ADDITIONAL_USER_STATE.UPDATE, user });
    },
    [onShowSubheading, setUserState]
  );

  const updateUserFun = React.useCallback(() => {
    updateUserCall({
      CurrentAccountId: displayId,
      TargetUserEmail: (userState.user && userState.user.email) || '',
    }).then((response: number) => {
      dispatch(setUpdatedPrimaryUser(response));
    });
  }, [updateUserCall, dispatch, displayId, userState.user]);

  const handleSelectUser = useCallback(
    user => {
      setSecondryUser(`${user.firstName} ${user.lastName}`);
      updateUser(user);
      setShowSelect(false);
    },
    [updateUser, setShowSelect]
  );
  
  return (
    <React.Fragment>
      {(loading || updateUserLoading) && <Spinner shouldShow={true} />}
      {userState.type === ADDITIONAL_USER_STATE.MAIN && (
        <div className={styles.primaryUser}>
          <p>{t('admin.adduser.primary')}</p>
          {showSelect ? (
            <UserSelect
              primaryUser={primaryUser}
              userList={allUsers}
              onMenuClose={() => setShowSelect(false)}
              onSelect={handleSelectUser}
            />
          ) : (
            <UserCard
              icon={<EditIcon className={styles.iconEdit} />}
              isDisabled={allUsers.length <= 1}
              isPrimary={true}
              user={primaryUser}
              onSelect={() => setShowSelect(true)}
            />
          )}
        </div>
      )}
      <div className={styles.content}>
        {userState.type === ADDITIONAL_USER_STATE.MAIN && (
          <React.Fragment>
            {!users.length && (
              <div className={styles.img_div}>
                <p>{t('admin.adduser.nouser')}</p>
              </div>
            )}
            {users.map((user: User) => (
              <UserCard
                key={user.email}
                icon={<Icon name='cross-special' size={18} />}
                user={user}
                onRemove={(userData: User) => removeUser(userData)}
              />
            ))}
          </React.Fragment>
        )}
        {userState.type === ADDITIONAL_USER_STATE.REMOVE && (
          <div className={styles.img_div}>
            <p>
              {t('admin.adduser.remove')} <b>{t('admin.adduser.removeb')}</b>{' '}
              {t('admin.adduser.removeaccess')} {userState.user.firstName}{' '}
              {userState.user.lastName}?
            </p>
            {/* <p className={styles.notes}>{t('admin.adduser.notes')}</p> */}
          </div>
        )}
        {userState.type === ADDITIONAL_USER_STATE.UPDATE && (
          <div className={styles.img_div}>
            <p>{`${t('admin.adduser.replaceText.primaryUser')} ${
              primaryUser.firstName
            } ${primaryUser.lastName} ${t(
              'admin.adduser.replaceText.with'
            )} ${secondryUser} ?`}</p>
            {/* <p className={styles.notes}>{t('admin.adduser.notes')}</p> */}
          </div>
        )}
      </div>
      {close === false && productCode === 'sbp' && (
              <div className={styles.outGuideCls}>
                <div className={styles.guideCls}>
                <div>
                  <img alt='Guidance' className={styles.guidanceIcon} src={Guidance}/>
                  <h6 className={styles.guiddeTxt}>{t('admin.adduser.guidance')}</h6>
                  <button
                    className={styles.btnChange}
                    onClick={() => setClose(true)}
                  ><img alt='Close' src={Close} />
                  </button>
                  <p>
                  {t('admin.adduser.guidanceText')} 
                  </p>
                </div>
              </div>
              </div>
       )}
      <SidebarFooter
        addUserText={addUserText}
        canAddAdditionalUsers={canAddAdditionalUsers}
        userState={userState}
        onChangePanel={changePanel}
        onClearState={clearState}
        onRemoveUser={removeUserFun}
        onUpdateUser={updateUserFun}
      />
    </React.Fragment>
  );
};
