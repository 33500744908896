export const homepageEn = {
  headersection: {
    access: 'Admin Access',
    title: 'Deloitte provides',
    btitle: 'Assurance to your business',
    ptitle: 'Through innovative and digital assets'
  },
  services: {
    title: 'Service Areas',
    cardtitle1: 'Accounting & Reporting Advisory',
    // carddescription1:
    //   'Financial markets and stakeholder expectations are continuously evolving. Deloitte can help navigate the complexity of today’s accounting and reporting standards, providing tailored levels of on-call support as needed. To achieve greater integration in financial reporting, we can guide the implementation of a comprehensive strategy for the assimilation of financial and non-financial information, allowing businesses to effectively demonstrate their value in the marketplace.',
    carddescription1:
      'Supporting you in implementing complex accounting requirements of new standards',
    cardtitle2: 'Disruptive Events',
    cardtitle2b: 'Advisory',
    // carddescription2:
    //   'Deloitte brings deep accounting, financial reporting, financial operations, and regulatory experience to corporate transactions including mergers and acquisitions, divestments, IPOs and restructures, or new business models. We provide assurance to CFOs and their finance functions to successfully navigate disruptive events through the corporate lifecycle and exceed stakeholder expectations.',
    carddescription2:
    'Assisting you in dealing with financial events, specifically tailored for integration planning with events such as, M&A, IPO, spin-offs and tuck-ins',
    cardtitle3: 'Accounting Operations Advisory',
    // carddescription3:
    //   'Deloitte supports the development and continuance of world-class Financial Reporting. Our deep accounting experience and legacy give us the knowledge to deliver efficient processes, effective internal controls, and innovative technologies to enable finance functions for the future. We recognize that everyone is starting from a different point with accounting operations and have different regulatory and business environments in which they operate. We diagnose what’s needed and provide tailored plans to help close the gaps to simplify and improve the financial reporting cycle with a set of proven offerings.',
    carddescription3:
    'Enhancing your financial cycle and internal controls processes',
    cardtitle4: 'Business Assurance',
    // carddescription4:
    //   'Regulatory, societal, technological, economic and environmental changes are resulting in disruption and uncertainty for organizations. Business Assurance services aim to provide clients with the confidence in new subject matters, technology, and markets.'
    carddescription4:
      'Combining Assurance with technology to enhance your business'
  },
  section1: {
    title: 'Accounting & Reporting Advisory',
    heading: 'IBR Calculator',
    description:
      'The IBR calculator allows organizations to measure the value of their lease liabilities by applying a discount rate to their future lease payments compliant with IFRS 16.',
    launchbtn: 'Launch IBR',
    downloadbtn: 'Download brochure',
    fileName: 'Sales sheet IBR'
  },
  section2: {
    title: 'Accounting & Reporting Advisory',
    heading: 'Share Based Payments Calculator',
    innerHeading: 'Offering two applications to support companies with their Share Based Payments Calculator needs in compliance with IFRS 2.',
    description:
      'Share Based Payments Calculator (SBP) allows companies to perform employee share plan valuations in compliance with IFRS 2. The calculator offers multiple valuation models, including Binomial, Black-Scholes, Monte Carlo, and real-time TSR monitoring.',
    launchbtn: 'LAUNCH SBP',
    ausClientsOnly: 'For Australian Clients Only',
    australiacomingsoon: 'SBP FOR CLIENTS OUTSIDE AUSTRALIA COMING SOON',
    downloadbtn: 'Download brochure',
    fileName: 'Sales sheet SBP'
  },
  section3: {
    title: 'Accounting & Reporting Advisory',
    heading: 'My Incentive Plan',
    description:
      'The accounting of share-based payments is prone to a high degree of error due to intensive manual calculations and disparate spreadsheets. This application provides a user-driven management system, which simplifies the creation and management of Share Based Payments Calculator accounting expenses in compliance with IFRS 2. End-users can then view and/or export the output using a user-friendly interface.',
      launchbtn: 'LAUNCH My Incentive Plan',
    australiacomingsoon: 'My Incentive Plan FOR CLIENTS OUTSIDE AUSTRALIA COMING SOON',
    downloadbtn: 'Download brochure',
    fileName: 'Sales sheet My Incentive Plan'
  },
  section4: {
    title: "Deloitte's digital reporting accelerator",
    heading: "Deloitte's digital reporting accelerator",
    description: "Deloitte’s digital reporting accelerator is a cloud-based application that enables users to accelerate preparation of their financial statements.",
    launchbtn: "LAUNCH DELOITTE'S DIGITAL REPORTING ACCELERATOR",
    australiacomingsoon: 'Subtext demo',
    downloadbtn: 'Download brochure',
    fileName: 'Dra-Brochure'
  }
};
