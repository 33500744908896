import { string, object } from 'yup';
import { withFormik } from 'formik';

import { noop } from 'shared/utils';

export interface ConfigureRetentionDialogForm {
  product: string;
  country: string;
  retention: string;
}

export const initialFormValues: ConfigureRetentionDialogForm = {
  product: '',
  country: '',
  retention: ''
};

export const commentLengthLimit = 255;

export interface ConfigureRetentionDialogProps {
  onSubmit: (values: DTO.UpdateRetentionDto) => void;
  onCancel: () => void;
  setconfirmCancel: (confirm: boolean) => void;
}

export const ConfigureRetentionDialogEnhancer = withFormik<
  ConfigureRetentionDialogProps,
  ConfigureRetentionDialogForm
>({
  mapPropsToValues: () => initialFormValues,
  validationSchema: object().shape({
    product: string()
      .required()
      .trim(),
    country: string()
      .required()
      .trim(),
    retention: string()
    .required()
    .trim()  
 
  }),
  handleSubmit: noop
});
