import React from 'react';
import cs from 'classnames';

import { Spinner } from 'components';
import { SubmissionsGrid } from './SubmissionsGrid';
import styles from './SubmissionsGrid.module.css';

interface Props {
  className?: string;
  submissions: DTO.SubmissionRecord[];
  loading: boolean;
  productRoles: Array<any>;
  showUserIcon: boolean;
  handleGetGridData: () => Promise<void>;
  gridHeaders: Array<any>;
  sortBy: string;
  direction: AP.SortDirection;
  onSortChange: (sortBy: string, sort: AP.SortDirection) => void;
  userRole: string;
}

export const SubmissionsGridContainer = ({
  className,
  submissions,
  loading,
  productRoles,
  showUserIcon,
  handleGetGridData,
  gridHeaders,
  sortBy,
  direction,
  onSortChange,
  userRole
}: Props): JSX.Element => (
  <div className={cs(styles.container, className)}>
    <Spinner className={styles.spinner} shouldShow={loading} />
    <SubmissionsGrid
      direction={direction}
      gridHeaders={gridHeaders}
      handleGetGridData={handleGetGridData}
      productRoles={productRoles}
      showUserIcon={showUserIcon}
      sortBy={sortBy}
      submissions={submissions}
      userRole={userRole}
      onSortChange={onSortChange}
    />
  </div>
);
