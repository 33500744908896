// because function noop must be empty
/* eslint-disable-next-line @typescript-eslint/no-empty-function */
export const noop = (): void => {};

export const getWindow = (): any => window;

export const findSlot = (elements: any[], name: string): JSX.Element =>
  elements.find((el): boolean => {
    return el.type.displayName === name || el.type.name === name;
  }) || null;

export const redirectTo = (url: string): void => window.location.replace(url);

export const getCookie = (cname: string): string => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const clearCookie = (key: string): void => {
  document.cookie = `${key}=;path=/;secure`;
};
