import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from 'components';
import { SubmissionPlansGrid } from '../../Submission/SubmissionPlansGrid/SubmissionPlansGrid';
import { Details } from './Details/Details';
import styles from './DetailedInformationDialog.module.css';

interface Props {
  submission: DTO.SubmissionRecord;
  productRoles?: Array<any>;
  onClose: () => void;
  updatePlanControl?: boolean;
}

export const DetailedInformationDialog: React.FC<Props> = ({
  submission,
  onClose,
  productRoles,
  updatePlanControl,
}): JSX.Element => {
  const { t } = useTranslation();
  const [show, setShow] = React.useState<boolean>(true);

  const handleOnClose = React.useCallback((): void => {
    setShow(false);
    onClose();
  }, [onClose]);

  return (
    <Dialog
      className={styles.detailDialog}
      show={show}
      showCrossButton={true}
      size='xl'
      title={t('dialogs.detailedInformation')}
      onClose={handleOnClose}
      onHide={handleOnClose}
    >
      <Details className={styles.submission} submission={submission} />
      <SubmissionPlansGrid
        className={styles.detailDialogGrid}
        email={submission.user.email}
        isDetailsDialog={true}
        plans={submission.plans}
        productRoles={productRoles}
        updatePlanControl={updatePlanControl}
      />
    </Dialog>
  );
};
