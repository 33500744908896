import React from 'react';
import cs from 'classnames';
import { components } from 'react-select';
import { OptionProps } from 'react-select/src/components/Option';

import { Icon } from 'components/Icon/Icon';
import styles from './Option.module.css';

export function Option<T = AP.SelectOption>(
  props: OptionProps<T, boolean>
): JSX.Element {
  const { isSelected, isFocused, children, isMulti } = props;

  const icon =
    isSelected && !isMulti ? (
      <Icon className={styles.tick} name='tick-special' size={20} />
    ) : null;

  return (
    <components.Option
      {...props}
      className={cs(styles.option, {
        [styles.optionSelected]: isSelected,
        [styles.optionFocused]: isFocused
      })}
    >
      <span className={styles.label}>{children}</span>
      {icon}
    </components.Option>
  );
}
