import { createSelector } from 'reselect';

import { RootState } from 'store/root.reducer';

export const selectProductCode = ({
  product
}: RootState): AP.Nullable<string> => product.code;
export const selectProductTitle = ({ product }: RootState): string =>
  product.title;
export const selectProductAppUrl = ({ product }: RootState): string =>
  product.appUrl;

export const reselectProductInfo = createSelector(
  [selectProductCode, selectProductTitle, selectProductAppUrl],
  (code, title, appUrl): DTO.ProductInfo => ({ code, title, appUrl })
);

export const selectProductPlans = ({ product }: RootState): any =>
  product.plans;
export const selectCountries = ({ product }: RootState): any =>
  product.countries;
export const selectMemberFirms = ({ product }: RootState): any =>
  product.memberFirms;
export const selectFieldSettings = ({ product }: RootState): any =>
  product.fieldSettings;

// export const selectCountries = ({ product }: RootState): any =>
//   product.countries;

export const selectFields = createSelector(
  [selectCountries, selectMemberFirms, selectFieldSettings],
  (countries, memberFirms, fieldSettings): DTO.DisplayFieldSettings => ({
    countries,
    memberFirms,
    fieldSettings
  })
);

// export const selectFields = createSelector(
//   [selectCountries, selectFieldSettings],
//   (countries, fieldSettings): DTO.FieldSettings => ({ countries, fieldSettings })
// );
