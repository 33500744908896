import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

class I18n {
  init = ({ defaultLang, translations, fallbackLng }: any) => {
    return Promise.resolve(
      i18next.use(initReactI18next).init({
        lng: defaultLang,
        debug: process.env.NODE_ENV === 'development',
        fallbackLng,
        resources: Object.keys(translations).reduce((res, key) => {
          return {
            ...res,
            [key]: {
              translation: translations[key]
            }
          };
        }, {})
      })
    );
  };

  changeLanguage = (lang: string) => {
    i18next.changeLanguage(lang);
  };

  addResourceBundle = (lng: string, ns: string, resource: any[]) => {
    i18next.addResourceBundle(lng, ns, resource);
  };

  hasResourceBundle = (lng: string, ns: string) => {
    return i18next.hasResourceBundle(lng, ns);
  };

  getResourceBundle = (lng: string, ns: string) => {
    return i18next.getResourceBundle(lng, ns);
  };

  //   getResource(lng, ns, key, options) {
  //     return i18next.getResource(lng, ns, key, options);
  //   }

  language = () => {
    return i18next.language;
  };

  getCurrLangResource = (ns?: string) => {
    return this.getResourceBundle(i18next.language, ns || 'translation');
  };
}

export const i18nService = new I18n();
