export const formsEn = {
  email: 'Email',
  emailAddress: 'Email Address',
  firstName: 'First Name',
  lastName: 'Last Name',
  companyFullLegalName: 'Company Full Legal Name',
  globalParentFullLegalNameOptional: 'Global Parent Full Legal Name (optional)',
  departmentOptional: 'Department (optional)',
  address: 'Address',
  townCity: 'Town/City',
  stateProvinceCounty: 'State/Province/County',
  zipPostalCode: 'Zip/Postal Code',
  isDeloitteAuditClient: 'Are you a Deloitte Audit Client?',
  yes: 'Yes',
  no: 'No',
  dk: "I don't know",
  referralName: 'Referral Name',
  referralEmail: 'Referral Email',
  accountInformation: 'Account Information',
  userInformation: 'User Information',
  privacyStatement: 'Privacy Statement | Deloitte',
  referralInformation: 'Referral Information',
  selectOption: 'Select option',
  termsOfUseConfirmation: 'I have read and accept the Terms of Use.',
  acceptableUseOfPolicyConfirmation: 'I have read and accept the Acceptable Use Policy.',
  checkboxLabel: 'I have read and accept the Privacy Policy.',
  registrationSubmission: {
    text:
      'Are you sure you wish to process this registration? To proceed please leave a mandatory comment.',
    commentLabel: 'Comments',
    commentPlaceholder: 'Leave your comment'
  },
  updateCompany: {
    text:
      'Are you sure you wish to update the Company Name? By doing so, please note the updated changes and confidential information of this Company is exposed to a greater number of Reports and to more Reviewers. If Yes, click  “Submit”. If No, click “Cancel” below.',
  },
  role: 'Role',
  additionalInformationSection: {
    header:'Additional Information', 
    checkBoxTitle: 'I confirm both the additional user and the primary user belong to the same organization.',
    checkBoxTitleMultipleUsers: 'I confirm both the additional user(s) and the primary user belong to the same organization.'
  },
  configureRetention: {
    text:
      'Enter the information below to determine when a Subscription should be purged'
  }
};
