import { useTranslation } from 'react-i18next';

export const DateLocale = (value: string): string => {
  const { t } = useTranslation();
  const month = new Date(value).getMonth() + 1;
  const dd = new Date(value).getDate();
  const year = new Date(value).getFullYear();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let mon = '';
  if (month === 1) mon = 'january';
  else if (month === 2) mon = 'february';
  else if (month === 3) mon = 'march';
  else if (month === 4) mon = 'april';
  else if (month === 5) mon = 'may';
  else if (month === 6) mon = 'june';
  else if (month === 7) mon = 'july';
  else if (month === 8) mon = 'august';
  else if (month === 9) mon = 'september';
  else if (month === 10) mon = 'october';
  else if (month === 11) mon = 'november';
  else if (month === 12) mon = 'december';
  return `${dd} ${t(`admin.month.${mon}`)} ${year}`;
};
