import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { USER_ROLES } from 'features/AdminPage/OnboardingPage/OnboardingFilters/OnboardingFilters';
import { reselectUser } from 'store/auth';
import styles from './Detail.module.css';

export interface DetailProps {
  title: string;
  value: string;
}

export const Detail: React.FC<DetailProps> = ({ title, value }) => {
  const { t } = useTranslation();
  const user = useSelector(reselectUser);
  const contentHiddenText = t('admin.grid.hiddencontent');

  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      {(user.role === USER_ROLES.SUPER_ADMIN &&
        (title === `${t('admin.grid.companyName')}:` ||
        title === `${t('admin.grid.subscriberName')}:` ||
      title === `${t('admin.grid.subscriberEmail')}:`)) ? (
        <p className={styles.hiddenContent} title={contentHiddenText}>
          {contentHiddenText}
        </p>
      ) : (
        <p className={styles.text} title={value}>
          {value}
        </p>
      )}
    </div>
  );
};
