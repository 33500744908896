import React from 'react';
import cs from 'classnames';
import { Button as BootstrapButton, ButtonProps } from 'react-bootstrap';

import { noop } from 'shared/utils';
import { Icon, IconName } from 'components/Icon/Icon';
import styles from './Button.module.css';

interface Props extends ButtonProps {
  className?: string;
  size?: 'sm' | 'lg';
  thin?: boolean;
  icon?: IconName;
  onClick?: (values?: any) => void;
}

export const Button: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className = '',
  variant = 'primary',
  onClick = noop,
  disabled = false,
  href = '',
  size,
  thin = false,
  icon
}) => (
  <BootstrapButton
    className={cs(styles.button, className, {
      [styles.btnSm]: size === 'sm',
      [styles.btnOutlinePrimary]: variant === 'outline-primary',
      [styles.btnOutlineSecondary]: variant === 'outline-secondary',
      [styles.link]: variant === 'link',
      [styles.thin]: thin,
      [styles.withIcon]: !!icon
    })}
    disabled={disabled}
    href={href}
    size={size}
    variant={variant}
    onClick={onClick}
  >
    {icon && <Icon className={styles.icon} name={icon} size={24} />}
    <span>{children}</span>
  </BootstrapButton>
);
