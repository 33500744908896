import React, { useState, Fragment, useCallback } from 'react';
import { Card as BSCard } from 'react-bootstrap';
import cs from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { getFromLocalStorage } from 'shared/utils/storage.utils';
import { SUBMISSION_PLAN_STATUS } from 'shared/constants/submission.consts';
import { UPDATE_SUBSCRIPTIONS_ACTIONS } from 'features/UpdateSubscription/UpdateSubscription.helpers';
import { Button, Checkbox, Dialog } from 'components';
import { renewSubscriptions } from 'store/updateSubscription';
import styles from '../SelectPlans.module.css';
import { reselectUser } from '../../../../../store/auth';
import { DateLocale } from '../../../../AdminPage/DateLocale';
import { reselectSubscriptionPlans } from '../../../../../store/updateSubscription';

interface Props {
  availableLicenses: any;
  code: any;
  checked: any;
  title: any;
  description: any;
  model: any;
  purchasedLicenses: any;
  status: any;
  performedAt: any;
  expiredAt: any;
  action: string;
  activationDate: string;
  handlePlanCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
  durationList: any;
  productCode: any;
  setmipDuration: any;
}

export const PlanCard: React.FC<Props> = ({
  availableLicenses,
  code,
  checked,
  title,
  description,
  model,
  purchasedLicenses,
  status,
  performedAt,
  expiredAt,
  action,
  handlePlanCheck,
  activationDate,
  durationList,
  productCode,
  setmipDuration
}) => {
  const { t } = useTranslation();
  const { profileSettings } = useSelector(reselectUser);
  const dispatch = useDispatch();
  const dateFormat = React.useCallback(
    (date: any) => {
      return profileSettings.dateFormat !== 'dd MMMM yyyy'
        ? format(new Date(date), profileSettings.dateFormat)
        : DateLocale(format(new Date(date), profileSettings.dateFormat));
    },
    [profileSettings.dateFormat]
  );
  const MAX_LICENSES = 999;
  const plans = useSelector(reselectSubscriptionPlans);
  const [initialLicenses] = useState(availableLicenses);
  const [initialPurchasedLicenses] = useState(purchasedLicenses);
  const disableDiv = (licenseStatus: string) => {
    return !!(
      (action &&
        (licenseStatus === action.toLocaleLowerCase() ||
          (action.toLocaleLowerCase() ===
            UPDATE_SUBSCRIPTIONS_ACTIONS.RE_ACTIVATE.toLocaleLowerCase() &&
            licenseStatus === SUBMISSION_PLAN_STATUS.ACTIVE) ||
          (action.toLocaleLowerCase() !==
            UPDATE_SUBSCRIPTIONS_ACTIONS.RENEW.toLocaleLowerCase() &&
            licenseStatus === SUBMISSION_PLAN_STATUS.EXPIRED) ||
          (action.toLocaleLowerCase() ===
            UPDATE_SUBSCRIPTIONS_ACTIONS.CANCEL.toLocaleLowerCase() &&
            licenseStatus === SUBMISSION_PLAN_STATUS.CANCELLED))) ||
      (action.toLocaleLowerCase() ===
        UPDATE_SUBSCRIPTIONS_ACTIONS.RENEW.toLocaleLowerCase() &&
        licenseStatus === SUBMISSION_PLAN_STATUS.CANCELLED) ||
      // licenseStatus === SUBMISSION_PLAN_STATUS.REJECTED ||
      (action && 
        (action ===
        t(
          `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.ADD_NEW_PLAN.toLowerCase().replaceAll(' ', '')}`
        ) &&
          (licenseStatus === SUBMISSION_PLAN_STATUS.ACTIVE || licenseStatus === SUBMISSION_PLAN_STATUS.EXPIRED
            || licenseStatus === SUBMISSION_PLAN_STATUS.CANCELLED))) ||
      (action &&
        (action !==
        t(
        `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.ADD_NEW_PLAN.toLowerCase().replaceAll(' ', '')}`
      ) &&
          (licenseStatus === SUBMISSION_PLAN_STATUS.REJECTED ||
            licenseStatus === SUBMISSION_PLAN_STATUS.UNSUBSCRIBED)))
    );
  };

  const changeLicensesAmount = (
    purchasedLicencesAmount: number,
    avalialbleLicensesAmount: number
  ) => {
    const newPlans = plans.map((plan) => {
      if (plan.code === code) {
        return {
          ...plan,
          purchasedLicenses: purchasedLicencesAmount,
          availableLicenses: avalialbleLicensesAmount
        };
      }
      return plan;
    });
    dispatch(renewSubscriptions(newPlans));
  };

  // handle + and - operation for licenses
  const handleIncrement = (newValue: number, operation: string) => {
    setShowDialogFlag(false);
    if(operation === '+') {
      if(newValue > MAX_LICENSES) {
        // show MAX_Warning dialog and reset AL, P -> MAX
        setDialogTitle(t('dialogs.exceededTokenLimit'));
        setDialogMessage(`${t('dialogs.maxLicenseWarning')} '999'`);
        setShowDialogFlag(true);
        changeLicensesAmount(MAX_LICENSES, MAX_LICENSES);
      } else {
        changeLicensesAmount(newValue, availableLicenses + 1);
      }
    } else if (status !== 'rejected'){ // operation === '-'
      changeLicensesAmount(newValue, availableLicenses - 1);
    } else if (status === 'rejected') {
      if(availableLicenses === 0) {
        changeLicensesAmount(newValue, 0);
      } else {
        changeLicensesAmount(newValue, availableLicenses - 1);
      }
    }
  };
  
  // set to TRUE when license textbox value gets changed
  const [isChangeEventCalled, setChangeEventCalledFlag] = useState(false);
  // dialog related common states variabled
  const [showDialog, setShowDialogFlag] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');

  // logic for AL, PL when initially AL == 0
  const setLicensesWhenInitialAvailableLicensesAreZero = (licenses: number) => {
    if(licenses > MAX_LICENSES) {
      // show MAX_Warning dialog and reset AL, P -> MAX
      displayMaxTokenLimitDialog();
      changeLicensesAmount(MAX_LICENSES, MAX_LICENSES);
    } else if(licenses === 0  || (licenses - initialPurchasedLicenses) < 0) {
      if(status === 'rejected') {
        if(licenses === 0) {
          // displayMinTokenLimitDialog();
          setDialogTitle(t('dialogs.addLicense'));
          setDialogMessage(t('dialogs.requestedLicenseZero'));
          setShowDialogFlag(true);
        }
        changeLicensesAmount(licenses === 0 ? 1 : licenses, 0);
      } else {
        // show Min License warning and reset AL, PL -> base values
        if(initialLicenses === initialPurchasedLicenses && initialLicenses === 0)
        {
          // do nothing
        } else {
          displayMinTokenLimitDialog();          
        } 
        
        changeLicensesAmount(initialPurchasedLicenses, initialLicenses);
      }
    } else {
      // bothe AL, PL -> new given value
      changeLicensesAmount(licenses, licenses);
    }
  };

  const displayMinTokenLimitDialog = () => {
    setDialogTitle(t('dialogs.minTokenLimit'));
    setDialogMessage(t('dialogs.minLicenseWarning'));
    setShowDialogFlag(true);
  };

  const displayMaxTokenLimitDialog = () => {
    setDialogTitle(t('dialogs.exceededTokenLimit'));
    setDialogMessage(`${t('dialogs.maxLicenseWarning')} '999'`);
    setShowDialogFlag(true);
  };

  // logic for AL, PL when initially AL != 0
  const setLicensesWhenInitialAvailableLicensesNotZero = (licenses: number) => {
    if(licenses > MAX_LICENSES) {
      displayMaxTokenLimitDialog();
      changeLicensesAmount(MAX_LICENSES, MAX_LICENSES);
    } else {
      const extraPLValue = licenses - initialPurchasedLicenses;
      if(initialLicenses + extraPLValue < 0) {
        displayMinTokenLimitDialog();
        changeLicensesAmount(initialPurchasedLicenses, initialLicenses);
      } else {
        changeLicensesAmount(licenses, initialLicenses + extraPLValue);
      }
    }
  };
  
  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDialogFlag(false);
    if(isChangeEventCalled) {
      const eventLicences = +event.target.value; // absolute value of PL
      if(initialLicenses === 0) {
        setLicensesWhenInitialAvailableLicensesAreZero(eventLicences);
      } else { // initialLicenses !== 0
        setLicensesWhenInitialAvailableLicensesNotZero(eventLicences);
      }
      setChangeEventCalledFlag(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPurchasedLicenses = +event.target.value;
    if (!Number.isNaN(newPurchasedLicenses)) {
      setChangeEventCalledFlag(true);
      changeLicensesAmount(newPurchasedLicenses, +availableLicenses);
    }
  };

  const handleOnClose = React.useCallback((): void => {
    setShowDialogFlag(false);
  }, [setShowDialogFlag]);

  const handleDurationChange = useCallback(
    (e: any): void => {
      setmipDuration(Number(e.target.value));
    },
    [setmipDuration]
  );

  const [actions] = React.useState<AP.DialogAction[]>([
    {
      label: t('actions.ok'),
      variant: 'primary',
      onClick: handleOnClose
    }
  ]);

  const evaluateCardStatus = () => {
    const oldPlans: any = getFromLocalStorage('initialPlans');
    const oldPlan = oldPlans && oldPlans.length > 0 ? oldPlans.find((op: any)=> {
      return op.code === code;
    }): null;
    return oldPlan ? oldPlan.status.toLocaleLowerCase() : status.toLocaleLowerCase();
  };
  return (
    <Fragment>
      <Dialog
        actions={actions}
        className='noBorderAndPadding'
        show={showDialog}
        title={dialogTitle}
        onClose={handleOnClose}
        onClosed={() => console.log('onClosed')}
        onHide={handleOnClose}
      >
        <span className={styles.warningText}>{dialogMessage}</span>
      </Dialog>

      <BSCard
        className={`${cs(styles.planCard, styles.card)} ${
          disableDiv(status) ? styles.disabled : ''
        }`}
      >
        <BSCard.Body bsPrefix={styles.card_main}>
          <div className={styles.card_header}>
            <span
              className={`${styles.status} ${styles[evaluateCardStatus()]}`}
            />
            {t(`admin.plans.statuses.${evaluateCardStatus()}`)}
          </div>
          <div className={styles.card_body}>
            <div className={styles.card_sub_title}>
              <Checkbox
                checked={checked}
                disabled={disableDiv(status)}
                label={title}
                labelClass={styles.headLabel}
                name={code}
                onChange={handlePlanCheck}
              />
              {/* <h6>{title}</h6> */}
            </div>
            <div className={`pb-4 ${styles.card_discription}`}>
              {description}
            </div>
            <BSCard.Footer className={styles.card_footer}>
              {model !== 'subscription' && (
                <React.Fragment>
                  <div className={styles.licenseSection}>
                    <div className={styles.labelText}>
                      <b> {t('registration.plans.availableLicenses')} </b>
                    </div>
                    {status === 'rejected' && checked ? null : (
                      <div className={styles.labelValue}>
                        {availableLicenses}
                      </div>
                    )}
                  </div>
                  <div className={styles.licenseSection}>
                    <div className={styles.labelText}>
                      <b>
                        {' '}
                        {status === 'rejected'
                          ? t('registration.plans.requestedLicenses')
                          : t('registration.plans.purchasedLicenses')}
                      </b>
                    </div>
                    {(action ===
                      t(
                        `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.RENEW.toLowerCase()}`
                      ) ||
                      action ===
                        t(
                          `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.ADD_NEW_PLAN.toLowerCase().replaceAll(
                            ' ',
                            ''
                          )}`
                        )) &&
                    checked ? (
                      <div className={styles.editPlan}>
                        {/* <Button
                          className={styles.updateSubscription}
                          disabled={
                            (status === 'rejected' && purchasedLicenses === 1
                            || status !== 'rejected' && availableLicenses === 0)
                          }
                          size='sm'
                          variant='outline-secondary'
                          onClick={() =>
                            handleIncrement(purchasedLicenses - 1, '-')
                          }
                        >
                          -
                        </Button> */}
                        {/* This is temp fix, when implement new logic for AL and PL
                          uncomment input and remove below div  */}
                        <input
                          className={styles.licencesInput}
                          max={999}
                          min={
                            status === 'rejected'
                              ? 1
                              : initialPurchasedLicenses - initialLicenses
                          }
                          type='number'
                          value={String(purchasedLicenses).replace(/^00+/, '')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {/* <div className={styles.licenseDisplayLabel}>{purchasedLicenses}</div> */}
                        {/* <Button
                          className={styles.updateSubscription}
                          disabled={purchasedLicenses === MAX_LICENSES}
                          size='sm'
                          variant='outline-secondary'
                          onClick={() =>
                            handleIncrement(purchasedLicenses + 1, '+')
                          }
                        >
                          +
                        </Button> */}
                      </div>
                    ) : (
                      <div className={styles.rightAligned}>
                        {purchasedLicenses}
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
              {model === 'subscription' && (
                <div className={styles.subscriptionSection}>
                  {(productCode === 'mip' || productCode === 'dra') &&
                    action.toLocaleLowerCase() ===
                      UPDATE_SUBSCRIPTIONS_ACTIONS.RENEW.toLocaleLowerCase() && (
                      <React.Fragment>
                        <b>{t('registration.plans.renewSubscription')}</b>
                        <div>
                          <select
                            className={styles.selectYears}
                            onChange={(e) => handleDurationChange(e)}
                          >
                            {durationList}
                          </select>
                        </div>
                      </React.Fragment>
                    )}
                  <div className={styles.labelText}>
                    <b>{t('registration.plans.duration')}</b>
                  </div>
                  <div className={styles.durationValue}>
                    {status !== 'rejected'
                      ? `${dateFormat(activationDate)} - ${dateFormat(
                          expiredAt
                        )}`
                      : '-'}
                  </div>
                </div>
              )}
            </BSCard.Footer>
          </div>
        </BSCard.Body>
      </BSCard>
    </Fragment>
  );
};
