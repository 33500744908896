import React, { FC, Fragment, PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { reselectUser, selectIsFirstLogin } from 'store/auth';
import {
  selectAdminRoles,
  selectHadCreatedAdmin
} from 'store/onboarding/onboarding.selectors';
import { Footer } from 'features/Footer/Footer';
import { AppUserNav } from 'features/AppLayout';
import { ConfirmDialog, Dialog, AdminDialog } from 'components';
import { getAdminRoles } from 'store/onboarding/onboarding.actions';
import { AddAdminForm } from './AddAdminForm/AddAdminForm';
import styles from './OnboardingCreation.module.css';
import { selectProductCode } from '../../../store/product';
import { UserMenu } from '../UserMenu/UserMenu';
import { UserProductsSelect } from '../UserProductsSelect/UserProductsSelect';
import { confirmDialogConfig } from '../AdminPage';
import { USER_ROLES } from '../OnboardingPage/OnboardingFilters/OnboardingFilters';

interface Props {
  onCancel? : () => void;
}
export const OnboardingCreation: FC<PropsWithChildren<Props>> = ({ onCancel }) => {
  const dispatch = useDispatch();
  const productCode = useSelector(selectProductCode);
  const adminRoles = useSelector(selectAdminRoles);
   const isFirstLogin = useSelector(selectIsFirstLogin);
  const { t } = useTranslation();
  const hadCreatedAdmin = useSelector(selectHadCreatedAdmin);
  const history = useHistory();
  const user = useSelector(reselectUser);
  const [showSettings, setShowSettings] = React.useState<boolean>(true);
  const [showAdminDialog, setShowAdminDialog] = React.useState<boolean>(true);
  // const closeModal = () => {
  //   setShowSettings(false);
  //   setShowAdminDialog(false);
  // };

  useEffect(() => {
    if (hadCreatedAdmin) {
      history.push(`/admin/onboarding/${productCode}`);
    }
  }, [hadCreatedAdmin, history, productCode]);

  useEffect(() => {
    if (productCode && !adminRoles.length && user.userRoleId === 1) {
      dispatch(getAdminRoles(productCode));
    }
  }, [adminRoles.length, dispatch, productCode, user.userRoleId]);

  return (
     <AdminDialog
        show={showAdminDialog}
        title={user.userRoleId === 1 ? t('onboarding.adminCreation.addAdmin') : t('onboarding.adminCreation.addProductAdmin')}
        onClose={onCancel}
      >
      {isFirstLogin && (
        <ConfirmDialog
          cancelBtnName={confirmDialogConfig.closeButtonName}
          close={onCancel}
          description={confirmDialogConfig.description}
          firstLogin={true}
          okBtnName={confirmDialogConfig.okBtnName}
          showModal={showSettings}
          subTitle={confirmDialogConfig.subTitle}
          subTitle2={confirmDialogConfig.subTitle2}
          subTitle3={confirmDialogConfig.subTitle3}
          title={confirmDialogConfig.title}
        />
      )}
      {user.profileSettings && (
        <Fragment>
          <AddAdminForm adminRoles={adminRoles} comments='' onCancel={onCancel} />
        </Fragment>
      )}
      </AdminDialog>
     );
};
