/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/imports-first */
import React, { useState } from 'react';
import cs from 'classnames';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { reselectUser } from 'store/auth';
import { selectProductCode, selectProductTitle } from 'store/product';
import { reselectLocaleCode } from 'store/localize';
import { BlankLink } from 'components/BlankLink/BlankLink';
// import { apiService } from 'shared/services';
// import { BASE_HTTP_HEADERS } from 'shared/constants/api.consts';
// import { FOOTER_LINK } from 'shared/constants/footer-link.consts';
import styles from './Footer.module.css';
import { CONFIG } from 'shared/constants/config.consts';
import { Dialog } from '../../components/Dialog/Dialog';
import NewTabIcon from 'assets/images/modal-share-icon.svg';
import { USER_ROLES } from '../AdminPage/OnboardingPage/OnboardingFilters/OnboardingFilters';
import { getFromLocalStorage, setToLocalStorage } from 'shared/utils/storage.utils';
import { ModalCustom } from '../../components/Modal/Modal';
import { selectRegistrationCountryCode, selectProductCountries, selectCountryCodes } from 'store/registration';
import { GLOBAL_PRIVACY_STATEMENT } from 'shared/constants/common.consts';


interface Props {
  className?: string;
}

function setModalStyles () {
  document.getElementById("onetrust-pc-sdk").classList.remove("ot-hide");
  document.getElementById("onetrust-pc-sdk").style.display="block";
  document.getElementById("onetrust-pc-sdk").style.visibility="visible";
  document.getElementById("onetrust-pc-sdk").style.opacity="1";

  document.querySelector<HTMLElement>('#onetrust-consent-sdk .onetrust-pc-dark-filter').classList.remove("ot-hide");
  document.querySelector<HTMLElement>('#onetrust-consent-sdk .onetrust-pc-dark-filter').style.display="block";
  document.querySelector<HTMLElement>('#onetrust-consent-sdk .onetrust-pc-dark-filter').style.visibility="visible";
  document.querySelector<HTMLElement>('#onetrust-consent-sdk .onetrust-pc-dark-filter').style.opacity="1";
}

export function Footer({ className }: Props): JSX.Element {
  const location = useLocation();
  const user = useSelector(reselectUser);
  const { t } = useTranslation();
  const localeCode = useSelector(reselectLocaleCode);
  const Code = useSelector(selectProductCode);
  const [modalShow, setModalShow] = useState(false);
  //const Title = useSelector(selectProductTitle);
  const [isOpen, setIsOpen] = useState(false);
  const countryCode = useSelector(selectRegistrationCountryCode);
  const environment = (window as any).APP_CONFIG.ENV;
    let CookieNoticeFile = 'LOCAL-GlobalCookieNotice.pdf';
    if (environment === 'DEV') CookieNoticeFile = 'DEV-GlobalCookieNotice.pdf';
    else if (environment === 'QA') CookieNoticeFile = 'QA-GlobalCookieNotice.pdf';
    else if (environment === 'STAGE') CookieNoticeFile = 'STAGE-GlobalCookieNotice.pdf';
    else if (environment === 'LOAD') CookieNoticeFile = 'LOAD-GlobalCookieNotice.pdf';
    else if (environment === 'PROD') CookieNoticeFile = 'PROD-GlobalCookieNotice.pdf';

    let DRACookieNoticeFile = 'LOCAL-DRACookieNotice.pdf';
    if (environment === 'DEV') DRACookieNoticeFile = 'DEV-DRACookieNotice.pdf';
    else if (environment === 'QA') DRACookieNoticeFile = 'QA-DRACookieNotice.pdf';
    else if (environment === 'STAGE') DRACookieNoticeFile = 'STAGE-DRACookieNotice.pdf';
    else if (environment === 'LOAD') DRACookieNoticeFile = 'LOAD-DRACookieNotice.pdf';
    else if (environment === 'PROD') DRACookieNoticeFile = 'PROD-DRACookieNotice.pdf';
  // const isAgreed: any = getFromLocalStorage<string>('isAgreed');
  // const headers: Record<string, string> = { ...BASE_HTTP_HEADERS };
  // const documentDownload = (doc: any): any => {
  //   fetch(apiService.getAssetUrl(doc), {
  //     method: 'GET',
  //     headers
  //   }).then(response => {
  //     response.blob().then((blob: any) => {
  //       const url = window.URL.createObjectURL(blob);
  //       window.open(url);
  //     });
  //   });
  // };
  const openModalPrivacy = () => {
    setModalShow(true);
  }; 
let privacyLinks;
  const getCountryPrivacyLink = () => {
    if(countryCode.toLocaleLowerCase() === "sgp" || countryCode.toLocaleLowerCase() === "mys"){
      privacyLinks = `https://www2.deloitte.com/${codesISO[countryCode]}/en/footerlinks1/privacy.html?icid=bottom_privacy`;
    } else if(countryCode.toLocaleLowerCase() === "gbr"){ 
      privacyLinks =`https://www2.deloitte.com/uk/en/legal/privacy.html`;
    } else {
      privacyLinks =`https://www2.deloitte.com/${codesISO[countryCode]}/en/legal/privacy.html`;
    }
    return privacyLinks;
  };

  const productCountries = useSelector(selectProductCountries);
  const country = (productCountries || []).find(
    (productCountry: DTO.Country) => productCountry.code === countryCode
  );

  const codesISO = useSelector(selectCountryCodes);

  let privacyHrefCountryCode = '';

  if(countryCode){
    const globalPrivacyData = GLOBAL_PRIVACY_STATEMENT.find((item) => item.countryCode === countryCode);
    const linkByProduct = Code === "dra" ? globalPrivacyData?.privacyStatement : getCountryPrivacyLink();
    privacyHrefCountryCode = countryCode !== null ? linkByProduct : '';
  }

  return (
    <footer className={cs(styles.footer, className)}>
      <ModalCustom        
        show={modalShow}
        title = 'Privacy'
        onHide={() => setModalShow(false)}
      >
        <React.Fragment>
          <p>
          {Code === "dra" && location.pathname.toLowerCase().includes('registration/dra') ?(
        <a href={`/docs/dra/${localeCode}/footer-privacy.pdf`} rel="noopener noreferrer" target="_blank">
          Global Privacy Statement
        </a>
      ) 
      : (
        <a href={`/docs/${localeCode}/footer-privacy.pdf`} rel="noopener noreferrer" target="_blank">
          Global Privacy Statement
        </a>
      )}
          </p>
          {country &&
            (
            <p>
              <a href={privacyHrefCountryCode} rel="noopener noreferrer" target="_blank">
                {`${country.name} Privacy Statement`}
              </a>
            </p>
            )
          }
        </React.Fragment>
      </ModalCustom>
      <div>
        {/* <div className='mt-2'>
          <Trans i18nKey='app.footerParaTwo'>
            None of the Deloitte organization shall be responsible for any loss
            whatsoever sustained by any person who relies on this communication.
            Deloitte is a leading global provider of audit and assurance,
            consulting, financial advisory, risk advisory, tax and related
            services. Our network of member firms in more than 150 countries and
            territories serves four out of five Fortune Global 500® companies.
            Learn how Deloitte’s approximately 312,000 people make an impact
            that matters at&nbsp;
            <strong>
              <a
                href='https://www2.deloitte.com/'
                rel='noopener noreferrer'
                target='_blank'
              >
                www.deloitte.com.
              </a>
            </strong>
            &nbsp;.
          </Trans>
        </div>
        <div className='mt-2'>
          <Trans i18nKey='app.footerParaThree'>
            This communication contains general information only, and none of
            Deloitte Touche Tohmatsu Limited (&ldquo;DTTL&rdquo;), its global
            network of member firms or their related entities (collectively, the
            &ldquo;Deloitte organization&rdquo;) is, by means of this
            communication, rendering professional advice or services. Before
            making any decision or taking any action that may affect your
            finances or your business, you should consult a qualified
            professional adviser.
          </Trans>
        </div>
        <div className='mt-2'>
          <Trans i18nKey='app.footerParaFour'>
            No representations, warranties or undertakings (express or implied)
            are given as to the accuracy or completeness of the information in
            this communication, and none of DTTL, its member firms, related
            entities, employees or agents shall be liable or responsible for any
            loss or damage whatsoever arising directly or indirectly in
            connection with any person relying on this communication. DTTL and
            each of its member firms, and their related entities, are legally
            separate and independent entities.
          </Trans>
        </div> */}
        <p className='mt-2 mb-2'>
          <strong>
            {(Code === null ||
              (user?.role === USER_ROLES.SUPER_ADMIN &&
                location.pathname.toLowerCase().includes('home'))) && (
                  <Trans
                  components={[
                    <BlankLink key={0} href={`/docs/${localeCode}/footer-privacy.pdf`}/>,
                    <BlankLink key={1} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                    <BlankLink key={2} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                  ]}
                  i18nKey='app.footerCopyrightShort'
                  values={{ year: new Date().getFullYear() }}
                />
              )}
            {
            (user?.role === USER_ROLES.SUPER_ADMIN && (location.pathname.toLowerCase().includes('admin/onboarding') || location.pathname.toLowerCase().includes('admin/submissions'))) && (
                <Trans
                  components={[
                    <BlankLink key={0} href={`/docs/${localeCode}/footer-privacy.pdf`} />,
                    <BlankLink key={1} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                    <BlankLink key={2} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                  ]}
                  i18nKey='app.footerCopyrightShort'
                  values={{ year: new Date().getFullYear() }}
                />
              )}
            {Code === "sbp" && countryCode === null && (location.pathname.toLowerCase().includes('registration/sbp')) && getFromLocalStorage("isTermsAgreed") == null &&
              (
                <Trans
                  components={[
                    <BlankLink key={0} href={`/docs/${localeCode}/footer-privacy.pdf`} />,
                    <BlankLink key={1} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                    <BlankLink key={2} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                  ]}
                  i18nKey='app.footerCopyrightShort'
                  values={{ year: new Date().getFullYear() }}
                />
              )}
            {Code === "sbp" && countryCode !== null && (location.pathname.toLowerCase().includes('registration/sbp')) && getFromLocalStorage("isTermsAgreed") == null &&
              (
                <Trans
                  components={[
                    <BlankLink key={0} onClickHandler={openModalPrivacy} />,
                    <BlankLink key={1} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                    <BlankLink key={2} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                  ]}
                  i18nKey='app.footerCopyrightShort'
                  values={{ year: new Date().getFullYear() }}
                />
              )}              
            {Code === "sbp" && (location.pathname.toLowerCase().includes('registration/sbp')) && getFromLocalStorage("isTermsAgreed") === true &&
              (
                <Trans
                  components={[
                    <BlankLink key={0} href={`/acceptableusepolicy/${localeCode}/${getFromLocalStorage("CountryCode").toString().replaceAll(' ', '')}/${CONFIG.AUP_SBP_URL}`} />,
                    <BlankLink key={1} onClickHandler={openModalPrivacy} />,
                    <BlankLink key={2} className='optanon-toggle-display' onClickHandler={setModalStyles} />,
                    <BlankLink key={3} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                  ]}
                  i18nKey='app.footerCopyrightSBP'
                  values={{ year: new Date().getFullYear() }}
                />
              )}
              {Code === "dra" && countryCode === null && (location.pathname.toLowerCase().includes('registration/dra')) && getFromLocalStorage("isTermsAgreed") === null &&
              (
                <Trans
                  components={[
                    <BlankLink key={0} href={`/docs/dra/${localeCode}/footer-privacy.pdf`} />,
                    <BlankLink key={1} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                    <BlankLink key={2} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                  ]}
                  i18nKey='app.footerCopyrightDRAShort'
                  values={{ year: new Date().getFullYear() }}
                />
              )}
              {Code === "dra" && countryCode !== null && (location.pathname.toLowerCase().includes('registration/dra')) && getFromLocalStorage("isTermsAgreed") === null &&
              (
                <Trans
                  components={[
                    <BlankLink key={0} onClickHandler={openModalPrivacy} />,
                    <BlankLink key={1} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                    <BlankLink key={2} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                  ]}
                  i18nKey='app.footerCopyrightDRAShort'
                  values={{ year: new Date().getFullYear() }}
                />
              )}       
              {Code === "dra" && (location.pathname.toLowerCase().includes('registration/dra')) && getFromLocalStorage("isTermsAgreed") &&
              (
                <Trans
                  components={[
                    <BlankLink key={0} href={`/acceptableusepolicy/${localeCode}/${getFromLocalStorage("CountryCode").toString().replaceAll(' ', '')}/${CONFIG.AUP_DRA_URL}`} />,
                    <BlankLink key={1} onClickHandler={openModalPrivacy} />,
                    <BlankLink key={2} className='optanon-toggle-display' onClickHandler={setModalStyles} />,
                    <BlankLink key={3} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                  ]}
                  i18nKey='app.footerCopyrightDRA'
                  values={{ year: new Date().getFullYear() }}
                />
              )}

              {(Code === "sbp" && user?.role !== USER_ROLES.SUPER_ADMIN && (location.pathname.toLowerCase().includes('submissions') || location.pathname.toLowerCase().includes('onboarding') || location.pathname.toLowerCase().includes('update-subscription')) ) && (
              <Trans
                components={[
                  <BlankLink key={0} href={`/docs/${localeCode}/footer-privacy.pdf`} />,
                  <BlankLink key={1} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                  <BlankLink key={2} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                ]}
                i18nKey='app.footerCopyrightSBPShort'
                values={{ year: new Date().getFullYear() }}
              />
            )}
            {(Code === "dra" && user?.role !== USER_ROLES.SUPER_ADMIN && (location.pathname.toLowerCase().includes('submissions') || location.pathname.toLowerCase().includes('onboarding') || location.pathname.toLowerCase().includes('update-subscription')) ) && (
              <Trans
                components={[
                 <BlankLink key={0} href={`/docs/${localeCode}/footer-privacy.pdf`} />,
                 <BlankLink key={1} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                  <BlankLink key={0} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                ]}
                i18nKey='app.footerCopyrightDRAShort'
                values={{ year: new Date().getFullYear() }}
              />
            )}
            {Code === "ibr" && countryCode === null && (location.pathname.toLowerCase().includes('registration/ibr')) && getFromLocalStorage("isTermsAgreed") == null && (
              <Trans
                components={[
                  <BlankLink key={0} href={`/docs/${localeCode}/footer-privacy.pdf`} />,
                  <BlankLink key={1} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                  <BlankLink key={2} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                ]}
                i18nKey='app.footerCopyrightShort'
                values={{ year: new Date().getFullYear() }}
              />
            )}
            {Code === "ibr" && countryCode !== null && (location.pathname.toLowerCase().includes('registration/ibr')) && getFromLocalStorage("isTermsAgreed") == null && (
              <Trans
                components={[
                  <BlankLink key={0} onClickHandler={openModalPrivacy} />,
                  <BlankLink key={1} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                  <BlankLink key={2} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                ]}
                i18nKey='app.footerCopyrightShort'
                values={{ year: new Date().getFullYear() }}
              />
            )}            
            {Code === "ibr" && (location.pathname.toLowerCase().includes('registration/ibr')) && getFromLocalStorage("isTermsAgreed") === true &&
              (
                <Trans
                  components={[
                    <BlankLink key={0} href={`/termsofuse/${localeCode}/${getFromLocalStorage("CountryCode").toString()}/GASS-ToU-IBR.pdf`} />,
                    <BlankLink key={1} onClickHandler={openModalPrivacy} />, 
                    <BlankLink key={2} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                    <BlankLink key={3} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                  ]}
                  i18nKey='app.footerCopyright'
                  values={{ year: new Date().getFullYear() }}
                />
              )}
              {Code === "ibr" && (user?.role !== USER_ROLES.SUPER_ADMIN && (location.pathname.toLowerCase().includes('submissions') || location.pathname.toLowerCase().includes('onboarding') || location.pathname.toLowerCase().includes('update-subscription'))) && (
              <Trans
                components={[
                  <BlankLink key={0} onClickHandler={openModalPrivacy} />,
                  <BlankLink key={1} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                  <BlankLink key={2} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                ]}
                i18nKey='app.footerCopyrightIBR'
                values={{ year: new Date().getFullYear() }}
              />
            )}
            {Code === "mip" && countryCode === null && (location.pathname.toLowerCase().includes('registration/mip')) && getFromLocalStorage("isTermsAgreed") == null &&
              (
                <Trans
                  components={[
                    <BlankLink key={0} href={`/docs/${localeCode}/footer-privacy.pdf`} />,
                    <BlankLink key={1} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                    <BlankLink key={2} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                  ]}
                  i18nKey='app.footerCopyrightMIPShort'
                  values={{ year: new Date().getFullYear() }}
                />
              )}
            {Code === "mip" && countryCode !== null && (location.pathname.toLowerCase().includes('registration/mip')) && getFromLocalStorage("isTermsAgreed") == null &&
              (
                <Trans
                  components={[
                    <BlankLink key={0} onClickHandler={openModalPrivacy} />,
                    <BlankLink key={1} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                    <BlankLink key={2} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                  ]}
                  i18nKey='app.footerCopyrightMIPShort'
                  values={{ year: new Date().getFullYear() }}
                />
              )}              
              {Code === "mip" && (location.pathname.toLowerCase().includes('registration/mip')) && getFromLocalStorage("isTermsAgreed") === true &&
              (
                <Trans
                  components={[
                    <BlankLink key={0} href={`/acceptableusepolicy/${localeCode}/${getFromLocalStorage("CountryCode").toString().replaceAll(' ', '')}/${CONFIG.AUP_MIP_URL}`} />,
                    <BlankLink key={1} onClickHandler={openModalPrivacy} />,
                    <BlankLink key={2} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                    <BlankLink key={3} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                  ]}
                  i18nKey='app.footerCopyrightMIP'
                  values={{ year: new Date().getFullYear() }}
                />
              )}

              {Code === "mip" && (user?.role !== USER_ROLES.SUPER_ADMIN && (location.pathname.toLowerCase().includes('submissions') || location.pathname.toLowerCase().includes('onboarding') || location.pathname.toLowerCase().includes('update-subscription'))) && (
              <Trans
                components={[
                  <BlankLink key={0} onClickHandler={openModalPrivacy} />,
                  <BlankLink key={1} className='optanon-toggle-display' onClickHandler={setModalStyles}/>,
                  <BlankLink key={2} href={`/docs/${localeCode}/footer-notices.pdf`} />,
                ]}
                i18nKey='app.footerCopyrightMIPShort'
                values={{ year: new Date().getFullYear() }}
              />
              )}
              
            {/* <Trans
              components={[
                Code === "" ? <BlankLink key={0} onClickHandler={() => { setIsOpen(true); }} /> :
                  Code === "sbp" ? <BlankLink key={0} href={`/termsofuse/${localeCode}/GASS-ToU-SBP.pdf`} /> :
                    <BlankLink key={0} href={`/termsofuse/${localeCode}/GASS-ToU-IBR.pdf`} />,
                <BlankLink key={1} href={`/docs/${localeCode}/footer-privacy.pdf`} />,
                <BlankLink key={2} href={`/docs/${localeCode}/footer-notices.pdf`} />,
              ]}
              i18nKey='app.footerCopyright'
              values={{ year: new Date().getFullYear() }}
            /> */}
            {isOpen && (
              <Dialog
                actions={[]}
                className={styles.detailDialog}
                noCaps={false}
                show={isOpen}
                showCrossButton={true}
                size='sm'
                title={t('dialogs.termsofuse')}
                onClose={() => {
                  setIsOpen(false);
                }}
              >
                <div className={styles.textBold}>
                  {t('dialogs.termsofusesub')}
                </div>
                <div>
                  <a
                    href={`/termsofuse/${localeCode}/GASS-ToU-IBR.pdf`}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    {`${t('registration.data.title.ibr')} (IBR)`}
                    <img
                      alt=''
                      className={styles.newtabIcon_space}
                      src={NewTabIcon}
                    />
                  </a>
                </div>
                <div>
                  <a
                    href={`/termsofuse/${localeCode}/GASS-ToU-SBP.pdf`}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    {`${t('registration.data.title.sbp')} (SBP)`}
                    <img
                      alt=''
                      className={styles.newtabIcon_space}
                      src={NewTabIcon}
                    />
                  </a>
                </div>
                <div>
                  {/* <a href={`/termsofuse/${localeCode}/GASS-ToU-MIP.pdf`} rel='noopener noreferrer' target='_blank'>
                    {`${t('registration.data.title.mip')} (MIP)`}
                    <img
                      alt=''
                      className={styles.newtabIcon_space}
                      src={NewTabIcon}
                    /></a> */}
                  <a
                    href={
                      // `/termsofuse/${localeCode}/GASS-AUP-MIP.pdf`
                      `/acceptableusepolicy/${localeCode}/${CONFIG.AUP_MIP_URL}`
                    }
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    {`${t('registration.data.title.mip')} (MIP)`}
                    <img
                      alt=''
                      className={styles.newtabIcon_space}
                      src={NewTabIcon}
                    />
                  </a>
                </div>
              </Dialog>
            )}
          </strong>
        </p>

        {/* <div className='mt-2'>
          © {new Date().getFullYear()}&nbsp;
          <Trans>For information, contact Deloitte Global.</Trans>
        </div> */}
        <div>
          <Trans i18nKey='app.footer'>
            Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited
            (&ldquo;DTTL&rdquo;), its global network of member firms, and their
            related entities (collectively, the &ldquo;Deloitte
            organization&rdquo;). DTTL (also referred to as &ldquo;Deloitte
            Global&rdquo;) and each of its member firms and related entities are
            legally separate and independent entities, which cannot obligate or
            bind each other in respect of third parties. DTTL and each DTTL
            member firm and related entity is liable only for its own acts and
            omissions, and not those of each other. DTTL does not provide
            services to clients. Please see&nbsp;
            {/* Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited,
             a UK private company limited by guarantee (&ldquo;DTTL&rdquo;), its network of 
             member firms, and their related entities. DTTL and each of its member
              firms are legally separate and independent entities. DTTL
               (also referred to as &ldquo;Deloitte Global&rdquo;) does not provide services to clients
            . Please see &nbsp;  */}
            <strong>
              <a
                href='https://www2.deloitte.com/global/en/pages/about-deloitte/articles/about-the-network.html'
                rel='noopener noreferrer'
                target='_blank'
              >
                www.deloitte.com/about
              </a>
            </strong>
            {/* &nbsp;to learn more. */}
            &nbsp;for a more detailed description of DTTL and its member firms.
          </Trans>
        </div>
      </div>
    </footer>
  );
}
