import React from 'react';
import cs from 'classnames';
import { noop } from 'lodash';

import { Paginator } from './Paginator/Paginator';
import { PageNumberInput } from './PageNumberInput/PageNumberInput';
import { PageSizeSelector } from './PageSizeSelector/PageSizeSelector';
import styles from './Pagination.module.css';

interface Props {
  total: number;
  onPageChange: (currentPage: number) => void;

  className?: string;
  pageSize?: number;
  currentPage?: number;
  withPageSizeSelector?: boolean;
  withPageNumberInput?: boolean;
  pageSizeOptions?: number[];
  onPageSizeChange?: (pageSize: number) => void;
  visiblePagesCount?: number;
  disabled?: boolean;
  reversedMenuList?: boolean;
}

export const Pagination: React.FC<Props> = ({
  total,
  onPageChange,

  className = '',
  pageSize = 25,
  currentPage = 1,
  withPageSizeSelector = false,
  withPageNumberInput = false,
  visiblePagesCount = 5,
  disabled = false,
  pageSizeOptions = [25, 50, 75, 100],
  onPageSizeChange = noop,
  reversedMenuList = false
}) => {
  const lastPage = Math.ceil(total / pageSize);

  const onPageSizeChangeCb = React.useCallback(
    size => {
      if (currentPage * size - total >= size) onPageChange(1);
      onPageSizeChange(size);
    },
    [currentPage, total, onPageChange, onPageSizeChange]
  );

  return (
    <div className={cs(styles.container, className)}>
      <div className={styles.paginator}>
        {/* {withPageNumberInput && (
          <PageNumberInput
            currentPage={currentPage}
            disabled={disabled}
            maxPage={lastPage}
            onPageChange={onPageChange}
          />
        )} */}
        <Paginator
          currentPage={currentPage}
          disabled={disabled}
          lastPage={lastPage}
          visiblePagesCount={visiblePagesCount}
          onPageChange={onPageChange}
        />
      </div>
      {/* {withPageSizeSelector && (
        <PageSizeSelector
          currentPage={currentPage}
          disabled={disabled}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          reversedMenuList={reversedMenuList}
          total={total}
          onPageSizeChange={onPageSizeChangeCb}
        />
      )} */}
    </div>
  );
};
