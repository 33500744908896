import React from 'react';
import { useTranslation } from 'react-i18next';

import { SORT_DIRECTION } from 'shared/constants/common.consts';
import { AdminGrid } from '../AdminGrid/AdminGrid';
import { SubmissionContainer } from './Submission/Submission.container';
import styles from './SubmissionsGrid.module.css';

interface Props {
  submissions: DTO.SubmissionRecord[];
  productRoles: Array<any>;
  showUserIcon: boolean;
  handleGetGridData: () => Promise<void>;
  gridHeaders: Array<any>;
  sortBy: string;
  direction: AP.SortDirection;
  onSortChange: (sortBy: string, sort: AP.SortDirection) => void;
  userRole: string;
}

export const SubmissionsGrid: React.FC<Props> = ({
  submissions = [],
  productRoles,
  showUserIcon,
  handleGetGridData,
  gridHeaders,
  sortBy,
  onSortChange,
  direction,
  userRole
}) => {
  const { t } = useTranslation();

  return (
    <AdminGrid<DTO.SubmissionRecord>
      className={styles.grid}
      dataKey='displayId'
      direction={direction}
      gridData={submissions}
      gridHeaders={gridHeaders}
      isSubmissionRecord={true}
      offset={30}
      productRoles={productRoles}
      rowDataRenderer={React.useCallback(
        (submission: DTO.SubmissionRecord) => (
          <SubmissionContainer
            handleGetGridData={handleGetGridData}
            productRoles={productRoles}
            showUserIcon={showUserIcon}
            submission={submission}
          />
        ),
        [productRoles, showUserIcon, handleGetGridData]
      )}
      sortBy={sortBy}
      userRole={userRole}
      onSortChange={onSortChange}
    />
  );
};
