import React from 'react';
import { components } from 'react-select';
import { MenuProps } from 'react-select/src/components/Menu';

import styles from './Menu.module.css';

export function Menu<T = AP.SelectOption>(
  props: MenuProps<T, boolean>
): JSX.Element {
  return <components.Menu {...props} className={styles.container} />;
}
