import { ThunkDispatch } from 'redux-thunk';

import { apiService, i18nService as i18n } from 'shared/services';
import {
  LocaleState,
  SET_LOCALE,
  FETCH_LOCALE_LIST,
  // SetLocaleAction$,
  FetchLocaleList$
} from './localize.types';

// export const setLocale = (data: any): SetLocaleAction$ => ({
//   type: SET_LOCALE,
//   payload: data
// });

export const fetchLocaleList = (): FetchLocaleList$ => ({
  type: FETCH_LOCALE_LIST,
  payload: apiService.fetchLocaleList()
});

export const changeLanguage = (localeCode: string): any => {
  const hasResource = i18n.hasResourceBundle(localeCode, 'translation');
  return (dispatch: ThunkDispatch<LocaleState, null, any>): void => {
    const setLanguage = (code: string) => {
      i18n.changeLanguage(code);
      dispatch({
        type: SET_LOCALE,
        payload: code
      });
    };
    if (!hasResource) {
      apiService.getDictionary(localeCode).then((data: any) => {
        i18n.addResourceBundle(data.localeCode, 'translation', {
          ...data.dictionary
        });
        setLanguage(data.localeCode);
      });
    } else {
      setLanguage(localeCode);
    }
  };
};
