import React from 'react';
import { Form } from 'react-bootstrap';

import { noop } from 'shared/utils';
import { Icon } from 'components/Icon/Icon';
import styles from './Checkbox.module.css';

interface Props {
  label: string;
  name: string;
  labelClass?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  miconClass?: string;
}

export const Checkbox: React.FC<Props> = ({
  label,
  labelClass = '',
  name,
  disabled = false,
  checked = false,
  onChange = noop,
  miconClass = ''
}) => (
  <Form.Check className={styles.container} id={label} type='checkbox'>
    <Form.Check.Input
      checked={checked}
      className={styles.check}
      disabled={disabled}
      name={name}
      onChange={onChange}
    />
    <Form.Check.Label className={`${styles.label} ${labelClass}`}>
      <Icon
        className={(miconClass==='customIcon') ? styles.customcheckedIcon : styles.checkedIcon }
        name='checkbox-tick-special'
        size={(miconClass==='customIcon') ? 32 : 20}
      />
      <span>{label}</span>
    </Form.Check.Label>
  </Form.Check>
); 
