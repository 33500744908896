import { useEffect } from 'react';

import { noop } from '../utils';

const events = [
  'mousemove',
  'keydown',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'scroll'
];

export const useEventsTracking = (actionCallback = noop, deps?: any[]) => {
  useEffect(() => {
    events.forEach(item => document.addEventListener(item, actionCallback));

    return () =>
      events.forEach(item =>
        document.removeEventListener(item, actionCallback)
      );
  }, [deps, actionCallback]);
};
