import React from 'react';

import styles from './Sidebar.module.css';
import { Header } from './Header';
import { Footer } from './Footer';

type Props = { children: JSX.Element[] };

export function Sidebar({ children }: Props): JSX.Element {
  return (
    <React.Fragment>
      <div className={styles.Overlay} />
      <div className={styles.Sidebar}>{children}</div>
    </React.Fragment>
  );
}

Sidebar.Header = Header;
Sidebar.Footer = Footer;
