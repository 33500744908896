import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {
  AuthenticationState,
  AzureAD,
  IAzureADFunctionProps
} from 'react-aad-msal';

import { setProductFromPath } from 'store/product';
import {
  AdminRoutes,
  TermsOfUseRoutes,
  OtherRoutes,
  RegistrationRoutes
} from './components';
import { APP_PATH, RoutesContext } from './Routes.helpers';
import { authProvider } from '../../authProvider';

export const Routes = (): JSX.Element => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const setProductCallback = useCallback((): void => {
    dispatch(setProductFromPath(pathname));
  }, [dispatch, pathname]);

  const adminRoutes = useCallback(() => {
    if (window.location.hostname === 'localhost') {
      return <AdminRoutes />;
    }
    return (
      <AzureAD provider={authProvider}>
        {({ authenticationState }: IAzureADFunctionProps) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              return <AdminRoutes />;
            case AuthenticationState.Unauthenticated:
              return <Redirect to={APP_PATH.HOME} />;
            default:
              return null;
          }
        }}
      </AzureAD>
    );
  }, []);

  const termsOfUseRoutes = useCallback(() => {
    if (window.location.hostname === 'localhost') {
      return <TermsOfUseRoutes />;
    }

    return (
      <AzureAD forceLogin={true} provider={authProvider}>
        <TermsOfUseRoutes />
      </AzureAD>
    );
  }, []);

  return (
    <RoutesContext.Provider value={{ setProductCallback }}>
      <Switch>
        <Route path={APP_PATH.ADMIN_ROOT} render={adminRoutes} />
        <Route path={APP_PATH.EXTERNAL_TERMS_OF_USE} render={termsOfUseRoutes} />
        <Route path={APP_PATH.INTERNAL_TERMS_OF_USE} render={termsOfUseRoutes} />
        {/* <ExternalUserRoute isAuthenticated ={hasUser} isInternal ={isInternal} component={TermsOfUseExternal} path={APP_PATH.TERMS_OF_USE} /> */}

        <Route
          component={RegistrationRoutes}
          path={APP_PATH.REGISTRATION_ROOT}
        />

        <Route component={OtherRoutes} path={APP_PATH.ROOT} />
      </Switch>
    </RoutesContext.Provider>
  );
};
