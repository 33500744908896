import React from 'react';
import { Form } from 'react-bootstrap';

import { noop } from 'shared/utils';
import styles from './Radio.module.css';

interface Props {
  name: string;
  label: string;
  id: string;

  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent) => void;
}

export const Radio: React.FC<Props> = ({
  name,
  label,
  checked = false,
  id,
  disabled = false,
  onChange = noop
}) => (
  <Form.Check className={styles.container} id={id} name={name}>
    <Form.Check.Input
      checked={checked}
      className={styles.check}
      disabled={disabled}
      name={name}
      type='radio'
      onChange={onChange}
    />
    <Form.Check.Label className={styles.label}>
      <span>{label}</span>
    </Form.Check.Label>
  </Form.Check>
);
