import React from 'react';
import { withFormik } from 'formik';

import { noop } from 'shared/utils';

export interface CompaniesQuery {
  countryList: string[];
  companyName: string;
  planStatus: string;
}

export interface UsersQuery {
  UserName: string;
  RoleId?: number;
  planStatus?: string;
}
export interface AdminQuery {
  Email: string;
  RoleId: number;
}

export interface FiltersFormProps<T> {
  value: T;
  appendTo: React.RefObject<HTMLElement>;
  onChange: (value: T, toggle?: boolean) => void;
  onClose?: (toggle: boolean) => void;
  showFilters: DTO.FilterFields[];
  countryOptionValue: AP.CountryOption;
  fetchAdminCountries?: boolean;
  fetchSubmissionsCountries?: boolean;
}

export function FiltersFormEnhancer<T>() {
  return withFormik<
    FiltersFormProps<T>,
    T
  >({
    mapPropsToValues: ({ value }) => value,
    handleSubmit: noop
  });
}
