import { apiService } from 'shared/services';
import {
  FETCH_PRODUCT_COUNTRIES,
  FetchProductCountriesAction$,
  FETCH_COUNTRY_CODES,
  FetchCountryCodesAction$,
  FETCH_TERMS_OF_USE,
  FETCH_INTERNAL_TERMS_OF_USE,
  FetchTermsOfUseAction$,
  SET_REGISTRATION_COUNTRY_CODE,
  SetRegistrationCountryCodeAction,
  FETCH_COUNTRY_STATES,
  FetchCountryStates$,
  FETCH_PRODUCT_PLANS,
  FetchProductPlansAction$,
  SUBMIT_REGISTRATION_DATA,
  SubmitRegistrationAccountDetails,
  SetRegistrationPlansAction,
  SET_REGISTRATION_PLANS,
  SetRegistrationTermsOfUseAgreement,
  SET_REGISTRATION_TERMS_OF_USE_AGREEMENT
} from './registration.types';

export const fetchProductCountries = (
  product: string
): FetchProductCountriesAction$ => ({
  type: FETCH_PRODUCT_COUNTRIES,
  payload: apiService.fetchProductCountries(product)
});

export const fetchCountryCodes = (): FetchCountryCodesAction$ => ({
  type: FETCH_COUNTRY_CODES,
  payload: apiService.fetchCountryCodes().then(
    (response: DTO.CountryCode[]): Record<string, string> =>
      response.reduce(
        (
          acc: Record<string, string>,
          { iso2Code, iso3Code }: DTO.CountryCode
        ) => ({
          ...acc,
          [iso3Code]: iso2Code
        }),
        {}
      )
  )
});

export const fetchTermsOfUse = (
  product: string,
  countryCode: string
): FetchTermsOfUseAction$ => ({
  type: FETCH_TERMS_OF_USE,
  payload: apiService.fetchTermsOfUse(product, countryCode)
});

export const fetchInternalTermsOfUse = (
  product: string,
  countryCode: string
): FetchTermsOfUseAction$ => ({
  type: FETCH_INTERNAL_TERMS_OF_USE,
  payload: apiService.fetchInternalTermsOfUse(product, countryCode)
});


// export const fetchCountryStates = (
//   countryCode: string
// ): FetchCountryStates$ => ({
//   type: FETCH_COUNTRY_STATES,
//   payload: apiService
//     .fetchCountryStates(countryCode)
//     .then((response: string[]) =>
//       response.map((state: string) => ({ name: state, code: state }))
//     )
// });

export const fetchCountryStates = (
  countryCode: string
): FetchCountryStates$ => ({
  type: FETCH_COUNTRY_STATES,
  payload: apiService
    .fetchCountryStates(countryCode)
    .then((response: DTO.State[]) =>
      response.map((state: DTO.State) => ({
        id: state.id,
        name: state.name,
        code: state.code
      }))
    )
});

export const setRegistrationCountryCode = (
  countryCode: string
): SetRegistrationCountryCodeAction => ({
  type: SET_REGISTRATION_COUNTRY_CODE,
  payload: countryCode
});

export const setRegistrationTermsOfUseAgreement = (
  isAgreed: boolean
): SetRegistrationTermsOfUseAgreement => ({
  type: SET_REGISTRATION_TERMS_OF_USE_AGREEMENT,
  payload: isAgreed
});

export const setRegistrationPlans = (
  plans: DTO.BasePlan[]
): SetRegistrationPlansAction => ({
  type: SET_REGISTRATION_PLANS,
  payload: plans
});

export const fetchProductPlans = (

  product: string,

  countryCode:string

): FetchProductPlansAction$ => ({

  type: FETCH_PRODUCT_PLANS,

  payload: apiService.fetchProductPlans(product,countryCode)

});

export const submitRegistrationAccountDetails = (
  accountDetails: AP.AccountDetailsFormFields
): SubmitRegistrationAccountDetails => ({
  type: SUBMIT_REGISTRATION_DATA,
  payload: accountDetails
});
