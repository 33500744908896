import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { reactPlugin } from 'shared/utils/AppInsights';
import { getWindow } from 'shared/utils';
import { rootReducer, RootState } from 'store/root.reducer';
import { App } from './App';

const composeEnhancers =
  getWindow().__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(promise, thunk as ThunkMiddleware<RootState>)
  )
);

export const AppContainer = (): JSX.Element => (
  <Provider store={store}>
    <BrowserRouter>
      <AppInsightsContext.Provider value={reactPlugin}>
        <App />
      </AppInsightsContext.Provider>
    </BrowserRouter>
  </Provider>
);
