import { useEffect } from 'react';

import { noop } from 'shared/utils';

export const useOnMount = (callback = noop): void => {
  useEffect((): void => {
    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
