import React, { FC } from 'react';
import cs from 'classnames';
import { noop } from 'lodash';

import { Button } from 'components';
import styles from './ThemedButton.module.css';

interface Props {
  children: React.ReactNode;
  onClick?: () => void;

  link?: string;
  filled?: boolean;
  className?: string;
}

export const ThemedButton: FC<Props> = ({
  children,
  onClick = noop,
  link,
  filled,
  className
}) => {
  return (
    <Button
      className={cs(className, styles.button, filled && styles.filled)}
      href={link}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
