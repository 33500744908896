import React from 'react';

import { Icon, Select } from 'components';
import { OptionUser } from './OptionUser';
import styles from './UserSelect.module.css';
import { User } from '../UserList/UserList.helpers';
import { EditIcon } from '../../../../../../icons/EditIcon';

interface Props {
  userList: User[];
  primaryUser: User;
  onSelect: (user: User) => void;
  onMenuClose: () => void;
}

export const UserSelect: React.FC<Props> = ({
  userList,
  primaryUser,
  onSelect,
  onMenuClose
}) => {
  const handleSelectUser = React.useCallback(
    (user: User): void => {
      if (user.email !== primaryUser.email) {
        onSelect(user);
      }
    },
    [primaryUser, onSelect]
  );

  const renderOption = React.useCallback(
    (user: User): JSX.Element => (
      <div className={styles.option}>
        <span
          className={styles.userName}
          title={`${user.firstName} ${user.lastName}`}
        >
          {user.firstName} {user.lastName}
        </span>
        <span className={styles.userEmail} title={user.email}>
          {user.email}
        </span>
        <Icon
          className={styles.selectedOption}
          name='checkbox-tick-special'
          size={24}
        />
      </div>
    ),
    []
  );

  const getOptionValue = React.useCallback(
    ({ email }: User): string => email,
    []
  );

  const additionalThemeProps = {
    borderRadius: 0,
    colors: {
      neutral0: '#FFFFFF',
      primary25: '#EBF8FD',
      primary: '#007CB0'
    },
    spacing: {
      baseUnit: 4,
      controlHeight: 4,
      menuGutter: 2
    }
  };

  return (
    <div className={styles.selectContainer}>
      <Select<User>
        additionalThemeProps={additionalThemeProps}
        className={styles.userSelect}
        getOptionValue={getOptionValue}
        isClearable={false}
        isSearchable={false}
        OptionComponent={OptionUser}
        options={userList}
        renderOption={renderOption}
        value={primaryUser}
        onChange={handleSelectUser}
        onMenuClose={onMenuClose}
      />
      <EditIcon className={styles.iconEdit} />
    </div>
  );
};
