import React from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import cs from 'classnames';

import { noop } from 'shared/utils';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import styles from './Dialog.module.css';
import WarningPng from '../../assets/images/WarningCircle.png';

interface Props extends ModalProps {
  title: string;
  noCaps?:boolean;
  actions?: AP.DialogAction[];
  className?: string;
  showCrossButton?: boolean;
  onClose?: () => void;
  onClosed?: () => void;
}

export function Dialog({
  title,
  children,
  show = false,
  size,
  actions = [],
  className,
  noCaps = true,
  showCrossButton = false,
  onHide = noop,
  onClose = noop,
  onClosed = noop,
}: React.PropsWithChildren<Props>): JSX.Element {
  const footer = React.useMemo(
    () =>
      actions.length ? (
        <Modal.Footer className={cs(styles.footer, className === 'noBorderAndPadding' ? cs(styles.noBorder, styles.paddingTop_0) : '') }>
          {actions.map(
            ({
              label,
              onClick,
              variant = 'primary',
              disabled = false
            }: AP.DialogAction): JSX.Element => (
              <Button
                key={label}
                className={styles.action}
                disabled={disabled}
                thin={true}
                variant={variant}
                onClick={onClick}
              >
                {label}
              </Button>
            )
          )}
        </Modal.Footer>
      ) : null,
    [actions, className]
  );

  return (
    <Modal
      centered={true}
      className={cs(styles.modal, className)}
      show={show}
      size={size}
      onExited={onClosed}
      onHide={onHide}
    >
      <Modal.Header
        className={cs(
          styles.header,
          className === 'noBorderAndPadding'
            ? cs(styles.noBorder, styles.paddingBottom_0)
            : ''
        )}
      >
        <Modal.Title className={cs(noCaps ? styles.title : styles.title_caps)}>
          <span className={styles.warningImage}>
            <img alt='ETL' src={WarningPng} />
          </span>
          <span>{title}</span>
        </Modal.Title>
        {/* Below cross button is hidden based on new requirement of Omnia changes */}
        {showCrossButton && (
          <Button
          className={styles.close}
          size='sm'
          variant='outline-secondary'
          onClick={onClose}
        >
          <Icon name='cross-special' size={30} />
        </Button>
        )}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {footer}
    </Modal>
  );
}
