export enum ADDITIONAL_USER_STATE {
  MAIN = 'main',
  REMOVE = 'remove',
  UPDATE = 'update'
}

export interface UserState {
  type: ADDITIONAL_USER_STATE;
  user: AP.Nullable<User>;
}

export interface User {
  email: string;
  firstName: string;
  lastName: string;
}
