import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import cs from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { Dialog, Form, Select, Confirmation} from 'components';
import { apiService } from 'shared/services';
import {
  reselectUser
} from 'store/auth';
import {
  getProducts,
} from 'store/onboarding/onboarding.actions';
import { errorException } from 'shared/utils/AppInsights';
import {
  ConfigureRetentionDialogEnhancer,
  ConfigureRetentionDialogProps,
  ConfigureRetentionDialogForm
} from './ConfigureRetentionDialog.helpers';
import {
  selectCountries,
  selectProductCode
} from '../../../../../store/product/product.selectors';
import styles from './ConfigureRetentionDialog.module.css';
import { selectProducts } from '../../../../../store/onboarding/onboarding.selectors';
import sendMail from '../../../../../assets/images/send-mail.png';
import { UserRoles } from '../../OnboardingFilters/OnboardingFilters';

interface ProductOptions {
  value: string;
  label: string;
}

interface CountryOptions {
  value: string;
  label: string;
}

export function ConfigureRetentionDialogComponent({
  setFieldError,
  values,  
  setValues,
  onSubmit,
  onCancel,
  isValid,
  setconfirmCancel
}:
  ConfigureRetentionDialogProps &
    FormikProps<ConfigureRetentionDialogForm>):JSX.Element {
  const { t } = useTranslation();
  const user = useSelector(reselectUser);
  const productCode = useSelector(selectProductCode);
  const [show, setShow] = React.useState<boolean>(true);
  const [sendValues, setSendValues] = React.useState<boolean>(false);
  const [productOptions, setProductOptions] = useState([]);
  const products = useSelector(selectProducts);
  const [countries, setCountries] = useState(useSelector(selectCountries));
  const [countryOptions, setCountryOptions] = useState([]);
  const [retentionYear, setRetentionYear] = useState<any>('');
  const [disableSave, setDisableSave] = useState(true);
  const [isFormValid, setisFormValid] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [retentionDetails, setRetentionDetails] = useState<DTO.RetentionResponseDto>();
  const [retentionErrMsg, setRetentionErrMsg] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {  
    if(retentionDetails)  {
      setRetentionYear(retentionDetails.retentionYear);
    }
  }, [retentionDetails]);
  
  useEffect(() => {
    setisFormValid(values.product && values.product.trim() &&
      values.country && values.country.trim() &&
      values.retention && /^[1-9][0-9]{0,}$/.test(values.retention) && isValid);
    if (values.retention && !(/^[1-9][0-9]{0,}$/.test(values.retention))) {
      // AppInsights Error Exception
      errorException(`Error: ${t('admin.grid.retentionValidationMsg')}, File reference: ConfigureRetentionDialog.tsx in useEffect function`);

      setRetentionErrMsg(t('admin.grid.retentionValidationMsg'));
    } else {
      setRetentionErrMsg('');
    }
  }, [isValid, values.product, values.country, values.retention, t]);

  useEffect(() => {
    if (!products.length) {
      dispatch(getProducts());
    }
  }, [dispatch, products.length]);

  useEffect(() => {
    setProductOptions(
      products.map((product) => ({
        value: product.code,
        label: product.title
      }))
    );
  }, [products]);

  const fetchCountries = React.useCallback((code: string) => {
    setCountryOptions([]);
    apiService
      .fetchProductCountries(code)
      .then((response: any) => {
        const options = response.map((country: any) => ({
          value: country.code,
          label: country.name
        }));
        setCountryOptions(options);
        setCountries(response);
      })
      .catch((err) => {
        if (err.status === 404) {
          // do something for countries error.

          // AppInsights Error Exception
          errorException('Error: /product/<code>/countries/locale/<i18n.language>, File reference: ConfigureRetentionDialog.tsx in fetchCountries function');
        }
      });
  }, []);
 
  const getRetentionDetails = (payload: DTO.RetentionRequestDto) => {
    apiService.getRetentionDetails(payload).then((res: any) => {
      setRetentionDetails(res);
    })
    .catch((err) => {
      console.log(err);
      // AppInsights Error Exception
      errorException(`Error: ${err}, File reference: ConfigureRetentionDialog.tsx in getRetentionDetails function`);
    });
  };

  const handleProductChange = React.useCallback((product: ProductOptions) => {
    setCountryOptions([]);
    setRetentionDetails(null);
    setRetentionYear('');
    setSelectedCountryCode(null);
    setValues({
      ...values,
      product: product && product.value,
      country: '',
      retention: ''
    });
    fetchCountries(product.value);
  }, [setValues, values, fetchCountries]);

  useEffect(() => {
    if(productCode && user.userRoleId === UserRoles.productAdmin) {
      if(!values.product){
        setValues({
          ...values,
          product: productCode,
        });
      }
      setSelectedProduct(productCode);
      fetchCountries(productCode);
    } else {
      setSelectedProduct(values.product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCode, user.userRoleId, fetchCountries, productOptions]);

  const handleCountryChange = (country: CountryOptions) => {
    setSelectedCountryCode(country.value);
    setValues({
      ...values,
      country: country.value,
    });
    getRetentionDetails({
      productCode: user.userRoleId === UserRoles.productAdmin ? productCode : values.product,
      countryCode: country.value,
      email: user.email
    });
  };

  const handleRetentionChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDisableSave(false);
      setRetentionYear(e.target.value);
      setValues({
        ...values,
        retention: e.target.value,
      });
    },
    [setDisableSave, setValues, values]
  );

  const handleOnClose = React.useCallback(() => {
    onCancel();
    setShow(false);
  }, [onCancel]);

  const handleOnSubmit = React.useCallback(() => {
    console.log(values);
    setSendValues(true);
    setShow(false);
    const payload = {
      productCode: values.product,
      userEmail: user.email,
      RetentionYears: Number(values.retention),
      countryCode: countries.find((country:any) => country.code === values.country).id,
      userRoleId: user.userRoleId
    };
    onSubmit(payload);
  }, [values, countries, user.email, user.userRoleId, onSubmit]);

  const getOptionValueCallback = React.useCallback(
    ({ value }): string => value,
    []
  );

  const getOptionLabelCallback = React.useCallback(
    ({ label }): string => label,
    []
  );

  const findSelectedCountryFromOptions = React.useCallback(
    productCountry => productCountry.value === selectedCountryCode,
    [selectedCountryCode]
  );

  const countryValue = React.useMemo(
    (): CountryOptions => 
    countryOptions.find(findSelectedCountryFromOptions),
    [countryOptions, findSelectedCountryFromOptions]
  );

  const findSelectedProductFromOptions = React.useCallback(
    product => product.value === selectedProduct,
    [selectedProduct]
  );

  const productValue = React.useMemo(
    (): ProductOptions => 
    productOptions.find(findSelectedProductFromOptions),
    [productOptions, findSelectedProductFromOptions]
  );

  return (
    <React.Fragment>
      <Dialog
        actions={[
          {
            label: t('actions.cancel'),
            variant: 'outline-secondary',
            onClick: handleOnClose
          },
          {
            label: t('actions.save'),
            onClick: handleOnSubmit,
            disabled: !isFormValid
          }
        ]}
        show={show}
        title={t('dialogs.configureSettings')}
        onClose={handleOnClose}
      >
        <Form className={styles.container}>
          <p>{t('forms.configureRetention.text')}</p>
          <div className={styles.selectWrap}>
            <div className={cs(styles.label, styles.required)}>
              {t('onboarding.adminCreation.application')}
            </div>
            {user.userRoleId === UserRoles.productAdmin ?
              (
                <input
                  className={cs(styles.select, styles.autoheight, styles.retentionInput)}
                  disabled={true}
                  type='text'
                  value={(productOptions && productOptions.length > 0 && selectedProduct && productOptions.find(product => product.value === selectedProduct).label) || ''}
                />
              ) :
              (
                <Select<ProductOptions>
                  className={cs(styles.select, styles.multiSelect)}
                  getOptionLabel={getOptionLabelCallback}
                  getOptionValue={getOptionValueCallback}
                  isClearable={false}
                  isSearchable={false}
                  options={productOptions}
                  placeholder={t(
                    'onboarding.adminCreation.selectApplication'
                  )}
                  renderOption={(item) => <div>{item.label}</div>}
                  value={productValue || { value: '', label: '' }}
                  withDropdownIndicator={true}
                  onChange={(item) => {
                    handleProductChange(item);
                    setSelectedProduct(item === null ? null : item.value);

                  }}
                />
              )}
          </div>
            <div className={styles.selectWrap}>
              <div className={cs(styles.label, styles.required)}>
                {t('admin.grid.country')}
              </div>
              <Select<CountryOptions>
                className={cs(styles.select, styles.autoheight)}
                getOptionLabel={getOptionLabelCallback}
                getOptionValue={getOptionValueCallback}
                isClearable={false}
                isSearchable={false}
                options={countryOptions}
                placeholder={t('admin.grid.country')}
                renderOption={(item) => <div>{item.label}</div>}
                value={countryValue || { value: '', label: '' }}
                withDropdownIndicator={true}
                onChange={(country) => {
                  handleCountryChange(country);
                  setSelectedCountryCode(country === null ? null : country.value);
                }}
              />
            </div>
          <div className={styles.selectWrap}>
            <div className={cs(styles.label, styles.required)}>
              {t('admin.grid.retentionYear')}
            </div>
            <input
              className={cs(styles.select, styles.autoheight, styles.retentionInput, retentionErrMsg ? styles.retentionError : '') }
              disabled={ retentionDetails && user.userRoleId === UserRoles.superAdmin ? retentionDetails.isRetentionLocked : false} 
              type='text'
              value={retentionYear}
              onChange={handleRetentionChange}
            />
            {retentionErrMsg && (
              <div className={styles.error}>{retentionErrMsg}</div>
            )}
            <p className={styles.retentionLocked}>{user.userRoleId === UserRoles.superAdmin && retentionDetails && retentionDetails.isRetentionLocked ?
              t('admin.grid.tenantRetentionYearWarning') : ''}
            </p>
          </div>
          <div className={styles.selectWrap}>
            {retentionDetails &&
              (
                <p>{t('admin.grid.lastEdited')}: {retentionDetails ?
                  new Date(retentionDetails.lastModifiedDate).toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' })
                  : ''}
                </p>
              )}
            <div className={styles.modifiedUserDetail}>
              <p>{retentionDetails ? `${retentionDetails.firstName} ${retentionDetails.lastName},`  : ''}</p>
              <p className={styles.retentionLocked}>{retentionDetails ? ` ${retentionDetails.userEmail}` : ''}</p>
              {retentionDetails &&
                (
                  <a href={`mailto:${retentionDetails.userEmail}`} >
                    <img alt='sendMail' className={styles.sendMail} src={sendMail} />
                  </a>
                )}
            </div>
          </div>
        </Form>
      </Dialog>
    </React.Fragment>
  );
};

export const ConfigureRetentionDialog = ConfigureRetentionDialogEnhancer(
  ConfigureRetentionDialogComponent
);
