import React from 'react';
import { useField } from 'formik';
import { FormCheckInputProps } from 'react-bootstrap/FormCheckInput';

import { Checkbox } from 'components/Checkbox/Checkbox';

interface Props extends FormCheckInputProps {
  label: string;
  name: string;
  disabled?: boolean;
}

const CheckboxField: React.FC<Props> = props => {
  const { label, name, disabled = false } = props;
  const [field] = useField(name);
  const { value, onChange } = field;

  return (
    <Checkbox
      checked={value}
      disabled={disabled}
      label={label}
      name={name}
      onChange={onChange}
    />
  );
};

export default CheckboxField;
