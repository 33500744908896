import React from 'react';
import { components } from 'react-select';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';

import style from '../Select.module.css';

export function Placeholder<T>(
  placeholder?: string,
  placeholderPrefix?: JSX.Element
): (props: any) => JSX.Element {
  const placeholderComponent = (
    props: PlaceholderProps<T, boolean>
  ): JSX.Element => (
    <components.Placeholder {...props}>
      <div className={style.placeholder}>
        {placeholderPrefix}
        <span>{placeholder}</span>
      </div>
    </components.Placeholder>
  );
  return placeholderComponent;
}
