import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutate } from 'restful-react';

import { selectHttpHeaders, selectSourceSubjectId } from 'store/auth';
import { selectProductCode } from 'store/product';
import { API_PATH } from 'shared/constants/api.consts';
import { Spinner } from 'components';
import { AlertMessage } from 'components/AlertMessage';
import { errorException } from 'shared/utils/AppInsights';
import { UserForm } from './UserForm';
import styles from './UserForm.module.css';


export const UserFormContainer: React.FC<any> = ({
  isMultipleUser,
  changePanel,
  passData,
  reload,
  productRoles
}) => {
  const productCode = useSelector(selectProductCode);
  const httpHeaders = useSelector(selectHttpHeaders);
  const [formErrors, setFormErrors] = React.useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const { t } = useTranslation();
  const sourceSubjectId = useSelector(selectSourceSubjectId);
  const {
    mutate: submitAccountDetails,
    loading: submitLoading,
    error: submitError
  } = useMutate<AP.RegistrationData>({
    verb: 'POST',
    path: API_PATH.ADD_ADDITIONAL_USER(productCode, sourceSubjectId),
    requestOptions: () => ({ headers: httpHeaders })
  });

  const submitUserDetails = (values: any): void => {
    submitAccountDetails({
      UserEmail: values.UserEmail,
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.UserEmail,
      AccountId: passData.displayId,
      Organization: passData.organization.legalName,
      ProductCode: productCode,
      UserProductRole: values.UserProductRole
    }).then(() => {
      changePanel();
      reload();
      setShowAlert(false);
    }).catch((err) => {
      setShowAlert(true);
    });
  };

  React.useEffect((): void => {

    let errors = null;

    if(submitError && submitError.status === 409){

      // AppInsights Error Exception
      errorException('Error bad request to .../product/<product>/addAdditionalUser, File reference: UserForm.container.tsx in React.useEffect function');

      errors = {
        UserEmail: {   
          key: `${t('additionalEmailNotUnique')}`
        }
      };
    }

    setFormErrors(errors);
  }, [submitError,t]);
  
  return (
    <React.Fragment>
      {
        showAlert && (
          <div className={styles.alertContainer}>
            <AlertMessage message={t('onboarding.adminCreation.tweakedResponseCode')} type='error'/>
          </div>
        )
      }
      {submitLoading && <Spinner />}
      <UserForm
        formErrors={formErrors}
        loading={submitLoading}
        organization={passData.organization}
        productRoles={productRoles}
        onCancel={changePanel}
        onSubmit={submitUserDetails}
      />
    </React.Fragment>
  );
};
