export type RegistrationState = Readonly<{
  productCountries: Store.LoadableData<DTO.Country[]>;
  productPlans: Store.LoadableData<DTO.RegistrationPlan[]>;
  termsOfUse: Store.LoadableData<AP.Nullable<DTO.TermsOfUse>> & {
    isAgreed: boolean;
  };
  states: Store.LoadableData<DTO.State[]>;
  countryCodes: Store.LoadableData<Record<string, string>>;
  data: AP.RegistrationData;
}>;

export const FETCH_COUNTRY_CODES = '@registration/FETCH_COUNTRY_CODES';
export const FETCH_PRODUCT_COUNTRIES = '@registration/FETCH_PRODUCT_COUNTRIES';
export const FETCH_TERMS_OF_USE = '@registration/FETCH_TERMS_OF_USE';
export const FETCH_INTERNAL_TERMS_OF_USE = '@registration/FETCH_INTERNAL_TERMS_OF_USE';
export const FETCH_COUNTRY_STATES = '@registration/FETCH_COUNTRY_STATES';
export const FETCH_PRODUCT_PLANS = '@registration/FETCH_PRODUCT_PLANS';
export const SET_REGISTRATION_COUNTRY_CODE =
  '@registration/SET_REGISTRATION_COUNTRY_CODE';
export const SET_REGISTRATION_TERMS_OF_USE_AGREEMENT =
  '@registration/SET_REGISTRATION_TERMS_OF_USE_AGREEMENT';
export const SET_REGISTRATION_PLANS = '@registration/SET_REGISTRATION_PLANS';
export const SUBMIT_REGISTRATION_DATA =
  '@registration/SUBMIT_REGISTRATION_ACCOUNT_DETAILS';

export type FetchCountryCodesAction = Store.PaylodedAction<
  Record<string, string>
>;
export type FetchCountryCodesAction$ = Store.PaylodedAction<
  Promise<Record<string, string>>
>;

export type FetchProductCountriesAction = Store.PaylodedAction<DTO.Country[]>;
export type FetchProductCountriesAction$ = Store.PaylodedAction<
  Promise<DTO.Country[]>
>;

export type FetchTermsOfUseAction = Store.PaylodedAction<DTO.TermsOfUse>;
export type FetchTermsOfUseAction$ = Store.PaylodedAction<
  Promise<DTO.TermsOfUse>
>;

export type FetchInternalTermsOfUseAction = Store.PaylodedAction<DTO.TermsOfUse>;
export type FetchInternalTermsOfUseAction$ = Store.PaylodedAction<
  Promise<DTO.TermsOfUse>
>;

export type FetchCountryStates = Store.PaylodedAction<DTO.State[]>;
export type FetchCountryStates$ = Store.PaylodedAction<Promise<DTO.State[]>>;

export type SetRegistrationCountryCodeAction = Store.PaylodedAction<string>;
export type SetRegistrationTermsOfUseAgreement = Store.PaylodedAction<boolean>;
export type SetRegistrationPlansAction = Store.PaylodedAction<DTO.BasePlan[]>;

export type SubmitRegistrationAccountDetails = Store.PaylodedAction<
  AP.AccountDetailsFormFields
>;

export type FetchProductPlansAction = Store.PaylodedAction<
  DTO.RegistrationPlan[]
>;
export type FetchProductPlansAction$ = Store.PaylodedAction<
  Promise<DTO.RegistrationPlan[]>
>;

export type RegistrationActionTypes =
  | FetchCountryCodesAction
  | FetchProductCountriesAction
  | FetchTermsOfUseAction
  | FetchProductPlansAction
  | FetchCountryStates
  | SetRegistrationCountryCodeAction
  | SetRegistrationTermsOfUseAgreement
  | SetRegistrationPlansAction
  | SubmitRegistrationAccountDetails;
