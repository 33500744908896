import React, { useState} from 'react';
import { useSelector } from 'react-redux';
import { useMutate } from 'restful-react';
import { useTranslation } from 'react-i18next';

import { errorException } from 'shared/utils/AppInsights';
import { selectHttpHeaders, selectSourceSubjectId } from 'store/auth';
import { selectProductCode } from 'store/product';
import { API_PATH } from 'shared/constants/api.consts';
import { User } from '../UserList/UserList.helpers';
import { UserForm } from '../UserForm/UserForm';

type Props = {
  isMultipleUser: boolean;
  canAddAdditionalUsers: boolean;
  users: User[];
  changePanel: () => void;
  passData: User & { displayId: string; organization: DTO.Organization };
  reload: () => void;
  onShowSubheading: (value: boolean) => void;
  productRoles: any[];
};

export const UploadMultipleUser = ({
  passData,
  changePanel,
  reload,
  productRoles
}: Props) => {
  const { t } = useTranslation();
  const productCode = useSelector(selectProductCode);
  const httpHeaders = useSelector(selectHttpHeaders);


  const [showAlert, setShowAlert] = useState(false);

  const submitUserDetails = (values: any): void => {
    submitAccountDetails({
      UserEmail: values.UserEmail,
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.UserEmail,
      AccountId: passData.displayId,
      Organization: passData.organization.legalName,
      ProductCode: productCode,
      UserProductRole: values.UserProductRole
    })
      .then(() => {
        changePanel();
        reload();
        setShowAlert(false);
      })
      .catch((err) => {
        setShowAlert(true);
      });
  };

  const sourceSubjectId = useSelector(selectSourceSubjectId);
  const {
    mutate: submitAccountDetails,
    loading: submitLoading,
    error: submitError
  } = useMutate<AP.RegistrationData>({
    verb: 'POST',
    path: API_PATH.ADD_ADDITIONAL_USER(productCode, sourceSubjectId),
    requestOptions: () => ({ headers: httpHeaders })
  });

  const [formErrors, setFormErrors] = React.useState(null);

  React.useEffect((): void => {
    let errors = null;

    if (submitError && submitError.status === 409) {
      // AppInsights Error Exception
      errorException(
        'Error bad request to .../product/<product>/addAdditionalUser, File reference: UserForm.container.tsx in React.useEffect function'
      );

      errors = {
        UserEmail: {
          key: `${t('additionalEmailNotUnique')}`
        }
      };
    }

    setFormErrors(errors);
  }, [submitError, t]);

  return (
  <div>
      <div className='addInfo'>
        <UserForm
          displayId={passData.displayId}
          formErrors={formErrors}
          isMultipleUser={true}
          loading={submitLoading}
          organization={passData.organization}
          productRoles={productRoles}
          onCancel={changePanel}
          onSubmit={submitUserDetails}
        />
      </div>
    </div>
  );
};
