import React, { useMemo } from 'react';
import kebabCase from 'lodash/kebabCase';
import { Form, Col } from 'react-bootstrap';
import { Field, FieldProps } from 'formik';
import cs from 'classnames';

import styles from '../Form.module.css';

interface Props {
  label: string;
  name: string;
  t?: any;
  elem?: any;
}

export function Radio({ label, name, t, elem }: Props): JSX.Element {
  const inputLabel = useMemo(() => {
    return label ? (
      <Form.Label className={styles.label}>{label}</Form.Label>
    ) : null;
  }, [label]);
  const renderInput = React.useCallback(
    ({ field: { value, onChange } }: FieldProps): JSX.Element => {
      const errorMsg = t('validation.errors.isDeloitteAuditClient');
      return (
        <Form.Group as={Col} controlId={kebabCase(`form input ${label}`)}>
          <div>{inputLabel}</div>
          {elem.map((ele: any) => {
            return (
              <Form.Label key={ele.label} className={cs(styles.label, styles.specialLabelCss)}>
                <Field
                  checked={value === ele.value}
                  component='input'
                  // defaultChecked={ele.checked}
                  name={name}
                  type='radio'
                  value={ele.value}
                  onChange={onChange}
                />
                {t(ele.label)}
              </Form.Label>
            );
          })}
          {value === '1' && (
            <div className={`${styles.feedback} ${styles.auditClient}`}>
              {errorMsg}
            </div>
          )}
        </Form.Group>
      );
    },
    [name, inputLabel, label, t, elem]
  );

  return <Field name={name}>{renderInput}</Field>;
}

export default Radio;