import React from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './SubmissionPlanStatus.module.css';

interface Props {
  status: DTO.SubmissionPlanStatus;
  isDetailsDialog?: boolean;
}

export const SubmissionPlanStatus: React.FC<Props> = ({
  status,
  isDetailsDialog
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <span className={cs(styles.container, styles[status.toLowerCase()])} />
      <span
        className={cs(
          isDetailsDialog && styles[`status-text-${status.toLowerCase()}`]
        )}
        title={t(`admin.plans.statuses.${status.toLowerCase()}`)}
      >
        {t(`admin.plans.statuses.${status.toLowerCase()}`)}
      </span>
    </React.Fragment>
  );
};
