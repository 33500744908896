import React from 'react';
import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton
} from 'react-bootstrap';
import isEqual from 'lodash/isEqual';
import cs from 'classnames';

import { generateUniqueKey } from './RadioButtons.helpers';
import styles from './RadioButtons.module.css';

interface Props<T> {
  options: T[];
  value: T;
  renderOption: (option: T) => JSX.Element;
  onChange: (item: T) => void;
}

export function RadioButtons<T>({
  options,
  value,
  renderOption,
  onChange
}: Props<T>): JSX.Element {
  const onChangeCb = React.useCallback(
    (item: T) => (): void => onChange(item),
    [onChange]
  );

  const generateKeyCb = React.useCallback(
    (item: T): string => generateUniqueKey<T>(item),
    []
  );

  return (
    <ButtonToolbar>
      <ToggleButtonGroup defaultValue={value} name='options' type='radio'>
        {options.map(
          (item: T): JSX.Element => (
            <ToggleButton
              key={generateKeyCb(item)}
              className={cs(styles.toggleButton, {
                [styles.normal]: !isEqual(item, value)
              })}
              name={generateKeyCb(item)}
              value={item as any}
              variant={isEqual(item, value) ? 'primary' : 'outline-primary'}
              onChange={onChangeCb(item)}
            >
              {renderOption(item)}
            </ToggleButton>
          )
        )}
      </ToggleButtonGroup>
    </ButtonToolbar>
  );
}
