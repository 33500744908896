// a model which accepts 4 args and have typescript class shortcut
/* eslint-disable no-empty-function */

export class OidcUser {
  constructor(
    public idToken: string,
    public email: string,
    public accessToken: string,
    public isInternal: boolean
  ) {}
}
