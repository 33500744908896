import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { FormikProps } from 'formik';
import cs from 'classnames';
import Get from 'restful-react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { useOnMount } from 'shared/hooks';
import { changeLanguage } from 'store/localize';
import {
  getProducts,
  getMemberFirms,
  createAdmin,
  getAvailableProducts
} from 'store/onboarding/onboarding.actions';
import { selectHttpHeaders, reselectUser } from 'store/auth';
import { apiService } from 'shared/services';
import { UserRoles, USER_ROLES } from 'features/AdminPage/OnboardingPage/OnboardingFilters/OnboardingFilters';
import { Button, Form, Select, ConfirmationCancellation } from 'components';
import { SearchCallback } from 'features/AdminPage/SubmissionsFilters/FiltersControls/FiltersControls.helpers';
import { errorException } from 'shared/utils/AppInsights';
import {
  AddAdminFormEnhancer,
  AddAdminFormProps,
  AddAdminFormValues
} from './AddAdminForm.helpers';
import styles from './AddAdminForm.module.css';
import { getApiHost } from '../../../../shared/utils/api-host-resolver.utils';
import {
  selectProductCode,
  selectMemberFirms,
  selectCountries
} from '../../../../store/product/product.selectors';
import { selectProducts } from '../../../../store/onboarding/onboarding.selectors';

interface ProductOptions {
  value: string;
  label: string;
}

interface CountryOptions {
  value: string;
  label: string;
}

export function AddAdminFormComponent({
  values,
  adminRoles,
  setValues,
  onCancel
}: AddAdminFormProps & FormikProps<AddAdminFormValues>): JSX.Element {
  const productCode = useSelector(selectProductCode);
  const { t } = useTranslation();
  const history = useHistory();
  const [emailQuery, setEmailQuery] = useState('');
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const memberFirms = useSelector(selectMemberFirms);
  const [countries, setCountries] = useState(useSelector(selectCountries));
  const httpHeaders = useSelector(selectHttpHeaders);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState('');
  const user = useSelector(reselectUser);
  const [productOptions, setProductOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [showCountryField, setshowCountryField] = useState(false);
  const [showCommentField, setshowCommentField] = useState(false);
  const [isEmailProcessing, setEmailProcessingFlag] = useState(false);
  const [isConfiramtionCancel, setConfirmCancellation] = useState(false); 
  const [isWLUProductscharged, setWLUProductscharged,] = useState(false);
  const [allRoleProductsCharged, setAllRoleProductsCharged] = useState(false);

  useOnMount(
    useCallback((): void => {
      if (user.profileSettings)
        dispatch(changeLanguage(user.profileSettings.language));
    }, [dispatch, user.profileSettings])
  );

  const fetchCountries = (code: string) => {
    apiService
      .fetchProductCountries(code)
      .then((response: any) => {
        const options = response.map((country: any) => ({
          value: country.code,
          label: country.name
        }));
        setCountryOptions(options);
        setCountries(response);
      })
      .catch((err) => {
        if (err.status === 404) {
          // do something for countries error.

          // AppInsights Error Exception
          errorException('Error bad request to ... /product/<code>/countries/locale/<i18n.language>, File reference: AddAdminForm.tsx in fetchCountries function');
        }
      });
  };
  useEffect(() => {
    setValues({
      ...values,
      role: null,
      email: null,
      products: null,
      countries: null,
      comments: ''
    });
    setEmailErrorText('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCode]);

  const fetchEmailAvailability = (
    value: DTO.UserEmail,
    countryCodes: string[] = []
  ) => {
    setLoadingEmail(true);
    setEmailErrorText('');
    const roleId =
      values.role && values.role.id
        ? values.role.id.toString()
        : user.userRoleId.toString();
    const payload: DTO.ParamsSearchAdminsDto = {
      email: value.email,
      roleId: values.role?.id || user.userRoleId,
      // countryCodes: values.countries && values.countries.length ? values.countries: []
      countryCodes
    };

    apiService
      .fetchEmailAvailability(
        values.products && values.products.length > 0
          ? values.products[0]
          : productCode,
        payload
      )
      .then((response: any) => {
        if (response == null) {
          localStorage.setItem(
            'adminRole',
            JSON.stringify(values.role?.id || user.userRoleId)
          );
          setEmailError(false);
          setLoadingEmail(false);
        } else {
          // AppInsights Error Exception
          errorException(`Error bad request: ${t(`onboarding.adminCreation.tweakedResponseCode`)}, File reference: AddAdminForm.tsx in apiService function`);

          // Something wrong with the response code.
          setEmailErrorText(t(`onboarding.adminCreation.tweakedResponseCode`));
          setEmailError(true);
          setLoadingEmail(false);
        }
      })
      .catch((err) => {
        if (err.status === 404) {
          // AppInsights Error Exception
          errorException(`Error bad request: ${t(`onboarding.adminCreation.resourceNotFound`)}, File reference: AddAdminForm.tsx in apiService function in status code 404`);

          setEmailErrorText(t(`onboarding.adminCreation.resourceNotFound`));
        }
        else if (err.status === 409) { // admin already exists
        
          // AppInsights Error Exception
          errorException(`Error bad request: ${t(`onboarding.adminCreation.exists`)}, File reference: AddAdminForm.tsx in apiService function in status code 409`);

          setEmailErrorText(t(`onboarding.adminCreation.exists`));
        }
          else if (err.status === 422) { // WLU already exists
            
                 // AppInsights Error Exception
              errorException(`Error bad request: ${t(`onboarding.adminCreation.WLUexists`)}, File reference: AddAdminForm.tsx in apiService function in status code 409`);
  
              setEmailErrorText(t(`onboarding.adminCreation.WLUexists`));
          
         
        } else if (err.status === 401) { // admin not belongs to member firm
          // AppInsights Error Exception
          errorException(`Error bad request: ${t(`onboarding.adminCreation.invalidMemberFirmUser`)}, File reference: AddAdminForm.tsx in apiService function in status code 401`);

          setEmailErrorText(t(`onboarding.adminCreation.invalidMemberFirmUser`));
        }
        setEmailError(true);
        setLoadingEmail(false);
      })
      .finally(() => {
        setEmailProcessingFlag(false);
      });
  };

  const handleProductChange = (product: ProductOptions) => {
    setCountries([]);
    setValues({
      ...values,
      products: product && [product.value],
      countries: null
    });
    fetchCountries(product.value);
  };
  const handleCountryChange = (country: CountryOptions) => {
    let countryCodes: string[] = [];
    if (country instanceof Array) {
      countryCodes = country.map((item) => item.value);
    }else {
      countryCodes.push(country.value);
    }
    setValues({
      ...values,
      countries: countryCodes,
      // resetting comments based on country selection
      comments: !values.countries || values.countries?.length === 0 ? null : values.comments
    });
    fetchEmailAvailability(values.email, countryCodes);
  };

  const showRoleField = user.userRoleId === 1;
  const showEmailField = user.userRoleId === 3 || user.userRoleId === 7 || values.role;
  const showProductsField =
    values.role &&
    (values.role.id === UserRoles.productAdmin || values.role.id === UserRoles.whitelistedInternalUser) &&
    values.email &&
    !isEmailProcessing;

  // const showFirmsField = values.role;
  // const showCountryField = values.products && values.products.length > 0;
  // const getshowCommentField = () : boolean => {
  //   return (values.role && values.role?.id === UserRoles.superAdmin && values.email) ||
  //     (values.role?.id === UserRoles.productAdmin && showProductsField && showCountryField && values.countries) ||
  //     (user.userRoleId === 3 && values.email);
  // }

  const isSubmitAvailable = () => {
    if (values.role && values.role.id === 1) {
      if (!values.email || !values.role || !values.comments) return false;
      if (showProductsField && !values.products) return false;
    } else if (!values.email || !values.comments) return false;
    else if (showCountryField && !values.countries) return false;
    if (loadingEmail) return false;
    if (emailError) return false;
    if (values.role?.id === UserRoles.whitelistedInternalUser && values.countries.length > 1) return false;
    return true;
  };
  const handleLoadingMessage = () => {
    return `${t('admin.grid.loading')}...`;
  };
  useEffect(() => {
    if (values.role?.id === UserRoles.whitelistedInternalUser && isWLUProductscharged === false && values.email) {
      setWLUProductscharged(true);
      setAllRoleProductsCharged(false);
      dispatch(getAvailableProducts(values.email.email));
    } else if (values.role?.id !== UserRoles.whitelistedInternalUser && showProductsField && allRoleProductsCharged === false) {
      setWLUProductscharged(false);
      setAllRoleProductsCharged(true);
      dispatch(getProducts());
    }
  }, [dispatch, showProductsField, products.length, values, isWLUProductscharged, allRoleProductsCharged]);

  useEffect(() => {
    setshowCountryField(values.products && values.products.length > 0);
  }, [values.products]);

  useEffect(() => {
    setProductOptions(
      products.map((product) => ({
        value: product.code,
        label: product.title
      }))
    );
  }, [values.email, products]);

  useEffect(() => {
    if (
      (values.role &&
        values.role?.id === UserRoles.superAdmin &&
        values.email) ||
      ((values.role?.id === UserRoles.productAdmin || values.role?.id === UserRoles.whitelistedInternalUser) &&
        showProductsField &&
        showCountryField &&
        values.countries) ||
      ((user.userRoleId === 3 || user.userRoleId === 7) && values.email)
    ) {
      setshowCommentField(true);
    } else {
      setshowCommentField(false);
    }
  }, [
    showCountryField,
    showProductsField,
    user.userRoleId,
    values.countries,
    values.email,
    values.role
  ]);

  // useEffect(() => {
  //   if (showFirmsField && !memberFirms.length) {
  //     dispatch(getMemberFirms());
  //   }
  // }, [dispatch, memberFirms.length, showFirmsField]);

  const handleOnEmailSearch = React.useMemo(
    () => (
      refetch: () => Promise<DTO.UserEmail[]>
    ): SearchCallback<DTO.UserEmail> =>
      debounce(
        (q: string, callback: (options: DTO.UserEmail[]) => void): void => {
          setEmailQuery(q);
          setProductOptions([]);
          setCountries([]);
          setshowCommentField(false);
          setEmailProcessingFlag(true);
          refetch().then((items) => callback(items));
        },
        300
      ),
    []
  );

  const submitForm = () => {
    const payload: DTO.CreateAdminRequest = {
      firstName: values.email.firstName,
      lastName: values.email.lastName,
      email: values.email.email,
      userCountryCode: values.email.country.trim(),
      roleId: values.role?.id || user.userRoleId,
      productCodes: values.products,
      memberFirmCode: values.email.memberFirm,
      comments: values.comments || '',
      countryCodes: values.countries
    };
    dispatch(createAdmin(productCode, payload));
    onCancel();
  };
  const handleOnClose = () => {
    setConfirmCancellation(true);
  };
  const onCloseConfirmDialog = () => {
    setConfirmCancellation(false);
  };

  return (
    <Fragment>
      {isConfiramtionCancel && (<ConfirmationCancellation onReject={onCloseConfirmDialog} onResolve={onCancel} />)}
      <div className={cs(styles.formWrap, isConfiramtionCancel ? styles.disableAddAdminForm : '')}>
        <Form className={styles.form}>
          {showRoleField && (
            <div className={styles.selectWrap}>
              <div className={cs(styles.label)}>
                {t('onboarding.adminCreation.adminrole')}
              </div>
              <Select<DTO.AdminRole>
                className={styles.select}
                isClearable={false}
                isSearchable={false}
                options={adminRoles}
                placeholder={t('onboarding.adminCreation.selectRole')}
                renderOption={(item: DTO.AdminRole) => (
                  <div>{t(`onboarding.adminCreation.roles.${item.name}`)}</div>
                )}
                value={values.role}
                withDropdownIndicator={true}
                onChange={(role) => {
                  setEmailError(false);
                  setValues({
                    email: null,
                    products: [],
                    memberFirm: null,
                    role,
                    comments: '',
                    countries: null
                  });
                }}
              />
            </div>
          )}
          {showEmailField && (
            <React.Fragment>
              <div className={cs(styles.label, styles.withPadding)}>
                {t('onboarding.adminCreation.email')}
              </div>
              <div className={cs(styles.label, styles.withPadding, styles.emailText)}>
                {t('onboarding.adminCreation.emailtext')}
              </div>
              <Get<DTO.UserEmail[]>
                base={`${getApiHost('account')}`}
                lazy={true}
                path={apiService.fetchAdminsByEmail(null)}
                queryParams={{
                  email: emailQuery
                }}
                requestOptions={() => ({ headers: httpHeaders })}
              >
                {(
                  _emails,
                  { loading },
                  { refetch: refetchEmails }
                ): JSX.Element => (
                  <div className={styles.emailWrap}>
                    <Form.SelectAsync
                      className={cs(styles.select, { [styles.search]: !loading })}
                      load={handleOnEmailSearch(refetchEmails)}
                      loadMessage={handleLoadingMessage}
                      multiple={false}
                      name='email'
                      noOptions={() => t('admin.grid.nooptions')}
                      optionLabel='email'
                      optionValue='email'
                      placeholder={t('onboarding.adminCreation.selectEmail')}
                      withoutIndicator={true}
                      onChange={(item) => {
                        setValues({
                          ...values,
                          products: [],
                          countries: null,
                          comments: ''
                        });
                        setWLUProductscharged(false);
                        fetchEmailAvailability(item);
                      }}
                    />
                    {emailError && (
                      <div className={styles.error}>{emailErrorText}</div>
                    )}
                  </div>
                )}
              </Get>

              {!emailErrorText &&
                showProductsField &&
                values.role.id !== UserRoles.superAdmin && (
                  <div className={styles.selectWrap}>
                    <div className={cs(styles.label)}>
                      {t('onboarding.adminCreation.application')}
                    </div>
                    <Select<ProductOptions>
                      className={cs(styles.select, styles.multiSelect)}
                      isClearable={false}
                      isSearchable={false}
                      options={productOptions}
                      placeholder={t(
                        'onboarding.adminCreation.selectApplication'
                      )}
                      renderOption={(item) => <div>{item.label}</div>}
                      withDropdownIndicator={true}
                      onChange={(item) => {
                        setshowCountryField(false);
                        setshowCommentField(false);
                        handleProductChange(item);
                      }}
                    />
                  </div>
                )}
              {!isEmailProcessing &&
                !emailErrorText &&
                showCountryField &&
                values.products.length && (
                  <div className={styles.selectWrap}>
                    <div className={cs(styles.label)}>
                      {t('admin.grid.country')}
                    </div>
                    <Select<CountryOptions>
                      className={cs(styles.select, styles.autoheight)}
                      isClearable={false}
                      isMulti={values.role.id !== UserRoles.whitelistedInternalUser}
                      isSearchable={false}
                      options={countryOptions}
                      placeholder={t('admin.grid.country')}
                      renderOption={(item) => <div>{item.label}</div>}
                      withDropdownIndicator={true}
                      onChange={(country) => {
                        setshowCommentField(false);
                        handleCountryChange(country);
                      }}
                    />
                  </div>
                )}
              {showCommentField &&
                !isEmailProcessing &&
                !emailErrorText && (
                  <React.Fragment>
                    <div
                      className={cs(
                        styles.label,
                        styles.withPadding
                      )}
                    >
                      {t('onboarding.adminCreation.comments')}
                    </div>
                    <Form.Input
                      maxLength={255}
                      name='comments'
                      placeholder={t(
                        'onboarding.adminCreation.commentPlaceholder'
                      )}
                      type='text'
                    />
                  </React.Fragment>
                )}
            </React.Fragment>
          )}
        </Form>
        <div className={styles.formActions}>
          <Button
            className={styles.button}
            // href={`/admin/onboarding/${productCode}`}
            variant='outline-info'
            onClick={handleOnClose}
          >
            {t('onboarding.adminCreation.cancel')}
          </Button>
          <Button disabled={!isSubmitAvailable()} onClick={submitForm}>
            {user.userRoleId ===1 ? t('onboarding.adminCreation.addAdmin') : t('onboarding.adminCreation.addProductAdmin')}
          </Button>
        </div>
      </div>
    </Fragment>

  );
}

export const AddAdminForm = AddAdminFormEnhancer(AddAdminFormComponent);
