import { Reducer } from 'redux';

import { SORT_DIRECTION } from 'shared/constants/common.consts';
import {
  asyncActionFulfilled,
  asyncActionPending,
  asyncActionRejected
} from 'shared/utils';
import {
  OnboardingState,
  GET_ONBOARDING_USERS,
  OnboardingActionTypes,
  SetAdminUsers,
  GET_ADMIN_ROLES,
  SetAdminRoles,
  GET_PRODUCTS,
  GET_COUNTRIES,
  SetProducts,
  CREATE_ADMIN,
  RESET_CREATED_ADMIN,
  SetCountries,
  UPDATE_COLUMN_SORTING,
  SetSorting
} from './onboarding.types';

export const adminTableAllTile = <any[]>([
  { label: ('onboarding.table.userName'), value: 'DisplayName', sortOrder: SORT_DIRECTION.ASC },
  { label: ('onboarding.table.role'), value: 'RoleName', sortOrder: '' },
  { label: ('onboarding.table.email'), value: 'Email', sortOrder: '' },
  { label: ('onboarding.table.product'), value: 'ProductCode', sortOrder: '' },
  { label: ('onboarding.table.country'), value: 'Country', sortOrder: '' },
  { label: ('onboarding.table.actions'), value: '', sortOrder: '' },
]);

export const adminTableTenantTile = <any[]>([
  { label: ('onboarding.table.userName'), value: 'DisplayName', sortOrder: SORT_DIRECTION.ASC },
  { label: ('onboarding.table.email'), value: 'Email', sortOrder: '' },
  { label: ('onboarding.table.actions'), value: '', sortOrder: '' },
]);

export const adminTableProductTile = <any[]>([
  { label: ('onboarding.table.userName'), value: 'DisplayName', sortOrder: SORT_DIRECTION.ASC },
  { label: ('onboarding.table.email'), value: 'Email', sortOrder: '' },
  { label: ('onboarding.table.product'), value: 'ProductCode', sortOrder: '' },
  { label: ('onboarding.table.country'), value: 'Country', sortOrder: '' },
  { label: ('onboarding.table.actions'), value: '', sortOrder: '' },
]);


const initialProductState: OnboardingState = {
  hasSuccessData: false,
  isLoading: false,
  isLoaded: false,
  hadCreatedAdmin: false,
  userRoles: [],
  memberFirms: [],
  products: [],
  countries: [],
  usersPagination: {
    items: [],
    allItemsCount: 0,
    pageCount: 0,
    pageNumber: 0,
    pageSize: 0,
    usersCount: {
      all: 0,
      byCountry: 0,
      byMemberFirm: 0,
      byProduct: 0,
      byProductGroup: 0,
      byTenant: 0
    }
  },
  sorter: {
    fields: [
      {
        sortBy: adminTableAllTile[0].value,
        sortDirection: adminTableAllTile[0].sortOrder
      }
    ]
  },
  headerAllTile: adminTableAllTile,
  headerTenantTile: adminTableTenantTile,
  headerProductTile: adminTableProductTile
};

export const onboardingReducer: Reducer<
  OnboardingState,
  OnboardingActionTypes
> = (
  state: OnboardingState = initialProductState,
  action: OnboardingActionTypes
): OnboardingState => {
  switch (action.type) {
    case asyncActionPending(GET_ONBOARDING_USERS): {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        hasSuccessData: false
      };
    }

    case asyncActionRejected(GET_ONBOARDING_USERS): {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        hasSuccessData: false
      };
    }

    case asyncActionFulfilled(GET_ONBOARDING_USERS): {
      const usersPagination = (action as SetAdminUsers).payload;

      return {
        ...state,
        usersPagination,
        isLoading: false,
        isLoaded: true,
        hasSuccessData: true
      };
    }

    case asyncActionPending(GET_ADMIN_ROLES): {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        hasSuccessData: false
      };
    }

    case asyncActionRejected(GET_ADMIN_ROLES): {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        hasSuccessData: false
      };
    }

    case asyncActionFulfilled(GET_ADMIN_ROLES): {
      const userRoles = (action as SetAdminRoles).payload;

      return {
        ...state,
        userRoles,
        isLoading: false,
        isLoaded: true,
        hasSuccessData: true
      };
    }

    case asyncActionPending(GET_PRODUCTS): {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        hasSuccessData: false
      };
    }

    case asyncActionRejected(GET_PRODUCTS): {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        hasSuccessData: false
      };
    }

    case asyncActionFulfilled(GET_PRODUCTS): {
      const products = (action as SetProducts).payload;

      return {
        ...state,
        products,
        isLoading: false,
        isLoaded: true,
        hasSuccessData: true
      };
    }
    case asyncActionPending(GET_COUNTRIES): {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        hasSuccessData: false
      };
    }

    case asyncActionRejected(GET_COUNTRIES): {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        hasSuccessData: false
      };
    }

    case asyncActionFulfilled(GET_COUNTRIES): {
      const countries = (action as SetCountries).payload;

      return {
        ...state,
        countries,
        isLoading: false,
        isLoaded: true,
        hasSuccessData: true
      };
    }
    case asyncActionPending(CREATE_ADMIN): {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        hasSuccessData: false
      };
    }

    case asyncActionRejected(CREATE_ADMIN): {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        hasSuccessData: false
      };
    }

    case asyncActionFulfilled(CREATE_ADMIN): {
      return {
        ...state,
        hadCreatedAdmin: true,
        isLoading: false,
        isLoaded: true,
        hasSuccessData: true
      };
    }

    case RESET_CREATED_ADMIN: {
      return {
        ...state,
        hadCreatedAdmin: false
      };
    }

      case UPDATE_COLUMN_SORTING: {
        const { sortBy, sortDirection, currentTile } = (action as SetSorting).payload;
        let newHeaderTile;
        let tileToUpdate;
        let indexToSort;
        let prevSortIndex;
        switch (currentTile) {
          case 0: {
            newHeaderTile = [...state.headerAllTile];
            tileToUpdate = 'headerAllTile';
            indexToSort = state.headerAllTile.findIndex(item => item?.value === sortBy);
            prevSortIndex = state.headerAllTile.findIndex(item => item?.sortOrder !== '');
            break;
          }
          case 1: {
            newHeaderTile = [...state.headerTenantTile];
            tileToUpdate = 'headerTenantTile';
            indexToSort = state.headerTenantTile.findIndex(item => item?.value === sortBy);
            prevSortIndex = state.headerTenantTile.findIndex(item => item?.sortOrder !== '');
            break;
          }
          default: {
            newHeaderTile = [...state.headerProductTile];
            tileToUpdate = 'headerProductTile';
            indexToSort = state.headerProductTile.findIndex(item => item?.value === sortBy);
            prevSortIndex = state.headerProductTile.findIndex(item => item?.sortOrder !== '');
            break;
          }
        }

        if (newHeaderTile[prevSortIndex].value !== sortBy) {
          newHeaderTile[prevSortIndex].sortOrder = '';
        }
        newHeaderTile[indexToSort].sortOrder = sortDirection;
        return {
          ...state,
          sorter: {
            ...state.sorter, fields: [{
              sortBy,
              sortDirection
            }]
          },
          [tileToUpdate]: newHeaderTile
        };
      }

      default:
        return state;
    }
  };
