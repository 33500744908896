// export type SpinnerSize = 'sm' | 'md' | 'lg';

export enum SpinnerSize {
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg'
}

export const SPINNER_SIZE_MAP: Record<SpinnerSize, string> = {
  sm: '50px',
  md: '100px',
  lg: '200px'
};
