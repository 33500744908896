import { useEffect } from 'react';

import { noop } from '../utils';

export const useOnUnmount = (callback = noop, deps: any[] = []): void => {
  useEffect(
    () => callback,
    // because of a need to trigger the hook only on unmount phase
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps]
  );
};
