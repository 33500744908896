import React from 'react';
import cs from 'classnames';
import { components } from 'react-select';
import { ControlProps } from 'react-select/src/components/Control';

import styles from './Control.module.css';

export function Control<T = AP.SelectOption>(
  props: ControlProps<T, boolean>
): JSX.Element {
  const { isFocused, isMulti } = props;

  return (
    <components.Control
      {...props}
      className={cs('form-control', styles.control, {
        [styles.controlFocused]: isFocused,
        [styles.controlMultiple]: isMulti
      })}
    />
  );
}
