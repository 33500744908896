import { actionsEn } from './actions.en';
import { adminEn } from './admin.en';
import { appEn } from './app.en';
import { commonEn } from './common.en';
import { dialogsEn } from './dialogs.en';
import { formsEn } from './forms.en';
import { pagesEn } from './pages.en';
import { placeholdersEn } from './placeholders.en';
import { registrationEn } from './registration.en';
import { validationEn } from './validation.en';
import { homepageEn } from './homepage.en';
import { onboardingEn } from './onboarding.en';

export const en = {
  empty: '',
  actions: actionsEn,
  admin: adminEn,
  app: appEn,
  common: commonEn,
  dialogs: dialogsEn,
  forms: formsEn,
  registration: registrationEn,
  pages: pagesEn,
  placeholders: placeholdersEn,
  validation: validationEn,
  homepage: homepageEn,
  onboarding: onboardingEn,
};
