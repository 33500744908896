function getFirstPageInSlice(
  calculatedFirstPage: number,
  calculatedLastPage: number,
  firstPage: number,
  lastPage: number
): number {
  if (calculatedFirstPage < firstPage) {
    return firstPage;
  }

  if (calculatedLastPage > lastPage) {
    return calculatedFirstPage - calculatedLastPage + lastPage;
  }

  return calculatedFirstPage;
}

function getLastPageInSlice(
  calculatedFirstPage: number,
  calculatedLastPage: number,
  firstPage: number,
  lastPage: number
): number {
  if (calculatedLastPage > lastPage) {
    return lastPage;
  }

  if (calculatedFirstPage < firstPage) {
    return calculatedLastPage + firstPage - calculatedFirstPage;
  }

  return calculatedLastPage;
}

export function getPagesSlice(
  currentPage: number,
  visiblePagesCount: number,
  lastPage: number
): number[] {
  const pages = Array.from(Array(lastPage), (_, i) => i + 1);

  if (pages.length <= visiblePagesCount) {
    return pages;
  }

  const FIRST_PAGE = 1;
  const stepFromMiddlePagePosition = Math.floor(visiblePagesCount / 2);
  const calculatedFirstPage = currentPage - stepFromMiddlePagePosition;
  const calculatedLastPage = currentPage + stepFromMiddlePagePosition;

  const firstPageInSlice = getFirstPageInSlice(
    calculatedFirstPage,
    calculatedLastPage,
    FIRST_PAGE,
    lastPage
  );

  const lastPageInSlice = getLastPageInSlice(
    calculatedFirstPage,
    calculatedLastPage,
    FIRST_PAGE,
    lastPage
  );

  return pages.slice(firstPageInSlice - 1, lastPageInSlice);
}
