// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { Configuration } from 'msal';

import { getWindow } from './shared/utils';


// Msal Configurations
const config: Configuration = {
  auth: {
    authority: `${getWindow().APP_CONFIG.AUTHORITY_URL}/${
      getWindow().APP_CONFIG.AAD_TENANT_ID
    }`,
    clientId: `${getWindow().APP_CONFIG.AAD_CLIENT_ID}`,
    redirectUri: `${getWindow().APP_CONFIG.GAP_APP_URL}`
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
};

// Authentication Parameters
const authenticationParameters = {
  scopes: ['user.read']
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: getWindow().APP_CONFIG.GAP_APP_URL
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
