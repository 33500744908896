import React from 'react';
import './AlertMessage.css';

interface AlertMessageProps {
  message: string;
  type: string;
}

export const AlertMessage: React.FC<
  React.PropsWithChildren<AlertMessageProps>
> = ({ message, type }: AlertMessageProps) => {

  const icon: any = {
    error: 'icon-error-special',
    warning:'icon-exclamation-mark-special'
  };
  
  return (
    <div className={`${type} message-container d-flex mb-4`}>
      <span className={`${icon[type]} bg-${type}-icon icon  mt-2 mr-3`} />
      <span className='changeSettingsTxt'>{message}</span>
    </div>
  );
};
