import { ThunkDispatch } from 'redux-thunk';

import { apiService } from 'shared/services';
import { UPDATE_SUBSCRIPTIONS_ACTIONS } from 'features/UpdateSubscription/UpdateSubscription.helpers';
import { errorException } from 'shared/utils/AppInsights';
import {
  SET_SUBSRIPTION_DETAILS,
  SET_SUBSRIPTION_COUNTRY_CODE,
  SET_SUBSRIPTION_PLANS,
  SET_USER_ACTION,
  SET_INIT_VALUES,
  SET_SUBSRIPTION_EMAIL,
  RENEW_SUBSCRIPTIONS,
  SET_IS_LOADING
} from './subscription.types';

export const renewSubscriptions = (plansData: any) => ({
  type: RENEW_SUBSCRIPTIONS,
  payload: plansData
});

export const setSubscriptionDetails = (subscription: {
  userDetails: DTO.UserDetails;
  states: DTO.State[];
  plans: DTO.BasePlan[];
}) => ({
  type: SET_SUBSRIPTION_DETAILS,
  payload: subscription
});

export const setCountryCode = ({ code, email }: any): any => ({
  type: SET_SUBSRIPTION_COUNTRY_CODE,
  payload: { countryCode: code, userEmail: email }
});

export const setSelectedEmail = (email: string): any => ({
  type: SET_SUBSRIPTION_EMAIL,
  payload: email
});

export const setPlans = (plans: any) => ({
  type: SET_SUBSRIPTION_PLANS,
  payload: plans
});

export const setUserAction = (action: UPDATE_SUBSCRIPTIONS_ACTIONS) => ({
  type: SET_USER_ACTION,
  payload: action
});

export const setInitValues = () => ({
  type: SET_INIT_VALUES,
  payload: ''
});

export const setIsLoading = (isLoading: boolean) => ({
  type: SET_IS_LOADING,
  payload: isLoading
});

export const fetchSubscriptionDetails = (
  product: string,
  email: string
) => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    setIsLoading(true);
    const subscriptionDetails = await apiService.fetchSubscriptionDetails(
      product,
      email
    );
    const states = await apiService.fetchCountryStates(
      subscriptionDetails.userDetails.country.code
    );
    dispatch(setSubscriptionDetails({ ...subscriptionDetails, states }));
  } catch (error) {
    // AppInsights Error Exception
    errorException(`Error ${error}, File reference: subscription.actions.tsx in fetchSubscriptionDetails function`);

    throw error;
  } finally {
    setIsLoading(false);
  }
};
