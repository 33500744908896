const numberFormatList = [
  {
    label: '1,000,000.00',
    format: '#,###,###.##',
    id: 'nf1',
    checked: true
  },
  {
    label: '1.000.000,00',
    format: '#.###.###,##',
    id: 'nf2',
    checked: false
  },
  {
    label: '1 000 000,00',
    format: '# ### ###,##',
    id: 'nf3',
    checked: false
  }
];
export default numberFormatList;
