import { Reducer } from 'redux';

import {
  asyncActionPending,
  asyncActionRejected,
  asyncActionFulfilled
} from 'shared/utils';
import {
  AuthActionTypes,
  AuthState,
  GetCurrentUserAction,
  SetAccessTokenAction,
  GET_CURRENT_USER,
  SET_ACCESS_TOKEN,
  PROCEED_WITHOUT_USER,
  SET_CURRENT_USER_FILTERS,
  SET_CURRENT_USER_ADMIN_FILTERS,
  SetCurrentUserFiltersAction,
  SetCurrentUserAdminFiltersAction,
  SET_SOURCE_SUBJECT_ID,
  SetSourceSubjectId
} from './auth.types';

const initialState: AuthState = {
  accessToken: null,
  user: null,
  isLoading: false,
  isLoaded: false,
  hasUserDeniedAccess: false,
  hasUserExpiredToken: false,
  isInternal: false,
  filter: {
    planStatus: [],
    countryCode: [],
    legalName: [],
    user: [],
    subscriberEmail: []
  },
  adminFilters: {
    emails: [],
    displayName: null,
    countryIds: [],
    role: 0
  },
  isFirstAdminFiltersInit: true,
  isFirstLogin: false,
  selectedAdminFilters: [],
  sourceSubjectId: "",
};

const getFilter = ({
  company,
  country,
  planStatus,
  user
}: DTO.RegistrationsFilter): any => {
  return {
    countryCode: country || [],
    legalName: (company as any) || [],
    planStatus: planStatus || [],
    user: user
      ? user.map(item => ({ firstName: item, email: item, lastName: item }))
      : []
  };
};

export const authReducer: Reducer<AuthState, AuthActionTypes> = (
  state: AuthState = initialState,
  action: AuthActionTypes
): AuthState => {
  switch (action.type) {
    case SET_ACCESS_TOKEN: {
      const accessToken = (action as SetAccessTokenAction).payload;
      return {
        ...state,
        accessToken
      };
    }

    case PROCEED_WITHOUT_USER:
      return {
        ...state,
        accessToken: null,
        user: null,
        isLoaded: true
      };

    case SET_CURRENT_USER_FILTERS:
      return {
        ...state,
        filter: (action as SetCurrentUserFiltersAction).payload
      };
    
    case SET_SOURCE_SUBJECT_ID:
      return {
        ...state,
        sourceSubjectId: (action as SetSourceSubjectId).payload
      };

    case SET_CURRENT_USER_ADMIN_FILTERS: {
      const { countryCode, displayName, emails } = (action as SetCurrentUserAdminFiltersAction).payload;
      const countryName = countryCode && countryCode.length > 0 ? countryCode.map(({ name }) => name) : [];
      const adminName = displayName && displayName.length > 0 ? displayName.map(({ fullName }) => fullName) : [];
      const adminEmails = emails && emails.length > 0 ? emails.map(({ email }) => email) : [];
      return {
        ...state,
        adminFilters: (action as SetCurrentUserAdminFiltersAction).payload,
        isFirstAdminFiltersInit: false,
        selectedAdminFilters: [
          {
            key: 'countryCode',
            value: countryName
          }, {
            key: 'displayName',
            value: adminName
          }, {
            key: 'emails',
            value: adminEmails
          }
        ]
      };
    }

    case asyncActionPending(GET_CURRENT_USER):
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      };

    case asyncActionRejected(GET_CURRENT_USER):
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        user: null,
        hasUserDeniedAccess:
          (action as Store.ErrorAction).payload.status === 403,
        hasUserExpiredToken:
          (action as Store.ErrorAction).payload.status === 401,
        isInternal: (action as Store.ErrorAction).payload.status === 401
      };

    case asyncActionFulfilled(GET_CURRENT_USER): {
      const user = (action as GetCurrentUserAction).payload;
      const initialUserData = user.profileSettings
        ? user
        : {
            ...user,
            profileSettings: {
              ...user.profileSettings,
              language: 'En-US',
              numberFormat: 'decimal',
              dateFormat: '1',
              registrationsFilter: {
                company: null,
                country: null,
                user: null,
                planStatus: null
              }
            }
          };

      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        user: initialUserData,
        hasUserDeniedAccess: user.adminProducts.length === 0,
        hasUserExpiredToken: false,
        isInternal: user.userType === 'Internal',
        // filter: getFilter(initialUserData.profileSettings.registrationsFilter),
        isFirstLogin: !user.profileSettings
      };
    }

    default:
      return state;
  }
};
