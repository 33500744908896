import React from 'react';
import { Pagination as BSPagination } from 'react-bootstrap';

import { Icon } from 'components/Icon/Icon';
import { getPagesSlice } from './Pagination.helpers';
import styles from './Paginator.module.css';

interface Props {
  currentPage: number;
  lastPage: number;
  visiblePagesCount: number;
  disabled: boolean;
  onPageChange: (currentPage: number) => void;
}

export const Paginator: React.FC<Props> = ({
  currentPage,
  lastPage,
  visiblePagesCount,
  disabled,
  onPageChange
}) => {
  const [pagesSlice, setPagesSlice] = React.useState<number[]>([]);
  const [activePage, setActivePage] = React.useState<number>();

  const onPageChangeCb = React.useCallback(
    page => (): void => {
      if (page !== currentPage) {
        onPageChange(page);
      }
    },
    [onPageChange, currentPage]
  );

  React.useEffect((): void => {
    setPagesSlice(getPagesSlice(currentPage, visiblePagesCount, lastPage));
    setActivePage(currentPage);
  }, [currentPage, visiblePagesCount, lastPage]);

  return (
    <BSPagination className={styles.paginator}>
      <BSPagination.First
        className={styles.pageControl}
        disabled={currentPage === 1 || disabled}
        onClick={onPageChangeCb(1)}
      >
        <Icon
          className={styles.controlIcon}
          name='arrow-double-left-special'
          size={18}
        />
      </BSPagination.First>
      <BSPagination.Prev
        className={styles.pageControl}
        disabled={currentPage === 1 || disabled}
        onClick={onPageChangeCb(currentPage - 1)}
      >
        <Icon name='smallchevron-left' size={15} />
      </BSPagination.Prev>
      {!pagesSlice.includes(1) && <span className={styles.ellipsis}>...</span>}

      {pagesSlice.map(item => (
        <BSPagination.Item
          key={item}
          active={item === activePage}
          className={styles.pageItem}
          disabled={disabled}
          onClick={onPageChangeCb(item)}
        >
          {item}
        </BSPagination.Item>
      ))}

      {!pagesSlice.includes(lastPage) && (
        <React.Fragment>
          <span className={styles.ellipsis}>...</span>
          <BSPagination.Item
            key={lastPage}
            active={lastPage === activePage}
            className={styles.pageItem}
            disabled={disabled}
            onClick={onPageChangeCb(lastPage)}
          >
            {lastPage}
          </BSPagination.Item>
        </React.Fragment>
      )}

      <BSPagination.Next
        className={styles.pageControl}
        disabled={currentPage === lastPage || disabled}
        onClick={onPageChangeCb(currentPage + 1)}
      >
        <Icon name='smallchevron-right' size={15} />
      </BSPagination.Next>
      <BSPagination.Last
        className={styles.pageControl}
        disabled={currentPage === lastPage || disabled}
        onClick={onPageChangeCb(lastPage)}
      >
        <Icon
          className={styles.controlIcon}
          name='arrow-double-right-special'
          size={18}
        />
      </BSPagination.Last>
    </BSPagination>
  );
};
