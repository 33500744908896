import React, { FC } from 'react';

interface Props {
  className?: string;
}

export const EditIcon: FC<Props> = ({ className = '' }) => (
  <svg
    className={className}
    fill='none'
    height='15'
    viewBox='0 0 15 15'
    width='15'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1 1H6.40909C6.68523 1 6.90909 1.22386 6.90909 1.5C6.90909 1.74546 6.73222 1.94961 6.49897 1.99194L6.40909 2H1V14H13V8.59091C13 8.31477 13.2239 8.09091 13.5 8.09091C13.7455 8.09091 13.9496 8.26778 13.9919 8.50103L14 8.59091V14C14 14.5128 13.614 14.9355 13.1166 14.9933L13 15H1C0.487164 15 0.0644928 14.614 0.00672773 14.1166L0 14V2C0 1.48716 0.38604 1.06449 0.883379 1.00673L1 1ZM12.8536 0.146447L14.8536 2.14645C15.0488 2.34171 15.0488 2.65829 14.8536 2.85355L8.35355 9.35355C8.28375 9.42335 8.19485 9.47093 8.09806 9.49029L5.59806 9.99029C5.2482 10.0603 4.93974 9.7518 5.00971 9.40194L5.50971 6.90194C5.52907 6.80515 5.57665 6.71625 5.64645 6.64645L12.1464 0.146447C12.3417 -0.0488155 12.6583 -0.0488155 12.8536 0.146447ZM12.5 1.20711L6.4606 7.24651L6.13738 8.86262L7.75349 8.5394L13.7929 2.5L12.5 1.20711Z'
      fill='#007CB0'
    />
  </svg>
);
