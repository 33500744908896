import React from 'react';
import { Table as BSTable } from 'react-bootstrap';

interface Props {
  className: string;
  isBorderless: boolean;
}

export const Table: React.FC<React.PropsWithChildren<Props>> = ({
  className = '',
  children,
  isBorderless
}): JSX.Element => (
  <BSTable borderless={isBorderless} className={className}>
    {children}
  </BSTable>
);
