// TODO: refactor this
/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';

import { selectUserInitials } from 'store/auth';
import { Icon } from 'components';
import styles from './UserMenuToggle.module.css';

interface Props {
  onClick?: () => void;
}

export const UserMenuToggle = React.forwardRef<HTMLDivElement, Props>(
  ({ onClick }, ref) => {
    const userInitials = useSelector(selectUserInitials);

    return (
      <div
        ref={ref}
        className={styles.toggle}
        role='presentation'
        onClick={onClick}
      >
        <span className={styles.toggleBox}>{userInitials}</span>
        <Icon
          className={styles.toggleIcon}
          name='smallchevron-down'
          size={12}
        />
      </div>
    );
  }
);
