const dateFormatList = [
  {
    label: 'MM/DD/YYYY',
    format: 'MM/dd/yyyy',
    id: 'df1',
    checked: true
  },
  {
    label: 'YYYY/MM/DD',
    format: 'yyyy/MM/dd',
    id: 'df2',
    checked: false
  },
  {
    label: 'DD/MM/YYYY',
    format: 'dd/MM/yyyy',
    id: 'df3',
    checked: false
  },
  {
    label: 'DD Month YYYY',
    format: 'dd MMMM yyyy',
    id: 'df4',
    checked: false
  }
];

export default dateFormatList;
