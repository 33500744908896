import React from 'react';
import cs from 'classnames';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { USER_ROLES } from 'features/AdminPage/OnboardingPage/OnboardingFilters/OnboardingFilters';
import { AdminContext } from 'features/AdminPage/AdminPageContent/AdminPageContent.helpers';
import { selectProductCode } from 'store/product';
import { DateLocale } from 'features/AdminPage/DateLocale';
import { reselectUser } from 'store/auth';
import formatNumber from 'shared/services/formatNumber';
import { SUBMISSION_PLAN_STATUS, SUBMISSION_TYPE } from 'shared/constants/submission.consts';
import { Radio, Form, Select, Confirmation } from 'components';
import { SubmissionPlanStatus } from '../SubmissionPlanStatus/SubmissionPlanStatus';
import { SubscriptionsState } from '../SubmissionPlansGrid/SubmissionPlansGrid.helpers';
import { formatDuration } from './SubmissionPlan.helpers';
import styles from './SubmissionPlan.module.css';

interface Props {
  id: string;
  plan: DTO.SubmissionPlan;
  state: SubscriptionsState;
  enablePlanControl: boolean;
  onChoose: (code: string, action: DTO.SubmissionPlanStatus, selectedProductCode: number) => void;
  onRoleSelect: (selectedRoleId: number, status: string) => void;
  isDetailsDialog?: boolean;
  productRoles:Array<any>;
}

export const SubmissionPlan: React.FC<Props> = ({
  id,
  enablePlanControl,
  plan: {
    title,
    code,
    model,
    availableLicenses,
    purchasedLicenses,
    status,
    performedBy,
    performedAt,
    expiredAt,
    duration,
    userProductRole
  },
  state,
  onChoose,
  onRoleSelect,
  isDetailsDialog,
  productRoles
}) => {
  const { t } = useTranslation();
  const user = useSelector(reselectUser);
  const DATE_FORMAT = user.profileSettings.dateFormat;
  const NUMBER_FORMAT = user.profileSettings.numberFormat;
  const [confirm, setConfirm] = React.useState<boolean>(false);
  const productCode = useSelector(selectProductCode);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
  const licenses =
    status === SUBMISSION_PLAN_STATUS.PENDING
      ? purchasedLicenses
      : availableLicenses;
  const [selectedProductCode, setSelectedProductCode] = React.useState<any>(null);
  const [activeStatus]= React.useState('active');
  const ifStatusActiveOrCancel = status === SUBMISSION_PLAN_STATUS.ACTIVE || status === SUBMISSION_PLAN_STATUS.CANCELLED;
  const roleToSelect = productRoles && productRoles.find(pR => pR.productRoleId !== userProductRole)?.roleName;
  const roleSelected = productRoles && productRoles.find(pR => pR.productRoleId === userProductRole)?.roleName;
  const message = `Are you sure you want to change ${roleSelected} to ${roleToSelect}?`;
  const { type } = React.useContext(AdminContext);
  
  const handleRejectCallback = React.useCallback((): void => {
    setIsDisabled(true);
    onChoose(code,SUBMISSION_PLAN_STATUS.REJECTED,0);
  }, [onChoose, code]);

  const handleApproveCallback = React.useCallback((): void => {
    setIsDisabled(false);
    onChoose(code, SUBMISSION_PLAN_STATUS.APPROVED,selectedProductCode);
  }, [onChoose, code, selectedProductCode]);

  const handleOnReject = React.useCallback((): void => {
     setConfirm(false);
  }, []);

  const handleOnResolve = React.useCallback(() => {
    const changedRoleId = productRoles && productRoles.find(pR => pR.productRoleId !== userProductRole)?.productRoleId;
    setConfirm(true);
    onRoleSelect(changedRoleId, status);
    }, [onRoleSelect, productRoles, userProductRole, status]);

  const handleProductRoleCallback = React.useCallback((value: AP.Nullable<DTO.Roles>) => {
    const userProductRoles = (value === null ? 0 : value.productRoleId);
    setSelectedProductCode(userProductRoles);
    onChoose(code, state[code].status, userProductRoles);
    if (ifStatusActiveOrCancel && userProductRoles>0 && userProductRoles !== userProductRole)
      setConfirm(true);
    else
      setConfirm(false);
  }, [onChoose, code, state, ifStatusActiveOrCancel, userProductRole]);

  const getOptionValueCallback = React.useCallback(
    ({ ids }): string => ids,
    []
  );

  const getOptionLabelCallback = React.useCallback(
    ({ roleName }): string => roleName,
    []
  );

  const renderOptionCallback = React.useCallback(
    ({ roleName }: DTO.Roles): JSX.Element => (
      <div className={styles.option}>
        <span>{roleName}</span>
      </div>
    ),
    []
  );

  const findSelectedProductFromOptions = React.useCallback(
    productRole =>{ 
      return productRole.productRoleId === selectedProductCode;
    }, [selectedProductCode]
  );

  const value = React.useMemo(
    (): DTO.Roles => {
     return productRoles && productRoles.find(findSelectedProductFromOptions);
    },[productRoles, findSelectedProductFromOptions]
  );

  const getSelectedRole = () => {
    return productRoles && productRoles.find(productRole => productRole.productRoleId === selectedProductCode)?.roleName || '';
  };

  const getDuration = formatDuration({
    status,
    model,
    duration,
    performedAt,
    expiredAt,
    DATE_FORMAT,
    translation: t
  });
  React.useEffect((): void => {
    if (Object.keys(state).length > 0) {
      if (!isDisabled && status === "rejected") {
        setIsDisabled(true);
        handleProductRoleCallback(null);
      }
    }
    else
      setSelectedProductCode(userProductRole);
  }, [type, userProductRole, state, status, handleProductRoleCallback, isDisabled]);
  React.useEffect((): void => {
    if (Object.keys(state).length > 0) {
      if ((status === "rejected" || state[code].status === "rejected") && userProductRole !== selectedProductCode) {
        setIsDisabled(true);
        // handleProductRoleCallback(null);
      }
    }
    else
      setSelectedProductCode(userProductRole);
  }, [userProductRole, state, status, handleProductRoleCallback, code, selectedProductCode]);

  return (
    <React.Fragment>
      <div title={title}>{title}</div>
      <div
        className={cs(styles.shifted)}
        title={licenses && formatNumber(licenses, NUMBER_FORMAT).toString()}
      >
        {formatNumber(licenses, NUMBER_FORMAT)}
      </div>
      <div>
        <SubmissionPlanStatus
          isDetailsDialog={isDetailsDialog}
          status={status}
        />
      </div>
      <div title={performedBy || ''}>{performedBy || '-'}</div>
      <div
        title={
          // eslint-disable-next-line no-nested-ternary
          performedAt
            ? DATE_FORMAT !== 'dd MMMM yyyy'
              ? format(new Date(performedAt), DATE_FORMAT)
              : DateLocale(format(new Date(performedAt), DATE_FORMAT))
            : ''
        }
      >
        {// eslint-disable-next-line no-nested-ternary
        performedAt
          ? DATE_FORMAT !== 'dd MMMM yyyy'
            ? format(new Date(performedAt), DATE_FORMAT)
            : DateLocale(format(new Date(performedAt), DATE_FORMAT))
          : '-'}
      </div>
      <div
        className={cs(styles.long)}
        title={getDuration !== '-' ? getDuration : ''}
      >
        {getDuration}
      </div>
      { (productCode==="mip")?(        
        <React.Fragment>
          {isDetailsDialog ?
            (
              <div title={(getSelectedRole()).toString() || ''}>{getSelectedRole() || ''}</div>
            )
            :
            (
              <Form className={styles.form}>
                <Form.Group
                  note={null}
                  title={null}
                >
                  <Select<DTO.Roles>
                    getOptionLabel={getOptionLabelCallback}
                    getOptionValue={getOptionValueCallback}
                    isDisabled={isDisabled || user.role === USER_ROLES.SUPER_ADMIN }
                    options={productRoles}
                    placeholder={t('placeholders.selectRole')}//"Select Role"
                    renderOption={renderOptionCallback}
                    value={value}
                    onChange={handleProductRoleCallback}
                  />
                </Form.Group>
              </Form>
            )
          }
          {confirm && ifStatusActiveOrCancel ? (
            <Confirmation
              message={message}
              title="Change Role"
              onReject={handleOnReject}
              onResolve={handleOnResolve}
            />
          ) : null}
        </React.Fragment>
  ):null
    }
      {enablePlanControl && Object.keys(state).length > 0 && (
        <React.Fragment>
          {(status === SUBMISSION_PLAN_STATUS.PENDING ||
            status === SUBMISSION_PLAN_STATUS.PENDING_REACTIVATION ||
            status === SUBMISSION_PLAN_STATUS.PENDING_RENEWAL ||
            status === SUBMISSION_PLAN_STATUS.PENDING_ACTIVATION) && (
            <React.Fragment>
              <div />
              <div className={styles.shifted}>
                <Radio
                  checked={
                    state[code].status === SUBMISSION_PLAN_STATUS.REJECTED
                  }
                  disabled={user.role === USER_ROLES.SUPER_ADMIN}
                  id={`${id}-${code}-reject`}
                  label={t('admin.plans.reject')}
                  name={`${id}-${code}`}
                  onChange={handleRejectCallback}
                />
              </div>
              <div className={styles.shifted}>
                <Radio
                  checked={
                    state[code].status === SUBMISSION_PLAN_STATUS.APPROVED
                  }
                  disabled={user.role === USER_ROLES.SUPER_ADMIN}
                  id={`${id}-${code}-approve`}
                  label={t('admin.plans.approve')}
                  name={`${id}-${code}`}
                  onChange={handleApproveCallback}
                />
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
