import React, { useState } from 'react';
import { format } from 'date-fns';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { reselectUser } from 'store/auth';
import { USER_ROLES } from 'features/AdminPage/OnboardingPage/OnboardingFilters/OnboardingFilters';
import { selectProductCode } from 'store/product';
import { SUBMISSION_TYPE } from 'shared/constants/submission.consts';
import { Icon, UsersIcon } from 'components';
import { DateLocale } from 'features/AdminPage/DateLocale';
import { AdminContext } from 'features/AdminPage/AdminPageContent/AdminPageContent.helpers';
import editIconImage from 'assets/images/edit-icon.svg';
import styles from '../SubmissionsGrid.module.css';
import { EditableComponent } from './EditableComponent';

export interface SubmissionRecordProps {
  submission: DTO.SubmissionRecord;
  onDetailsClick: () => void;
  onCommentClick?: () => void;
  onUsersclick?: () => void;
  onChangeEditMode: () => void;
  onConfirmEdit: () => void;
  onCancelEdit: () => void;
  onStartEditing: (e: any) => void;
  DATE_FORMAT?: string;
  isEnableEdit: boolean;
  editLegalName: string;
  showUserIcon: boolean;
}

export const Submission: React.FC<SubmissionRecordProps> = ({
  onCommentClick,
  onDetailsClick,
  onUsersclick,
  onChangeEditMode,
  onConfirmEdit,
  onCancelEdit,
  onStartEditing,
  isEnableEdit,
  DATE_FORMAT,
  showUserIcon,
  submission: {
    comment,
    country: { name: countryName },
    organization: { legalName },
    user: { firstName, lastName, email, submissionDate, fullName },
    plans
  },
  editLegalName,
}) => {
  const { type } = React.useContext(AdminContext);
  const { t } = useTranslation();
  const productCode = useSelector(selectProductCode);
  const user = useSelector(reselectUser);
  const contentHiddenText = t('admin.grid.hiddencontent');
 
  const formatSubscriberName = () => {
    return user.role === USER_ROLES.SUPER_ADMIN
      ? contentHiddenText
      : fullName;
  };

  const formatCompanyName = () => {
    return user.role === USER_ROLES.SUPER_ADMIN ? contentHiddenText : legalName;
  };

  const formatEmail = () => {
    return user.role === USER_ROLES.SUPER_ADMIN ? contentHiddenText : email;
  };

  return (
    <React.Fragment>
      <p>
        <button
          className={cs(styles.iconButton)}
          title={t('admin.grid.submissionDetails')}
          type='button'
          onClick={React.useCallback((): void => {
            onDetailsClick();
          }, [onDetailsClick])}
        >
          <Icon name='document-1-special' size={24} />
        </button>
      </p>
      <p>{countryName}</p>
      <p className={styles.overflow} title={legalName}>
        {isEnableEdit &&
        legalName &&
        type === SUBMISSION_TYPE.PENDING &&
        productCode === 'mip' ? (
          <EditableComponent
            existingValue={editLegalName}
            onCancelEdit={onCancelEdit}
            onConfirmEdit={onConfirmEdit}
            onStartEditing={onStartEditing}
          />
        ) : (
          <React.Fragment>
            <span
              className={cs(
                styles.overflow,
                styles.fixSpan,
                user.role === USER_ROLES.SUPER_ADMIN ? styles.hiddenContent : ''
              )}
              title={formatCompanyName()}
            >
              {formatCompanyName()}
            </span>
            {legalName &&
              type === SUBMISSION_TYPE.PENDING &&
              user.role !== USER_ROLES.SUPER_ADMIN &&
              productCode === 'mip' && (
                <button
                  className={cs(styles.iconButton)}
                  title={t('admin.grid.editCompanyName')}
                  type='button'
                  onClick={onChangeEditMode}
                >
                  <img
                    alt='edit company'
                    className={styles.editImg}
                    src={editIconImage}
                  />
                </button>
              )}
          </React.Fragment>
        )}
      </p>
      <p
        className={cs(
          styles.overflow,
          user.role === USER_ROLES.SUPER_ADMIN ? styles.hiddenContent : ''
        )}
        title={formatSubscriberName()}
      >
        {formatSubscriberName()}
      </p>
      <p
        className={cs(
          styles.overflow,
          user.role === USER_ROLES.SUPER_ADMIN ? styles.hiddenContent : ''
        )}
        title={formatEmail()}
      >
        {formatEmail()}
      </p>
      <p>
        {DATE_FORMAT !== 'dd MMMM yyyy'
          ? format(new Date(submissionDate), DATE_FORMAT)
          : DateLocale(format(new Date(submissionDate), DATE_FORMAT))}
      </p>
      <p>
        {comment && (
          <button
            className={cs(
              styles.iconButton,
              user.role === USER_ROLES.SUPER_ADMIN
                ? styles.iconButtonDisable
                : null
            )}
            type='button'
            onClick={onCommentClick}
          >
            <Icon name='speech-2-special' size={24} />
          </button>
        )}
        {type === SUBMISSION_TYPE.PROCESSED && showUserIcon && (
          <button
          className={cs(
            styles.iconButton,
            plans.some((plan: any) => plan.status === 'active')
              ? styles.iconButton
              : styles.iconButtonDisable,
            user.role === (USER_ROLES.SUPER_ADMIN)
              ? styles.iconButtonDisable
              : null              
          )}           
          style={{ verticalAlign: 'text-bottom' }}
          title={t('admin.grid.aruser')}
          type='button'
          onClick={onUsersclick}
        >
          {/* <Icon name='user-2-special' size={24} /> */}
          <UsersIcon />
        </button>
        )}
      </p>
    </React.Fragment>
  );
};
