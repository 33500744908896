import React, { useEffect, useState } from 'react';
import { components } from 'react-select';
import { OptionProps } from 'react-select/src/components/Option';
import cs from 'classnames';

import styles from './Option.module.css';

export function Option<T>(props: OptionProps<T, boolean>): JSX.Element {
  const { isSelected, isFocused, isDisabled } = props;
  const [isFirstInit, setIsFirstInit] = useState(true);
  const [hasFocus, setHasFocus] = useState(false);

  // To disable autofocus on the first option, which is set by library by default
  useEffect(() => {
    if (isFirstInit) {
      setIsFirstInit(false);
    } else {
      setHasFocus(isFocused);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, isFocused]);

  return (
    <components.Option
      {...props}
      className={cs(styles.customOption, {
        [styles.selected]: isSelected,
        [styles.focused]: hasFocus,
        [styles.disabled]: isDisabled
      })}
    />
  );
}
