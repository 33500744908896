import React from 'react';

interface Props {
  href?: string;
  className?: string;
  onClickHandler?: () => void;
}

export const BlankLink: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  href,
  className,
  onClickHandler
}) => (
  <a
    className={className}
    href={href}
    rel='noopener noreferrer'
    target='_blank'
    onClick={onClickHandler}
  >
    {children}
  </a>
);
