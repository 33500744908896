import React from 'react';
import { Card as BSCard } from 'react-bootstrap';
import cs from 'classnames';

import { findSlot } from 'shared/utils';
import { CardHeader } from './CardHeader/CardHeader';
import { CardContent } from './CardContent/CardContent';
import { CardFooter } from './CardFooter/CardFooter';
import styles from './Card.module.css';

interface Props {
  children: React.ReactChild | React.ReactChild[];
  highlighted?: boolean;
  className?: string;
}

export function Card({ highlighted, className, children }: Props): JSX.Element {
  const childrenArray = React.Children.toArray(children);
  const header = findSlot(childrenArray, CardHeader.name);
  const content = findSlot(childrenArray, CardContent.name);
  const footer = findSlot(childrenArray, CardFooter.name);

  return (
    <BSCard
      className={cs(className, styles.card, {
        [styles.highlighted]: highlighted
      })}
    >
      <BSCard.Body>
        {header}
        {content}
      </BSCard.Body>
      {footer && (
        <BSCard.Footer
          className={cs(styles.noBorder, {
            [styles.highlighted]: highlighted
          })}
        >
          {footer}
        </BSCard.Footer>
      )}
    </BSCard>
  );
}

Card.Header = CardHeader;
Card.Content = CardContent;
Card.Footer = CardFooter;
