import React from 'react';

import { Icon } from 'components';
import styles from '../../SubmissionsGrid.module.css';

interface Props {
  isOpen: boolean;
  onToggle: () => void;
}

export const SubmissionPlanToggler: React.FC<Props> = ({
  onToggle,
  isOpen
}) => (
  <button className={styles.iconButton} type='button' onClick={onToggle}>
    <Icon
      className={styles.iconButton}
      name={isOpen ? 'smallchevron-up' : 'smallchevron-right'}
      size={12}
    />
  </button>
);
