import React from 'react';

import styles from './Sidebar.module.css';

type Props = {
  children: JSX.Element;
};

export function Footer({ children }: Props): JSX.Element {
  return <div className={styles.Footer}>{children}</div>;
}
