import React from 'react';
import { IndicatorProps } from 'react-select/src/components/indicators';
import cs from 'classnames';

import { Icon } from 'components/Icon/Icon';
import styles from './DropdownIndicator.module.css';

export function DropdownIndicator<T>({
  isFocused
}: IndicatorProps<T, boolean>): JSX.Element {
  return (
    <Icon
      className={cs(styles.icon, { [styles.focused]: isFocused })}
      name={isFocused ? 'smallchevron-up' : 'smallchevron-down'}
      size={12}
    />
  );
}
