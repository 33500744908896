import { createSelector } from 'reselect';

import { BASE_HTTP_HEADERS } from 'shared/constants/api.consts';
import { RootState } from 'store/root.reducer';

const selectUser = ({ auth }: RootState): AP.Nullable<DTO.User> => auth.user;

export const selectHasUserLoaded = ({ auth }: RootState): boolean =>
  auth.isLoaded;

export const selectHasUser = ({ auth }: RootState): boolean =>
  auth.user !== null;

export const selectIsUserLoading = ({ auth }: RootState): boolean =>
  auth.isLoading;

export const reselectUser = createSelector(
  selectUser,
  user => user
);

export const reselectAdminProducts = createSelector(
  selectUser,
  user => (user === null ? [] : user.adminProducts)
);

export const selectFirstAdminProductCode = ({
  auth
}: RootState): AP.Nullable<string> =>
  auth.user === null || auth.user.adminProducts.length === 0
    ? null
    : auth.user.adminProducts[0].code;

export const selectHasUserDeniedAccess = ({ auth }: RootState): boolean =>
  auth.hasUserDeniedAccess;

export const selectHasUserExpiredToken = ({ auth }: RootState): boolean =>
  auth.hasUserExpiredToken;

export const selectIsInternal = ({ auth }: RootState): boolean =>
  auth.isInternal;

export const selectSubscriptionProductCode = ({
  auth
}: RootState): AP.Nullable<string> =>
  auth.user === null || auth.user.product === null
    ? null
    : auth.user.product.productId;

export const selectProductAppUrl = ({ auth }: RootState): AP.Nullable<string> =>
  auth.user === null || auth.user.product === null
    ? null
    : auth.user.product.appUrl;

export const selectProductName = ({ auth }: RootState): AP.Nullable<string> =>
  auth.user === null || auth.user.product === null
    ? null
    : auth.user.product.name;

export const selectSubscriptionCountryCode = ({
  auth
}: RootState): AP.Nullable<string> =>
  auth.user === null || auth.user.product === null
    ? null
    : auth.user.product.countryCode;

export const selectUserInitials = ({ auth }: RootState): string =>
  auth.user === null
    ? ''
    : `${auth.user.firstName[0].toLocaleUpperCase()}${auth.user.lastName[0].toLocaleUpperCase()}`;

// TODO: remove when all useGet/useMutate are removed
export const selectHttpHeaders = ({ auth }: RootState): any => {
  const headers: Record<string, string> = {
    ...BASE_HTTP_HEADERS
  };

  if (auth.accessToken !== null) {
    headers.Authorization = `Bearer ${auth.accessToken}`;
  }

  return headers;
};

export const reselectAutoLogoutData = createSelector(
  reselectUser,
  ({ idleTimeToLogOut, beforeLogOutPopUpTime }) => {
    const originalLogoutTimeout = idleTimeToLogOut - beforeLogOutPopUpTime;
    const originalKeepAliveTimeout = Math.floor(
      (originalLogoutTimeout - 1) / 2
    );

    return {
      originalLogoutTimeout,
      originalKeepAliveTimeout,
      originalPopupTimeout: beforeLogOutPopUpTime
    };
  }
);

export const selectAdminFilters = ({ auth }: RootState) => auth.adminFilters;
export const selectIsFirstAdminFiltersInit = ({ auth }: RootState) =>
  auth.isFirstAdminFiltersInit;
export const selectIsFirstLogin = ({ auth }: RootState): boolean =>
  auth.isFirstLogin;
export const selectAppliedAdminFilters = ({ auth }: RootState) => auth.selectedAdminFilters;

export const selectSourceSubjectId = ({ auth }: RootState): string => auth.sourceSubjectId;
