// export const DATE_FORMAT = 'd MMM yyyy';
// export const FULL_DATE_FORMAT = 'dd MMMM yyyy';

import { getWindow } from '../utils';

export const defaultDateFormat = 'MM/dd/yyyy';
export const defaultNumberFormat = '#,###,###.##';

export enum SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc'
}

export const LOCAL_STORAGE_TOKEN_KEY = 'oidc.token';

export const AppLocales = {
  enUS: 'en-US'
};

export const defaultLocale = {
  localeCode: 'en-US',
  language: 'English (United States)',
  nativeName: 'English (United States)',
  englishName: 'English (United States)'
};
export const LOCAL_STORAGE_EXTERNAL_USER_PRODUCT_KEY = 'external_product';

export const MSAL_TOKEN_KEY = `msal.${
  getWindow().APP_CONFIG.AAD_CLIENT_ID
}.idtoken`;

export const GLOBAL_PRIVACY_STATEMENT = [
  {
      countryCode: "bra",
      name: "Brazil",
      privacyStatement: "https://www2.deloitte.com/br/pt/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "can",
      name: "Canada",
      privacyStatement: "https://www2.deloitte.com/ca/en/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "chl",
      name: "Chile",
      privacyStatement: "https://www2.deloitte.com/cl/es/footerlinks1/privacidad.html?icid=bottom_privacidad"
  },
  {
      countryCode: "cyp",
      name: "Cyprus",
      privacyStatement: "https://www2.deloitte.com/cy/en/footerlinks1/deloitte-legal-privacy.html?icid=bottom_deloitte-legal-privacy"
  },
  {
      countryCode: "mex",
      name: "Mexico",
      privacyStatement: "https://www2.deloitte.com/mx/es/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "abw",
      name: "Aruba",
      privacyStatement: "https://www.deloitte.com/an/en/legal/privacy.html?icid=bn_privacy"
  },
  {
      countryCode: "cuw",
      name: "Curacao",
      privacyStatement: "https://www.deloitte.com/an/en/legal/privacy.html?icid=bn_privacy"
  },
  {
      countryCode: "nld",
      name: "Netherlands",
      privacyStatement: "https://www2.deloitte.com/nl/nl/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "arg",
      name: "Argentina",
      privacyStatement: "https://www2.deloitte.com/ar/es/legal/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "dom",
      name: "Dominican Republic",
      privacyStatement: "https://www2.deloitte.com/do/es/footerlinks1/aviso-privacidad.html?icid=bottom_aviso-privacidad"
  },
  {
      countryCode: "hnd",
      name: "Honduras",
      privacyStatement: "https://www2.deloitte.com/hn/es/footerlinks1/aviso-privacidad.html?icid=bottom_aviso-privacidad"
  },
  {
      countryCode: "nic",
      name: "Nicaragua",
      privacyStatement: "https://www2.deloitte.com/ni/es/footerlinks1/aviso-privacidad.html?icid=bottom_aviso-privacidad"
  },
  {
      countryCode: "slv",
      name: "El Salvador",
      privacyStatement: "https://www2.deloitte.com/sv/es/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "col",
      name: "Colombia",
      privacyStatement: "https://www2.deloitte.com/co/es/footerlinks1/Politica-de-Privacidad1.html?icid=bottom_"
  },
  {
      countryCode: "cri",
      name: "Costa Rica",
      privacyStatement: "https://www2.deloitte.com/cr/es/footerlinks1/aviso-privacidad.html?icid=bottom_aviso-privacidad"
  },
  {
      countryCode: "ecu",
      name: "Ecuador",
      privacyStatement: "https://www2.deloitte.com/ec/es/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "gtm",
      name: "Guatemala",
      privacyStatement: "https://www2.deloitte.com/gt/es/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "pan",
      name: "Panama",
      privacyStatement: "https://www2.deloitte.com/pa/es/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "per",
      name: "Peru",
      privacyStatement: "https://www2.deloitte.com/pe/es/footerlinks1/Politica-de-privacidad.html?icid=bottom_Politica-de-privacidad"
  },
  {
      countryCode: "pry",
      name: "Paraguay",
      privacyStatement: "https://www2.deloitte.com/py/es/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "ury",
      name: "Uruguay",
      privacyStatement: "https://www2.deloitte.com/uy/es/pages/about-deloitte/articles/avisos-de-privacidad.html?icid=bottom_avisos-de-privacidad"
  },
  {
      countryCode: "ven",
      name: "Venezuela",
      privacyStatement: "https://www2.deloitte.com/ve/es/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "bol",
      name: "Bolivia",
      privacyStatement: "https://www2.deloitte.com/bo/es/legal/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "are",
      name: "United Arab Emirates",
      privacyStatement: "https://www2.deloitte.com/xe/en/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "bhr",
      name: "Bahrain",
      privacyStatement: "https://www2.deloitte.com/bh/en/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "egy",
      name: "Egypt",
      privacyStatement: "https://www2.deloitte.com/eg/en/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "irq",
      name: "Iraq",
      privacyStatement: "https://www2.deloitte.com/iq/en/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "jor",
      name: "Jordan",
      privacyStatement: "https://www2.deloitte.com/jo/en/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "kwt",
      name: "Kuwait",
      privacyStatement: "https://www2.deloitte.com/kw/en/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "lbn",
      name: "Lebanon",
      privacyStatement: "https://www2.deloitte.com/lb/en/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "lby",
      name: "Libya",
      privacyStatement: "https://www2.deloitte.com/ly/en/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "pmn",
      name: "Oman",
      privacyStatement: "https://www2.deloitte.com/om/en/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "pse",
      name: "Palestinian Territory",
      privacyStatement: "https://www2.deloitte.com/xe/en/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "qat",
      name: "QATAR",
      privacyStatement: "https://www2.deloitte.com/qa/en/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "yem",
      name: "Yemen",
      privacyStatement: "https://www2.deloitte.com/ye/en/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "ita",
      name: "Italy",
      privacyStatement: "https://www2.deloitte.com/it/it/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
      countryCode: "mlt",
      name: "Malta",
      privacyStatement: "https://www2.deloitte.com/mt/en/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
    countryCode: "sau",
    name: "Saudi Arabia",
    privacyStatement: "https://www2.deloitte.com/sa/en/footerlinks1/privacy.html?icid=bottom_privacy"
  },
  {
    countryCode: "esp",
    name: "Spain",
    privacyStatement: "https://www2.deloitte.com/es/es/footerlinks1/politica-privacidad.html?icid=bottom_politica-privacidad"
  },

];
