import React from 'react';
import kebabCase from 'lodash/kebabCase';
import cs from 'classnames';
import AsyncSelect from 'react-select/async';
import { ValueType } from 'react-select/src/types';
import { SelectComponents } from 'react-select/src/components';
import { Form, Col, Row } from 'react-bootstrap';
import { useField } from 'formik';

import { noop } from 'shared/utils';
import { Nothing } from 'components/Nothing/Nothing';
import { DropdownIndicator } from './DropdownIndicator/DropdownIndicator';
import { Placeholder } from './Placeholder/Placeholder';
import { Control } from './Control/Control';
import { SingleValue } from './SingleValue/SingleValue';
import { MultiValue } from './MultiValue/MultiValue';
import { Menu } from './Menu/Menu';
import { Option } from './Option/Option';
import { useOptionProp, useSelectLabel, SelectProps } from './Select.helpers';
import { useError } from '../Form.helpers';
import styles from '../Form.module.css';

interface Props<T> extends SelectProps {
  load: (q: string, cb: (data: T[]) => void) => void;
  noOptions?: () => string;
  loadMessage?: () => string;
  className?: string;
  withoutIndicator?: boolean;
}

export default function SelectAsyncFieldFilters<T = AP.SelectOption>({
  name,
  span,
  label,
  load,
  onChange = noop,
  multiple = false,
  placeholder = '',
  optionLabel = 'label',
  optionValue = 'value',
  validationErrMsg = (): string => '',
  noOptions,
  loadMessage,
  className,
  withoutIndicator
}: Props<T>): JSX.Element {
  const renderSelectLabel = useSelectLabel(label);
  const handleGetOptionLabel = useOptionProp<T>(optionLabel);
  const handleGetOptionValue = useOptionProp<T>(optionValue);
  const [components] = React.useState<Partial<SelectComponents<T, boolean>>>({
    DropdownIndicator: withoutIndicator ? Nothing : DropdownIndicator,
    Placeholder,
    SingleValue,
    Control,
    Option,
    Menu,
    MultiValue,
    IndicatorSeparator: Nothing
  });

  const [field, meta, form] = useField(name);
  const { value } = field;
  const { error, touched } = meta;
  const { setValue, setTouched } = form;
  const isInvalid = error && touched;

  return (
    <Form.Group
      className={cs(
         styles.filterFormGroup,
         styles.content,
      )}
      controlId={kebabCase(`form select ${label}`)}
    >
      {renderSelectLabel}
          <AsyncSelect
          className={cs({
            [styles.formControlInvalid]: isInvalid
          },
            className,
          )}
          components={components}
          getOptionLabel={handleGetOptionLabel}
          getOptionValue={handleGetOptionValue}
          isClearable={false}
          isMulti={multiple}
          loadingMessage={loadMessage}
          loadOptions={load}
          noOptionsMessage={noOptions}
          placeholder={placeholder}
          value={value}
          onBlur={(): void => setTouched(touched)}
          onChange={(selectedValue: ValueType<any, boolean>): void => {
            onChange(selectedValue);
            setValue(selectedValue);
          }}
        />
      {useError(error, touched, validationErrMsg)}
    </Form.Group>
  );
}
