import React from 'react';
import cs from 'classnames';

export type IconName =
  | 'exclamation-mark-special'
  | 'cross-special'
  | 'checkbox-tick-special'
  | 'smallchevron-up'
  | 'smallchevron-down'
  | 'smallchevron-left'
  | 'smallchevron-right'
  | 'skip-rewind-special'
  | 'skip-forward-special'
  | 'speech-2-special'
  | 'sort-amount-ascending-special'
  | 'sort-amount-descending-special'
  | 'filter-special'
  | 'exit-special'
  | 'tick-special'
  | 'document-1-special'
  | 'user-2-special'
  | 'arrow-double-left-special'
  | 'arrow-double-right-special'
  | 'plus-special';

interface Props {
  name: IconName;
  size?: number;
  className?: string;
}

export function Icon({ name, className, size = 14 }: Props): JSX.Element {
  return (
    <span
      className={cs(`icon-${name}`, className)}
      style={{ fontSize: `${size}px` }}
    />
  );
}
