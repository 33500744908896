import React from 'react';
import get from 'lodash/get';
import cs from 'classnames';

import { Step } from './Step/Step';
import styles from './Stepper.module.css';

interface Props {
  current: string;
  children: React.ReactChild | React.ReactChild[];
  isUpdateSubscriptionPage?: boolean;
}

export function Stepper({ current, children, isUpdateSubscriptionPage = false }: Props): JSX.Element {
  const { points, content } = React.useMemo(() => {
    const childrenArray = React.Children.toArray(children);
    const active = childrenArray.findIndex(
      (el: any) => el.props.name === current
    );

    const pointsToShow = childrenArray.map((item: any, i) =>
      React.cloneElement(item, {
        passed: i < active,
        active: i === active,
        index: i
      })
    );
    const contentToShow = get(childrenArray[active], 'props.children');

    return { points: pointsToShow, content: contentToShow };
  }, [current, children]);

  return (
    <React.Fragment>
      <div className={styles.stepContainer}>
        <ul className={cs(styles.stepper, 
          isUpdateSubscriptionPage ? styles.handleStepperMargin: styles.borderBottom)}>{points}</ul>
        {!isUpdateSubscriptionPage && (<hr className={styles.hr} />)}

        {content}
      </div>
    </React.Fragment>
  );
}

Stepper.Step = Step;
