import { Reducer } from 'redux';

import {
  asyncActionFulfilled,
  asyncActionPending,
  asyncActionRejected
} from 'shared/utils';
import {
  ProductActionTypes,
  ProductState,
  SET_PRODUCT_FROM_PATH,
  SetProductAction
} from './product.types';

const initialProductState: ProductState = {
  hasSuccessData: false,
  isLoading: false,
  isLoaded: false,
  code: null,
  title: '',
  appUrl: '',
  plans: [],
  countries: [],
  memberFirms: [],
  fieldSettings: []
};

export const productReducer: Reducer<ProductState, ProductActionTypes> = (
  state: ProductState = initialProductState,
  action: ProductActionTypes
): ProductState => {
  switch (action.type) {
    case asyncActionPending(SET_PRODUCT_FROM_PATH):
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        hasSuccessData: false
      };

    case asyncActionRejected(SET_PRODUCT_FROM_PATH):
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        hasSuccessData: false
      };

    case asyncActionFulfilled(SET_PRODUCT_FROM_PATH): {
      const {
        code,
        title,
        appUrl,
        plans,
        countries,
        memberFirms,
        fieldSettings
      } = (action as SetProductAction).payload;

      return {
        ...state,
        code,
        title,
        appUrl,
        plans,
        countries,
        memberFirms,
        fieldSettings,
        isLoading: false,
        isLoaded: true,
        hasSuccessData: true
      };
    }

    default:
      return state;
  }
};
