import React, { PropsWithChildren, FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';
import { useLocation } from 'react-router-dom';

import { Navbar } from 'components';
import { USER_ROLES } from 'features/AdminPage/OnboardingPage/OnboardingFilters/OnboardingFilters';
import logo from 'shared/icons/logo.svg';
import Omnialogo from 'shared/icons/Omnia.svg';
// import { selectProductName, selectProductCode } from 'store/auth';
import {
  selectProductCode,
  selectProductTitle
} from '../../store/product/product.selectors';
import { SelectLocale } from '../SelectLocale/SelectLocale';
import { AppContext } from './AppLayout.helpers';
import styles from './AppLayout.module.css';
import { Tab } from '../../components/Tab/Tab';
import { reselectUser } from '../../store/auth/auth.selectors';

export interface Props {
  children: React.ReactChild | React.ReactChild[];
}

export const AppLayout: FC<PropsWithChildren<Props>> = ({ children }) => {
  const { t } = useTranslation();
  const productName = useSelector(selectProductTitle);
  const userNavPlaceholderRef = React.useRef();
  const location = useLocation();
  const user = useSelector(reselectUser);
  const productCode = useSelector(selectProductCode);
    
  const chooseLogo = () => {
    if(productCode === 'mip' && location.pathname.indexOf('registration') !== -1){
      return <span className={styles.headerSubtitle}>My Incentive Plan</span>;
    } 
      if(productCode === 'dra' && location.pathname.indexOf('registration') !== -1){
      return <span className={styles.headerSubtitle}>Digital Reporting Accelerator</span>;
    }
    if(productCode === 'ibr' && location.pathname.indexOf('registration') !== -1){
      return <span className={styles.headerSubtitle}>Incremental Borrowing Rate</span>;
    }
    if(productCode === 'sbp' && location.pathname.indexOf('registration') !== -1){
      return <span className={styles.headerSubtitle}>Share Based Payments Calculator</span>;
    }  
    return <span className={styles.headerSubtitle}>Assurance Products</span>;
  }; 

  return (
    <AppContext.Provider value={{ userNavPlaceholder: userNavPlaceholderRef }}>
      <Navbar bg='white' className={styles.navbar} fixed='top' variant='light'>
        <div className={styles.headerCommon}>
          <Navbar.Brand className={cs(styles.brand, styles.logo)}>
            <img alt='logo' src={logo} />
          </Navbar.Brand>
          {/* <div className={styles.separator} /> */}
          <Navbar.Brand className={styles.brand}>
            {/* <img alt='Omnialogo' src={Omnialogo} /> delete this img */}
            {chooseLogo()}

          </Navbar.Brand>
          {/* <div className={styles.separator} /> */}
          <Navbar.Brand className={styles.brand}>
            {/* {isInternal === false ? productName : t('app.name')} */}
            {/* {!productName ? t('app.name') : productName} */}
            {/* {t('app.name')} */}
          </Navbar.Brand>
        </div>
        {/* {((user && user.role === USER_ROLES.PRODUCT_ADMIN) ||
          (user && user.role === USER_ROLES.PRODUCT_GROUP_ADMIN) ||
          (user && user.role === USER_ROLES.SUPER_ADMIN)) && (
            <React.Fragment>
              <Tab
                className={styles.tab}
                highlightActive={true}
                href={`/admin/submissions/${productCode}`}
                tabRoute='submissions'
              >
                {t('admin.tabs.submission')}
              </Tab>
              <Tab
                className={styles.tab}
                highlightActive={true}
                href={`/admin/onboarding/${productCode}`}
                tabRoute='onboarding'
              >
                {t('admin.tabs.admin')}
              </Tab>
            </React.Fragment>
          )} */}
        <div className={styles.headerContent}>
          <div />
          {location.pathname.toLowerCase().includes('admin') ? null : (
            <SelectLocale />
          )}
          <div ref={userNavPlaceholderRef} className={styles.headerUserInfo} />
        </div>
      </Navbar>
      {children}

    </AppContext.Provider>
  );
};
