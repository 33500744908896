import { Reducer } from 'redux';

import {
  SubscriptionActionTypes,
  SubscriptionState,
  SET_SUBSRIPTION_DETAILS,
  SET_SUBSRIPTION_EMAIL,
  SET_SUBSRIPTION_PLANS,
  SET_USER_ACTION,
  SET_INIT_VALUES,
  FetchSubscriptionAccountDetailsAction,
  RENEW_SUBSCRIPTIONS,
  SET_IS_LOADING
} from './subscription.types';

const initialSubscriptionState: SubscriptionState = {
  countryCode: null,
  userEmail: null,
  isLoading: false,
  userAction: '',
  states: null,
  data: {
    // comment:'',
    country: null,
    plans: [],
    user: {
      email: '',
      firstName: '',
      lastName: ''
    },
    organization: {
      companyId: '',
      legalName: '',
      address: '',
      city: '',
      state: '',
      postalCode: '',
      isDeloitteAuditClient: '0',
      deloitteAuditClientType: '',
      stateId: 0,
      stateL10NKey: ''
    },
    referral: {}
  }
};

export const subscriptionReducer: Reducer<
  SubscriptionState,
  SubscriptionActionTypes
> = (
  state: SubscriptionState = initialSubscriptionState,
  action: SubscriptionActionTypes
): SubscriptionState => {
  switch (action.type) {
    case SET_SUBSRIPTION_EMAIL: {
      return {
        ...state,
        userEmail: action.payload
      };
    }

    case SET_SUBSRIPTION_PLANS: {
      return {
        ...state,
        data: {
          ...state.data,
          plans: action.payload
        }
      };
    }

    case SET_USER_ACTION: {
      return {
        ...state,
        userAction: action.payload
      };
    }

    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload
      };
    }

    case SET_SUBSRIPTION_DETAILS: {
      const actionData = (action as FetchSubscriptionAccountDetailsAction)
        .payload;
      return {
        ...state,
        isLoading: false,
        countryCode: actionData.userDetails.country.code,
        data: {
          ...state.data,
          ...actionData.userDetails,
          country: actionData.userDetails.country,
          organization: {
            ...actionData.userDetails.organization,
            isDeloitteAuditClient: actionData.userDetails.organization.isDeloitteAuditClient.toString(),
            state: {
              code: actionData.userDetails.organization.state,
              name: actionData.userDetails.organization.state
            }
          },
          plans: actionData.plans
        },
        states: actionData.states
      };
    }

    case RENEW_SUBSCRIPTIONS: {
      const actionData = action.payload;

      return { ...state, data: { ...state.data, plans: actionData } };
    }

    case SET_INIT_VALUES: {
      return initialSubscriptionState;
    }

    default:
      return state;
  }
};
