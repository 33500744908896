import React from 'react';
import { components } from 'react-select';
import { ControlProps } from 'react-select/src/components/Control';

import style from '../Select.module.css';

export function ControlComponent<T>(): (props: any) => JSX.Element {
  const clear = (props: ControlProps<T, boolean>): JSX.Element => {
    return (
      <div className={style.control}>
        <span>
          <components.Control {...props} />
        </span>
      </div>
    );
  };
  return clear;
}
