import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { FormikProps } from 'formik';
import { useGet, Get } from 'restful-react';

import { reselectLocaleCode } from 'store/localize';
import { selectHttpHeaders, reselectUser } from 'store/auth';
import { selectProductCode } from 'store/product';
import { getApiHost, makeQueryString } from 'shared/utils';
import { SUBMISSION_TYPE } from 'shared/constants/submission.consts';
import { API_PATH } from 'shared/constants/api.consts';
import {
  AdminContext,
  PLAN_STATUS
} from 'features/AdminPage/AdminPageContent/AdminPageContent.helpers';
import { FiltersControls } from '../FiltersControls/FiltersControls';
import {
  SearchCallback,
  User
} from '../FiltersControls/FiltersControls.helpers';
import { FiltersActions } from '../FiltersActions/FiltersActions';
import {
  CompaniesQuery,
  UsersQuery,
  FiltersFormProps,
  FiltersFormEnhancer,
  AdminQuery
} from './FiltersForm.helpers';
import styles from './FiltersForm.module.css';

// FiltersFormProps & FormikProps<DTO.SubmissionFilters>
interface GenericFilters {
  countryCode?: DTO.Country[];
  role?: DTO.UserRoles;
  // legalName: DTO.Organization[];
  // user: User[];
}

function FiltersFormComponent<T extends GenericFilters>({
  value,
  values,
  appendTo: { current },
  onChange,
  setFieldValue,
  onClose,
  showFilters,
  fetchAdminCountries,
  // fetchSubmissionsCountries,
  countryOptionValue,
}: FiltersFormProps<T> & FormikProps<T>): JSX.Element {
  const user = useSelector(reselectUser);
  const { t } = useTranslation();
  const productCode = useSelector(selectProductCode);
  const localeCode = useSelector(reselectLocaleCode);
  const httpHeaders = useSelector(selectHttpHeaders);
  const [usersQuery, setUsersQuery] = React.useState<UsersQuery>();
  const [companiesQuery, setCompaniesQuery] = React.useState<CompaniesQuery>();
  const [adminQuery, setAdminQuery] = React.useState<AdminQuery>();
  const [addMoreFilter, setAddMoreFilter] = React.useState<Array<any>>([]);
  const [disableReset, setDisableReset] = React.useState<boolean>(true); 
  /*
   * queryParams prop of the restful-react works bad (arrays)
   * that's why we use querystring tool
   */
  const companiesQueryString = makeQueryString(companiesQuery);
  const { type } = React.useContext(AdminContext);

  const {
    data: adminCountries,
    refetch: refetchAdminCountries,
    loading: loadingAdminCountries
  } = useGet<DTO.Country[], Error, CompaniesQuery>({
    path: API_PATH.GET_ADMIN_COUNTRIES(productCode),
    requestOptions: () => ({ headers: httpHeaders }),
    lazy: true  
  });

  const planStatus = React.useMemo(() => {
    return type === SUBMISSION_TYPE.PENDING
      ? [PLAN_STATUS.PENDING]
      : [PLAN_STATUS.ACTIVE];
  }, [type]);

  const { data: countries } = useGet<DTO.Country[], Error, CompaniesQuery>({
    path:
       user.role === 'superAdmin' ?
         API_PATH.GET_COUNTRIES_ALL(localeCode) :
      API_PATH.GET_COUNTRIES_PRODUCT(productCode, localeCode),
    requestOptions: () => ({ headers: httpHeaders })
  });

  const [statuses, setStatuses] = React.useState<DTO.PlanStatusFilterValue[]>([]);

  useEffect(() => {
    const arr: any = [];
    Object.keys(value).forEach((keyName: any) => {
      const filterArr: any = (value[keyName as keyof typeof value]);
      if (filterArr?.length > 0) {        
        if (keyName === 'legalName') {
          arr.push('Company Name');
        } else if (keyName === 'subscriberEmail') {
          arr.push('Subscriber Email');
        } else if (keyName === 'countryCode' || keyName === 'countryIds') {
          arr.push('Country');
        } else if (keyName === 'planStatus') {
          arr.push('status');
        } else if (keyName === 'user') {
          arr.push('Subscriber Name');
        } else if (keyName === 'emails') {
          arr.push('Admin Email');
        } else if (keyName === 'displayName') {
          arr.push('Admin Name');
        } else {
          arr.push(keyName);
        }
      }
    });
    setAddMoreFilter([...addMoreFilter, ...arr]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setDisableReset(!Object.values(values).some(item => item?.length > 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleOnClose = React.useCallback((): void => onClose(false), [
    onClose
  ]);

  const handleOnReset = React.useCallback((): void => {
    setFieldValue('countryCode', []);
    setFieldValue('legalName', []);
    setFieldValue('user', []);
    setFieldValue('displayName', []);
    setFieldValue('emails', []);
    setFieldValue('subscriberEmail', []);
    setAddMoreFilter([]);
  }, [setFieldValue]);

  const handleAddNewFilter = React.useCallback((): any => {    
    setAddMoreFilter([...addMoreFilter, (addMoreFilter.length + 1).toString()]);
  }, [addMoreFilter]);

  const handleOnApply = React.useCallback((): void => onChange(values, true), [
    onChange,
    values
  ]);

  const handleNoOptionsMessage = () => {
    return t('admin.grid.nooptions');
  };
  const handleLoadingMessage = () => {
    return `${t('admin.grid.loading')}...`;
  };
  const handleOnCompaniesSearch = React.useMemo(
    () => (
      refetch: () => Promise<DTO.Items<Partial<DTO.Organization>>>
    ): SearchCallback<Partial<DTO.Organization>> =>
      debounce(
        (
          q: string,
          callback: (options: Partial<DTO.Organization>[]) => void
        ): void => {
          setCompaniesQuery({
            companyName: q,
            countryList: values.countryCode
              ? values.countryCode.map(({ code }) => code)
              : [],
            planStatus: `${planStatus}`
          });
          refetch().then(({ items }) => callback(items));
        },
        300
      ),
    [values.countryCode, planStatus]
  );

  const handleOnSubscriberEmailSearch = React.useMemo(
    () => (
      refetch: () => Promise<DTO.Items<DTO.User>>
    ): SearchCallback<Partial<DTO.User>> =>
      debounce(
        (q: string, callback: (options: Partial<DTO.User>[]) => void): void => {
          setUsersQuery({
            UserName: q,
            planStatus: `${planStatus}`
          });

          refetch()
            .then(({ items }): User[] =>
              items.map(
                (item): User => ({
                  ...item,
                  email: item.email
                })
              )
            )
            .then(items => callback(items));
        },
        300
      ),
    [planStatus]
  );

  const handleOnUsersSearch = React.useMemo(
    () => (
      refetch: () => Promise<DTO.Items<DTO.User>>
    ): SearchCallback<Partial<DTO.User>> =>
      debounce(
        (q: string, callback: (options: Partial<DTO.User>[]) => void): void => {
          if(showFilters.includes('Subscriber Name')) {
            setUsersQuery({
              UserName: q,
              planStatus: `${planStatus}`
            });
          } else {
            setUsersQuery({
              UserName: q,
              RoleId: user.role === 'superAdmin' ? value?.role : 3
            });
          }

          refetch()
            .then(({ items }): User[] =>
              items.map(
                (item): User => ({
                  ...item,
                  fullName: `${item.firstName} ${item.lastName}`
                })
              )
            )
            .then(items => callback(items));
        },
        300
      ),
    [value?.role, planStatus, showFilters, user.role]
  );
  
  const handleOnAdminEmailSearch = React.useMemo(
    () => (refetch: () => any): SearchCallback<DTO.UserEmail> =>
      debounce(
        (q: string, callback: (options: DTO.UserEmail[]) => void): void => {
          setAdminQuery({ Email: q.trim(), RoleId: user.role === 'superAdmin' ? value?.role : 3 });
          refetch().then((items: DTO.UserEmail[]) => {
            return callback(items);
          });
        },
        300
      ),
    [value?.role, user.role]
  );

  const getUserFilterQueryPath = () => {
    let path = '';
    if (showFilters.includes('Subscriber Name')) {
      path = API_PATH.GET_USER_FILTER_QUERY(productCode);
    } else if (user.role === 'superAdmin') {
      path = API_PATH.GET_USER_FILTER_QUERY_ALL();
    } else {
      path = API_PATH.GET_USER_FILTER_QUERY_PRODUCT(productCode);
    }
    return path;
  };

  const form = (
    <Get<DTO.Items<Partial<DTO.Organization>>, Error, CompaniesQuery>
      base={`${getApiHost('account')}`}
      lazy={true}
      path={`${API_PATH.GET_COMPANY_FILTER_QUERY(
        productCode
      )}?${companiesQueryString}`}
      requestOptions={() => ({ headers: httpHeaders })}
    >
      {(
        _companies,
        _companiesMeta,
        { refetch: loadCompanies }
      ): JSX.Element => (
        <Get<DTO.Items<DTO.User>, Error, UsersQuery>
          base={`${getApiHost('account')}`}
          lazy={true}
          path={getUserFilterQueryPath()}
          queryParams={usersQuery}
          requestOptions={() => ({ headers: httpHeaders })}
        >
          {(_users, _usersMeta, { refetch: loadUsers }): JSX.Element => (
            <Get<DTO.Items<DTO.User>, Error, AdminQuery>
              base={`${getApiHost('account')}`}
              lazy={true}
              path={user.role === 'superAdmin' ?
                API_PATH.GET_ADMIN_FILTER_QUERY_ALL() :
                API_PATH.GET_ADMIN_FILTER_QUERY_PRODUCT(productCode)}
              queryParams={adminQuery}
              requestOptions={() => ({ headers: httpHeaders })}
            >
              {(_admins, _adminMeta, { refetch: loadAdmins }): JSX.Element => (
                <div className={styles.container}>
                  <FiltersControls
                    activeRole={value?.role}
                    addMoreFilter={addMoreFilter}
                    appliedFilters={values}
                    countries={fetchAdminCountries ? adminCountries : countries}
                    countryOptionValue={countryOptionValue}
                    disableReset={disableReset}
                    loadingMessage={handleLoadingMessage}
                    noOptionsMessage={handleNoOptionsMessage}
                    removeFilterRow={onChange}
                    setAddMoreFilter={setAddMoreFilter}
                    setFieldValue={setFieldValue}
                    showFilters={showFilters}
                    statuses={statuses}
                    onAdminsSearch={handleOnAdminEmailSearch(loadAdmins)}
                    onCompaniesSearch={handleOnCompaniesSearch(loadCompanies)}      
                    onReset={handleOnReset}
                    onSubscriberEmailSearch={handleOnSubscriberEmailSearch(loadUsers)}
                    onUsersSearch={handleOnUsersSearch(loadUsers)}
                  />
                  <FiltersActions
                    addNewFilter={handleAddNewFilter}
                    disableFilter={addMoreFilter?.length === showFilters.length}
                    onApply={handleOnApply}
                    onClose={handleOnClose}
                  />
                </div>
              )}
            </Get>
          )}
        </Get>
      )}
    </Get>
  );

  return current ? createPortal(form, current) : null;
};

export const FiltersForm = FiltersFormEnhancer()(FiltersFormComponent);
