import querystring from 'querystring';

const parseQueryString = (queryString: string): string =>
  queryString.includes('&&')
    ? parseQueryString(queryString.replace('&&', '&'))
    : queryString;

export const makeQueryString = (input: string | any): string =>
  parseQueryString(
    querystring.stringify(input as querystring.ParsedUrlQueryInput)
  );

export const getProductCodeFromPath = (pathname: string): string => {
  const lastSlashIndex = pathname.lastIndexOf('/');
  return pathname.slice(lastSlashIndex + 1);
};

export const getProductCodeFromExternalPath = (pathname: string): string => {
  return pathname.split('/').reverse()[1];
};

export const getCountryCodeFromPath = (pathname: string): string => {
  const lastSlashIndex = pathname.lastIndexOf('/');
  return pathname.slice(lastSlashIndex + 1);
};
