import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMutate } from 'restful-react';
import { useTranslation } from 'react-i18next';

import { removeFromLocalStorage, setToLocalStorage } from 'shared/utils/storage.utils';
import { USER_ROLES } from 'features/AdminPage/OnboardingPage/OnboardingFilters/OnboardingFilters';
import { selectProductCode } from 'store/product';
import { SUBMISSION_PLAN_STATUS } from 'shared/constants/submission.consts';
import { reselectUser, selectHttpHeaders, selectSourceSubjectId } from 'store/auth';
import { setSelectedEmail } from 'store/updateSubscription/subscription.actions';
import { useOnMount } from 'shared/hooks';
import { API_PATH } from 'shared/constants/api.consts';
import { AdminContext } from 'features/AdminPage/AdminPageContent/AdminPageContent.helpers';
import { AdminGrid } from 'features/AdminPage/AdminGrid/AdminGrid';
import { Nothing, Spinner, Confirmation } from 'components';
import { SubmissionPlan } from '../SubmissionPlan/SubmissionPlan';
import { APP_PATH } from '../../../../Routes/Routes.helpers';
import {
  initialSubscriptionsState,
  SUBSCRIPTIONS_ACTION_TYPES,
  subscriptionsReducer,
  SubscriptionsState,
  SubscriptionsRequest,
  ProductRoleRequest,
  useTableHeaders,
  Subscription,
  APPROVAL_ACTION_TYPES,
  APPROVAL_ACTION_TYPES_NAMES
} from './SubmissionPlansGrid.helpers';
import { SubmissionPlansFooter } from '../SubmissionPlansFooter/SubmissionPlansFooter';
import { RegistrationSubmissionDialog } from '../../dialogs/RegistrationSubmissionDialog/RegistrationSubmissionDialog';

interface Props {
  plans: DTO.SubmissionPlan[];
  email: string;
  className?: string;
  enablePlanControl?: boolean;
  updatePlanControl?: boolean;
  isDetailsDialog?: boolean;
  productRoles?: Array<any>;
}

export const SubmissionPlansGrid: React.FC<Props> = ({
  plans,
  email,
  className = '',
  enablePlanControl = false,
  updatePlanControl = true,
  isDetailsDialog = false,
  productRoles,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const sourceSubjectId = useSelector(selectSourceSubjectId);
  const user = useSelector(reselectUser);
  const productCode = useSelector(selectProductCode);
  const httpHeaders = useSelector(selectHttpHeaders);
  const updateUrl = `${APP_PATH.ADMIN_UPDATESUBSCRIPTION_ROOT}/${productCode}`;
  const { onSubscriptionsSubmit } = React.useContext(AdminContext);
  const [approvalActionType, setApprovalActionType] = React.useState<
    APPROVAL_ACTION_TYPES
  >(null);
  const [isOpenSubmissionDialog, setOpenSubmissionDialog] = React.useState<
    boolean
  >(false);
  const [IsRoleSelected, setRoleSelected] = React.useState<
    boolean
  >(false);
  const ifPageMIP = (productCode === "mip");

  const [subscriptions, dispatchSubscriptions] = React.useReducer(
    subscriptionsReducer,
    initialSubscriptionsState
  );
  const [isSubmitAvailable, setSubmitAvailability] = React.useState(false);
  const { mutate: saveSubscriptions, loading: isLoading } = useMutate<
    SubscriptionsRequest
  >({
    verb: 'POST',
    path: API_PATH.SAVE_SUBSCRIPTION(productCode, sourceSubjectId),
    requestOptions: () => ({ headers: httpHeaders })
  });

  const { mutate: saveProductRoleInfo } = useMutate<
    ProductRoleRequest
  >({
    verb: 'POST',
    path: API_PATH.SAVE_PRODUCT_ROLE_INFO(productCode),
    requestOptions: () => ({ headers: httpHeaders })
  });

  const generateConfirmationPopupText = (): string => {
    if (approvalActionType === APPROVAL_ACTION_TYPES.RENEW) {
      const approvedPlan = Object.values(subscriptions).find(plan => {
        return plan.checked && plan.status === SUBMISSION_PLAN_STATUS.APPROVED;
      });
      return approvedPlan
        ? t('admin.plans.approveMessage')
        : t('admin.plans.rejectMesaage');
    }
    return t('admin.plans.changePlanMessage');
  };

  const handleClearActionType = React.useCallback((): void => {
    setApprovalActionType(null);
  }, []);

  const handleOnResolve = React.useCallback((): void => {
    // update cache variable to keep track for highlighted subscription
    setToLocalStorage('recentUserEmail', email);
    saveSubscriptions({
      comment: APPROVAL_ACTION_TYPES_NAMES[approvalActionType],
      plans: Object.values(subscriptions),
      user: { email }
    }).finally((): void => {
      onSubscriptionsSubmit();
    });
    handleClearActionType();
  }, [
    saveSubscriptions,
    approvalActionType,
    subscriptions,
    email,
    handleClearActionType,
    onSubscriptionsSubmit
  ]);

  const handleRejectAll = React.useCallback((): void => {
    dispatchSubscriptions({ type: SUBSCRIPTIONS_ACTION_TYPES.REJECT_ALL });
  }, []);

  const handleApproveAll = React.useCallback((): void => {
    dispatchSubscriptions({ type: SUBSCRIPTIONS_ACTION_TYPES.APPROVE_ALL });
  }, []);

  const handleUpdate = React.useCallback((): void => {
    removeFromLocalStorage('recentUserEmail');
    removeFromLocalStorage('initialPlans');
    removeFromLocalStorage('showCancelButton');
    dispatch(setSelectedEmail(email));
    history.push(updateUrl);
  }, [dispatch, email, history, updateUrl]);

  const {
    defaultTableHeaders,
    controlTableHeaders,
    emptyTableHeaders,
    defaultTableHeadersIfMIP,
    updateTableHeaders,
  } = useTableHeaders({
    onReject: handleRejectAll,
    onApprove: handleApproveAll,
    onUpdate: handleUpdate
  });

  const [tableHeaders, setTableHeaders] = React.useState<any[]>(
    enablePlanControl
      ? (defaultTableHeaders as any).concat(
        plans.length > 1 ? controlTableHeaders : emptyTableHeaders
      )
      : defaultTableHeaders
  );

  React.useEffect((): void => {
    if (ifPageMIP) {
      let nextTableHeaders;
      if (enablePlanControl) {
        nextTableHeaders = (defaultTableHeadersIfMIP as any).concat(
          plans.length > 1 ? controlTableHeaders : emptyTableHeaders
        );
      } else if (!enablePlanControl && updatePlanControl) {
        nextTableHeaders = (defaultTableHeadersIfMIP as any).concat(updateTableHeaders);
      } else {
        nextTableHeaders = defaultTableHeadersIfMIP;
      }
      setTableHeaders(nextTableHeaders);
    }
    else {
      let nextTableHeaders;
      if (enablePlanControl) {
        nextTableHeaders = (defaultTableHeaders as any).concat(
          plans.length > 1 ? controlTableHeaders : emptyTableHeaders
        );
      } else if (!enablePlanControl && updatePlanControl) {
        nextTableHeaders = (defaultTableHeaders as any).concat(updateTableHeaders);
      } else {
        nextTableHeaders = defaultTableHeaders;
      }
      setTableHeaders(nextTableHeaders);
    }
  }, [
    enablePlanControl,
    plans.length,
    controlTableHeaders,
    defaultTableHeaders,
    defaultTableHeadersIfMIP,
    emptyTableHeaders,
    ifPageMIP,
    updateTableHeaders,
    updatePlanControl
  ]);

  useOnMount(
    React.useCallback((): void => {
      dispatchSubscriptions({
        type: SUBSCRIPTIONS_ACTION_TYPES.INITIALIZE,
        payload: plans.reduce(
          (acc: SubscriptionsState, value) => ({
            ...acc,
            [value.code]: {
              code: value.code,
              status:
                value.status === SUBMISSION_PLAN_STATUS.PENDING ||
                  value.status === SUBMISSION_PLAN_STATUS.PENDING_REACTIVATION ||
                  value.status === SUBMISSION_PLAN_STATUS.PENDING_RENEWAL ||
                  value.status === SUBMISSION_PLAN_STATUS.PENDING_ACTIVATION
                  ? null
                  : value.status,
              id: value.id
            }
          }),
          subscriptions
        )
      });
    }, [plans, subscriptions])
  );

  React.useEffect((): void => {
    const areRadiosChecked = Object.values(subscriptions).every(
      (subscription: Subscription) => subscription.status !== null
    );

    if (ifPageMIP) {
      if (IsRoleSelected && areRadiosChecked && user.role !== USER_ROLES.SUPER_ADMIN) {
        setSubmitAvailability(true);
      }
      else {
        setSubmitAvailability(false);
      }
    }
    else {
      setSubmitAvailability(areRadiosChecked && user.role !== USER_ROLES.SUPER_ADMIN);
    }
  }, [subscriptions, IsRoleSelected, ifPageMIP, user.role]);

  const handleChoosePlanAction = React.useCallback(
    (code: string, value: DTO.SubmissionPlanStatus, productRoleId: number) => {
      if (ifPageMIP) {
        if (value === "rejected" || (productRoleId > 0)) {
          setRoleSelected(true);
        }
        else {
          setRoleSelected(false);
        }
      }

      dispatchSubscriptions({
        type: SUBSCRIPTIONS_ACTION_TYPES.CHANGE_VALUE,
        payload: { code, value, productRoleId }
      });
    },
    [ifPageMIP]
  );

  const handleOpenSubmissionDialog = React.useCallback((): void => {
    plans.forEach(plan => {
      switch (plan.status) {
        case SUBMISSION_PLAN_STATUS.PENDING:{
          setOpenSubmissionDialog(true);
          setApprovalActionType(APPROVAL_ACTION_TYPES.ACTIVATE);
          break;
        }
        case SUBMISSION_PLAN_STATUS.PENDING_REACTIVATION: {
          setApprovalActionType(APPROVAL_ACTION_TYPES.REACTIVATE);
          break;
        }
        case SUBMISSION_PLAN_STATUS.PENDING_RENEWAL: {
          setApprovalActionType(APPROVAL_ACTION_TYPES.RENEW);
          break;
        }
        case SUBMISSION_PLAN_STATUS.PENDING_ACTIVATION: {
          if(productCode !== 'sbp')
            setOpenSubmissionDialog(true);
          else 
            setOpenSubmissionDialog(false);
          setApprovalActionType(APPROVAL_ACTION_TYPES.ACTIVATE);
          break;
        }
        default: {
          break;
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  const handleCloseSubmissionDialog = React.useCallback((): void => {
    setOpenSubmissionDialog(false);
  }, []);

  const handleSubmitSubscriptions = React.useCallback(
    (comment: string): void => {
      // update cache variable to keep track for highlighted subscription
      setToLocalStorage('recentUserEmail', email);
      saveSubscriptions({
        comment,
        plans: Object.values(subscriptions),
        user: { email }
      }).finally((): void => {
        onSubscriptionsSubmit();
      });
      setOpenSubmissionDialog(false);
    },
    [saveSubscriptions, subscriptions, email, onSubscriptionsSubmit]
  );

  const handleProductRoleRequest = React.useCallback(
    (ProductRoleId: number, status: string): void => {
      saveProductRoleInfo({
        email,
        ProductCode: productCode,
        status,
        ProductRoleId
      }).finally((): void => {
        onSubscriptionsSubmit();
      });
    },
    [saveProductRoleInfo, email, productCode, onSubscriptionsSubmit]
  );

  const handleDataRendererCallback = React.useCallback(
    (plan: DTO.SubmissionPlan) => (
      <SubmissionPlan
        enablePlanControl={enablePlanControl}
        id={email}
        isDetailsDialog={isDetailsDialog}
        plan={plan}
        productRoles={productRoles}
        state={subscriptions}
        onChoose={handleChoosePlanAction}
        onRoleSelect={handleProductRoleRequest}
      />
    ),
    [
      enablePlanControl,
      email,
      productRoles,
      subscriptions,
      handleChoosePlanAction,
      isDetailsDialog,
      handleProductRoleRequest
    ]
  );

  const handleFooterRendererCallback = React.useCallback(
    ({ columnsCount, rowStyles, rowClassName }) =>
      enablePlanControl ? (
        <SubmissionPlansFooter
          columnsCount={columnsCount}
          isSubmitAvailable={isSubmitAvailable}
          rowClassName={rowClassName}
          rowStyles={rowStyles}
          shouldShowText={plans.length > 1}
          onOpenSubmissionDialog={handleOpenSubmissionDialog}
        />
      ) : (
        <Nothing />
      ),
    [
      enablePlanControl,
      plans.length,
      isSubmitAvailable,
      handleOpenSubmissionDialog
    ]
  );

  if (isLoading) return <Spinner />;

  return (
    <React.Fragment>
      <AdminGrid<DTO.SubmissionPlan>
        className={className}
        dataKey='code'
        footerRenderer={handleFooterRendererCallback}
        gridData={plans}
        gridHeaders={tableHeaders}
        ifPageMIP={ifPageMIP}
        isDetailsDialog={isDetailsDialog}
        productRoles={productRoles}
        rowDataRenderer={handleDataRendererCallback}
        userRole={user?.role}
      />
      {(isOpenSubmissionDialog) && (
        <RegistrationSubmissionDialog
          onCancel={handleCloseSubmissionDialog}
          onSubmit={handleSubmitSubscriptions}
        />
      )}
      {(approvalActionType === APPROVAL_ACTION_TYPES.RENEW ||
        approvalActionType === APPROVAL_ACTION_TYPES.REACTIVATE ||
        (productCode === 'sbp' && approvalActionType === APPROVAL_ACTION_TYPES.ACTIVATE && !isOpenSubmissionDialog && plans.every((plan:any) => plan.performedBy))) && (
          <Confirmation
            message={generateConfirmationPopupText()}
            reactivate={approvalActionType}
            title={
              `${t(`admin.plans.${APPROVAL_ACTION_TYPES_NAMES[approvalActionType].toLocaleLowerCase()}`)} ${t(`admin.plans.confirmation`)}`
            }
            onReject={handleClearActionType}
            onResolve={handleOnResolve}
          />
        )}
    </React.Fragment>
  );
};
