import { RootState } from 'store/root.reducer';

export const adminSorter = ({
  onboarding
}: RootState): any => onboarding.sorter;

export const selectHeaderForAllTile = ({
  onboarding
}: RootState): any[] => onboarding.headerAllTile;

export const selectHeaderForTenantTile = ({
  onboarding
}: RootState): any[] => onboarding.headerTenantTile;

export const selectHeaderForProductTile = ({
  onboarding
}: RootState): any[] => onboarding.headerProductTile;

export const selectAdminUsersPagination = ({
  onboarding
}: RootState): DTO.AdminPaginationResponse => onboarding.usersPagination;

export const selectAdminRoles = ({ onboarding }: RootState): DTO.AdminRole[] =>
  onboarding.userRoles;

export const selectProducts = ({ onboarding }: RootState): DTO.AppProduct[] =>
  onboarding.products;

  export const selectCountries = ({ onboarding }: RootState): DTO.Country[] =>
  onboarding.countries;

export const selectIsLoading = ({ onboarding }: RootState): boolean =>
  onboarding.isLoading;

export const selectIsLoaded = ({ onboarding }: RootState): boolean =>
  onboarding.isLoaded;

export const selectMemberFirms = ({
  onboarding
}: RootState): DTO.ProductMemberFirm[] => onboarding.memberFirms;

export const selectHadCreatedAdmin = ({ onboarding }: RootState) =>
  onboarding.hadCreatedAdmin;
