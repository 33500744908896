export const appEn = {
  name: 'Assurance Products',
  selectlanguage: 'Select Language',
  rights: 'Deloitte. All rights reserved. See',    
    footerCopyright: '©{{year}}. For information, contact Deloitte Global. See <0>Acceptable Use Policy</0>, <1>Privacy</1>, <2>View Cookies</2> and <3>Notices</3> for more information.',
    footerCopyrightShort: '©{{year}}. For information, contact Deloitte Global. See <0>Privacy</0>, <1>View Cookies</1> and <2>Notices</2> for more information.',
    footerCopyrightMIP: '©{{year}}. For information, contact Deloitte Global. See <0>Acceptable Use Policy</0>, <1>Privacy</1>, <2>View Cookies</2> and <3>Notices</3> for more information.',
    footerCopyrightMIPShort: '©{{year}}. For information, contact Deloitte Global. See <0>Privacy</0>, <1>View Cookies</1> and <2>Notices</2> for more information.',
    footerCopyrightSBP: '©{{year}}. For information, contact Deloitte Global. See <0>Acceptable Use Policy</0>, <1>Privacy</1>, <2>View Cookies</2> and <3>Notices</3> for more information.',
    footerCopyrightDRA: '©{{year}}. For information, contact Deloitte Global. See <0>Acceptable Use Policy</0>, <1>Privacy</1>, <2>View Cookies</2> and <3>Notices</3> for more information.',
    footerCopyrightDRAShort: '©{{year}}. For information, contact Deloitte Global. See <0>Privacy</0>, <1>View Cookies</1> and <2>Notices</2> for more information.',
    footerCopyrightIBR: '©{{year}}. For information, contact Deloitte Global. See <0>Privacy</0>, <1>View Cookies</1> and <2>Notices</2> for more information.',
    footerCopyrightSBPShort: '©{{year}}. For information, contact Deloitte Global. See <0>Privacy</0>, <1>View Cookies</1> and <2>Notices</2> for more information.',
    footerDocumentLinks:
    'See <1>Terms of Use</1>, <3>Privacy</3> and <5>Notices</5> for more information.',
  copyright: 'Deloitte. All rights reserved.',
  footer: 'Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited (“DTTL”), its global network of member firms, and their related entities (collectively, the “Deloitte organization”). DTTL (also referred to as “Deloitte Global”) and each of its member firms and related entities are legally separate and independent entities, which cannot obligate or bind each other in respect of third parties. DTTL and each DTTL member firm and related entity is liable only for its own acts and omissions, and not those of each other. DTTL does not provide services to clients. Please see <1><0>www.deloitte.com/about</0></1> to learn more.',
  footerParaTwo:
    'None of the Deloitte organization shall be responsible for any loss whatsoever sustained by any person who relies on this communication. Deloitte is a leading global provider of audit and assurance, consulting, financial advisory, risk advisory, tax and related services. Our network of member firms in more than 150 countries and territories serves four out of five Fortune Global 500® companies. Learn how Deloitte’s approximately 312,000 people make an impact that matters at <1><0>www.deloitte.com</0></1>.',
  footerParaThree:
    'This communication contains general information only, and none of Deloitte Touche Tohmatsu Limited (“DTTL”), its global network of member firms or their related entities (collectively, the “Deloitte organization”) is, by means of this communication, rendering professional advice or services. Before making any decision or taking any action that may affect your finances or your business, you should consult a qualified professional adviser.',
  footerParaFour:
    'No representations, warranties or undertakings (express or implied) are given as to the accuracy or completeness of the information in this communication, and none of DTTL, its member firms, related entities, employees or agents shall be liable or responsible for any loss or damage whatsoever arising directly or indirectly in connection with any person relying on this communication. DTTL and each of its member firms, and their related entities, are legally separate and independent entities.',
  omnia:
  'OMNIA'  
};
