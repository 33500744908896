import React, { FC } from 'react';
import cs from 'classnames';

import styles from './UserCard.module.css';
import { User } from '../UserList/UserList.helpers';
import { noop } from '../../../../../../shared/utils';

type Props = {
  user: User;
  icon: JSX.Element;
  isPrimary?: boolean;
  onRemove?: (value: User) => void;
  onSelect?: () => void;
  isDisabled?: boolean
};

export const UserCard: FC<Props> = ({
  user,
  icon,
  isPrimary,
  onRemove = noop,
  onSelect = noop,
  isDisabled = false,
}) => {
  const { firstName, lastName, email } = user;

  const handleClick = () => {
    return isPrimary ? onSelect() : onRemove(user);
  };
  
  return (
    <div className={styles.content_div}>
      <div className={styles.initial_div}>
        {firstName.charAt(0).toLocaleUpperCase()}
        {lastName.charAt(0).toLocaleUpperCase()}
      </div>
      <div className={`flex-grow-1 px-2 ${styles.name}`}>
        <span>
          {firstName} {lastName}
        </span>
        <span>{email}</span>
      </div>
      <div className='px-1'>
        <button
          className={cs(styles.iconButton,  isDisabled ? styles.disabled_div: '')}
          type='button'
          onClick={handleClick}
        >
          {icon}
        </button>
      </div>
    </div>
  );
};
