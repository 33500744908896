import React from 'react';

import { SPINNER_SIZE_MAP, SpinnerSize } from '../Spinner.helpers';
import SpinnerSvg from './Spinner.svg';

interface Props {
  size: SpinnerSize;
}

export const SpinnerImage: React.FC<Props> = ({ size }) => {
  const [style] = React.useState({
    width: SPINNER_SIZE_MAP[size],
    height: SPINNER_SIZE_MAP[size]
  });

  return <img alt='Loader.' src={SpinnerSvg} style={style} />;
};
