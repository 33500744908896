import React, { lazy, useContext, Suspense, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Spinner } from 'components';
import { APP_PATH, RoutesContext } from '../Routes.helpers';

const RegistrationPage = lazy(() =>
  import('features/RegistrationPage/RegistrationPage').then(
    ({ RegistrationPage: RegistrationPageComponent }) => ({
      default: RegistrationPageComponent
    })
  )
);

export const RegistrationRoutes = (): JSX.Element => {
  const { setProductCallback } = useContext(RoutesContext);

  useEffect(() => {
    setProductCallback();
  }, [setProductCallback]);

  return (
    <Switch>
      <Route
        exact={true}
        path={APP_PATH.REGISTRATION_PRODUCT}
        render={() => {
          return (
            <Suspense fallback={<Spinner />}>
              <RegistrationPage />
            </Suspense>
          );
        }}
      />

      <Redirect from={APP_PATH.WILDCARD} to={APP_PATH.ROOT} />
    </Switch>
  );
};
