import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { noop, redirectTo } from 'shared/utils';
import { useOnMount } from 'shared/hooks';
import { Button } from 'components/Button/Button';
import { Status } from './Confirmation.helpers';
import { selectProductCode } from '../../store/product/product.selectors';
import styles from './Confirmation.module.css';
import WarningArgyle from '../../assets/images/WarningArgyle.png';

interface Props {
  title?: string;
  message?: string;
  variant?: 'primary' | 'danger';
  note?: string;
  reactivate?: string;
  onResolve?: () => void;
  onReject?: () => void;
}
export function ConfirmationCancellation({
  variant = 'primary',
  onResolve = noop,
  onReject = noop
}: Props): JSX.Element {
  const [show, setShow] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<Status>(null);
  const productCode = useSelector(selectProductCode);
  const { t } = useTranslation();

  const callbackMap: Record<Status, () => void> = React.useMemo(
    () => ({
      [Status.resolved]: onResolve,
      [Status.rejected]: onReject
    }),
    [onResolve, onReject]
  );

  const handleOnExited = React.useCallback((): void => {
    callbackMap[status]();
  }, [status, callbackMap]);

  const handleYes = React.useCallback(() => {
    setShow(false);
    setStatus(Status.resolved);
    // redirectTo(`/admin/onboarding/${productCode}`);
  }, []);

  const handleNo = React.useCallback(() => {
    setShow(false);
    setStatus(Status.rejected);
    onReject();
  }, [onReject]);

  useOnMount(React.useCallback((): void => setShow(true), []));

  return (
    <Modal
      centered={true}
      className={styles.modal}
      show={show}
      onExited={handleOnExited}
    >
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>
        <span className={styles.warningImage}>
            <img alt='ETL' src={WarningArgyle} />
          </span>
          <span>{t('actions.confirmcancel')}</span></Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
      {t('actions.canceltext')}
             </Modal.Body>
          <Modal.Footer className={styles.footer}>
          <Button
            className={styles.cancelAction}
            size='sm'
            variant='outline-secondary'
            onClick={handleNo}
          >
            {t('actions.no')}
          </Button>
          <Button
            className={styles.confirmatiocancelAction}
            size='sm'
            // variant={variant}
            onClick={handleYes}
          >
            {t('actions.yes')}
          </Button>
        </Modal.Footer>
          </Modal>
  );
}
