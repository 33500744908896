import { Reducer } from 'redux';

import {
  asyncActionFulfilled,
  asyncActionPending,
  asyncActionRejected
} from 'shared/utils';
import {
  RegistrationActionTypes,
  RegistrationState,
  FETCH_PRODUCT_COUNTRIES,
  FetchProductCountriesAction,
  FETCH_COUNTRY_CODES,
  FetchCountryCodesAction,
  FETCH_TERMS_OF_USE,
  FetchTermsOfUseAction,
  FETCH_INTERNAL_TERMS_OF_USE,
  FetchInternalTermsOfUseAction,
  SET_REGISTRATION_COUNTRY_CODE,
  SetRegistrationCountryCodeAction,
  FETCH_COUNTRY_STATES,
  FetchCountryStates,
  FETCH_PRODUCT_PLANS,
  FetchProductPlansAction,
  SUBMIT_REGISTRATION_DATA,
  SubmitRegistrationAccountDetails,
  SET_REGISTRATION_PLANS,
  SetRegistrationPlansAction,
  SET_REGISTRATION_TERMS_OF_USE_AGREEMENT,
  SetRegistrationTermsOfUseAgreement
} from './registration.types';

const initialRegistrationState: RegistrationState = {
  productCountries: {
    isLoading: false,
    data: []
  },
  countryCodes: {
    isLoading: false,
    data: null
  },
  productPlans: {
    isLoading: false,
    data: []
  },
  termsOfUse: {
    isLoading: false,
    data: null,
    isAgreed: false
  },
  states: {
    isLoading: false,
    data: null
  },
  data: {
    country: null,
    plans: [],
    user: {
      email: '',
      firstName: '',
      lastName: ''
    },
    organization: {
      companyId: '',
      legalName: '',
      address: '',
      city: '',
      state: '',
      stateId: 0,
      postalCode: '',
      isDeloitteAuditClient: '2'
    },
    referral: {
      name: 'N/A',
      email: 'N/A'
    }
  }
};

export const registrationReducer: Reducer<
  RegistrationState,
  RegistrationActionTypes
> = (
  state: RegistrationState = initialRegistrationState,
  action: RegistrationActionTypes
): RegistrationState => {
  switch (action.type) {
    case SET_REGISTRATION_COUNTRY_CODE: {
      const country = (action as SetRegistrationCountryCodeAction).payload;
      const data = { ...state.data, country };
      const termsOfUse =
        country === state.data.country
          ? state.termsOfUse
          : {
              isLoading: false,
              isAgreed: false,
              data: null
            };
      return {
        ...state,
        termsOfUse,
        data
      };
    }

    case SET_REGISTRATION_TERMS_OF_USE_AGREEMENT: {
      const isAgreed = (action as SetRegistrationTermsOfUseAgreement).payload;
      return {
        ...state,
        termsOfUse: {
          ...state.termsOfUse,
          isAgreed
        }
      };
    }

    case SET_REGISTRATION_PLANS: {
      const plans = (action as SetRegistrationPlansAction).payload;
      const data = {
        ...state.data,
        plans
      };
      return {
        ...state,
        data
      };
    }

    case SUBMIT_REGISTRATION_DATA: {
      const accountDetails = (action as SubmitRegistrationAccountDetails)
        .payload;
      const data = {
        ...state.data,
        ...accountDetails
      };
      return {
        ...state,
        data
      };
    }

    case asyncActionPending(FETCH_PRODUCT_COUNTRIES): {
      return {
        ...state,
        productCountries: {
          isLoading: true,
          data: []
        }
      };
    }

    case asyncActionRejected(FETCH_PRODUCT_COUNTRIES): {
      return {
        ...state,
        productCountries: {
          isLoading: false,
          data: []
        }
      };
    }

    case asyncActionFulfilled(FETCH_PRODUCT_COUNTRIES): {
      const countriesData = (action as FetchProductCountriesAction).payload;
      return {
        ...state,
        productCountries: {
          isLoading: false,
          data: countriesData
        }
      };
    }

    case asyncActionPending(FETCH_COUNTRY_CODES): {
      return {
        ...state,
        countryCodes: {
          isLoading: true,
          data: null
        }
      };
    }

    case asyncActionRejected(FETCH_COUNTRY_CODES): {
      return {
        ...state,
        countryCodes: {
          isLoading: false,
          data: {}
        }
      };
    }

    case asyncActionFulfilled(FETCH_COUNTRY_CODES): {
      const codesData = (action as FetchCountryCodesAction).payload;
      return {
        ...state,
        countryCodes: {
          isLoading: false,
          data: codesData
        }
      };
    }

    case asyncActionPending(FETCH_TERMS_OF_USE): {
      return {
        ...state,
        termsOfUse: {
          isAgreed: false,
          isLoading: true,
          data: null
        }
      };
    }

    case asyncActionRejected(FETCH_TERMS_OF_USE): {
      return {
        ...state,
        termsOfUse: {
          isAgreed: false,
          isLoading: false,
          data: null
        }
      };
    }

    case asyncActionFulfilled(FETCH_TERMS_OF_USE): {
      const termsOfUseData = (action as FetchTermsOfUseAction).payload;
      return {
        ...state,
        termsOfUse: {
          isAgreed: false,
          isLoading: false,
          data: termsOfUseData
        }
      };
    }

    case asyncActionPending(FETCH_INTERNAL_TERMS_OF_USE): {
      return {
        ...state,
        termsOfUse: {
          isAgreed: false,
          isLoading: true,
          data: null
        }
      };
    }

    case asyncActionRejected(FETCH_INTERNAL_TERMS_OF_USE): {
      return {
        ...state,
        termsOfUse: {
          isAgreed: false,
          isLoading: false,
          data: null
        }
      };
    }

    case asyncActionFulfilled(FETCH_INTERNAL_TERMS_OF_USE): {
      const termsOfUseData = (action as FetchInternalTermsOfUseAction).payload;
      return {
        ...state,
        termsOfUse: {
          isAgreed: false,
          isLoading: false,
          data: termsOfUseData
        }
      };
    }

    case asyncActionPending(FETCH_COUNTRY_STATES): {
      return {
        ...state,
        states: {
          isLoading: true,
          data: null
        }
      };
    }

    case asyncActionRejected(FETCH_COUNTRY_STATES): {
      return {
        ...state,
        states: {
          isLoading: false,
          data: []
        }
      };
    }

    case asyncActionFulfilled(FETCH_COUNTRY_STATES): {
      const statesData = (action as FetchCountryStates).payload;
      return {
        ...state,
        states: {
          isLoading: false,
          data: statesData
        }
      };
    }

    case asyncActionPending(FETCH_PRODUCT_PLANS): {
      return {
        ...state,
        productPlans: {
          isLoading: true,
          data: []
        }
      };
    }

    case asyncActionRejected(FETCH_PRODUCT_PLANS): {
      return {
        ...state,
        productPlans: {
          isLoading: false,
          data: []
        }
      };
    }

    case asyncActionFulfilled(FETCH_PRODUCT_PLANS): {
      const plans = (action as FetchProductPlansAction).payload;
      return {
        ...state,
        productPlans: {
          isLoading: false,
          data: plans
        }
      };
    }

    default:
      return state;
  }
};
