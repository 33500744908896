// import { i18nService as i18n } from 'shared/services/i18n.service';
import { getApiHost } from 'shared/utils';

export const API_PATH = {
  GET_COUNTRIES_PRODUCT: (product: string, localeCode: string): string =>
    `${getApiHost(
      'account'
    )}/product/${product}/permitted-countries/locale/${localeCode}`,
  // )}/product/${product}/permitted-countries/locale/${localeCode}`,

  GET_COUNTRIES_ALL: (localeCode: string): string =>
    `${getApiHost(
      'account'
    )}/product/all/permitted-countries/locale/${localeCode}`,
    // )}/product/${product}/permitted-countries/locale/${localeCode}`,

  GET_SUBMISSIONS_GRID: (product: string, localeCode: string): string =>
    `${getApiHost(
      'account'
    )}/product/${product}/registrations/query/locale/${localeCode}`,
  // )}/product/${product}/registrations/query/locale/${i18n.language()}`,

  EXPORT_REGISTRATIONS: (product: string, locale: string): string =>
    `${getApiHost(
      'account'
    )}/product/${product}/registrations/export/locale/${locale}`,

  SUBMIT_REGISTRATION: (product: string, localeCode: string): string =>
    `${getApiHost(
      'account'
    )}/product/${product}/registration/locale/${localeCode}`,

  // TODO: the place which uses this endpoint has an issue with absolute path format and should be fixed later
  GET_COMPANY_FILTER_QUERY: (product: string): string =>
    `product/${product}/companyFilter/query`,

  GET_USER_FILTER_QUERY: (product: string): string =>
    `product/${product}/userfilter/query`,

  GET_USER_FILTER_QUERY_PRODUCT: (product: string): string =>
    `product/${product}/admin/adminuserfilter/query`,

  GET_USER_FILTER_QUERY_ALL: (): string =>
    `product/all/admin/adminuserfilter/query`,

  SAVE_SUBSCRIPTION: (product: string, sourceSubjectId: string): string =>
    `${getApiHost('account')}/product/${product}/subscription/save/${sourceSubjectId}`,

  GET_PROFILE: `${getApiHost('account')}/profile/save`,

  SAVE_PRODUCT_ROLE_INFO: (product: string): string =>
  `${getApiHost('account')}/product/${product}/subscription/roles/update`,

  SAVE_USER_PREFERENCE: (): string =>
    `${getApiHost('account')}/user/preference`,

  UPDATE_TERMS_OF_USE: (product: string): string =>
    `${getApiHost('integration')}/product/${product}/termsofuse/update`,

  UPDATE_INTERNAL_TERMS_OF_USE: (product: string): string =>
    `${getApiHost('integration')}/product/${product}/internaltermsofuse/update`,

  GET_ADDITIONAL_USERS: (id: string, sourceSubjectId: string): string =>
    `${getApiHost('account')}/getAdditionalUsers/${id}/${sourceSubjectId}`,

  ADD_ADDITIONAL_USER: (product: string, sourceSubjectId: string): string =>
    `${getApiHost('account')}/product/${product}/addAdditionalUser/${sourceSubjectId}`,

  REMOVE_ADDITIONAL_USER: (product: string): string =>
    `${getApiHost('account')}/product/${product}/removeAdditionalUser`,
  
  UPDATE_COMPANY_NAME: (product: string): string =>
    `${getApiHost('account')}/product/${product}/company/update`,

  GET_ADMIN_FILTER_QUERY_ALL: (): string =>
    `product/all/admin/adminemailfilter/query`,

  GET_ADMIN_FILTER_QUERY_PRODUCT: (product: string): string =>
    `product/${product}/admin/adminemailfilter/query`,
  
  GET_ADMIN_COUNTRIES: (product: string): string =>
    `${getApiHost('account')}/product/${product}/admin/usercountries`,

  UPDATE_PRIMARY_USER: (product: string, localeCode: string): string =>
    `${getApiHost('account')}/product/${product}/update-primary-user/locale/${localeCode}`,

  PROFILE_LOGOUT: `${getApiHost('account')}/profile/logout`
};

export const BASE_HTTP_HEADERS: AP.BaseHttpHeaders = {
  'Content-Type': 'application/json',
  'api-version': 'v1'
  // 'Content-Security-Policy': "frame-ancestors 'none'",
  // 'X-XSS-Protection': '1',
  // 'X-Content-Type-Options': 'nosniff',
  // 'Referrer-Policy': 'strict-origin-when-cross-origin'
};
