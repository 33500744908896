import get from 'lodash/get';
import React, {
  PropsWithChildren,
  FC,
  useState,
  useContext,
  useEffect
} from 'react';
import { createPortal } from 'react-dom';

import { Nothing } from 'components';
import { AppContext } from '../AppLayout.helpers';

export interface Props {
  children: React.ReactChild | React.ReactChild[];
}

export const AppUserNav: FC<PropsWithChildren<Props>> = ({ children }) => {
  const [node, setNode] = useState<Element>(null);
  const { userNavPlaceholder } = useContext(AppContext);

  useEffect((): void => {
    setNode(get(userNavPlaceholder, 'current'));
  }, [userNavPlaceholder]);

  return node ? createPortal(children, node) : <Nothing />;
};
