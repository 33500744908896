import React from 'react';
import ReactDOM from 'react-dom';

import { AppLocales } from 'shared/constants/common.consts';
import { i18nService as i18n } from 'shared/services';
import { en } from 'locales';
import { AppContainer } from 'features/App/App.container';
import 'flag-icon-css/css/flag-icon.css';
import 'assets/styles/style.scss';

i18n
  .init({
    defaultLang: AppLocales.enUS,
    fallbackLng: AppLocales.enUS,
    translations: {
      [AppLocales.enUS]: { ...en }
    }
  })
  .then(() => {
      ReactDOM.render(<AppContainer />, document.getElementById('root'));
      //console.log('First');
     
      const environment = (window as any).APP_CONFIG.ENV;
      const cookiesUrl = (window as any).APP_CONFIG.COOKIES_JS;
      const cookeKey = (window as any).APP_CONFIG.COOKIES_JS_KEY;

      const newScript = document.createElement("script");
      newScript.src = cookiesUrl;
      newScript.type = "text/javascript";
      newScript.charset = "UTF-8";

      
      if (environment === 'PROD') {
          newScript.setAttribute('data-domain-script', cookeKey);
      }
      else {
          newScript.setAttribute('data-domain-script', cookeKey);
      }
      newScript.onload = updateCookiePolicyLink;
      document.getElementsByTagName("head")[0].appendChild(newScript);
  });
 const updateCookiePolicyLink = () => {
     let maxTries = 5;
     const environment = (window as any).APP_CONFIG.ENV;
  const timerId = setInterval(() => {
    const cookieContent = document.getElementById('onetrust-consent-sdk');
    if (cookieContent) {
      clearInterval(timerId);
      const cookyPolicyelement: HTMLElement | null = document.getElementById('onetrust-policy-text');
      if (cookyPolicyelement) {
        const alinks: any = cookyPolicyelement.getElementsByTagName('a') || [];
        const link = alinks[0];
          if (link) {
              link.href = ('/docs/'.concat(  environment  ,'-GlobalCookieNotice.pdf'));
          alinks[0].target = '_blank';
        }
      } else {
        maxTries -= 1;
        if (maxTries < 1) {
          clearInterval(timerId);
        }
      }
      const cookyOTPolicyelement: HTMLElement | null = document.getElementById('ot-pc-desc');
      if (cookyOTPolicyelement) {
        const alinks: any = cookyOTPolicyelement.getElementsByTagName('a') || [];
        const link = alinks[0];
          if (link) {
              link.href = ('/docs/'.concat(  environment  ,'-GlobalCookieNotice.pdf'));
          alinks[0].target = '_blank';
        }
      } else {
        maxTries -= 1;
        if (maxTries < 1) {
          clearInterval(timerId);
        }
      }
    }
  }, 500);
};
// i18next
//   .use(initReactI18next)
//   .init({
//     lng: 'en',
//     debug: process.env.NODE_ENV === 'development',
//     resources: {
//       en: {
//         translation: en
//       }
//     }
//   })
//   .then((): void => {
//     ReactDOM.render(<AppContainer />, document.getElementById('root'));
//   });

/*
TODO: implement env key to register sw
if (process.env.NODE_ENV === 'development') {
  register();
}
*/
