import React from 'react';
import { MultiValueProps } from 'react-select/src/components/MultiValue';

import { Icon } from 'components/Icon/Icon';
import styles from './MultiValue.module.css';

export function MultiValue<T = AP.SelectOption>({
  children,
  removeProps: { onClick }
}: MultiValueProps<T>): JSX.Element {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
      <div className={styles.remove} role='presentation' onClick={onClick}>
        <Icon name='cross-special' size={20} />
      </div>
    </div>
  );
}
