import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  getCurrentUser,
  selectIsUserLoading,
  setAccessToken
} from 'store/auth';
import { fetchLocaleList } from 'store/localize';
import { useOnMount } from 'shared/hooks';
import { AppLayout } from 'features/AppLayout';
import { Routes } from 'features/Routes/Routes';
import {
  LOCAL_STORAGE_EXTERNAL_USER_PRODUCT_KEY,
  MSAL_TOKEN_KEY
} from '../../shared/constants/common.consts';
import {
  getProductCodeFromExternalPath,
  getWindow,
} from '../../shared/utils';
import { Spinner } from '../../components';

export const App: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const msalToken = localStorage.getItem(MSAL_TOKEN_KEY);
  const product = getProductCodeFromExternalPath(pathname);
  const isLoading = useSelector(selectIsUserLoading);

  useOnMount(
    React.useCallback((): void => {
      if (msalToken || getWindow().APP_CONFIG.MOCK_USER) {
        if (pathname.includes('termsofuse')) {
          localStorage.setItem(
            LOCAL_STORAGE_EXTERNAL_USER_PRODUCT_KEY,
            product
          );
        }
        dispatch(setAccessToken(msalToken));
        dispatch(getCurrentUser());
      }
    }, [dispatch, msalToken, pathname, product])
  );

  useOnMount(
    React.useCallback((): void => {
      dispatch(fetchLocaleList());
    }, [dispatch])
  );

  if (isLoading) return <Spinner />;

  return (
    <AppLayout>
      <Routes />
    </AppLayout>
  );
};