export const PRODUCT_ROLE: Record<string, DTO.UserRole> = {
  SUPER_ADMIN: 'superAdmin',
  PRODUCT_GROUP_ADMIN: 'productGroupAdmin',
  PRODUCT_ADMIN: 'productAdmin',
  MEMBER_FIRM_ADMIN: 'memberFirmAdmin',
  COUNTRY_ADMIN: 'countryAdmin',
  WHITELISTED_INTERNAL_USER: 'whitelistedInternalUser'
};

export const PRODUCT_CODE = {
  IBR_CODE: 'ibr'
};
