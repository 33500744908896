import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import styles from './SidePanel.module.css';
import {
  selectProductCode,
} from '../../store/product/product.selectors';
import { Tab } from '../../components/Tab/Tab';
import submissionLogoGreen from '../../assets/images/submissionLogoGreen.svg';
import userIconBlack from '../../assets/images/userIconBlack.svg';
import submissionLogoBlack from '../../assets/images/submissionLogoBlack.svg';
import userIconGreen from '../../assets/images/userIconGreen.svg';
import arrowLeft from '../../assets/images/arrowLeft.svg';
import arrowRight from '../../assets/images/arrowRight.svg';


export const SidePanel = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const productCode = useSelector(selectProductCode);
  const [isOpenSideBar, setIsOpenSideBar] = useState(false);
  const [routeLocation, setRouteLocation] = useState(location);

  useEffect(() => {
    setRouteLocation(location);  
  }, [location]);

  const toggleNav = () => {
    setIsOpenSideBar(!isOpenSideBar);
    if (document.getElementById('mySidebar').style.width === '' || document.getElementById('mySidebar').style.width === '0px') {
      openNav();
    } else {
      closeNav();
    }
  };

  const openNav = () => {
    document.getElementById('mySidebar').style.width = '250px';
    document.getElementById('mySidebar').style.marginLeft = '15px';
  };

  const closeNav = () => {
    document.getElementById('mySidebar').style.width = '0';
    document.getElementById('mySidebar').style.marginLeft = '0';
  };


  return (
    <div className={styles.sidebarToggle} id='toggle'>
      <React.Fragment>
        <Tab
          className={styles.tab}
          highlightActive={true}
          href={`/admin/submissions/${productCode}`}
          isSidePanel={true}
          tabRoute={routeLocation?.pathname?.toLowerCase().includes('submissions') ? 'submissions' : 'update-subscription'}
        >
          {routeLocation?.pathname?.toLowerCase().includes('submissions') || routeLocation?.pathname?.toLowerCase().includes('update-subscription') ?
            <img alt='submissionsvg' src={submissionLogoGreen} />
            : <img alt='submissionsvg' src={submissionLogoBlack} />
          }
        </Tab>  
        <Tab
          className={styles.tab}
          highlightActive={true}
          href={`/admin/onboarding/${productCode}`}
          isSidePanel={true}
          tabRoute='onboarding'
        >
          {routeLocation?.pathname?.toLowerCase().includes('onboarding') ?
            <img alt='usersvg' src={userIconGreen} />
            : <img alt='usersvg' src={userIconBlack} />
          }
        </Tab>
      </React.Fragment>

      <button className={styles.openbtn} onClick={() => toggleNav()}>
        <img alt='arrow' src={isOpenSideBar ? arrowLeft : arrowRight} />
      </button>
      <div className={styles.sidebar} id='mySidebar'>
        <p>{t('admin.tabs.submission')}</p>
        <p>{t('admin.tabs.admin')}</p>
      </div>
    </div>
  );
};
