import { createSelector } from 'reselect';

import { RootState } from 'store/root.reducer';

export const selectCountryCode = ({ updateSubscription }: RootState): string =>
  updateSubscription.countryCode;

export const selectIsLoading = ({ updateSubscription }: RootState): boolean =>
  updateSubscription.isLoading;

export const selectSubscriptionData = ({
  updateSubscription
}: RootState): AP.RegistrationData => updateSubscription.data;

export const selectAction = ({ updateSubscription }: RootState): string =>
  updateSubscription.userAction;

export const selectEmail = ({ updateSubscription }: RootState): string =>
  updateSubscription.userEmail;

export const selectCountryStatesData = ({
  updateSubscription
}: RootState): any => {
  // DTO.State[]
  return updateSubscription.states;
};

export const reselectSubscriptionData = createSelector(
  selectSubscriptionData,
  (data: AP.RegistrationData) => data
);

export const reselectCountry = createSelector(
  selectSubscriptionData,
  ({ country }): any => country
);

export const reselectSubscriptionEmail = createSelector(
  selectEmail,
  (email: string) => email
);

export const reselectAction = createSelector(
  selectAction,
  (data: string) => data
);

export const reselectSubscriptionPlans = createSelector(
  selectSubscriptionData,
  (data: AP.RegistrationData) => data.plans
);
