import React, {Children, FC} from 'react';
import Button from 'react-bootstrap/Button';
import { Modal, ModalProps } from 'react-bootstrap';

interface Props extends ModalProps {
    onHide?: () => void;
    show: boolean;    
    title: string;
}

export const ModalCustom: FC <ModalProps> = ({onHide, show, children, title}) => {
  return (
    <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered = {true}
        show = {show}
        size = "sm"
        onHide = {onHide}
    >
        <Modal.Header closeButton = {show}>
            <Modal.Title id="contained-modal-title-vcenter" >
                {title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {children}
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
    </Modal>
  );
};
