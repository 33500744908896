import { string, object } from 'yup';
import { withFormik } from 'formik';

import { noop } from 'shared/utils';

export interface RegistrationSubmissionForm {
  comment: string;
}

export const initialFormValues: RegistrationSubmissionForm = {
  comment: ''
};

export const commentLengthLimit = 255;

export interface RegistrationSubmissionDialogProps {
  onSubmit: (comment: string) => void;
  onCancel: () => void;
}

export const registrationSubmissionDialogEnhancer = withFormik<
  RegistrationSubmissionDialogProps,
  RegistrationSubmissionForm
>({
  mapPropsToValues: () => initialFormValues,
  validationSchema: object().shape({
    comment: string()
      .required()
      .trim()
      .max(commentLengthLimit)
  }),
  handleSubmit: noop
});
