import React from 'react';
import { kebabCase } from 'lodash';
import cs from 'classnames';
import { Form, Col } from 'react-bootstrap';
import { useField } from 'formik';

import { useError } from '../Form.helpers';
import styles from '../Form.module.css';

interface Props {
  name: string;
  span?: number;
  label?: string;
  placeholder?: string;
  rows?: number;
  validationErrMsg?: (err: string) => string;
  renderFooter?: () => JSX.Element;
  maxLength?: number;
}

export default function Textarea({
  label,
  name,
  placeholder,
  span,
  rows = 4,
  validationErrMsg = (): string => '',
  renderFooter = (): JSX.Element => null,
  maxLength
}: Props): JSX.Element {
  const [field, meta] = useField(name);
  const { value, onChange, onBlur } = field;
  const { error, touched } = meta;
  const isInvalid = error && touched;

  return (
    <Form.Group
      as={Col}
      controlId={kebabCase(`form textarea ${label}`)}
      lg={span}
    >
      {label && <Form.Label className={styles.label}>{label}</Form.Label>}
      <Form.Control
        as='textarea'
        className={cs(styles.noResize, {
          [styles.formControlInvalid]: isInvalid
        })}
        isInvalid={isInvalid}
        maxLength={maxLength}
        name={name}
        placeholder={placeholder}
        rows={rows}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
      {renderFooter()}
      {useError(error, touched, validationErrMsg)}
    </Form.Group>
  );
}
