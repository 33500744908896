import React from 'react';
import kebabCase from 'lodash/kebabCase';
import cs from 'classnames';
import ReactSelect from 'react-select';
import { ValueType } from 'react-select/src/types';
import { SelectComponents } from 'react-select/src/components';
import { Form, Col, Row } from 'react-bootstrap';
import { useField } from 'formik';

import { noop } from 'shared/utils';
import { Nothing } from 'components/Nothing/Nothing';
import { DropdownIndicator } from './DropdownIndicator/DropdownIndicator';
import { Placeholder } from './Placeholder/Placeholder';
import { Control } from './Control/Control';
import { SingleValue } from './SingleValue/SingleValue';
import { Menu } from './Menu/Menu';
import { Option } from './Option/Option';
import { MultiValue } from './MultiValue/MultiValue';
import { useOptionProp, useSelectLabel, SelectProps } from './Select.helpers';
import { useError } from '../Form.helpers';
import styles from '../Form.module.css';

interface Props<T> extends SelectProps {
  disabled?: boolean;
  options: T[];
  className?: string;
}

export default function SelectFieldFilters<T = AP.SelectOption>({
  disabled,
  name,
  span,
  label,
  options,
  onChange = noop,
  multiple = false,
  placeholder = '',
  optionLabel = 'label',
  optionValue = 'value',
  validationErrMsg = (): string => '',
  className
}: Props<T>): JSX.Element {
  const renderSelectLabel = useSelectLabel(label);
  const handleGetOptionLabel = useOptionProp<T>(optionLabel);
  const handleGetOptionValue = useOptionProp<T>(optionValue);
  const [components] = React.useState<Partial<SelectComponents<T, boolean>>>({
    DropdownIndicator,
    Placeholder,
    SingleValue,
    MultiValue,
    Control,
    Option,
    Menu,
    IndicatorSeparator: Nothing
  });

  const [field, meta, form] = useField(name);
  const { value } = field;
  const { error, touched } = meta;
  const { setValue, setTouched } = form;
  const isInvalid = error && touched;

  return (
    <Form.Group
      className={cs(styles.filterFormGroup, styles.content
      )}
      controlId={kebabCase(`form select ${label}`)}
    >
      {renderSelectLabel}
      <ReactSelect
        className={cs({
          [styles.formControlInvalid]: isInvalid},
          className
          )}
        components={components}
        getOptionLabel={handleGetOptionLabel}
        getOptionValue={handleGetOptionValue}
        isClearable={false}
        isDisabled={disabled}
        isMulti={multiple}
        options={options}
        placeholder={placeholder}
        value={value}
        onBlur={(): void => setTouched(touched)}
        onChange={(selectedValue: ValueType<T, boolean>): void => {
          onChange(selectedValue);
          setValue(selectedValue);
        }}
      />
      {useError(error, touched, validationErrMsg)}
    </Form.Group>
  );
}
