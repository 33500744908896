import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cs from 'classnames';

import { USER_ROLES } from 'features/AdminPage/OnboardingPage/OnboardingFilters/OnboardingFilters';
import { reselectUser } from 'store/auth';
import { selectProductCode } from 'store/product';
import { SUBMISSION_PLAN_STATUS } from 'shared/constants/submission.consts';
import { Button } from 'components';
import styles from './SubmissionPlansGrid.module.css';

export interface Subscription {
  code: string;
  status: DTO.SubmissionPlanStatus;
  id: string;
  checked?: boolean;
}

export interface SubscriptionsRequest {
  comment: string;
  plans: Subscription[];
}

export interface ProductRoleRequest {
  productRoleId: number;
}

export type SubscriptionsState = Record<string, Subscription>;

export const initialSubscriptionsState: SubscriptionsState = {};

export enum SUBSCRIPTIONS_ACTION_TYPES {
  INITIALIZE,
  REJECT_ALL,
  APPROVE_ALL,
  CHANGE_VALUE
}

type SubscriptionsActions =
  | { type: SUBSCRIPTIONS_ACTION_TYPES.INITIALIZE; payload: SubscriptionsState }
  | { type: SUBSCRIPTIONS_ACTION_TYPES.REJECT_ALL }
  | { type: SUBSCRIPTIONS_ACTION_TYPES.APPROVE_ALL }
  | {
      type: SUBSCRIPTIONS_ACTION_TYPES.CHANGE_VALUE;
      payload: { code: string; value: DTO.SubmissionPlanStatus, productRoleId: number};
    };

const isPendingStatus = (status: DTO.SubmissionPlanStatus) => {
  if (
    status === SUBMISSION_PLAN_STATUS.PENDING ||
    status === SUBMISSION_PLAN_STATUS.PENDING_REACTIVATION ||
    status === SUBMISSION_PLAN_STATUS.PENDING_RENEWAL ||
    status === SUBMISSION_PLAN_STATUS.REJECTED ||
    status === SUBMISSION_PLAN_STATUS.APPROVED ||
    status === SUBMISSION_PLAN_STATUS.PENDING_ACTIVATION ||
    status === null // pending statuses might be null at that point
  ) {
    return true;
  }

  return false;
};

export const subscriptionsReducer = (
  state: SubscriptionsState,
  action: SubscriptionsActions
): SubscriptionsState => {
  switch (action.type) {
    case SUBSCRIPTIONS_ACTION_TYPES.INITIALIZE:
      return action.payload;

    case SUBSCRIPTIONS_ACTION_TYPES.REJECT_ALL:
      return Object.keys(state).reduce(
        (acc: SubscriptionsState, planCode: string) => {
          if (isPendingStatus(state[planCode].status)) {
            return {
              ...acc,
              [planCode]: {
                code: planCode,
                status: SUBMISSION_PLAN_STATUS.REJECTED,
                id: state[planCode].id,
                checked: !(state[planCode].status === SUBMISSION_PLAN_STATUS.REJECTED)
              }
            };
          }
          return {
            ...acc,
            [planCode]: {
              code: planCode,
              status: state[planCode].status,
              id: state[planCode].id
            }
          };
        },
        {}
      );

    case SUBSCRIPTIONS_ACTION_TYPES.APPROVE_ALL:
      return Object.keys(state).reduce(
        (acc: SubscriptionsState, planCode: string) => {
          if (isPendingStatus(state[planCode].status)) {
            return {
              ...acc,
              [planCode]: {
                code: planCode,
                status: SUBMISSION_PLAN_STATUS.APPROVED,
                id: state[planCode].id,
                checked: !(state[planCode].status === SUBMISSION_PLAN_STATUS.REJECTED)
              }
            };
          }
          return {
            ...acc,
            [planCode]: {
              code: planCode,
              status: state[planCode].status,
              id: state[planCode].id
            }
          };
        },
        {}
      );

    case SUBSCRIPTIONS_ACTION_TYPES.CHANGE_VALUE: {
      const { code, value, productRoleId  } = action.payload;

      return Object.keys(state).reduce(
        (acc: SubscriptionsState, planCode: string) => {
          const status = code === planCode ? value : state[planCode].status;
          return {
            ...acc,
            [planCode]: {
              code: planCode,
              status,
              userProductRole: productRoleId,
              id: state[planCode].id,
              checked:
                code === planCode
                  ? true
                  : state[planCode] && state[planCode].checked
            }
          };
        },
        {}
      );
    }

    default:
      return state;
  }
};

export const useTableHeaders = ({
  onReject,
  onApprove,
  onUpdate
}: {
  onReject: () => void;
  onApprove: () => void;
  onUpdate: () => void;
}): {
  controlTableHeaders: JSX.Element[];
  defaultTableHeaders: string[];
  emptyTableHeaders: null[];
  defaultTableHeadersIfMIP: string[];
  updateTableHeaders: JSX.Element[];
} => {
  const { t } = useTranslation();
  const user = useSelector(reselectUser);
  const productCode = useSelector(selectProductCode);
  const [defaultTableHeaders] = React.useState<string[]>([
    `${t('admin.plans.plan')}:`,
    `${t('admin.plans.licenses')}:`,
    `${t('admin.plans.status')}:`,
    `${t('admin.plans.reviewedBy')}:`,
    `${t('admin.plans.date')}:`,
    `${t('admin.plans.duration')}:`
  ]);

  const [defaultTableHeadersIfMIP] = React.useState<string[]>([
    `${t('admin.plans.plan')}:`,
    `${t('admin.plans.licenses')}:`,
    `${t('admin.plans.status')}:`,
    `${t('admin.plans.reviewedBy')}:`,
    `${t('admin.plans.date')}:`,
    `${t('admin.plans.duration')}:`,
    `${t('admin.plans.role')}:`
  ]);

  const [controlTableHeaders] = React.useState<JSX.Element[]>([
    null,
    <Button
      key='rejectAll'
      className={cs(styles.right, user.role === USER_ROLES.SUPER_ADMIN ? styles.noEvent : null)}
      size='sm'
      variant='link'
      onClick={onReject}
    >
      {t('admin.plans.rejectAll')}
    </Button>,
    <Button
      key='approveAll'
      className={cs(styles.right, user.role === USER_ROLES.SUPER_ADMIN ? styles.noEvent : null)}
      size='sm'
      variant='link'
      onClick={onApprove}
    >
      {t('admin.plans.approveAll')}
    </Button>
  ]);

  const [updateTableHeaders] = React.useState<JSX.Element[]>([
    null,
    <Button
      key='updateSubscription'
      className={cs(
        styles.right,
        styles.btnMoveLeft,
        user.role === USER_ROLES.SUPER_ADMIN ? styles.taDisable : null,
        productCode === 'mip' ? styles.mipUpdateSubs : null)}
      disabled={user.role === USER_ROLES.SUPER_ADMIN}
      size='sm'
      variant='link'
      onClick={onUpdate}
    >
      {t('admin.plans.updateSubscription')}
    </Button>
  ]);

  const [emptyTableHeaders] = React.useState<null[]>([null, null, null]);

  return {
    defaultTableHeaders,
    controlTableHeaders,
    emptyTableHeaders,
    defaultTableHeadersIfMIP,
    updateTableHeaders
  };
};

export enum APPROVAL_ACTION_TYPES {
  RENEW = 'renew',
  REACTIVATE = 'reactivate',
  ACTIVATE = 'activate'
}

export const APPROVAL_ACTION_TYPES_NAMES: Record<
  APPROVAL_ACTION_TYPES,
  string
> = {
  [APPROVAL_ACTION_TYPES.RENEW]: 'Renewal',
  [APPROVAL_ACTION_TYPES.REACTIVATE]: 'Reactivation',
  [APPROVAL_ACTION_TYPES.ACTIVATE]: 'Activate'
};
