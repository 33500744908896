import { apiService } from 'shared/services';
import {
  CREATE_ADMIN,
  GET_ADMIN_ROLES,
  GET_MEMBER_FIRMS,
  GET_ONBOARDING_USERS,
  GET_PRODUCTS,
  GET_COUNTRIES,
  RESET_CREATED_ADMIN,
  UPDATE_COLUMN_SORTING
} from './onboarding.types';

export const getUsersForOnboarding = (
  productCode: string,
  pageSize: number,
  pageNumber: number,
  userFilters: DTO.UserFilters,
  sorter: DTO.Sorter,
  applyFilters: boolean,
  role: string
) => ({
  type: GET_ONBOARDING_USERS,
  payload: apiService.fetchOnboardingUsers(
    productCode,
    pageSize,
    pageNumber,
    userFilters,
    sorter,
    applyFilters,
    role
  )
});

export const getAdminRoles = (productCode: string) => ({
  type: GET_ADMIN_ROLES,
  payload: apiService.fetchAdminRoles(productCode)
});

export const getProducts = () => ({
  type: GET_PRODUCTS,
  payload: apiService.fetchProducts()
});

export const getAvailableProducts = (email: string) => ({
  type: GET_PRODUCTS,
  payload: apiService.fetchAvaiableProducts(email)
});

export const getCountries = (productCode: string) => ({
  type: GET_COUNTRIES,
  payload: apiService.fetchProductCountries(productCode)
});
export const getMemberFirms = () => ({
  type: GET_MEMBER_FIRMS,
  payload: apiService.fetchMemberFirms()
});

export const resedCreatedAdmin = () => ({
  type: RESET_CREATED_ADMIN
});

export const updateColumnSorting = (sortBy: string, sortDirection: string, currentTile: number) => ({
  type: UPDATE_COLUMN_SORTING,
  payload: { sortBy, sortDirection, currentTile }
});

export const createAdmin = (
  productCode: string,
  requestBody: DTO.CreateAdminRequest
) => ({
  type: CREATE_ADMIN,
  payload: apiService.createAdmin( productCode,requestBody)
});
