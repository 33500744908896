import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';
import { Button } from 'react-bootstrap';

import { Form, Icon, Select } from 'components';
import { SearchCallback } from './FiltersControls.helpers';
import styles from './FiltersControls.module.css';
import { FilterRow } from '../FilterRow/FilterRow';

interface Props {
  countries: DTO.Country[];
  onCompaniesSearch: SearchCallback<Partial<DTO.Organization>>;
  onUsersSearch: SearchCallback<Partial<DTO.User>>;
  setFieldValue: (name: string, value: string[]) => void;
  noOptionsMessage: () => string;
  loadingMessage: () => string;
  showFilters: DTO.FilterFields[];
  onAdminsSearch?: SearchCallback<DTO.UserEmail>;
  countryOptionValue: AP.CountryOption;
  statuses: DTO.PlanStatusFilterValue[];
  activeRole: number;
  onSubscriberEmailSearch: SearchCallback<Partial<DTO.User>>;
  addMoreFilter: Array<number>;
  appliedFilters: any;
  onReset: () => void;
  disableReset: boolean;
  removeFilterRow: (p1: any, p2: any) => void;
  setAddMoreFilter: (a1: any) => void;
}

export function FiltersControls({
  onSubscriberEmailSearch,
  countries,
  onCompaniesSearch,
  onUsersSearch,
  setFieldValue,
  noOptionsMessage,
  loadingMessage,
  showFilters,
  onAdminsSearch,
  countryOptionValue,
  statuses,
  activeRole,
  addMoreFilter,
  onReset,
  disableReset,
  appliedFilters,
  removeFilterRow,
  setAddMoreFilter
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div>
      <Form className='mb-3'>
        <div className={cs(styles.filterHeader, styles.content)}>
          <span className={styles.filterHeaderCol}>
            <Icon name='filter-special' size={22} />
            {t('actions.filters')}
          </span>
          <Button
            className={cs(styles.filterHeaderCol, styles.toggle)}
            disabled={disableReset}
            variant='link'
            onClick={onReset}
          >
            {t('actions.resetFilter')}
          </Button>
        </div>
        <div className={cs(styles.filterHeader, styles.content)}>
          <span className={styles.filterSubHeaderCol}>Filter Type</span>
          <span className={styles.filterSubHeaderCol}>Criteria</span>
        </div>
        {addMoreFilter.map((addFilter: any, index: any) => {
              return (
                <FilterRow
                  key={`filter-${addFilter}`}
                  activeRole={activeRole}
                  addFilter={addFilter}
                  addMoreFilter={addMoreFilter}
                  appliedFilter={appliedFilters}
                  countries={countries}
                  loadingMessage={loadingMessage}
                  noOptionsMessage={noOptionsMessage}
                  removeFilterRow={removeFilterRow}
                  setAddMoreFilter={setAddMoreFilter}
                  setFieldValue={setFieldValue}
                  showFilters={showFilters}
                  onAdminsSearch={onAdminsSearch}
                  onCompaniesSearch={onCompaniesSearch}
                  onSubscriberEmailSearch={onSubscriberEmailSearch}
                  onUsersSearch={onUsersSearch}
                />
              );
            })
          }
      </Form>
    </div>
  );
}
