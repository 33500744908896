import React from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import circleTickImage from 'assets/images/circle-tick.svg';
import circleCrossImage from 'assets/images/circle-cross.svg';
import styles from '../SubmissionsGrid.module.css';

interface Props {
    existingValue: string,
    onStartEditing: (e: any) => void;
    onConfirmEdit: () => void;
    onCancelEdit: () => void;
}

export function EditableComponent
    ({
        existingValue,
        onStartEditing,
        onConfirmEdit,
        onCancelEdit
    }: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <input
                className={cs(styles.input, styles.editField)}
                name="userName"
                type="text"
                value={existingValue} 
                // onBlur={onCancelEdit}
                onChange={onStartEditing}
            />
            <button
                className={cs(styles.editFieldImg)}
                title={t('admin.grid.confirmEdit')}
                type='button'
                onMouseDown={onConfirmEdit}
            >
                <img
                    alt='confirm edit'
                    className=''
                    src={circleTickImage}
                />
            </button>
            <button
                className={cs(styles.editFieldImg)}
                title={t('admin.grid.cancelEdit')}
                type='button'
                onMouseDown={onCancelEdit}
            >
                <img
                    alt='cancel edit'
                    src={circleCrossImage}
                />
            </button>
        </React.Fragment>
    );
}
