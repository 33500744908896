import React from 'react';
import { Form } from 'react-bootstrap';

import { errorException } from 'shared/utils/AppInsights';
import styles from './Form.module.css';

export const useError = (
  error: string,
  touched: boolean,
  validation: (err: string) => string
): JSX.Element => {
  if (touched && error) {

    // AppInsights Error Exception
    errorException(`${error}. File reference: Form.help.tsx in useError function`);
    
    return (
      <Form.Control.Feedback className={styles.feedback} type='invalid'>
        {validation(error)}
      </Form.Control.Feedback>
    );
  }

  return null;
};
