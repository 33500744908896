import React, { useEffect } from 'react';
import cs from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutate } from 'restful-react';
import find from 'lodash/find';

import {
  getCurrentUser,
  reselectUser,
  selectUserInitials,
  selectHttpHeaders
} from 'store/auth';
import { errorException } from 'shared/utils/AppInsights';
import { reselectLocaleList, changeLanguage } from 'store/localize';
import { API_PATH } from 'shared/constants/api.consts';
import { Select } from 'components';
import { ConfirmDialog } from 'components/Localization_Confirm/ConfirmDialog';
import { defaultLocale } from 'shared/constants/common.consts';
import styles from './UserMenuContent.module.css';
import { authProvider } from '../../../../authProvider';
import exit from '../../../../assets/images/exit.svg';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  'aria-labelledby'?: string;
}

export const UserMenuContent = React.forwardRef<HTMLDivElement, Props>(
  ({ style, className, 'aria-labelledby': labeledBy }, ref): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(reselectUser);
    const userInitials = useSelector(selectUserInitials);
    const languageLists = useSelector(reselectLocaleList);
    const httpHeaders = useSelector(selectHttpHeaders);
    const [showSettings, setShowSettings] = React.useState(false);
    const [selectedLanguage, setSelectedLanguage] = React.useState<any>();
    const [fallbackScenario, setFallBackScenario] = React.useState(false);
    const { dateFormat, numberFormat, language } = user.profileSettings;

    const renderSelectLanguages = React.useCallback(
      (langData: any): JSX.Element => (
        <div>
          <span>{langData.language}</span>
        </div>
      ),
      []
    );

    useEffect((): void => {
      if (languageLists.length) {
        const userLanguage = find(languageLists, {
          localeCode: language
        });
        setSelectedLanguage(userLanguage);
      }
    }, [languageLists, language]);

    useEffect((): void => {
      if (!languageLists.length) {
        setFallBackScenario(true);
      }
    }, [languageLists]);

    const { mutate: SaveSettings } = useMutate<DTO.UserSettings>({
      verb: 'POST',
      path: API_PATH.SAVE_USER_PREFERENCE(),
      requestOptions: () => ({ headers: httpHeaders })
    });

    const handleLanguageChange = React.useCallback(
      (langData: any): any => {
        setSelectedLanguage(langData);
        SaveSettings({
          language: langData.localeCode,
          dateFormat,
          numberFormat
        }).then(() => {
          dispatch(getCurrentUser());
        });
        dispatch(changeLanguage(langData.localeCode));
      },
      [SaveSettings, dispatch, dateFormat, numberFormat]
    );

    const confirmDialogConfig = {
      closeButtonName: t('actions.cancel'),
      okBtnName: t('actions.apply'),
      title: t('actions.settings'),
      subTitle: t('admin.first.selectLanguage'),
      subTitle2: t('admin.first.selectDateFormat'),
      subTitle3: t('admin.first.selectNumberFormat')
    };

    const onModalClose = () => {
      setShowSettings(false);
    };
    const onModalOpen = () => {
      setShowSettings(true);
    };

    const getOptionValue = React.useCallback(
      ({ localeCode }: AP.DefaultLocale): string => localeCode,
      []
    );

    const handleLogout = async() => {
      try{
        const resp = await fetch(API_PATH.PROFILE_LOGOUT, { headers: httpHeaders });
        const data = await resp.json();
        if(data){
          authProvider.logout();
        }
      } catch(error) {
        errorException(`${error}. In UserMenuContent.tsx`);
      }
    };

    return (
      <div
        ref={ref}
        aria-labelledby={labeledBy}
        className={cs(className, styles.container)}
        style={style}
      >
        <div className={styles.menuHeaderItem}>
          <div className={styles.user}>
            <div className={styles.userIcon}>{userInitials}</div>
            <div className={styles.userInfo}>
              <h3 className={styles.userInfoTitle}>
                {`${user.firstName} ${user.lastName}`}
              </h3>
              <span className={styles.userInfoSubTitle}>
                {t(`admin.role.${user.role}`)}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.menuHeaderItem}>
          <div className={styles.selectLangTitle}>
            {t('actions.selectLanguage')}
          </div>
          <Select<AP.DefaultLocale>
            getOptionValue={getOptionValue}
            isClearable={false}
            isDisabled={fallbackScenario}
            isSearchable={false}
            options={languageLists}
            renderOption={renderSelectLanguages}
            value={selectedLanguage || defaultLocale}
            withDropdownIndicator={true}
            onChange={handleLanguageChange}
          />
          {fallbackScenario && (
            <div className={styles.fallbackTxt}>
              Localization settings are temporarily unavailable. <br /> Please,
              try again later.
            </div>
          )}
        </div>

        <button
          className={cs(styles.menuButton, styles.profileSettings)}
          type='button'
          onClick={onModalOpen}
        >
          <span className='icon-cog-special align-middle mr-1' />
          {t('actions.profileSettings')}
        </button>

        <button
          className={cs(styles.menuButton, styles.signOut)}
          type='button'
          onClick={handleLogout}
        >
          <img alt='exitsvg' className='mr-2' src={exit} />
          {t('actions.logOut')}
        </button>

        <ConfirmDialog
          cancelBtnName={confirmDialogConfig.closeButtonName}
          close={onModalClose}
          dateFormat={dateFormat}
          numberFormat={numberFormat}
          okBtnName={confirmDialogConfig.okBtnName}
          showModal={showSettings}
          subTitle={confirmDialogConfig.subTitle}
          subTitle2={confirmDialogConfig.subTitle2}
          subTitle3={confirmDialogConfig.subTitle3}
          title={confirmDialogConfig.title}
          userLanguage={
            (selectedLanguage && selectedLanguage.localeCode) || language
          }
        />
      </div>
    );
  }
);
