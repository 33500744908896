export const pagesEn = {
  termsOfUse: 'Terms of Use',
  privacy: 'Privacy',
  notices: 'Notices',
  and: 'and',
  termspdf: 'GASS_[pages_termspdf__Asset__]_en-US_pdf',
  privacypdf: 'GASS_[pages_privacypdf__Asset__]_en-US_pdf',
  noticepdf: 'GASS_[pages_noticepdf__Asset__]_en-US_pdf',
  aboutpdf: 'GASS_[pages_aboutpdf__Asset__]_en-US_pdf',
  dbdoc: 'GASS_[pages_dbdoc__Asset__]_en-US_pdf'
};
