import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cs from 'classnames';

import { reselectUser } from 'store/auth';
import { Icon, Table } from 'components';
import { getIconName } from 'features/AdminPage/Sorting/Sorting.helpers';
import { SORT_DIRECTION } from 'shared/constants/common.consts';
import { DirectionButton } from 'components/DirectionButton/DirectionButton';
import styles from './OnboardingPageTable.module.css';
import {
  selectIsLoaded,
  selectIsLoading
} from '../../../../store/onboarding/onboarding.selectors';
import { useTimeoutBeforeLoader } from '../../../../shared/hooks/useTimeoutBeforeLoader';
import { Spinner } from '../../../../components/Spinner/Spinner';
import { UserRoles, USER_ROLES } from '../OnboardingFilters/OnboardingFilters';

interface Props {
  users: DTO.AdminUser[];
  handleDelete: (admin: DTO.AdminUser) => void;
  currentTile: any;
  allTileHeaders: any[];
  sortBy: string;
  direction: AP.SortDirection;
  sorter: DTO.Sorter;
  handleSortOnChange: (sortBy: string, sort: AP.SortDirection) => void
  tenantTileHeaders: any[],
  productTileHeaders: any[]
}

export const OnboardingPageTable: React.FC<Props> = ({
  users,
  handleDelete,
  currentTile,
  allTileHeaders,
  direction,
  sortBy,
  sorter,
  handleSortOnChange,
  tenantTileHeaders,
  productTileHeaders
}) => {
  const { t } = useTranslation();
  const isLoading = useSelector(selectIsLoading);
  const isLoaded = useSelector(selectIsLoaded);
  const showLoader = useTimeoutBeforeLoader(isLoading, 1000);
  const currentAdminUser = useSelector(reselectUser);

  const onSortingChangeCb = React.useCallback(
    (value: string, sortdirection) => {
      let sortingOrder: SORT_DIRECTION;
      if (value === sortBy) {
        switch (direction) {
          case '':
            sortingOrder = SORT_DIRECTION.DESC;
            break;
          case SORT_DIRECTION.DESC:
            sortingOrder = SORT_DIRECTION.ASC;
            break;
          case SORT_DIRECTION.ASC:
            sortingOrder = SORT_DIRECTION.DESC;
            break;
          default:
            sortingOrder = SORT_DIRECTION.ASC;
            break;
        }
      } else {
        switch (sortdirection) {
          case '':
            sortingOrder = SORT_DIRECTION.DESC;
            break;
          case SORT_DIRECTION.DESC:
            sortingOrder = SORT_DIRECTION.ASC;
            break;
          case SORT_DIRECTION.ASC:
            sortingOrder = SORT_DIRECTION.DESC;
            break;
          default:
            sortingOrder = SORT_DIRECTION.ASC;
            break;
        }
      }
      handleSortOnChange(value, sortingOrder);
    },
    [sortBy, direction, handleSortOnChange]
  );

  if (showLoader) {
    return (
      <Spinner
        className={styles.loaderWrap}
        shouldFill={false}
        shouldShow={true}
      />
    );
  }
  const getActionItems = (user: DTO.AdminUser) => {
    return (
      <td
        className={styles.tcell}
        title={
          currentAdminUser.email === user.email
            ? t('onboarding.deletetenantadmin')
            : ''
        }
      >
        <svg
          className={cs(
            styles.svgIcon,
            currentAdminUser.email === user.email ? styles.disableDelete : null
          )}
          fill='none'
          height='24'
          viewBox='0 0 24 24'
          width='24'
          xmlns='http://www.w3.org/2000/svg'
          onClick={() => currentAdminUser.email !== user.email ? handleDelete(user) : null}
        >
          <path
            className={styles.svgPath}
            clipRule='evenodd'
            d='M8 8V19H16V8H8ZM10.5 10C10.7761 10 11 10.2239 11 10.5V16.5C11 16.7761 10.7761 17 10.5 17C10.2239 17 10 16.7761 10 16.5V10.5C10 10.2239 10.2239 10 10.5 10ZM13.5 10C13.7761 10 14 10.2239 14 10.5V16.5C14 16.7761 13.7761 17 13.5 17C13.2239 17 13 16.7761 13 16.5V10.5C13 10.2239 13.2239 10 13.5 10ZM13.7192 5H10.2808L9.78078 7H14.2192L13.7192 5ZM17 8V19.5C17 19.7455 16.8231 19.9496 16.5899 19.9919L16.5 20H7.5C7.25454 20 7.05039 19.8231 7.00806 19.5899L7 19.5V8H5.5C5.22386 8 5 7.77614 5 7.5C5 7.25454 5.17688 7.05039 5.41012 7.00806L5.5 7H8.75L9.40532 4.37873C9.46096 4.15615 9.66095 4 9.89039 4H14.1096C14.339 4 14.539 4.15615 14.5947 4.37873L15.25 7H18.5C18.7455 7 18.9496 7.17688 18.9919 7.41012L19 7.5C19 7.74546 18.8231 7.94961 18.5899 7.99194L18.5 8H17Z'
            fill='black'
            fillRule='evenodd'
          />
        </svg>
      </td>
    );
  };

  const getHeaderForAllTile = () => {
    return (
      <tr>
        {allTileHeaders.map((header, index) => {
          return (
            <React.Fragment key={`${index + 1}`}>
              {header.label === 'onboarding.table.userName' && (
                <th className={cs(styles.theader, styles.name)}
                  onClick={() => onSortingChangeCb(header?.value, header?.sortOrder)}
                >
                  {t(header.label)}
                  <DirectionButton
                    fill='black'
                    isDisable={!header?.sortOrder}
                    isReversed={header?.sortOrder === SORT_DIRECTION.ASC || header?.sortOrder === ''}
                    name={header?.sortOrder === '' || header?.sortOrder === SORT_DIRECTION.ASC ? 'firstArrow' : 'secondArrow'}
                  />
                </th>
              )}
              {header.label === 'onboarding.table.role' && currentAdminUser.role !== USER_ROLES.PRODUCT_ADMIN && (
                <th className={cs(styles.theader, styles.roleAllTile)}
                  onClick={() => onSortingChangeCb(header?.value, header?.sortOrder)}
                >
                  {t(header.label)}
                  <DirectionButton
                    fill='black'
                    isDisable={!header?.sortOrder}
                    isReversed={header?.sortOrder === SORT_DIRECTION.ASC || header?.sortOrder === ''}
                    name={header?.sortOrder === '' || header?.sortOrder === SORT_DIRECTION.ASC ? 'firstArrow' : 'secondArrow'}
                  />
                </th>
              )}
              {header.label === 'onboarding.table.email' && (
                <th className={cs(styles.theader, styles.emailAllTile)}
                  onClick={() => onSortingChangeCb(header?.value, header?.sortOrder)}
                >
                  {t(header.label)}
                  <DirectionButton
                    fill='black'
                    isDisable={!header?.sortOrder}
                    isReversed={header?.sortOrder === SORT_DIRECTION.ASC || header?.sortOrder === ''}
                    name={header?.sortOrder === '' || header?.sortOrder === SORT_DIRECTION.ASC ? 'firstArrow' : 'secondArrow'}
                  />
                </th>
              )}
              {header.label === 'onboarding.table.product' && currentAdminUser.role !== USER_ROLES.PRODUCT_ADMIN && (
                <th className={cs(styles.theader, styles.product)}
                  onClick={() => onSortingChangeCb(header?.value, header?.sortOrder)}
                >
                  {t(header.label)}
                  <DirectionButton
                    fill='black'
                    isDisable={!header?.sortOrder}
                    isReversed={header?.sortOrder === SORT_DIRECTION.ASC || header?.sortOrder === ''}
                    name={header?.sortOrder === '' || header?.sortOrder === SORT_DIRECTION.ASC ? 'firstArrow' : 'secondArrow'}
                  />
                </th>
              )}
              {header.label === 'onboarding.table.country' && (
                <th className={cs(styles.theader, styles.country)}
                  onClick={() => onSortingChangeCb(header?.value, header?.sortOrder)}
                >
                  {t(header.label)}
                  <DirectionButton
                    fill='black'
                    isDisable={!header?.sortOrder}
                    isReversed={header?.sortOrder === SORT_DIRECTION.ASC || header?.sortOrder === ''}
                    name={header?.sortOrder === '' || header?.sortOrder === SORT_DIRECTION.ASC ? 'firstArrow' : 'secondArrow'}
                  />
                </th>
              )}
              {header.label === 'onboarding.table.actions' && currentAdminUser.role !== USER_ROLES.PRODUCT_ADMIN && (
                <th className={cs(styles.theader)}>
                  {t(header.label)}
                </th>
              )}
            </React.Fragment>
          );
        })}
      </tr>
    );
  };
  const getHeaderForTenantTile = () => {
    return (
      <tr>
        {tenantTileHeaders.map((header, index) => {
          return (
            <React.Fragment key={`${index + 1}`}>
              {header.label === 'onboarding.table.userName' && (
                <th className={cs(styles.theader, styles.nameTenantTile)}
                  onClick={() => onSortingChangeCb(header?.value, header?.sortOrder)}
                >
                  {t(header.label)}
                  <DirectionButton
                    fill='black'
                    isDisable={!header?.sortOrder}
                    isReversed={header?.sortOrder === SORT_DIRECTION.ASC || header?.sortOrder === ''}
                    name={header?.sortOrder === '' || header?.sortOrder === SORT_DIRECTION.ASC ? 'firstArrow' : 'secondArrow'}
                  />
                </th>
              )}
              {header.label === 'onboarding.table.email' && (
                <th className={cs(styles.theader, styles.emailTenantTile)}
                  onClick={() => onSortingChangeCb(header?.value, header?.sortOrder)}
                >
                  {t(header.label)}
                  <DirectionButton
                    fill='black'
                    isDisable={!header?.sortOrder}
                    isReversed={header?.sortOrder === SORT_DIRECTION.ASC || header?.sortOrder === ''}
                    name={header?.sortOrder === '' || header?.sortOrder === SORT_DIRECTION.ASC ? 'firstArrow' : 'secondArrow'}
                  />
                </th>
              )}
              {header.label === 'onboarding.table.actions' && currentAdminUser.role !== USER_ROLES.PRODUCT_ADMIN && (
                <th className={cs(styles.theader)}>
                  {t(header.label)}
                </th>
              )}
            </React.Fragment>
          );
        })}
      </tr>
    );
  };

  const getHeaderForProductTile = () => {
    return (
      <tr>
        {productTileHeaders.map((header, index) => {
          return (
            <React.Fragment key={`${index + 1}`}>
              {header.label === 'onboarding.table.userName' && (
                <th className={cs(styles.theader, styles.name)}
                  onClick={() => onSortingChangeCb(header?.value, header?.sortOrder)}
                >
                  {t(header.label)}
                  <DirectionButton
                    fill='black'
                    isDisable={!header?.sortOrder}
                    isReversed={header?.sortOrder === SORT_DIRECTION.ASC || header?.sortOrder === ''}
                    name={header?.sortOrder === '' || header?.sortOrder === SORT_DIRECTION.ASC ? 'firstArrow' : 'secondArrow'}
                  />
                </th>
              )}
              {header.label === 'onboarding.table.email' && (
                <th className={cs(styles.theader, styles.email)}
                  onClick={() => onSortingChangeCb(header?.value, header?.sortOrder)}
                >
                  {t(header.label)}
                  <DirectionButton
                    fill='black'
                    isDisable={!header?.sortOrder}
                    isReversed={header?.sortOrder === SORT_DIRECTION.ASC || header?.sortOrder === ''}
                    name={header?.sortOrder === '' || header?.sortOrder === SORT_DIRECTION.ASC ? 'firstArrow' : 'secondArrow'}
                  />
                </th>
              )}
              {header.label === 'onboarding.table.product' && currentAdminUser.role !== USER_ROLES.PRODUCT_ADMIN && (
                <th className={cs(styles.theader, styles.product)}
                  onClick={() => onSortingChangeCb(header?.value, header?.sortOrder)}
                >
                  {t(header.label)}
                  <DirectionButton
                    fill='black'
                    isDisable={!header?.sortOrder}
                    isReversed={header?.sortOrder === SORT_DIRECTION.ASC || header?.sortOrder === ''}
                    name={header?.sortOrder === '' || header?.sortOrder === SORT_DIRECTION.ASC ? 'firstArrow' : 'secondArrow'}
                  />
                </th>
              )}
              {header.label === 'onboarding.table.country' && (
                <th className={cs(styles.theader, styles.country)}
                  onClick={() => onSortingChangeCb(header?.value, header?.sortOrder)}
                >
                  {t(header.label)}
                  <DirectionButton
                    fill='black'
                    isDisable={!header?.sortOrder}
                    isReversed={header?.sortOrder === SORT_DIRECTION.ASC || header?.sortOrder === ''}
                    name={header?.sortOrder === '' || header?.sortOrder === SORT_DIRECTION.ASC ? 'firstArrow' : 'secondArrow'}
                  />
                </th>
              )}
              {header.label === 'onboarding.table.actions' && currentAdminUser.role !== USER_ROLES.PRODUCT_ADMIN && (
                <th className={cs(styles.theader)}>
                  {t(header.label)}
                </th>
              )}
            </React.Fragment>
          );
        })}
      </tr>
    );
  };
  const getBodyForAllTile = (user: DTO.AdminUser) => {
    return (
      <tr key={user.id}>
        <td className={cs(styles.tcell, styles.name)}>{user.displayName}</td>
        {currentAdminUser.role !== USER_ROLES.PRODUCT_ADMIN && (
          <td className={cs(styles.tcell, styles.roleAllTile)}>{user.roleName}</td>
        )}
        <td className={cs(styles.tcell, styles.emailAllTile)}>{user.email}</td>
        {currentAdminUser.role !== USER_ROLES.PRODUCT_ADMIN && (
          <td className={cs(styles.tcell, styles.product)}>
            {+user.roleId === UserRoles.superAdmin ? '-' : user.productCode}
          </td>
        )}
        <td className={cs(styles.tcell, styles.country)}>
          {+user.roleId === UserRoles.superAdmin ? '-' : user.countryCode}
        </td>
        {currentAdminUser.role === USER_ROLES.PRODUCT_ADMIN
          ? null
          : getActionItems(user)}
      </tr>
    );
  };
  const getBodyForTenantTile = (user: DTO.AdminUser) => {
    return (
      <tr key={user.id}>
        <td className={cs(styles.tcell, styles.nameTenantTile)}>
          {user.displayName}
        </td>
        <td className={cs(styles.tcell, styles.emailTenantTile)}>
          {user.email}
        </td>
        {currentAdminUser.role === USER_ROLES.PRODUCT_ADMIN
          ? null
          : getActionItems(user)}
      </tr>
    );
  };
  const getBodyForProductTile = (user: DTO.AdminUser) => {
    return (
      <tr key={user.id}>
        <td className={cs(styles.tcell, styles.name)}>{user.displayName}</td>
        <td className={cs(styles.tcell, styles.email)}>{user.email}</td>
        <td className={cs(styles.tcell, styles.product)}>
          {user.productCode}
        </td>
        <td className={cs(styles.tcell, styles.country)}>{user.countryCode}</td>
        {currentAdminUser.role === USER_ROLES.PRODUCT_ADMIN
          ? null
          : getActionItems(user)}
      </tr>
    );
  };
  const getUsersDataForBodytemplate = (user: DTO.AdminUser) => {
    switch (currentTile) {
      case 0: {
        return getBodyForAllTile(user);
      }
      case UserRoles.superAdmin: {
        return getBodyForTenantTile(user);
      }
      default: {
        return getBodyForProductTile(user);
      }
    }
  };
  return (
    <Table className={styles.table} isBorderless={false}>
      <thead>
        {currentTile === 0 && getHeaderForAllTile()}
        {currentTile === UserRoles.superAdmin && getHeaderForTenantTile()}
        {(currentTile === UserRoles.productAdmin || currentTile === UserRoles.whitelistedInternalUser) && getHeaderForProductTile()}
      </thead>
      <tbody>
        {!users.length && isLoaded && (
          <tr>
            <td className={styles.noFound} colSpan={5}>
              {t('onboarding.table.noFound')}
            </td>
          </tr>
        )}
        {users.map((user) => {
          return getUsersDataForBodytemplate(user);
        })}

      </tbody>
    </Table>
  );
};
