import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { noop } from 'shared/utils';
import { useOnMount } from 'shared/hooks';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { Status } from './Confirmation.helpers';
import styles from './Confirmation.module.css';
import WarningPng from '../../assets/images/WarningCircle.png';

interface Props {
  title: string;
  message: string;
  variant?: 'primary' | 'danger';
  note?: string;
  reactivate?: string;
  onResolve?: () => void;
  onReject?: () => void;
}

export function Confirmation({
  title,
  message,
  note,
  reactivate,
  variant = 'primary',
  onResolve = noop,
  onReject = noop
}: Props): JSX.Element {
  const [show, setShow] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<Status>(null);
  const { t } = useTranslation();

  const callbackMap: Record<Status, () => void> = React.useMemo(
    () => ({
      [Status.resolved]: onResolve,
      [Status.rejected]: onReject
    }),
    [onResolve, onReject]
  );

  const handleOnExited = React.useCallback((): void => {
    callbackMap[status]();
  }, [status, callbackMap]);

  const handleResolve = React.useCallback(() => {
    setShow(false);
    setStatus(Status.resolved);
  }, []);

  const handleReject = React.useCallback(() => {
    setShow(false);
    setStatus(Status.rejected);
  }, []);

  useOnMount(React.useCallback((): void => setShow(true), []));

  return (
    <Modal
      centered={true}
      className={styles.modal}
      show={show}
      onExited={handleOnExited}
    >
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>
        <span className={styles.warningImage}>
            <img alt='ETL' src={WarningPng} />
          </span>
          <span>{title}</span></Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        {/* <div className={styles.iconCircle}>
          <Icon
            className={styles.icon}
            name='exclamation-mark-special'
            size={52}
          />
        </div> */}
        <div className={styles.text}>
          <p className={styles.message}>{message}</p>
          {note ? <p className={styles.note}>{note}</p> : null}
        </div>
      </Modal.Body>
      {reactivate !== 'reactivate' ? (
        <Modal.Footer className={styles.footer}>
          <Button
           className={styles.cancelAction}
           size='sm'
           variant='outline-secondary'
           onClick={handleReject}
         >
            {t('actions.cancel')}
          </Button>
          <Button
            className={styles.continueAction}
            size='sm'
            variant={variant}
            onClick={handleResolve}
          >
            {t('actions.continue')}
          </Button>
        </Modal.Footer>
      ) : (
        <Modal.Footer className={styles.footer}>
          <Button
            className={styles.cancelAction}
            size='sm'
            variant='outline-secondary'
            onClick={handleReject}
          >
            {t('actions.cancel')}
          </Button>
          <Button
            className={styles.continueAction}
            size='sm'
            variant={variant}
            onClick={handleResolve}
          >
            {t('actions.continue')}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
