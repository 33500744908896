import React from 'react';
import { components } from 'react-select';
import { InputProps } from 'react-select/src/components/Input';

import style from '../Select.module.css';

export const Input = (
  placeholderPrefix?: JSX.Element
): ((props: InputProps) => JSX.Element) => {
  const input = (props: InputProps): JSX.Element => (
    <div className={style.placeholder}>
      {placeholderPrefix}
      <span>
        <components.Input {...props} />
      </span>
    </div>
  );
  return input;
};
