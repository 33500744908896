export const SET_PRODUCT_FROM_PATH = '@product/SET_PRODUCT_FROM_PATH';

export type ProductState = Readonly<{
  hasSuccessData: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  code: string;
  title: string;
  appUrl: string;
  plans: DTO.SubmissionPlan[];
  countries: DTO.ProductCountry[];
  memberFirms: DTO.MemberFirm[];
  fieldSettings: DTO.FieldSettings[];
}>;

export type SetProductAction = Store.PaylodedAction<DTO.Product>;
export type SetProductAction$ = Store.PaylodedAction<Promise<DTO.Product>>;

export type ProductActionTypes = SetProductAction;
