import React from 'react';
import cs from 'classnames';
import { IndicatorProps } from 'react-select/src/components/indicators';

import { Nothing } from 'components/Nothing/Nothing';
import { Icon } from 'components/Icon/Icon';
import styles from './DropdownIndicator.module.css';

export function DropdownIndicator<T = AP.SelectOption>({
  isFocused,
  isMulti
}: IndicatorProps<T, boolean>): JSX.Element {
  return isMulti ? (
    <Nothing />
  ) : (
    <div className={styles.container}>
      <Icon
        className={cs(styles.icon, { [styles.iconFocused]: isFocused })}
        name={isFocused ? 'smallchevron-up' : 'smallchevron-down'}
        size={12}
      />
    </div>
  );
}
