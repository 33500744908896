import { PRODUCT_ROLE } from 'shared/constants/product.consts';

export const adminEn = {
  first: {
    selectLanguage: 'Please select your preferred language',
    selectDateFormat: 'Please select your preferred date format',
    selectNumberFormat: 'Please select your preferred number format',
    changePreference:
      'You may change your preferences any time in your user profile once logged into the application.'
  },
tabs:{
  submission:'Submissions',
  admin: 'Admin'
},
  submission: {
    pending: 'Pending Submissions',
    processed: 'Processed Submissions'
  },
  grid: {
    id: 'ID',
    submissionDetails: 'Submission Details',
    country: 'Country',
    companyName: 'Company Name',
    subscriberName: 'Subscriber Name',
    subscriberEmail: 'Subscriber Email',
    submissionDate: 'Submission Date',
    noData: 'There are no registrations to display at this time',
    noResults: 'We could not find any matches',
    aruser: 'Add/Remove User',
    nooptions: 'No options',
    loading: 'Loading',
    editCompanyName: 'Edit Company Name',
    confirmEdit: 'Confirm Edit',
    cancelEdit: 'Cancel Edit',
    hiddencontent: '<Hidden Content>',
    adminEmail: "Email",
    adminName: 'User Name',
    retentionYear: 'Retention Years',
    tenantRetentionYearWarning: 'The Retention year has already been set by the Product Admin',
    retentionValidationMsg: 'Only natural numbers are allowed. Zeroes, decimal values, alphabets etc., are not allowed',
    lastEdited: 'Last Edited'
  },
  plans: {
    names: {
      BlackScholes: 'Black-Scholes',
      MonteCarlo: 'Monte Carlo',
      Binomial: 'Binomial',
      TSRMonitoring: 'TSR Monitoring'
    },
    statuses: {
      approved: 'Approved',
      rejected: 'Rejected',
      pending: 'Pending',
      expired: 'Expired',
      active: 'Active',
      inactive: 'Inactive',
      cancelled: 'Cancelled',
      pendingreactivation: 'Pending Re-Activation',
      pendingrenewal: 'Pending Renewal',
      unsubscribed: 'Unsubscribed',
      pendingactivation: 'Pending Activation'
    },
    plan: 'Plan',
    licenses: 'Licenses',
    alicenses: 'Available Licenses',
    plicenses: 'Purchased Licenses',
    status: 'Status',
    reviewedBy: 'Reviewed By',
    date: 'Approval/Rejection Date',
    duration: 'Duration',
    reject: 'Reject',
    approve: 'Approve',
    rejectAll: 'Reject All',
    approveAll: 'Approve All',
    checkPlans: 'Check all plans to submit',
    submit: 'Submit',
    check: 'Check all plans to submit',
    noEndDate: 'No End Date',
    month: 'Month',
    months: 'Months',
    role: 'Role',
    updateSubscription: "Manage Subscription",
    select: 'Select Plan',
    selectPlanTo: 'Select Plan(s) to',
    approveMessage: 'Are you sure you wish to Approve this Renewal?',
    rejectMesaage: 'Are you sure you wish to Reject this Renewal?',
    changePlanMessage: 'Are you sure you wish to make changes to the selected plans?',
    renewal: 'Renewal',
    reactivation: 'Re-activation',
    activate: 'Activate',
    confirmation: 'Confirmation'
  },
  role: {
    [PRODUCT_ROLE.SUPER_ADMIN]: 'Tenant Admin',
    [PRODUCT_ROLE.COUNTRY_ADMIN]: 'Country Admin',
    [PRODUCT_ROLE.MEMBER_FIRM_ADMIN]: 'Member Firm Admin',
    [PRODUCT_ROLE.PRODUCT_ADMIN]: 'Product Admin',
    [PRODUCT_ROLE.PRODUCT_GROUP_ADMIN]: 'Product Group Admin',
    [PRODUCT_ROLE.WHITELISTED_INTERNAL_USER]: 'Whitelisted Internal User'
  },
  accessDenied: {
    title: 'No access',
    message: 'You do not have permission to access this page ',
    contactAdmin: 'Please contact ',
    support: 'Support'
  },
  month: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    ma: 'May',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec'
  },
  sidebar: {
    activeText:
      'You can always remove access from this subscription for added users',
    disableText: 'Add/Remove users is disabled for this product'
  },
  adduser: {
    add: 'ADD',
    addUsers: 'ADD USERS',
    button: 'ADD USER',
    buttonAddUser: 'Add User',
    buttonMul: 'ADD MULTIPLE USERS',
    cancel: 'CANCEL',
    deloittemail: 'cannot be Deloitte email',
    downloadtemplate: 'Download Template',
    // notes: 'Please note this will take effect within 24 hours.',
    nouser: 'There are no additional users in this Subscription for now',
    primary: "Primary user",
    remove: 'Are you sure you would like to',
    removeaccess: 'access to this Subscription for',
    removeb: 'remove',
    submission: 'User Management',
    multipleUser: 'Add Multiple Users',
    dragandDrop: 'Drag and drop a file here or',
    maxFileSize: 'Maximum 10 MB and .xlsx file only',
    maxFileSizeError: 'File size exceeds',
    unSupportedFile: 'Unsupported file type',
    somethingWentWrong: 'Something went wrong',
    fileUploadFailed: 'File upload failed',
    browse: 'Browse',
    downloadTemplate: 'DOWNLOAD TEMPLATE',
    guidance: 'Guidance',
    guidanceText: 'You can always remove access from this subscription for added users',    
    replaceText: {
      primaryUser: "Are you sure you want to replace existing primary user",
      with: "with",
    }
  },
  onboarding: {
    manage: 'Manage Admins',
    addAdmin: 'Add Admin',
    adminTypes: {
      all: 'All',
      tenant: 'Tenant',
      country: 'Country',
      product: 'Product',
      memberFirm: 'Member Firm',
      productGroup: 'Product Group',
      whitelistedInternalUser: "Whitelisted Internal User"
    },
    
    adminCreation: {
      role: 'Role',
      adminRole: 'Admin Role',
      email: 'Email',
      comments: 'Comments',
      exists: 'A user with this email already exists.',
      country: 'A user with this email already exists for -',
      invalidMemberFirmUser: 'The user needs to be from the same member firm/country',
      application: 'Application',
      memberFirm: 'Member Firm',
      addAdmin: 'Add Admin',
      cancel: 'Cancel',
      item: 'Select Item',
      selectEmail: 'Enter Email',
      selectApplication: 'Choose Application',
      selectRole: 'Select Role',
      info: 'User Information',
      toastText: {
        1: 'Tenant Admin has been successfully added',
        2: 'Product Admin has been successfully added',
        3: 'Product Admin has been successfully added',
        7: 'Whitelisted Internal User has been successfully added',
        deleteAdminSuccess: 'Admin deleted successfully',
        deleteWhitelisteUserSuccess: 'Whitelisted Internal User deleted successfully',
        deleteAdminErrorUnautorized: 'Unauthorized delete admin action'
      },
      close: 'CLOSE',
      roles: {
        TenantAdmin: 'Tenant Admin',
        Productgroupadmin: 'Product Group Admin',
        ProductAdmin: 'Product Admin',
        Memberfirmadmin: 'Member Firm Admin',
        Countryadmin: 'Country Admin',
        User: 'User',
        WhitelistedInternalUser: 'Whitelisted Internal User'
      },
    },
    retentionSaveSuccessMsg: 'Retention Configurations are saved successfully',

  }
};

