import React from 'react';
import { useSelector } from 'react-redux';

import { GLOBAL_PRIVACY_STATEMENT } from 'shared/constants/common.consts';
import { selectProductCode } from 'store/product';
import styles from './Group.module.css';

interface Props {
  title: string;
  note?: string;
  link?: string;
  country?: string;
}

export default function Group({
  title,
  note,
  children,
  link,
  country
}: React.PropsWithChildren<Props>): JSX.Element {
  const Code = useSelector(selectProductCode);

  let privacyLinks;
  let privacyHrefCountryCode = '';
  const getCountryPrivacyLink = () => {
    if(country.toLocaleLowerCase() === "sgp" || country.toLocaleLowerCase() === "mys"){
      privacyLinks = `https://www2.deloitte.com/${country.substring(0, 2)}/en/footerlinks1/privacy.html?icid=bottom_privacy`;
    } else if(country.toLocaleLowerCase() === "gbr"){ 
      privacyLinks =  `https://www2.deloitte.com/uk/en/legal/privacy.html`;
    } else {
      privacyLinks = `https://www2.deloitte.com/${country.substring(0, 2)}/en/legal/privacy.html`;
    }
    return privacyLinks;
  };

  if(country){
    const globalPrivacyData = GLOBAL_PRIVACY_STATEMENT.find((item) => item.countryCode === country);
    const linkByProduct = Code === "dra" ? globalPrivacyData?.privacyStatement : getCountryPrivacyLink();
    privacyHrefCountryCode = country !== null ? linkByProduct : '';
  }

  return (
    <div className={styles.group}>
      {title && (
        <div className={styles.heading}>
          <h2 className={styles.title}>{title}</h2>
          {link && (
            <strong className={styles.link}>
              <a
                href = { privacyHrefCountryCode }
                rel='noopener noreferrer'
                target='_blank'
              >
                {link}
              </a>
            </strong>
          )}
          {note && <p className={styles.note}>{note}</p>}
        </div>
      )}
      <div>{children}</div>
    </div>
  );
}
