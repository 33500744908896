import React from 'react';
import { useTranslation } from 'react-i18next';

import noGridDataImage from 'assets/images/noGridData.svg';
import noGridResultsImage from 'assets/images/noGridResults.svg';
import styles from './NoGridData.module.css';

interface Props {
  areFilterEnabled?: boolean;
}

export const NoGridData: React.FC<Props> = ({ areFilterEnabled }) => {
  const { t } = useTranslation();

  const { image, text } = React.useMemo(
    () => ({
      image: areFilterEnabled ? noGridResultsImage : noGridDataImage,
      text: areFilterEnabled
        ? t('admin.grid.noResults')
        : t('admin.grid.noData')
    }),
    [t, areFilterEnabled]
  );

  return (
    <div className={styles.container}>
      <img alt={text} src={image} />
      <p className={styles.text}>{text}</p>
    </div>
  );
};
