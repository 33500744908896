import React from 'react';
import cs from 'classnames';

import { validateInputByInteger } from 'shared/utils';
import styles from './Counter.module.css';

interface Props {
  name: string;
  onChange: (val: number, name: string) => void;

  value?: number;
  disabled?: boolean;
  disabledInput?: boolean;
  minValue?: number;
  maxValue?: number;
}

export const Counter: React.FC<Props> = ({
  name,
  onChange,

  value = 0,
  disabled = false,
  disabledInput = true,
  minValue = Number.MIN_SAFE_INTEGER,
  maxValue = Number.MAX_SAFE_INTEGER
}) => {
  // const incrementCb = React.useCallback(() => onChange(value + 1, name), [
  //   value,
  //   onChange,
  //   name
  // ]);
  // const decrementCb = React.useCallback(() => onChange(value - 1, name), [
  //   value,
  //   onChange,
  //   name
  // ]);  
  const min=1;
  const max=999; 
  const changeInputValueCb = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(
        Math.max(min, Math.min(max, Number(e.target.value.slice(0,4)))) ,
        name
      );
    },
    [onChange, name]
  );
  return (
    <div className={cs(styles.container, disabled && styles.disabled)}>
      {/* <button
        className={styles.countButton}
        disabled={value === minValue}
        type='button'
        onClick={decrementCb}
      >
        -
      </button> */}
      <input
        className={styles.input} 
        type='number'       
        value={(value>999)?styles.customDisabled:value}
        onChange={changeInputValueCb}
      />
      {/* <button
        className={styles.countButton}
        disabled={value === maxValue}
        type='button'
        onClick={incrementCb}
      >
        +
      </button> */}
    </div>
  );
};
