import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast } from 'react-bootstrap';

import { removeFromLocalStorage } from 'shared/utils/storage.utils';
import { changeLanguage, reselectLocaleCode } from 'store/localize';
import {
  reselectUser,
  selectAdminFilters,
  setCurrentUserAdminFilters,
  selectAppliedAdminFilters
} from 'store/auth';
import { Button, Confirmation, Dialog } from 'components';
import { useOnMount } from 'shared/hooks';
import { SidePanel } from 'features/SidePanel/SidePanel';
import { apiService } from 'shared/services';
import { errorException } from 'shared/utils/AppInsights';
import styles from './OnboardingPage.module.css';
import { selectProductCode } from '../../../store/product/product.selectors';
import { Pagination } from '../../../components/Pagination/Pagination';
import { OnboardingFilters, USER_ROLES, UserRoles } from './OnboardingFilters/OnboardingFilters';
import {
  selectAdminUsersPagination,
  selectHadCreatedAdmin,
  selectHeaderForAllTile,
  adminSorter,
  selectHeaderForTenantTile,
  selectHeaderForProductTile,
} from '../../../store/onboarding/onboarding.selectors';
import {
  getUsersForOnboarding,
  resedCreatedAdmin,
  updateColumnSorting,
} from '../../../store/onboarding/onboarding.actions';
import { OnboardingPageTable } from './OnboardingPageTable/OnboardingPageTable';
import { ThemedButton } from '../../../components/ThemedButton/ThemedButton';
import { DeleteAdminModal } from './DeleteAdminModal/DeleteAdminModal';
import { selectIsFirstAdminFiltersInit } from '../../../store/auth/auth.selectors';
import { ConfigureRetentionDialog } from './dialogs/ConfigureRetentionDialog/ConfigureRetentionDialog';
import saveRetentionSvg from '../../../assets/images/save-retention.svg';
import information from '../../../assets/images/Information.png';
import { UserProductsSelect } from '../UserProductsSelect/UserProductsSelect';
import { OnboardingCreation } from '../OnboardingCreation/OnboardingCreation';
import { FilterBadge } from '../SubmissionsFilters/FilterBadge/FilterBadge';

export const OnboardingPage: FC = () => {
  const dispatch = useDispatch();
  const productCode = useSelector(selectProductCode);
  const userPagination = useSelector(selectAdminUsersPagination);
  const [pageSize, setPageSize] = useState(25);
  const user = useSelector(reselectUser);
  const [pageNumber, setPageNumber] = useState(1);
  const hadCreatedAdmin = useSelector(selectHadCreatedAdmin);
  const userFilters = useSelector(selectAdminFilters);
  const selectedAdminFilters = useSelector(selectAppliedAdminFilters);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [adminForDeletion, setAdminForDeletion] = useState<DTO.AdminUser>(null);
  const [isAdminDeleted, setAdminDeletedFlag] = useState(false);
  const [errorOnDeleteAdmin, setDeleteAdminErrorFlag] = useState(false);
  const [isAdminCreated, setAdminCreateFlag] = useState(false);
  const allTileHeaders = useSelector(selectHeaderForAllTile);
  const tenantTileHeaders = useSelector(selectHeaderForTenantTile);
  const productTileHeaders = useSelector(selectHeaderForProductTile);
  const sorter = useSelector(adminSorter);
  const filtersRef = useRef();
  const showFilters: DTO.FilterFields[] = [userFilters.role !== UserRoles.whitelistedInternalUser ? 'Admin Name' : 'User Name', userFilters.role !== UserRoles.whitelistedInternalUser ? 'Admin Email' : 'Email', userFilters.role !== UserRoles.superAdmin && 'Country'];
  const { t } = useTranslation();
  const [hasRequestedUsers, setHasRequestedUsers] = useState(false);
  const isFirstInit = useSelector(selectIsFirstAdminFiltersInit);
  const storeAdminRole = JSON.parse(localStorage.getItem('adminRole'));
  const [isOpenConfigureRetentionDialog, setOpenConfigureRetentionDialog] = React.useState<
    boolean
  >(false);
  const [isAddAdminDialogOpen, setAddAdminDialogOpen] = React.useState(false);
  const [confirmCancel, setconfirmCancel] = useState(false);
  const [saveSuccess, setsaveSuccess] = useState('');
  const [showDialog, setShowDialogFlag] = useState(false);
  const [userDeleted, setUserDeleted] = useState<DTO.AdminUser>(null);
  const [dialogMessage, setDialogMessage] = useState('');

  // remove cache for highlighted subscription item
  // when Admin tab get clicked
  useEffect(() => {
    removeFromLocalStorage('recentUserEmail');
  }, []);
  useOnMount(
    useCallback((): void => {
      if (user.profileSettings) {
        localStorage.setItem('storeLanguage', JSON.stringify(user.profileSettings.language));
        dispatch(changeLanguage(user.profileSettings.language));
      }
    }, [dispatch, user.profileSettings])
  );
  useEffect(() => {
    if (hadCreatedAdmin) {
      setIsToastOpen(true);
      setAdminCreateFlag(true);
      dispatch(resedCreatedAdmin());
    } else if (isAdminDeleted || errorOnDeleteAdmin) {
      setIsToastOpen(true);
      setAdminCreateFlag(false);
    }
    else if (saveSuccess) {
      setIsToastOpen(true);
      setAdminCreateFlag(false);
    }

  }, [dispatch, hadCreatedAdmin, isAdminDeleted, errorOnDeleteAdmin, saveSuccess]);

  const openDeletePopup = (userData: DTO.AdminUser) => {
    setAdminForDeletion(userData);
    setUserDeleted(userData);
  };

  const setToastMessage = () => {
    let message = '';
    console.log('hadCreatedAdmin: ', hadCreatedAdmin);
    if (isAdminCreated) {
      message = t(`admin.onboarding.adminCreation.toastText.${storeAdminRole}`);
    } else if (isAdminDeleted) {
      if (parseInt(userDeleted.roleId, 10) === 7) {
        message = t(`admin.onboarding.adminCreation.toastText.deleteWhitelisteUserSuccess`);
      }
      else {
        message = t(`admin.onboarding.adminCreation.toastText.deleteAdminSuccess`);
      }
    } else if (errorOnDeleteAdmin) {
      message = t(`admin.onboarding.adminCreation.toastText.deleteAdminErrorUnautorized`);
    }
    else if (saveSuccess) {
      message = t(`admin.onboarding.retentionSaveSuccessMsg`);
    }
    // AppInsights Error Exception
    errorException(`Error: ${message}, File reference: OnboardingPage.tsx in setToastMessage function`);

    return message;
  };

  const handleHideDeletionPopup = () => setAdminForDeletion(null);

  const requestForUsers = useCallback(() => {
    const { role } = user;
    if (!productCode) {
      return;
    }
    const countryIds =
      userFilters.countryCode && userFilters.countryCode.length
        ? userFilters.countryCode.map(country => country.id)
        : [];

    const emails =
      userFilters.emails && userFilters.emails.length
        ? userFilters.emails.map(item => item.email)
        : [];

    const displayName =
      userFilters.displayName && userFilters.displayName.length
        ? userFilters.displayName.map(item => item.fullName)
        : null;

    dispatch(
      getUsersForOnboarding(
        productCode,
        pageSize || 25,
        pageNumber,
        {
          emails,
          displayName,
          countryIds,
          role: userFilters.role
        },
        sorter,
        hasRequestedUsers,
        role
      )
    );
    if (!hasRequestedUsers) setHasRequestedUsers(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber, pageSize, productCode, sorter, userFilters]);

  const handleDelete = (email: string) => {
    apiService.deleteAdmin(email, productCode).then(() => {
      requestForUsers();
      setAdminDeletedFlag(true);
      setAdminCreateFlag(false);
      setsaveSuccess('');
    }).catch((ex) => {
      console.log('status ok: ', ex.ok, ', statusText: ', ex.StatusText);
      setAdminDeletedFlag(false);
      setDeleteAdminErrorFlag(true);
      // AppInsights Error Exception
      errorException(`Error: ${ex}, File reference: OnboardingPage.tsx in handleDelete function`);

    }).finally(() => {
      setIsToastOpen(true);
      setAdminForDeletion(null);
    });
  };

  const handleCloseConfigureRetentionDialog = React.useCallback((): void => {
    setconfirmCancel(true);
    setOpenConfigureRetentionDialog(false);
  }, []);

  const handleCloseAdminDialog = () => {
    setAddAdminDialogOpen(false);
    setAdminDeletedFlag(false);
    setsaveSuccess('');
    setIsToastOpen(false);
    setAdminCreateFlag(true);
    setDeleteAdminErrorFlag(false);
    requestForUsers();

  };

  const handleConfirmSaveRetention = React.useCallback((payload: DTO.UpdateRetentionDto): Promise<void> => {
    setOpenConfigureRetentionDialog(false);
    apiService.updateRetentionDetails(payload).then((res: any) => {
      if (res) {
        setsaveSuccess(res);
        setAdminDeletedFlag(false);
        setAdminCreateFlag(false);
        setIsToastOpen(true);
      } else {
        setShowDialogFlag(true);
      }
      console.log(res);
    })
      .catch((err) => {
        console.log(err);
        // AppInsights Error Exception
        errorException(`Error: ${err}, File reference: OnboardingPage.tsx in handleConfirmSaveRetention function`);
      });
    return null;
  }, []);

  const handleOnClose = React.useCallback((): void => {
    setShowDialogFlag(false);
    setAdminDeletedFlag(false);
    setsaveSuccess('');
  }, [setShowDialogFlag]);

  const [actions] = React.useState<AP.DialogAction[]>([
    {
      label: t('actions.ok'),
      variant: 'primary',
      onClick: handleOnClose
    }
  ]);

  useEffect(() => {
    requestForUsers();
  }, [
    dispatch,
    pageNumber,
    pageSize,
    productCode,
    userFilters,
    sorter,
    requestForUsers
  ]);

  const handleSortOnChange = useCallback(
    (sortBy: string, sortDirection: AP.SortDirection): void => {
      dispatch(
        updateColumnSorting(sortBy, sortDirection, userFilters.role || 0)
      );
    },
    [dispatch, userFilters.role]
  );

  const setFilters = useCallback(
    (filters: DTO.UserFilters) => {
      dispatch(setCurrentUserAdminFilters(filters));
    },
    [dispatch]
  );

  const handleOnResolve = React.useCallback(() => {
    setconfirmCancel(false);
  }, [setconfirmCancel]);

  const handleOnReject = React.useCallback((): void => {
    setconfirmCancel(false);
    setOpenConfigureRetentionDialog(true);
  }, []);

  const removeFilter = (key: any, filterToRemove: any) => {
    const tempFilter = userFilters[key as keyof typeof userFilters];

    setFilters({
      ...userFilters,
      [key]: (tempFilter as any).filter((item: any) => !Object.values(item).includes(filterToRemove))
    });
  };

  const appliedFiltersValue = (filterArr: any) => {
    if (filterArr.some((item: any) => item.value.length > 0)) {
      return (
        <FilterBadge
          filterArray={filterArr}
          removeFilter={removeFilter}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (
      !user.profileSettings ||
      !user.profileSettings.adminFilter ||
      !isFirstInit
    )
      return;
    const { adminFilter } = user.profileSettings;

    const emails = adminFilter.emails
      ? adminFilter.emails.map(email => ({ email }))
      : [];
    const rolesMap: Record<DTO.UserRole, number> = {
      superAdmin: 1,
      productGroupAdmin: 2,
      productAdmin: 3,
      memberFirmAdmin: 4,
      countryAdmin: 5,
      user: 6,
      whitelistedInternalUser: 7
    };

    const filters = {
      countryCode: adminFilter.countries,
      emails,
      role: adminFilter.role ? rolesMap[adminFilter.role] : 0,
      displayName: adminFilter.displayName
    };
    setFilters(filters);
  }, [isFirstInit, setFilters, user]);
  return (
    <main className={styles.contentWrap}>
      <SidePanel />
      <div className={styles.contentCard}>
        {/* <span>{t('app.name')}</span> */}
        <div className={styles.cardHeader}>
          <h2 className={styles.cardHeading}>{user.role !== USER_ROLES.SUPER_ADMIN ? t('onboarding.productManage') : t('onboarding.manage') }</h2>
          <div className={styles.cardHeadingBtnGroup}>
            {user.role !== USER_ROLES.SUPER_ADMIN && <UserProductsSelect />}
            <Button
              className={styles.configRetention}
              thin={true}
              onClick={() => setOpenConfigureRetentionDialog(true)}
            >
              {t('onboarding.configureRetention')}
            </Button>
            <ThemedButton
              filled={true}
              // link={`/admin/onboarding/add/${productCode}`}
              onClick={() => setAddAdminDialogOpen(true)}
            >
              {user.role !== USER_ROLES.SUPER_ADMIN ? t('onboarding.addProductAdmin') : t('onboarding.addAdmin')}
            </ThemedButton>
          </div>
        </div>
        
        <div className={styles.tableWrap}>
          <OnboardingFilters
            filtersRef={filtersRef}
            setUserFilters={setFilters}
            showFilters={showFilters.filter(Boolean)}
            sorter={sorter}
            userFilters={userFilters}
            usersCount={userPagination.usersCount}
          />
          <div ref={filtersRef} />
          {selectedAdminFilters ? (
            appliedFiltersValue(selectedAdminFilters)
          ) :
            null}
          <OnboardingPageTable
            allTileHeaders={allTileHeaders}
            currentTile={userFilters.role || 0}
            direction={sorter.fields[0].sortDirection}
            handleDelete={openDeletePopup}
            handleSortOnChange={handleSortOnChange}
            productTileHeaders={productTileHeaders}
            sortBy={sorter.fields[0].sortBy}
            sorter={sorter}
            tenantTileHeaders={tenantTileHeaders}
            users={userPagination.items}
          />
          {userPagination.items.length ? (
            <Pagination
              className={styles.pagination}
              currentPage={pageNumber}
              pageSize={pageSize}
              reversedMenuList={true}
              total={userPagination.allItemsCount}
              withPageNumberInput={true}
              withPageSizeSelector={true}
              onPageChange={page => {
                setPageNumber(page);
              }}
              onPageSizeChange={size => {
                setPageSize(size);
              }}
            />
          ) : null}
          <DeleteAdminModal
            handleDelete={handleDelete}
            show={!!adminForDeletion}
            user={adminForDeletion}
            onHide={handleHideDeletionPopup}
          />
        </div>
      </div>
      <Toast
        animation={true}
        autohide={true}
        className={styles.toast}
        delay={5000}
        show={isToastOpen}
        onClose={() => setIsToastOpen(false)}
      >
        <Toast.Body className={styles.toastBody}>
          <img alt='information' className={styles.warningimages} src={information} />
          <div className={styles.toastText}>
            {(
              setToastMessage()
            )}
          </div>
          <svg
            className={styles.crossSvg}
            fill='none'
            height='12'
            viewBox='0 0 12 12'
            width='12'
            xmlns='http://www.w3.org/2000/svg'
            onClick={() => setIsToastOpen(false)}
          >
            <path
              clipRule='evenodd'
              d='M0.0885912 0.215695L0.146447 0.146447C0.320013 -0.0271197 0.589437 -0.0464049 0.784306 0.0885912L0.853553 0.146447L6 5.293L11.1464 0.146447C11.32 -0.0271197 11.5894 -0.0464049 11.7843 0.0885912L11.8536 0.146447C12.0271 0.320013 12.0464 0.589437 11.9114 0.784306L11.8536 0.853553L6.707 6L11.8536 11.1464C12.0271 11.32 12.0464 11.5894 11.9114 11.7843L11.8536 11.8536C11.68 12.0271 11.4106 12.0464 11.2157 11.9114L11.1464 11.8536L6 6.707L0.853553 11.8536C0.679987 12.0271 0.410563 12.0464 0.215695 11.9114L0.146447 11.8536C-0.0271197 11.68 -0.0464049 11.4106 0.0885912 11.2157L0.146447 11.1464L5.293 6L0.146447 0.853553C-0.0271197 0.679987 -0.0464049 0.410563 0.0885912 0.215695Z'
              fill='black'
              fillRule='evenodd'
            />
          </svg>
        </Toast.Body>
      </Toast>
      {isOpenConfigureRetentionDialog && (
        <ConfigureRetentionDialog
          setconfirmCancel={setconfirmCancel}
          onCancel={handleCloseConfigureRetentionDialog}
          onSubmit={handleConfirmSaveRetention}
        />
      )}
      {
        isAddAdminDialogOpen && (
          <OnboardingCreation onCancel={handleCloseAdminDialog} />
        )
      }
      {confirmCancel ? (
        <Confirmation
          message={t('onboarding.cancelRetentionPopup.message')}
          title={t('onboarding.cancelRetentionPopup.title')}
          onReject={handleOnReject}
          onResolve={handleOnResolve}
        />
      ) : null}
      <Dialog
        actions={actions}
        className='noBorderAndPadding'
        show={showDialog}
        title={t('dialogs.retentionFailureMsg.title')}
        onClose={handleOnClose}
        onClosed={() => console.log('onClosed')}
        onHide={handleOnClose}
      >
        <span className={styles.warningText}>
          {t('dialogs.retentionFailureMsg.message')}
        </span>
      </Dialog>
    </main>
  );
};
