import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, Icon } from 'components';
import styles from './RegistrationCommentDialog.module.css';

interface Props {
  submission: DTO.SubmissionRecord;
  onClose?: () => void;
  DATE_FORMAT?: string;
}

export function RegistrationCommentDialog({
  submission: { comment, plans },
  onClose
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [show, setShow] = React.useState<boolean>(true);

  const handleOnClose = React.useCallback((): void => {
    setShow(false);
  }, [setShow]);

  const [actions] = React.useState<AP.DialogAction[]>([
    {
      label: t('actions.ok'),
      variant: 'primary',
      onClick: handleOnClose
    }
  ]);

  return (
    <Dialog
      actions={actions}
      show={show}
      title={t('dialogs.registrationComment')}
      onClose={handleOnClose}
      onClosed={onClose}
      onHide={handleOnClose}
    >
      <div className={styles.container}>
        <Icon className={styles.icon} name='speech-2-special' size={52} />
        <h3>{plans[0].performedBy}</h3>
        <p>{comment}</p>
      </div>
    </Dialog>
  );
}
