import React, { lazy, useMemo, useCallback, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  selectHasUser,
  selectIsInternal,
  selectSubscriptionProductCode,
  selectSubscriptionCountryCode,
  selectHasUserDeniedAccess
} from 'store/auth';
import { Spinner } from 'components';
import { AccessDeniedPage } from 'features/AccessDeniedPage/AccessDeniedPage';
import { APP_PATH } from '../Routes.helpers';

const HomePage = lazy(() =>
  import('features/HomePage/HomePage').then(
    ({ HomePage: HomePageComponent }) => ({
      default: HomePageComponent
    })
  )
);

export const OtherRoutes = (): JSX.Element => {
  const hasUser = useSelector(selectHasUser);
  const isInternal = useSelector(selectIsInternal);
  const product = useSelector(selectSubscriptionProductCode);
  const country = useSelector(selectSubscriptionCountryCode);
  const hasUserDeniedAccess = useSelector(selectHasUserDeniedAccess);

  const isTermsOfUse = !isInternal && product && country;

  const shouldRedirect = useMemo(
    () => hasUser || isTermsOfUse || hasUserDeniedAccess,
    [hasUser, isTermsOfUse, hasUserDeniedAccess]
  );

  const getRedirectUrl = useCallback(() => {
    if (hasUserDeniedAccess) {
      return APP_PATH.ACCESS_DENIED;
    }

    if (isTermsOfUse) {
      return `${APP_PATH.TERMS_OF_USE_ROOT}/${product}/${country}`;
    }

    return APP_PATH.ADMIN_SUBMISSIONS_ROOT;
  }, [hasUserDeniedAccess, isTermsOfUse, product, country]);

  return (
    <Switch>
      <Route
        exact={true}
        path={APP_PATH.HOME}
        render={useCallback(() => {
          if (shouldRedirect) {
            return <Redirect to={getRedirectUrl()} />;
          }
          return (
            <Suspense fallback={<Spinner />}>
              <HomePage />
            </Suspense>
          );
        }, [shouldRedirect, getRedirectUrl])}
      />

      <Route
        component={AccessDeniedPage}
        exact={true}
        path={APP_PATH.ACCESS_DENIED}
      />

      <Redirect from={APP_PATH.ROOT} to={APP_PATH.HOME} />

      <Redirect from={APP_PATH.WILDCARD} to={APP_PATH.ROOT} />
    </Switch>
  );
};
