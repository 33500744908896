import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { UPDATE_SUBSCRIPTIONS_ACTIONS } from 'features/UpdateSubscription/UpdateSubscription.helpers';
import { reselectAction, setUserAction } from 'store/updateSubscription';
import { selectProductCode, selectProductAppUrl } from 'store/product';
import { noop, redirectTo } from 'shared/utils';
import { Button, Confirmation } from 'components';
import { APP_PATH } from '../../../Routes/Routes.helpers';
import styles from './StepActionsPanel.module.css';
import ChevronLeftButton from '../../../../assets/images/ChevronLeftRegular.png';

interface Props extends AP.DefaultRegistrationStepProps {
  showBackButton: boolean,
  isNextDisabled: boolean;
  nextLabel: string;
  className?: string;
  directCancel?: boolean;
  message?: string | null;
}

export const StepActionsPanel: React.FC<Partial<Props>> = ({
  className = '',
  nextLabel = null,
  showBackButton = false,
  isNextDisabled = false,
  onNext = noop,
  onBack = noop,
  directCancel,
  message
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const action = useSelector(reselectAction);
  const history = useHistory();
  const [confirm, setConfirm] = React.useState<boolean>(false);
  const productCode = useSelector(selectProductCode);
  const productAPPUrl = useSelector(selectProductAppUrl);
  const routeUrl = `${APP_PATH.ADMIN_SUBMISSIONS_ROOT}/${productCode}`;

  const handleOnResolve = React.useCallback(() => {
    if (productCode === null) {
      return;
    }
    if (directCancel) {
      history.replace(routeUrl);
    } else {
      redirectTo(productAPPUrl);
    }
  }, [productCode, directCancel, history, routeUrl, productAPPUrl]);

  const handleOnReject = React.useCallback((): void => {
    setConfirm(false);
  }, []);

  const handleOnCancel = React.useCallback((): void => {
    if (directCancel) {
      // dipatching CANCEL action will help in routing to Processed tab 
      dispatch(setUserAction(UPDATE_SUBSCRIPTIONS_ACTIONS.CANCEL));
      history.replace(routeUrl);
    } else {
      setConfirm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directCancel, history, routeUrl]);

  return (
    <React.Fragment>
      <div className={`${styles.buttonPanel} ${className}`}>
        <Button variant='link' onClick={handleOnCancel}>
          {t('actions.cancel')} 
        </Button>
        {/* {onBack && onBack !== noop && (
          <Button
            disabled={onBack === null}
            variant='outline-primary'
            onClick={onBack}
          >
            {t('actions.back')}
          </Button>
        )} */}
        {showBackButton && (
          <Button
          variant='link'
          onClick={onBack}
        >
          <span className={styles.leftArrow}>
            <img alt='ETL' src={ChevronLeftButton} />
          </span>
          <span>
            {t('actions.previousStep')}
          </span>
        </Button>
        )}
        <Button
          className={styles.rightAlignment}
          disabled={isNextDisabled}
          onClick={onNext}
        >
          {nextLabel || t('actions.next')}
        </Button>
      </div>
      {confirm ? (
        <Confirmation
          message={t('registration.cancelRegistration.message')}
          title={t('registration.cancelRegistration.title')}
          onReject={handleOnReject}
          onResolve={handleOnResolve}
        />
      ) : null}
    </React.Fragment>
  );
};
