export const GET_CURRENT_USER = '@auth/GET_CURRENT_USER';
export const SET_ACCESS_TOKEN = '@auth/SET_ACCESS_TOKEN';
export const PROCEED_WITHOUT_USER = '@auth/PROCEED_WITHOUT_USER';
export const SET_CURRENT_USER_FILTERS = '@auth/SET_CURRENT_USER_FILTERS';
export const SET_CURRENT_USER_ADMIN_FILTERS =
  '@auth/SET_CURRENT_USER_ADMIN_FILTERS';
export const SET_SOURCE_SUBJECT_ID = '@auth/SET_SOURCE_SUBJECT_ID';

export type AuthState = Readonly<{
  accessToken: AP.Nullable<string>;
  user: AP.Nullable<DTO.User>;
  isLoading: boolean;
  isLoaded: boolean;
  hasUserDeniedAccess: boolean;
  hasUserExpiredToken: boolean;
  isInternal: boolean;
  adminFilters: DTO.UserFilters;
  isFirstAdminFiltersInit: boolean;
  isFirstLogin: boolean;
  filter: DTO.SubmissionFilters;
  selectedAdminFilters: Array<any>;
  sourceSubjectId: string;
}>;

export type LoadUserAction = Store.BaseAction | Store.PaylodedAction<string>;

export type GetCurrentUserAction = Store.PaylodedAction<DTO.User>;

export type GetCurrentUserAction$ = Store.PaylodedAction<Promise<DTO.User>>;

export type SetAccessTokenAction = Store.PaylodedAction<string>;

export type ProceedWithoutUserAction = Store.BaseAction;

export type AuthActionTypes =
  | LoadUserAction
  | GetCurrentUserAction
  | SetAccessTokenAction
  | ProceedWithoutUserAction;

export type SetCurrentUserFiltersAction = Store.PaylodedAction<
  DTO.SubmissionFilters
>;

export type SetCurrentUserAdminFiltersAction = Store.PaylodedAction<
  DTO.UserFilters
>;

export type SetSourceSubjectId = Store.PaylodedAction<string>;
