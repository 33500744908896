import { createContext } from 'react';

export enum APP_PATH {
  ROOT = '/',

  HOME = '/home',
  ACCESS_DENIED = '/access-denied',

  ADMIN_ROOT = '/admin',
  ADMIN_SUBMISSIONS_ROOT = '/admin/submissions',
  ADMIN_SUBMISSIONS_PRODUCT = '/admin/submissions/:product',
  ADMIN_UPDATESUBSCRIPTION_ROOT = '/admin/update-subscription',
  ADMIN_UPDATESUBSCRIPTION = '/admin/update-subscription/:product',
  ADMIN_ONBOARDING = '/admin/onboarding/:product',
  ADMIN_ONBOARDING_CREATION = '/admin/onboarding/add/:product',
  
  REGISTRATION_ROOT = '/registration',
  REGISTRATION_PRODUCT = '/registration/:product',

  EXTERNAL_TERMS_OF_USE = '/termsofuse/:product/:country',
  INTERNAL_TERMS_OF_USE = '/termsofuse/internal/:product/:country',
  TERMS_OF_USE_ROOT = '/termsofuse',

  WILDCARD = '*',

  AUTH_CALLBACK = '/authcallback',
  LOGOUT_CALLBACK = '/oauth2/logout'
}

interface RoutesContextProps {
  setProductCallback: () => void;
}

export const RoutesContext = createContext<RoutesContextProps>({
  setProductCallback: null
});
