import React, { FC } from 'react';
import cs from 'classnames';

import styles from './Tag.module.css';

interface Props {
  text: string;

  label?: string;
  active?: boolean;
  onClick?: () => void;
}

export const Tag: FC<Props> = ({ text, label, active, onClick }) => {
  return (
    <div
      className={cs(styles.tag, { [styles.active]: active })}
      role='presentation'
      onClick={onClick}
    >
      <span className={styles.text}>{text}</span>
      {label && <span className={styles.label}>{label}</span>}
    </div>
  );
};
