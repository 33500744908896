import React from 'react';
import { IndicatorProps } from 'react-select/src/components/indicators';
import cs from 'classnames';

import style from '../Select.module.css';

export function ClearIndicator(): (props: any) => JSX.Element {
  const clear = (props: IndicatorProps<TextMetrics, any>): JSX.Element => {
    const {
      innerProps: { ref, ...restInnerProps }
    } = props;
    return (
      <div {...restInnerProps} ref={ref}>
        <div className={cs('icon-cross-special', style.clearIndicator)} />
      </div>
    );
  };
  return clear;
}
