import React, { Dispatch, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FieldSorting } from 'components/FieldSorting/FieldSorting';
import { SubmissionsFilters } from 'features/AdminPage/SubmissionsFilters/SubmissionsFilters';
import styles from './OnboardingFilters.module.css';
import { Tag } from '../../../../components/Tag/Tag';
import { noop } from '../../../../shared/utils/common.utils';
import { reselectUser } from '../../../../store/auth/auth.selectors';

interface Props {
  usersCount: DTO.AdminUserCount;
  setUserFilters: Dispatch<DTO.UserFilters>;
  userFilters: DTO.UserFilters;
  sorter: DTO.Sorter;
  filtersRef: React.MutableRefObject<undefined>;
  showFilters: DTO.FilterFields[];
}

// for the backend
export enum UserRoles {
  superAdmin = 1,
  productGroupAdmin = 2,
  productAdmin = 3,
  memberFirmAdmin = 4,
  countryAdmin = 5,
  user = 6,
  whitelistedInternalUser = 7
}

//for internal use
export enum USER_ROLES {
  SUPER_ADMIN = 'superAdmin',
  PRODUCT_GROUP_ADMIN = 'productGroupAdmin',
  PRODUCT_ADMIN = 'productAdmin',
  MEMBER_FIRM_ADMIN = 'memberFirmAdmin',
  COUNTRY_ADMIN = 'countryAdmin',
  USER = 'user',
  WHITELISTED_USER = 'whitelistedInternalUser'
}

export const OnboardingFilters: FC<Props> = ({
  usersCount,
  setUserFilters,
  userFilters,
  sorter,
  filtersRef,
  showFilters,
}) => {
  const {
    all,
    byCountry,
    byMemberFirm,
    byProduct,
    byProductGroup,
    byTenant
  } = usersCount;

  const { t } = useTranslation();
  const user = useSelector(reselectUser);

  const changeRoleFilter = (userRole: UserRoles) => {
    setUserFilters({
      'countryIds': [],
      'emails': [],
      'displayName': [],
      'countryCode': [],
      role: userRole
    });
  };

  const determineLableNumber = (number: number) =>
    number < 100 ? number.toString() : '99+';

  const fieldOptions: { value: DTO.UserTableFields; label: string }[] = [
    { value: 'DisplayName', label: t('onboarding.fieldSort.userName') },
    userFilters.role !== UserRoles.superAdmin && { value: 'Country', label: t('onboarding.fieldSort.country') },
    { value: 'Email', label: t('onboarding.fieldSort.email') }
  ];

  const appliedFilters = Object.values(userFilters).reduce(
    (acc, filter) => (filter && filter.length > 0 ? acc + 1 : acc),
    0
  );

  const onFiltersChange = (filters: DTO.UserFilters) => {
    setUserFilters({
      ...userFilters,
      emails: filters.emails,
      displayName: filters.displayName,
      countryCode: filters.countryCode
    });
  };

  return (
    <div className={styles.filters}>
      <div className={styles.tags}>
        {user.role !== USER_ROLES.PRODUCT_ADMIN && (
          <Tag
            active={!userFilters.role}
            // label={determineLableNumber(all)}
            text={t('onboarding.adminTypes.all')}
            onClick={() => changeRoleFilter(0)}
          />
        )}
        {user.role !== USER_ROLES.PRODUCT_GROUP_ADMIN &&
          user.role !== USER_ROLES.PRODUCT_ADMIN ? (
          <Tag
            active={userFilters.role === UserRoles.superAdmin}
            // label={determineLableNumber(byTenant)}
            text={t('onboarding.adminTypes.tenant')}
            onClick={() => changeRoleFilter(UserRoles.superAdmin)}
          />
        ) : null}
        {/* {user.role !== USER_ROLES.PRODUCT_ADMIN && (
          <Tag
            active={userFilters.role === UserRoles.productGroupAdmin}
            label={determineLableNumber(byProductGroup)}
            text={t('onboarding.adminTypes.productGroup')}
            onClick={() => changeRoleFilter(UserRoles.productGroupAdmin)}
          />
        )} */}
        {user.role !== USER_ROLES.PRODUCT_ADMIN && (
          <Tag
            active={userFilters.role === UserRoles.productAdmin}
            // label={determineLableNumber(byProduct)}
            text={t('onboarding.adminTypes.product')}
            onClick={() => changeRoleFilter(UserRoles.productAdmin)}
          />
        )}
        {user.role !== USER_ROLES.WHITELISTED_USER && user.role !== USER_ROLES.PRODUCT_ADMIN &&  
        (
          <Tag
            active={userFilters.role === UserRoles.whitelistedInternalUser}
            // label={determineLableNumber(byProduct)}
            text={t('onboarding.adminTypes.whitelistedInternalUser')}
            onClick={() => changeRoleFilter(UserRoles.whitelistedInternalUser)}
          />
        )}
        {/* <Tag
          active={userFilters.role === UserRoles.memberFirmAdmin}
          label={determineLableNumber(byMemberFirm)}
          text={t('onboarding.adminTypes.memberFirm')}
          onClick={() => changeRoleFilter(UserRoles.memberFirmAdmin)}
        />
        <Tag
          active={userFilters.role === UserRoles.countryAdmin}
          label={determineLableNumber(byCountry)}
          text={t('onboarding.adminTypes.country')}
          onClick={() => changeRoleFilter(UserRoles.countryAdmin)}
        /> */}
      </div>
      <div className={styles.filterControls}>
        <div className={styles.filtersOpener}>
          <SubmissionsFilters
            appendTo={filtersRef}
            appliedFilters={appliedFilters}
            countryOptionValue='id'
            fetchAdminCountries={true}
            filters={userFilters}
            showFilters={showFilters}
            onApply={noop}
            onChange={onFiltersChange}
          />
        </div>
      </div>
    </div>
  );
};
