import React, { lazy, useContext, useMemo, Suspense, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import {
  reselectAdminProducts,
  selectFirstAdminProductCode,
  selectHasUser,
  selectHasUserDeniedAccess,
  selectHasUserLoaded
} from 'store/auth';
import { getProductCodeFromPath } from 'shared/utils';
import { Spinner } from 'components';
import { USER_ROLES } from 'features/AdminPage/OnboardingPage/OnboardingFilters/OnboardingFilters';
import { APP_PATH, RoutesContext } from '../Routes.helpers';
import { OnboardingCreation } from '../../AdminPage/OnboardingCreation/OnboardingCreation';
import { OnboardingPageWithNav } from '../../AdminPage/OnboardingPage/OnboardingPageWithNav';
import { reselectUser } from '../../../store/auth/auth.selectors';
import { UpdateSubscription } from '../../UpdateSubscription/UpdateSubscription';

const AdminPage = lazy(() =>
  import('features/AdminPage/AdminPage').then(
    ({ AdminPage: AdminPageComponent }) => ({
      default: AdminPageComponent
    })
  )
);

export const AdminRoutes = (): JSX.Element => {
  const { pathname } = useLocation();
  const hasUserDeniedAccess = useSelector(selectHasUserDeniedAccess);
  const hasUser = useSelector(selectHasUser);
  const isLoaded = useSelector(selectHasUserLoaded);
  const adminProducts = useSelector(reselectAdminProducts);
  const firstAdminProductCode = useSelector(selectFirstAdminProductCode);
  const { setProductCallback } = useContext(RoutesContext);
  const product = getProductCodeFromPath(pathname);
  const isProductAvailable = adminProducts.find(
    adminProduct => adminProduct.code === product
  );
  const user = useSelector(reselectUser);

  const shouldRedirect = useMemo(
    () => !hasUser || hasUserDeniedAccess || !isProductAvailable || user?.role === USER_ROLES.WHITELISTED_USER,
    [hasUser, hasUserDeniedAccess, isProductAvailable, user?.role]
  );

  const isNotOnboardingAdmin =
  user &&
  (user.role !== USER_ROLES.SUPER_ADMIN &&
    user.role !== USER_ROLES.PRODUCT_ADMIN &&
    user.role !== USER_ROLES.PRODUCT_GROUP_ADMIN);

  const getRedirectUrlByPermission = useCallback(
    (isSubmissionsRoot = false): string => {
      if (!hasUser && !hasUserDeniedAccess) {
        return APP_PATH.HOME;
      }

      if (isSubmissionsRoot && !hasUserDeniedAccess) {
        return `${APP_PATH.ADMIN_SUBMISSIONS_ROOT}/${firstAdminProductCode}`;
      }

      return APP_PATH.ACCESS_DENIED;
    },
    [hasUserDeniedAccess, hasUser, firstAdminProductCode]
  );

  return (
    <Switch>
      <Route
        exact={true}
        path={APP_PATH.ADMIN_SUBMISSIONS_PRODUCT}
        render={useCallback(() => {
          if (!isLoaded) return null;
          if (shouldRedirect) {
            return <Redirect to={getRedirectUrlByPermission()} />;
          }

          setProductCallback();
          return (
            <Suspense fallback={<Spinner />}>
              <AdminPage />
            </Suspense>
          );
        }, [
          isLoaded,
          shouldRedirect,
          setProductCallback,
          getRedirectUrlByPermission
        ])}
      />
      <Route
        exact={true}
        path={APP_PATH.ADMIN_ONBOARDING}
        render={useCallback(() => {
          if (shouldRedirect || isNotOnboardingAdmin) {
            return <Redirect to={getRedirectUrlByPermission()} />;
          }

          setProductCallback();
          return (
            <Suspense fallback={<Spinner />}>
              <OnboardingPageWithNav />
            </Suspense>
          );
        }, [
          shouldRedirect,
          isNotOnboardingAdmin,
          setProductCallback,
          getRedirectUrlByPermission
        ])}
      />
      <Route
        exact={true}
        path={APP_PATH.ADMIN_ONBOARDING_CREATION}
        render={useCallback(() => {
          if (shouldRedirect || isNotOnboardingAdmin) {
            return <Redirect to={getRedirectUrlByPermission()} />;
          }

          setProductCallback();
          return (
            <Suspense fallback={<Spinner />}>
              <OnboardingCreation />
            </Suspense>
          );
        }, [
          shouldRedirect,
          isNotOnboardingAdmin,
          setProductCallback,
          getRedirectUrlByPermission
        ])}
      />
      <Route
        exact={true}
        path={APP_PATH.ADMIN_UPDATESUBSCRIPTION}
        render={useCallback(() => {
          if (shouldRedirect) {
            return <Redirect to={getRedirectUrlByPermission()} />;
          }

          setProductCallback();
          return (
            <Suspense fallback={<Spinner/>}>
              <UpdateSubscription ProductCode={product} />
            </Suspense>
          );
        }, [
          shouldRedirect,
          getRedirectUrlByPermission,
          setProductCallback,
          product
        ])}
      />
      <Route
        exact={true}
        path={APP_PATH.ADMIN_SUBMISSIONS_ROOT}
        render={() => <Redirect to={getRedirectUrlByPermission(true)} />}
      />

      <Redirect from={APP_PATH.WILDCARD} to={APP_PATH.ROOT} />
    </Switch>
  );
};
