export const actionsEn = {
  profileSettings: 'Profile Settings',
  selectLanguage: 'Select language',
  settings: 'Settings',
  register: 'Register',
  logIn: 'Log in',
  cancel: 'Cancel',
  canceltext: 'Are you sure you want to cancel out of this process?',
  confirm: 'Confirm',
  confirmcancel: 'Confirm Cancellation',
  continue: 'Continue',
  back: 'Back',
  next: 'Next',
  previousStep: 'Previous Step',
  submit: 'Submit',
  excelExport: 'Export to Excel',
  csvExport: 'Export to CSV',
  signOut: 'Sign Out',
  logOut: 'Log out',
  no: 'No',
  yes: 'Yes',
  ok: 'Ok',
  sortedBy: 'Sorted by',
  close: 'Close',
  clearAll: 'Clear All',
  apply: 'Apply',
  filters: 'Filters',
  No: 'NO',
  Yes: 'YES',
  logOutWarning: 'Logout warning',
  logOutWarningMsg: 'You will be logged out in',
  logOutWarningNote: 'Are you sure you want to log off?',
  remove: "Remove",
  save: "Save",
  addFilter: 'Add Filter',
  resetFilter: 'Reset FIlter'
};
