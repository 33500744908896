import { createSelector } from 'reselect';

import { RootState } from 'store/root.reducer';

export const selectLocaleData = ({ localize }: RootState): AP.LocaleDataInfo =>
  localize;

export const selectLocaleList = ({
  localize
}: RootState): Array<AP.DefaultLocale> => localize.localeList;

export const selectLocaleCode = ({ localize }: RootState): AP.LocaleCode => {
  return localize.localeCode;
};

export const reselectLocaleInfo = createSelector(
  [selectLocaleData],
  (locale): AP.LocaleDataInfo => locale
);

export const reselectLocaleList = createSelector(
  [selectLocaleList],
  (list): Array<AP.DefaultLocale> => list
);

export const reselectLocaleCode = createSelector(
  [selectLocaleCode],
  (localeCode): AP.LocaleCode => localeCode
);
