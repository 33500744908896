import React from 'react';
import cs from 'classnames';
import { Form as BsForm, FormProps } from 'react-bootstrap';

import Input from './Input/Input';
import SelectField from './SelectField/SelectField';
import SelectFieldFilters from './SelectField/SelectFieldFilters';
import SelectAsyncField from './SelectField/SelectAsyncField';
import SelectAsyncFieldFilters from './SelectField/SelectAsyncFieldFilters';
import CheckboxField from './CheckboxField/CheckboxField';
import Textarea from './Textarea/Textarea';
import Group from './Group/Group';
import { Radio } from './Radio/Radio';
import styles from './Form.module.css';

interface Props extends FormProps {
  className?: string;
}

export function Form({
  children,
  className,
  inline,
  validated
}: React.PropsWithChildren<Props>): JSX.Element {
  return (
    <BsForm
      className={cs(className, styles.form)}
      inline={inline}
      validated={validated}
    >
      {children}
    </BsForm>
  );
}

Form.Input = Input;
Form.Select = SelectField;
Form.SelectAsync = SelectAsyncField;
Form.Row = BsForm.Row;
Form.Group = Group;
Form.Checkbox = CheckboxField;
Form.Textarea = Textarea;
Form.Radio = Radio;
Form.SelectFilters = SelectFieldFilters;
Form.SelectAsyncFilters = SelectAsyncFieldFilters;
