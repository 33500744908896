import React from 'react';
import { connect, useSelector } from 'react-redux';
import { FormikProps, withFormik } from 'formik';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import { useTranslation } from 'react-i18next';
import { object, string, setLocale } from 'yup';

import { selectProductCode } from 'store/product';
import { reselectRegistrationData } from 'store/registration';
import { reselectSubscriptionData } from 'store/updateSubscription';
import { RootState } from 'store/root.reducer';
import { noop } from 'shared/utils';
import { Form } from 'components';
import { errorException } from 'shared/utils/AppInsights';


interface FormFieldProps {
  disabled?: boolean;
  name: string;
  span?: number;
  label?: string;
  placeholder?: string;
  options?: any[];
  elem?: any[];
  validationErrMsg?: (err: string | any) => string;
  productCode?: any;
  t?: any;
  maxLength?: number;
}

interface FormGroup {
  title: string;
  fields: FormField[][];
  note?: string;
  privacyText?: string
}

export interface FormField {
  disabled?: boolean;
  label: string;
  name: string;
  placeholder?: string;
  render: (props: FormFieldProps) => JSX.Element;
  options?: any[];
  elem?: any;
}

setLocale({
  mixed: {
    default: ({ path }: string | any): AP.ValidationError => ({
      key: camelCase(path)
    }),
    required: (): AP.ValidationError => {
      // AppInsights Error Exception
      errorException('Error data required, File reference: AccountDetailsForm.helpers.tsx in setLocale function ');

      return { key: 'required' };
    }
  },
  string: {
    email: (): AP.ValidationError => {
      // AppInsights Error Exception
      errorException('Error email, File reference: AccountDetailsForm.helpers.tsx in setLocale function ');

      return { key: 'email' };
    }
  }
});

function renderInput(
  type: 'text' | 'email',
  props: FormFieldProps
): JSX.Element {
  return <Form.Input type={type} {...props} />;
}

// TODO: refactor this
// eslint-disable-next-line react/no-multi-comp
function renderSelect({ options, ...props }: FormFieldProps): JSX.Element {
  return (
    <Form.Select
      optionLabel='name'
      options={options || []}
      optionValue='code'
      {...props}
    />
  );
}

// eslint-disable-next-line react/no-multi-comp
function renderRadio({ options, ...props }: FormFieldProps): JSX.Element {
  // eslint-disable-next-line react/destructuring-assignment
  return <Form.Radio label={props.label} name={props.name} {...props} />;
}

export const formGroups: FormGroup[] = [
  {
    title: 'forms.userInformation',
    privacyText: 'forms.privacyStatement',
    fields: [
      [
        {
          label: 'forms.email',
          name: 'user.email',
          render: (props): JSX.Element =>
            renderInput('email', { ...props, span: 6, maxLength: 50 })
        }
      ],
      [
        {
          label: 'forms.firstName',
          name: 'user.firstName',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 6, maxLength: 30 })
        }
      ],
      [
        {
          label: 'forms.lastName',
          name: 'user.lastName',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 6, maxLength: 30 })
        }
      ]
    ]
  },
  {
    title: 'forms.accountInformation',
    fields: [
      [
        {
          label: 'forms.companyFullLegalName',
          name: 'organization.legalName',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 6, maxLength: 100 })
        }
      ],
      [
        {
          label: 'forms.globalParentFullLegalNameOptional',
          name: 'organization.parentLegalName',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 6 })
        }
      ],
      [
        {
          label: 'forms.departmentOptional',
          name: 'organization.department',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 6 })
        }
      ]
    ]
  },
  {
    title: 'forms.address',
    fields: [
      [
        {
          label: 'forms.address',
          name: 'organization.address',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 6, maxLength: 100 })
        }
      ],
      [
        {
          label: 'forms.stateProvinceCounty',
          name: 'organization.state',
          placeholder: 'forms.selectOption',
          render: (props): JSX.Element => renderSelect({ ...props, span: 6 })
        }
      ],
      [
        {
          label: 'forms.townCity',
          name: 'organization.city',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 6, maxLength: 50 })
        },
        {
          label: 'forms.zipPostalCode',
          name: 'organization.postalCode',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 3, maxLength: 10 })
        }
      ],
      [
        {
          label: 'forms.isDeloitteAuditClient',
          name: 'organization.isDeloitteAuditClient',
          elem: [
            {
              name: 'isDeloitteAuditClient',
              label: 'forms.no',
              id: 'AuditNo',
              value: '2',
              checked: true
            },
            {
              name: 'isDeloitteAuditClient',
              label: 'forms.yes',
              id: 'AuditYes',
              value: '1',
              checked: false
            },
            {
              name: 'isDeloitteAuditClient',
              label: 'forms.dk',
              id: 'AuditDontKnow',
              value: '3',
              checked: false
            }
          ],
          render: (props): JSX.Element => renderRadio({ ...props, span: 3 })
        }
      ]
    ]
  }
];

export const subscriptionFormGroups: FormGroup[] = [
  {
    title: 'forms.userInformation',
    fields: [
      [
        {
          label: 'forms.email',
          name: 'user.email',
          render: (props): JSX.Element =>
            renderInput('email', { ...props, span: 6, disabled: true })
        }
      ],
      [
        {
          label: 'forms.firstName',
          name: 'user.firstName',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 6, maxLength: 30 })
        }
      ],
      [
        {
          label: 'forms.lastName',
          name: 'user.lastName',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 6, maxLength: 30 })
        }
      ]
    ]
  },
  {
    title: 'forms.accountInformation',
    fields: [
      [
        {
          label: 'forms.companyFullLegalName',
          name: 'organization.legalName',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 6, maxLength: 100 })
        }
      ],
      [
        {
          label: 'forms.globalParentFullLegalNameOptional',
          name: 'organization.parentLegalName',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 6 })
        }
      ],
      [
        {
          label: 'forms.departmentOptional',
          name: 'organization.department',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 6 })
        }
      ],
    ]
  }, 
  {
    title: 'forms.address',
    fields: [
      [
        {
          label: 'forms.address',
          name: 'organization.address',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 6, maxLength: 100 })
        }
      ],
      [
        {
          label: 'forms.stateProvinceCounty',
          name: 'organization.state',
          placeholder: 'forms.selectOption',
          render: (props): JSX.Element => renderSelect({ ...props, span: 6 })
        }
      ],
      [
        {
          label: 'forms.townCity',
          name: 'organization.city',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 6, maxLength: 50 })
        },
        {
          label: 'forms.zipPostalCode',
          name: 'organization.postalCode',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 3, maxLength: 10 })
        }
      ],
      [
        {
          label: 'forms.isDeloitteAuditClient',
          name: 'organization.isDeloitteAuditClient',
          elem: [
            {
              name: 'isDeloitteAuditClient',
              label: 'forms.no',
              id: 'AuditNo',
              value: '2',
              checked: true
            },
            {
              name: 'isDeloitteAuditClient',
              label: 'forms.yes',
              id: 'AuditYes',
              value: '1',
              checked: false
            },
            {
              name: 'isDeloitteAuditClient',
              label: 'forms.dk',
              id: 'AuditDontKnow',
              value: '3',
              checked: false
            }
          ],
          render: (props): JSX.Element => renderRadio({ ...props, span: 3 })
        }
      ]
    ]
  }
];

export function useRenderInput(lookups: {
  [key: string]: any[];
}): ({ name, label, placeholder, render, options }: FormField) => JSX.Element {
  const { t } = useTranslation();
  const productCode = useSelector(selectProductCode);
  return React.useCallback(
    ({ name, label, placeholder, render, elem }: FormField): JSX.Element => (
      <React.Fragment key={name}>
        {render({
          name,
          label: t(label),
          elem,
          options: get(lookups, name, []),
          placeholder: placeholder ? t(placeholder) : '',
          productCode,
          t,
          validationErrMsg: (err): string =>
            err ? t(`validation.errors.${err.key}`, { values: err.values }) : ''
        })}
      </React.Fragment>
    ),
    [t, lookups, productCode]
  );
}

const mapRegistrationDataToProps = (state: RootState) => ({
  data: reselectRegistrationData(state)
});

export const accountDetailsStoreEnhancer = connect(mapRegistrationDataToProps);

const mapSubscriptionDataToProps = (state: RootState) => () => {
  return { data: reselectSubscriptionData(state) };
};

export const subscriptionDetailsStoreEnhancer = connect(
  mapSubscriptionDataToProps
);

interface AccountDetailsFormProps
  extends AP.DefaultRegistrationStepProps,
    ReturnType<typeof mapRegistrationDataToProps> {
  loading: boolean;
  formErrors: Record<keyof AP.AccountDetailsFormFields, AP.ValidationError>;
  states: DTO.State[];
  onSubmit: (values: AP.AccountDetailsFormFields) => void;
}

export type InjectedFormikAccountDetailsFormProps = AccountDetailsFormProps &
  FormikProps<AP.AccountDetailsFormFields>;

export const accountDetailsFormEnhancer = withFormik<
  AccountDetailsFormProps,
  AP.AccountDetailsFormFields
>({
  mapPropsToValues: ({ data }) => data,
  validationSchema: object().shape({
    user: object().shape({
      email: string()
        .required()
        .email()
        .test(
          'cannot be Deloitte email',
          undefined,
          (value: string): boolean => {
            const emailLengthLimit = 64;

            if (!value) return true;
            if (value && value.toLowerCase().includes('@deloitte.'))
              return false;
            if (value) {
              const splitValue = value.split('@');
              if (splitValue && splitValue[0].length > emailLengthLimit)
                return false;
            }
            if (
              value &&
              /^(([^<>()[\].,;:\s@+"]+(\.[^<>()[\].,;:\s@+"]+)*)|(".+"))@(([^<>().,;\s@"]+\.{0,1})+[^<>().,;:\s@"]{2,})$/.test(
                value
              )
            ) {
              return true;
            }
            return false;
          }
        ),
      firstName: string()
        .required()
        .test(
          'Please enter valid characters in your First Name.',
          undefined,
          (value: string): boolean => {
            
           
            if (
              value &&
              /^(?:[A-Za-z.\s'-]+|\d+)$/.test(
                value
              )
            ) {
              return true;
            }
            return false;
          })
        .trim(),
      lastName: string()
        .required()
        .test(
          'Please enter valid characters in your Last Name.',
          undefined,
          (value: string): boolean => {
            
           
            if (
              value &&
              /^(?:[A-Za-z.\s'-]+|\d+)$/.test(
                value
              )
            ) {
              return true;
            }
            return false;
          })
        .trim()
    }),
    organization: object().shape({
      legalName: string()
        .required()
        .trim(),
      address: string()
        .required()
        .trim(),
      city: string()
        .required()
        .trim(),
      state: string()
        .transform(value => value !== null && value.toString ? value.toString() : value)
        .required(),
      postalCode: string()
        .required()
        .trim(),
      isDeloitteAuditClient: string()
        .required()
        .test(
          'Cannot be Deloitte Audit Client',
          undefined,
          (value: string): boolean => !value || !value.includes('1')
        )
    })
  }),

  handleSubmit: noop
});

export const accountDetailsSubscriptionFormEnhancer = withFormik<
  AccountDetailsFormProps,
  AP.AccountDetailsFormFields
>({
  mapPropsToValues: ({ data }) => {
    return data;
  },
  isInitialValid: true,
  validationSchema: object().shape({
    user: object().shape({
      email: string()
        .required()
        .email()
        .test(
          'cannot be Deloitte email',
          undefined,
          (value: string): boolean => {
            const emailLengthLimit = 64;

            if (!value) return true;
            if (value && value.includes('@deloitte.')) return false;
            if (value) {
              const splitValue = value.split('@');
              if (splitValue && splitValue[0].length > emailLengthLimit)
                return false;
            }
            if (
              value &&
              /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>().,;\s@"]+\.{0,1})+[^<>().,;:\s@"]{2,})$/.test(
                value
              )
            ) {
              return true;
            }
            return false;
          }
        ),
      firstName: string()
        .required()
        .test(
          'Please enter valid characters in your First Name.',
          undefined,
          (value: string): boolean => {
                     
            if (
              value &&
              /^(?:[A-Za-z.\s'-]+|\d+)$/.test(
                value
              )
            ) {
              return true;
            }
            return false;
          })
        .trim(),
      lastName: string()
        .required()
        .test(
          'Please enter valid characters in your Last Name.',
          undefined,
          (value: string): boolean => {
            
           
            if (
              value &&
              /^(?:[A-Za-z.\s'-]+|\d+)$/.test(
                value
              )
            ) {
              return true;
            }
            return false;
          })
        .trim()
    }),
    organization: object().shape({
      legalName: string()
        .required()
        .trim(),
      address: string()
        .required()
        .trim(),
      city: string()
        .required()
        .trim(),
      state: string().required(),
      postalCode: string()
        .required()
        .trim(),
      isDeloitteAuditClient: string().test(
        'Cannot be Deloitte Audit Client',
        undefined,
        (value: string): boolean => !value || !value.includes('true')
      )
    }),
    referral: object().shape({
      name: string()
        .required()
        .trim(),
      email: string()
        .required()
        .test('deloitteEmailOrNA', undefined, (value: string): boolean => {
          if (!value) {
            return true;
          }

          try {
            return string()
              .email()
              .validateSync(value.toLowerCase())
              .includes('@deloitte.');
          } catch (e) {
            return value === 'N/A';
          }
        })
    })
  }),

  handleSubmit: noop
});