/* eslint-disable no-console */
import { User, UserManager, UserManagerEvents } from 'oidc-client';

import { OIDC_CONFIG } from 'shared/constants/config.consts';
import { getWindow } from 'shared/utils/common.utils';
import { OidcUser } from 'shared/models';
import { errorException } from 'shared/utils/AppInsights';

export const CONFIG: AP.AppConfig = getWindow().APP_CONFIG;

class OidcService {
  public get events(): UserManagerEvents {
    return this.userManager.events;
  }

  private userManager: UserManager;

  private static getTokenData(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(window.atob(base64));
  }

  constructor() {
    this.userManager = new UserManager(OIDC_CONFIG);
  }

  // technically yes, it should be have a static method
  // but in that case, we'd have one more export and more complex app
  // eslint-disable-next-line class-methods-use-this
  public getOidcUser(user: User): OidcUser {
    const tokenData = OidcService.getTokenData(user.access_token);

    return new OidcUser(
      user.id_token,
      tokenData.email,
      user.access_token,
      tokenData.userType === 'Internal'
    );
  }

  signInRedirect = (): void => {
    this.userManager.signinRedirect().catch(console.error);
  };

  signInRedirectTermsofUse = (pathName: any): void => {
    // OIDC_CONFIG.redirect_uri = CONFIG.GAP_APP_URL + pathName;
    // this.userManager = new UserManager(OIDC_CONFIG);
    this.userManager.signinRedirect().catch(console.error);
  };

  signOut = (): void => {
    const idTokenHint = localStorage.getItem('id_token');
    this.userManager
      .signoutRedirect({ id_token_hint: idTokenHint })
      .catch((err) => {
        // AppInsights Error Exception
        errorException(`Error: ${err}, File reference: oidc.services.tsx in signOut function`);

        console.error(err);
      });
    this.userManager.clearStaleState().catch((err) => {
      // AppInsights Error Exception
      errorException(`Error: ${err}, File reference: oidc.services.tsx in signOut function`);

      console.error(err);
    });
  };

  signInRedirectCallback = (): void => {
    this.userManager
      .signinRedirectCallback()
      .then(res => {})
      .catch(console.error);
  };

  signOutRedirectCallback = (): void => {
    this.userManager.signoutRedirectCallback().catch((err) => {
      // AppInsights Error Exception
      errorException(`Error: ${err}, File reference: oidc.services.tsx in signOutRedirectCallback function`);

      console.error(err);
    });
    this.userManager.clearStaleState().catch((err) => {
      // AppInsights Error Exception
      errorException(`Error: ${err}, File reference: oidc.services.tsx in signOutRedirectCallback function`);

      console.error(err);
    });
    this.userManager.removeUser().catch((err) => {
      // AppInsights Error Exception
      errorException(`Error: ${err}, File reference: oidc.services.tsx in signOutRedirectCallback function`);

      console.error(err);
    });
  };
}

export const oidcService = new OidcService();
