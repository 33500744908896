import React from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import { SUBMISSION_TYPE } from 'shared/constants/submission.consts';
import { RadioButtons } from 'components';
import { Submission, getSubmissionsTabs } from './SubmissionsTabs.helpers';
import styles from './SubmissionsTabs.module.css';

interface Props {
  data: DTO.RegistrationTabsCount;
  submissionType: SUBMISSION_TYPE;
  setSubmissionType: (type: SUBMISSION_TYPE) => void;
  handleFiltersOnChange: any;
}

export const SubmissionsTabs: React.FC<Props> = ({
  data,
  submissionType,
  setSubmissionType,
  handleFiltersOnChange
}) => {
  const { t } = useTranslation();

  const renderOptionCb = React.useCallback(
    ({ name, count, type }: Submission): JSX.Element => (
      <div className={styles.submission}>
        <span>{t(name)}</span>
        {/* <span
          className={cs(styles.count, {
            [styles.active]: type === submissionType
          })}
        >
          {count > 99 ? '99+' : count}
        </span> */}
      </div>
    ),
    [t]
  );

  const onChangeSubmissionTabCb = React.useCallback(
    ({ type }: Submission) => {
      handleFiltersOnChange({
        'countryCode': [],
        'legalName': [],
        'user': [],
        'planStatus': [],
        'subscriberEmail': []
      });
      setSubmissionType(type);
    },
    [setSubmissionType, handleFiltersOnChange]
  );

  const submissionsTabs = React.useMemo(() => getSubmissionsTabs(data), [data]);

  return (
    <RadioButtons
      options={submissionsTabs}
      renderOption={renderOptionCb}
      value={submissionsTabs.find(
        ({ type }: Submission): boolean => type === submissionType
      )}
      onChange={onChangeSubmissionTabCb}
    />
  );
};
