import React from 'react';
import { components } from 'react-select';
import { OptionProps } from 'react-select/src/components/Option';
import cs from 'classnames';

import styles from './OptionAdminAction.module.css';

export function OptionAdminAction<T = Record<string, unknown>>(
  props: OptionProps<T, boolean, any>
): JSX.Element {
  const { isSelected, isFocused } = props;
  return (
    <components.Option
      {...props}
      className={cs(styles.customOption, {
        [styles.selected]: isSelected,
        [styles.focused]: isFocused
      })}
    />
  );
}
