import React from 'react';
import get from 'lodash/get';
import merge from 'lodash/merge';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import cs from 'classnames';

import { getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from 'shared/utils/storage.utils';
import { Form, Spinner } from 'components';
import { renewSubscriptions, reselectCountry, reselectSubscriptionPlans, setSubscriptionDetails } from 'store/updateSubscription';
import { selectFields } from 'store/product';
import {
  accountDetailsSubscriptionFormEnhancer,
  accountDetailsFormEnhancer,
  FormField,
  InjectedFormikAccountDetailsFormProps,
  subscriptionDetailsStoreEnhancer,
  subscriptionFormGroups,
  useRenderInput
} from '../../../RegistrationPage/steps/AccountDetailsForm/AccountDetailsForm.helpers';
import { StepActionsPanel } from '../../../RegistrationPage/components/StepActionsPanel/StepActionsPanel';
import styles from '../../../RegistrationPage/steps/AccountDetailsForm/AccountDetailsForm.module.css';
import { SpinnerSize } from '../../../../components/Spinner/Spinner.helpers';

const AccountDetailsFormComponent: React.FC<
  InjectedFormikAccountDetailsFormProps
> = ({
  loading,
  states = [],
  formErrors,
  isValid,
  values,
  setFieldError,
  showBackButton,
  onBack,
  onSubmit
}) => {
  const { t } = useTranslation();
  const showFields = useSelector(selectFields);
  const plans: any = useSelector(reselectSubscriptionPlans);
  const dispatch = useDispatch();
  const country = useSelector(reselectCountry);
  // TODO: remove toLowerCase
  const countryDetails = find(showFields.countries, {
    code: country?.code?.toLowerCase()
  }); //aus, uk countryCode
  const countrySetting =
  countryDetails && countryDetails.fieldSettings && // length
    countryDetails.fieldSettings.displayAuditClient;
  const memberFirmDetails = find(showFields.memberFirms, {
    code: countryDetails?.memberFirmId
  });
  const memberFirmSetting =
  memberFirmDetails && memberFirmDetails.fieldSettings &&
    memberFirmDetails.fieldSettings.displayAuditClient;
  const productSetting =
    showFields.fieldSettings && showFields.fieldSettings.displayAuditClient;

  let showAuditClient: any = true;

  if (countrySetting != null) showAuditClient = countrySetting;
  else if (memberFirmSetting != null) showAuditClient = memberFirmSetting;
  else showAuditClient = productSetting;

  const onPreviousStepClick = React.useCallback((): void => {
    const plansFetch = plans.length > 0 ? plans : getFromLocalStorage('plans');
    const prevPlans: any = getFromLocalStorage('initialPlans');
    if (plansFetch.length > 0) {
      dispatch(renewSubscriptions(prevPlans));
    }
    const stateData = states.find(
      state => state.code === values.organization.state.code
    );
    const valuestoPassed = merge(
      {},
      {
        ...values,
        plans: prevPlans,
        organization: {
          ...values.organization,
          stateId: (stateData && stateData.id) || 0
        },
        states,
      },
      {
        organization: { state: get(values, 'organization.state.code') }
      }
    );
    onBack(valuestoPassed);
  }, [dispatch, plans, onBack, states, values]);

  const handleSubmit = React.useCallback((): void => {
    const stateData = states.find(
      state => state.code === values.organization.state.code
    );
    // removing caching related to manage subscription changes
    removeFromLocalStorage('initialPlans');
    removeFromLocalStorage('showCancelButton');
    
    // update/set cache variable to keep track for highlighted subscription
    setToLocalStorage('recentUserEmail', values.user.email);
    onSubmit(
      merge(
        {},
        {
          ...values,
          organization: {
            ...values.organization,
            stateId: (stateData && stateData.id) || 0
          }
        },
        {
          organization: { state: get(values, 'organization.state.code') }
        }
      )
    );
  }, [onSubmit, states, values]);

  const renderInput = useRenderInput({
    'organization.state': states
  });

  const mapFormFields = React.useCallback(
    (row: FormField[], index: number) => {
      let render = null;
      if (index.toString() === '5') {
        if (showAuditClient !== false) {
          render = row.map(renderInput);
        }
      } else {
        render = row.map(renderInput);
      }
      return <Form.Row key={index.toString()}>{render}</Form.Row>;
    },
    [renderInput, showAuditClient]
  );

  const mapFormGroups = React.useCallback(
    ({ fields, title, note }, index: number): JSX.Element => {
      return (
        <Form.Group key={`${index}updateformgroup`} note={note ? t(note) : ''} title={t(title)}>
          {fields.map(mapFormFields)}
        </Form.Group>
      );
    },
    [t, mapFormFields]
  );

  const form = React.useMemo(
    (): JSX.Element[] => subscriptionFormGroups.map(mapFormGroups),
    [mapFormGroups]
  );

  React.useEffect((): void => {
    if (formErrors) {
      Object.keys(formErrors).forEach((key): void => {
        setFieldError(key, get(formErrors, key));
      });
    }
  }, [formErrors, setFieldError]);

  return (
    <React.Fragment>
      <div className={cs(styles.wrapper, styles.border_rule)}>
        <Form className={styles.content}>{form}</Form>
      </div>
      <StepActionsPanel
        className={styles.update_actionPanel}
        directCancel={true}
        isNextDisabled={!isValid}
        nextLabel={t('actions.submit')}
        showBackButton={showBackButton}
        onBack={onPreviousStepClick}
        onNext={handleSubmit}
      />
      <Spinner shouldShow={loading} size={SpinnerSize.Lg} />
    </React.Fragment>
  );
};

export const AccountDetailsForm = subscriptionDetailsStoreEnhancer(
  accountDetailsFormEnhancer(AccountDetailsFormComponent)
);
