import { SUBMISSION_TYPE } from 'shared/constants/submission.consts';

export interface Submission {
  type: SUBMISSION_TYPE;
  name: string;
  count: number;
}

export function getSubmissionsTabs(
  data: DTO.RegistrationTabsCount
): Submission[] {
  return [
    {
      name: 'admin.submission.pending',
      type: SUBMISSION_TYPE.PENDING,
      count: data.pending
    },
    {
      name: 'admin.submission.processed',
      type: SUBMISSION_TYPE.PROCESSED,
      count: data.processed
    }
  ];
}
