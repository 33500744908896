import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { globalHistory } from "@reach/router";

const reactPlugin = new ReactPlugin();
const instrumentationKey = (window as any).APP_CONFIG.APP_INSIGHTS_INSTRUMENTATION_KEY;

const ai = new ApplicationInsights({
    config: {
        instrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: globalHistory }
        }
    }
});

ai.loadAppInsights();

export default (Component: any) => withAITracking(reactPlugin, Component);
export const { appInsights } = ai;
export { reactPlugin };

// LOGS FUNCTIONS
export const errorException = (message: string): void => {
    appInsights.trackException({ error: new Error(message), severityLevel: SeverityLevel.Error });
};
