import { createContext } from 'react';

import { SORT_DIRECTION } from 'shared/constants/common.consts';
import { SUBMISSION_TYPE } from 'shared/constants/submission.consts';
import { noop } from 'shared/utils';

export const sortingParams: {
  [key: string]: { value: string; label: string };
} = {
  id: { value: 'id', label: 'admin.grid.id' },
  date: { value: 'submissionDate', label: 'admin.grid.submissionDate' },
  country: { value: 'country', label: 'admin.grid.country' },
  companyName: { value: 'companyName', label: 'admin.grid.companyName' },
  subscriberName: { value: 'subscriberName', label: 'admin.grid.subscriberName' },
  subscriberEmail: { value: 'subscriberEmail', label: 'admin.grid.subscriberEmail' },
};

export const tableHeaders = <any[]>([
  { label: ('admin.grid.submissionDetails'), value: '', sortOrder: '' },
  { label: ('admin.grid.country'), value: 'country', sortOrder: '' },
  { label: ('admin.grid.companyName'), value: 'companyName', sortOrder: '' },
  { label: ('admin.grid.subscriberName'), value: 'subscriberName', sortOrder: '' },
  { label: ('admin.grid.subscriberEmail'), value: 'subscriberEmail', sortOrder: '' },
  { label: ('admin.grid.submissionDate'), value: 'submissionDate', sortOrder: SORT_DIRECTION.DESC },
  null
]);

// export const sortingParams: {
//   [key: string]: string;
// } = {
//   id: 'submissionDate',
//   date: 'admin.grid.submissionDate'
// };

export interface GridOptionsStateQuery {
  pageNumber: number;
  pageSize: number;
  sorter: DTO.Sorter;
  filter: DTO.Filter;
}

export interface GridOptionsState {
  filters: DTO.SubmissionFilters;
  query: GridOptionsStateQuery;
  selectedFilters: any[];
  submissionHeaders: any[];
}

export enum PLAN_STATUS {
  PENDING = 1,
  ACTIVE
}

export const initialGridOptions: GridOptionsState = {
  filters: {
    countryCode: [],
    legalName: [],
    user: [],
    planStatus: [],
    subscriberEmail: []
  },
  query: {
    pageNumber: 0,
    pageSize: 25,
    filter: {
      planStatus: [1],
      legalName: [],
      countryCode: [],
      user: [],
      subscriberEmail: []
    },
    // sorter: {
    //   sortBy: sortingParams.id,
    //   sortDirection: SORT_DIRECTION.DESC
    // },
    sorter: {
      fields: [
        {
          sortBy: tableHeaders[5].value,
          sortDirection: tableHeaders[5].sortOrder
        }
      ]
    }
  },
  selectedFilters: [],
  submissionHeaders: tableHeaders
};

export enum GRID_OPTIONS_ACTION_TYPES {
  CHANGE_SORTING,
  CHANGE_PAGE_NUMBER,
  CHANGE_PAGE_SIZE,
  CHANGE_ONLY_PENDING,
  CHANGE_FILTERS
}

type GridOptionsActions =
  | { type: GRID_OPTIONS_ACTION_TYPES.CHANGE_PAGE_NUMBER; payload: number }
  | { type: GRID_OPTIONS_ACTION_TYPES.CHANGE_PAGE_SIZE; payload: number }
  | {
    type: GRID_OPTIONS_ACTION_TYPES.CHANGE_ONLY_PENDING;
    payload: DTO.PlanStatus;
  }
  | { type: GRID_OPTIONS_ACTION_TYPES.CHANGE_SORTING; payload: DTO.GridSorting }
  | {
    type: GRID_OPTIONS_ACTION_TYPES.CHANGE_FILTERS;
    payload: DTO.SubmissionFilters;
  };

export const gridOptionsReducer = (
  state: GridOptionsState,
  action: GridOptionsActions
): GridOptionsState => {
  switch (action.type) {
    case GRID_OPTIONS_ACTION_TYPES.CHANGE_PAGE_NUMBER: {
      const pageNumber = action.payload - 1;
      return {
        ...state,
        query: { ...state.query, pageNumber }
      };
    }

    case GRID_OPTIONS_ACTION_TYPES.CHANGE_PAGE_SIZE:
      return {
        ...state,
        query: { ...state.query, pageSize: action.payload }
      };

    case GRID_OPTIONS_ACTION_TYPES.CHANGE_ONLY_PENDING:
      return {
        ...state,
        query: {
          ...state.query,
          pageNumber: 0,
          filter: {
            ...state.query.filter,
            planStatus: [action.payload]
          }
        }
      };

    case GRID_OPTIONS_ACTION_TYPES.CHANGE_SORTING: {
      const { sortBy, sortDirection } = action.payload;
      const newSubmissionHeaders = [...state.submissionHeaders];
      const indexToSort = state.submissionHeaders.findIndex(item => item?.value === sortBy);
      const prevSortIndex = state.submissionHeaders.findIndex(item => item?.sortOrder !== '');
      if (newSubmissionHeaders[prevSortIndex]?.value !== sortBy) {
        newSubmissionHeaders[prevSortIndex].sortOrder = '';
      }
      if(newSubmissionHeaders[indexToSort] !== null) {
        newSubmissionHeaders[indexToSort].sortOrder = sortDirection;
      }

      return {
        ...state,
        query: { ...state.query, sorter: { fields: [action.payload] } },
        submissionHeaders: newSubmissionHeaders
      };
    };

    case GRID_OPTIONS_ACTION_TYPES.CHANGE_FILTERS: {
      const { countryCode, legalName, user, subscriberEmail } = action.payload;
      const countryCodeFilter = countryCode
        ? countryCode.map(({ code }) => code)
        : [];
      const legalNameFilter = legalName ? legalName.map(({ id }) => id) : [];
      const userFilter = user ? user.map(({ email }) => email) : [];
      const subscriberEmailFilter = subscriberEmail ? subscriberEmail.map(({ email }) => email) : [];

      // calculation for selected filters
      const countryName = countryCode && countryCode.length > 0 ? countryCode.map(({ name }) => name) : [];
      const companyName = legalName && legalName.length > 0 ? legalName.map(item => item.legalName) : [];
      const subscriberName = user && user.length > 0 ? user.map(({ fullName }) => fullName) : [];
      const subEmail = subscriberEmail && subscriberEmail.length > 0 ? subscriberEmail.map(({ email }) => email) : [];
      
      return {
        ...state,
        filters: action.payload,
        query: {
          ...state.query,
          pageNumber: 0,
          filter: {
            ...state.query.filter,
            countryCode: countryCodeFilter,
            legalName: legalNameFilter,
            user: userFilter,
            subscriberEmail: subscriberEmailFilter
          }
        },
        selectedFilters: [
          {
            key: 'countryCode',
            value: countryName
          }, {
            key: 'legalName',
            value: companyName
          }, {
            key: 'user',
            value: subscriberName
          }, {
            key: 'subscriberEmail',
            value: subEmail
          }
        ]
      };
    }

    default:
      return state;
  }
};

interface AdminContextProps {
  type: SUBMISSION_TYPE;
  onSubscriptionsSubmit: () => void;
}

export const AdminContext = createContext<Partial<AdminContextProps>>({
  type: null,
  onSubscriptionsSubmit: noop
});
