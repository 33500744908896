export enum SUBMISSION_TYPE {
  PENDING = 'pending',
  PROCESSED = 'processed'
}

export enum SUBMISSION_PLAN_STATUS {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
  PENDING_REACTIVATION = 'pendingReActivation',
  PENDING_RENEWAL = 'pendingRenewal',
  EXPIRED = 'expired',
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  UNSUBSCRIBED = 'unsubscribed',
  PENDING_ACTIVATION = 'pendingActivation',
  INACTIVE = 'inActive'
}

export enum PLAN_TYPE {
  LICENSE = 'license',
  SUBSCRIPTION = 'subscription'
}

export enum COUNTRY_CHECK {
  USA = 'usa',
  GBR = 'gbr'
}