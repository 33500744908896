import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { reselectLocaleList, changeLanguage } from 'store/localize';
import { defaultLocale } from 'shared/constants/common.consts';
import { Select, Icon } from 'components';
import { OptionUserProduct } from './OptionUserProduct/OptionUserProduct';
import styles from './SelectLocale.module.css';
import { selectProductCode } from '../../store/product';
import ShapeLanguage from '../../assets/images/languageShape.svg';

export const SelectLocale: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const languageLists = useSelector(reselectLocaleList);
  const product = useSelector(selectProductCode);
  const [selectedLanguage, setSelectedLanguages] = React.useState<any>();
  const [fallbackScenario, setFallBackScenario] = React.useState(false);
  const location = useLocation();
console.log('selectedLanguage: ',selectedLanguage);
  useEffect((): void => {
    if (languageLists && languageLists.length === 0) setFallBackScenario(true);
  }, [languageLists]);

  useEffect((): void => {
    if (
      localStorage.getItem('storeLanguage') &&
      localStorage.getItem('storeLanguage') !== undefined &&
      product !== 'sbp'  &&
      product !== null
    ) {
      const setLanguage = JSON.parse(localStorage.getItem('storeLanguage'));
      if (
        languageLists.some(item => item.localeCode === setLanguage.localeCode)
      ) {
        setSelectedLanguages(setLanguage);
        dispatch(changeLanguage(setLanguage.localeCode));
      }
    } else {
      setSelectedLanguages(defaultLocale);
      dispatch(changeLanguage(defaultLocale.localeCode));
    }
  }, [dispatch, languageLists, product]);

  const renderSelectLanguages = React.useCallback(
    ({ language }: any): JSX.Element => (
      <div className={styles.option}>
        <span>{language}</span>
        <Icon
          className={styles.selectedOption}
          name='checkbox-tick-special'
          size={24}
        />
      </div>
    ),
    []
  );
  const getOptionValue = React.useCallback(
    ({ localeCode }): string => localeCode,
    []
  );
  const handleLanguageChange = React.useCallback(
    (language: any): any => {
      localStorage.setItem('storeLanguage', JSON.stringify(language));
      setSelectedLanguages(language);
      dispatch(changeLanguage(language.localeCode));
    },
    [dispatch]
  );

  const productLanguageList = useMemo(() => {
    if ((product === 'sbp' || product === 'dra') && location.pathname !== '/home') {
      dispatch(changeLanguage(defaultLocale.localeCode));
      //handleLanguageChange(defaultLocale);
      return languageLists.filter(item => item.localeCode === defaultLocale.localeCode);
    }
    return languageLists;
  }, [dispatch, product, location, languageLists]);

  return (
    <React.Fragment>
      {fallbackScenario ? (
        <div className={styles.languagefbSelect}>
          <div className={styles.iconCircle}>
            <Icon
              className={styles.icon}
              name='exclamation-mark-special'
              size={20}
            />
          </div>
          <div className={styles.fallbackLanguage}>English (United States)</div>
          <div className={styles.tooltipText}>
            Localization settings are temporarily unavailable. Please try again
            later.
          </div>
        </div>
      ) : (
        <div className={styles.languageSelect}>
          <div>
            <img alt='language' className={styles.shapeLang} src={ShapeLanguage} />
          </div>
          {/* <div className={styles.languageLabel}>{t('app.selectlanguage')}</div> */}
          <div className={styles.dropdownLanguage}>
            <Select<AP.DefaultLocale>
              className={styles.language}
              getOptionValue={getOptionValue}
              isClearable={false}
              isSearchable={false}
              OptionComponent={OptionUserProduct}
              options={productLanguageList}
              renderOption={renderSelectLanguages}
              value={selectedLanguage}
              withDropdownIndicator={true}
              onChange={handleLanguageChange}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
