export type SubscriptionState = Readonly<{
  states: DTO.State[] | [];
  countryCode: string;
  isLoading: boolean;
  data: AP.RegistrationData;
  userEmail: string | null;
  userAction: string;
}>;

export const RENEW_SUBSCRIPTIONS = '@subscription/RENEW_SUBSCRIPTIONS';
export const FETCH_SUBSRIPTION_DETAILS =
  '@subscription/FETCH_SUBSRIPTION_DETAILS';
export const SET_SUBSRIPTION_PLANS = '@subscription/SET_SUBSRIPTION_PLANS';
export const SET_USER_ACTION = '@subscription/SET_USER_ACTION';
export const SET_INIT_VALUES = '@subscription/SET_INIT_VALUES';
export const SET_SUBSRIPTION_COUNTRY_CODE =
  '@subscription/SET_SUBSRIPTION_COUNTRY_CODE'; // SET_SUBSRIPTION_EMAIL
export const SUBMIT_SUBSRIPTION_DATA =
  '@subscription/SUBMIT_SUBSRIPTION_ACCOUNT_DETAILS';
export const SET_SUBSRIPTION_EMAIL = '@subscription/SET_SUBSRIPTION_EMAIL';
export const SET_SUBSRIPTION_DETAILS = '@subscription/SET_SUBSRIPTION_DETAILS';
export const SET_IS_LOADING = '@subscription/SET_IS_LOADING';

export type FetchSubscriptionAccountDetailsAction = Store.PaylodedAction<{
  userDetails: DTO.UserDetails;
  states: DTO.State[];
  plans: DTO.BasePlan[];
}>;

export type FetchSubscriptionAccountDetailsAction$ = Store.PaylodedAction<any>;
// AP.AccountDetailsFormFields
export type SubmitSubscriptionAccountDetails = Store.PaylodedAction<any>;

export type SubscriptionActionTypes =
  | FetchSubscriptionAccountDetailsAction
  | any;
