import React from 'react';
import { connect } from 'react-redux';
import { withFormik, FormikProps } from 'formik';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import { useTranslation } from 'react-i18next';
import { object, string, setLocale } from 'yup';

// import { selectProductCode } from 'store/product';
// import { reselectRegistrationData } from 'store/registration';
// import { RootState } from 'store/root.reducer';
import { noop } from 'shared/utils';
import { Form } from 'components';
import { RootState } from 'store/root.reducer';
import { reselectSubscriptionData } from 'store/updateSubscription';
import { errorException } from 'shared/utils/AppInsights';

interface FormFieldProps {
  disabled?: boolean;
  name: string;
  span?: number;
  label?: string;
  placeholder?: string;
  options?: any[];
  elem?: any[];
  validationErrMsg?: (err: string | unknown) => string;
  productCode?: any;
  t?: any;
}

interface FormGroup {
  //   title: string;
  fields: FormField[][];
  note?: string;
}

export interface FormField {
  disabled?: boolean;
  label: string;
  name: string;
  placeholder?: string;
  render: (props: FormFieldProps) => JSX.Element;
  elem?: any;
  options?: any[];
}

setLocale({
  mixed: {
    default: ({ path }: string | any): AP.ValidationError => ({
      key: camelCase(path)
    }),
    required: (): AP.ValidationError => {
      // AppInsights Error Exception
      errorException('Error data required, File reference: UserForm.helpers.tsx in setLocale function ');

      return { key: 'required' };
    }
  },
  string: {
    email: (): AP.ValidationError => {
      // AppInsights Error Exception
      errorException('Error userEmail, File reference: UserForm.helpers.tsx in setLocale function  ');

      return { key: 'userEmail' };
    }
  }
});

function renderInput(
  type: 'text' | 'email',
  props: FormFieldProps
): JSX.Element {
  return <Form.Input type={type} {...props } />;
}

// eslint-disable-next-line react/no-multi-comp
function renderSelect({ options, ...props }: FormFieldProps): JSX.Element {
  return (
    <Form.Select
      optionLabel='roleName'
      options={options || []}
      optionValue='productRoleId'
      {...props}
    />
  );
}

function renderStateSelect({ options, disabled, ...props }: FormFieldProps): JSX.Element {
  return (
    <Form.Select
      disabled={disabled}
      optionLabel='name'
      options={options || []}
      optionValue='code'
      {...props}
    />
  );
}

export const formGroups: FormGroup[] = [
  {
    fields: [
      [
        {
          label: 'forms.emailAddress',
          name: 'UserEmail',
          render: (props): JSX.Element =>
            renderInput('email', { ...props, span: 12 })
        },
        {
          label: 'forms.firstName',
          name: 'FirstName',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 12 })
        },
        {
          label: 'forms.lastName',
          name: 'LastName',
          render: (props): JSX.Element =>
            renderInput('text', { ...props, span: 12 })
        }
      ]
    ]
  }
];

export const mipFormGroups: FormGroup[] = [
  {
    fields: [
      [
        {
          label: 'forms.emailAddress',
          name: 'UserEmail',
          render: (props): JSX.Element =>
          renderInput('email', { ...props, span: 12 })
        },
        {
          label: 'forms.firstName',
          name: 'FirstName',
          render: (props): JSX.Element =>
          renderInput('text', { ...props, span: 12 })
        },
        {
          label: 'forms.lastName',
          name: 'LastName',
          render: (props): JSX.Element =>
          renderInput('text', { ...props, span: 12 })
        },
        {
          label: 'forms.role',
          name: 'Role',
          placeholder: 'forms.selectOption',
          render: (props): JSX.Element =>
          renderSelect({ ...props, span: 12 })
        }
      ]
    ]
  }
];
export const addInfoFormGroups: FormGroup[] = [
  {
    fields: [
      [
        {
          label: 'forms.companyFullLegalName',
          name: 'organization.legalName',
          render: (props): JSX.Element =>
          renderInput('text', { ...props, span: 12, disabled: true })
        },
        {
          label: 'forms.globalParentFullLegalNameOptional',
          name: 'organization.parentLegalName',
          render: (props): JSX.Element =>
          renderInput('text', { ...props, span: 12, disabled: true })
        },
        {
          label: 'forms.departmentOptional',
          name: 'organization.department',
          render: (props): JSX.Element =>
          renderInput('text', { ...props, span: 12, disabled: true })
        },
        {
          label: 'forms.stateProvinceCounty',
          name: 'organization.state',
          render: (props): JSX.Element =>
          renderStateSelect({ ...props, span: 12, disabled: true })
        },
        {
          label: 'forms.address',
          name: 'organization.address',
          render: (props): JSX.Element =>
          renderInput('text', { ...props, span: 12, disabled: true })
        },
        {
          label: 'forms.townCity',
          name: 'organization.city',
          render: (props): JSX.Element =>
          renderInput('text', { ...props, span: 12, disabled: true })
        },
        {
          label: 'forms.zipPostalCode',
          name: 'organization.postalCode',
          render: (props): JSX.Element =>
          renderInput('text', { ...props, span: 12, disabled: true })
        },
      ]
    ]
  }
];

export function useRenderInput(lookups: {
  [key: string]: any[];
}): ({
  name,
  label,
  placeholder,
  render,
  options
}: FormField) => JSX.Element {
  const { t } = useTranslation();
  
  return React.useCallback(
    ({ name, label, placeholder, render, elem }: FormField): JSX.Element => (
      <React.Fragment key={name}>
        {render({
          name,
          label: t(label),
          elem,
          options: get(lookups, name, []),
          placeholder: placeholder ? t(placeholder) : '',
          t,
          validationErrMsg: (err: string | any): string =>
            err ? t(`validation.errors.${err.key}`, { values: err.values }) : ''
        })}
      </React.Fragment>
    ),
    [t,lookups]
  );
}

interface UserForm {
  UserEmail: string;
  FirstName: string;
  LastName: string;
  organization: DTO.Organization;
}

type UserFormProps = {
  UserEmail: string;
  FirstName: string;
  LastName: string;
  organization: DTO.Organization
};

export const initialFormValues: UserForm = {
  UserEmail: '',
  FirstName: '',
  LastName: '',
  organization: null
};

export type InjectedFormikUserFormProps = UserProps &
  FormikProps<UserFormProps>;

  export type InjectedFormikUserFormPropsa = MultipleUserProps &
  FormikProps<UserFormProps>;

const mapUserFormToProps = (state: RootState) => ({
  data: {
    UserEmail: '',
    FirstName: '',
    LastName: '',
    organization: reselectSubscriptionData(state).organization
  }
});

export const accountDetailsStoreEnhancer = connect(mapUserFormToProps);
export interface UserProps extends ReturnType<typeof mapUserFormToProps> {
  isMultipleUser?: boolean;
  loading: boolean;
  formErrors: Record<keyof UserForm, AP.ValidationError>;
  onSubmit: (values: UserForm) => void;
  onCancel: () => void;
  productRoles?: Array<any>;
  organization?: DTO.Organization;
  displayId?:string;
}

export interface MultipleUserProps extends ReturnType<typeof mapUserFormToProps> {
  loading: boolean;
  formErrors: Record<keyof UserForm, AP.ValidationError>;
  onSubmit: (values: UserForm) => void;
  onCancel: () => void;
  productRoles?: Array<any>;
  organization?: DTO.Organization;
}

export const userFormEnhancer = withFormik<UserProps, UserFormProps>({
  mapPropsToValues: ({ data }) => data,
  validationSchema: object().shape({
    UserEmail: string()
      .required()
      .email()
      .test('cannot be Deloitte email', undefined, (value: string): boolean => {
        const emailLengthLimit = 64;

        if (!value) return true;
        if (value && value.toLowerCase().includes('@deloitte.')) return false;
        if (value) {
          const splitValue = value.split('@');
          if (splitValue && splitValue[0].length > emailLengthLimit)
            return false;
        }
        if (
          value &&
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>().,;\s@"]+\.{0,1})+[^<>().,;:\s@"]{2,})$/.test(
            value
          )
        ) {
          return true;
        }
        return false;
      }),
    FirstName: string()
      .required()
      .trim(),
    LastName: string()
      .required()
      .trim(),
    Role: object().shape({ 
      roleName: string()
      .required()
      .trim()  
      .optional()
    }) ,
    organization: object().shape({
      legalName: string()
        .required()
        .trim(),
      address: string()
        .required()
        .trim(),
      city: string()
        .required()
        .trim(),
      state: string()
        .transform(value => value !== null && value.toString ? value.toString() : value)
        .required(),
      postalCode: string()
        .required()
        .trim()
    })
  }),
  handleSubmit: noop
});
