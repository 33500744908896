import React, { PropsWithChildren, FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Footer } from 'features/Footer/Footer';
import { AutoLogout } from 'features/AutoLogout/AutoLogout';
import { SidePanel } from 'features/SidePanel/SidePanel';
import styles from './AdminLayout.module.css';

export interface Props {
  children: React.ReactChild | React.ReactChild[];
}

export const AdminLayout: FC<PropsWithChildren<Props>> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <AutoLogout />
      <div className={styles.wrapper}>
        <SidePanel />
        <div className={styles.container}>
          {/* <span>{t('app.name')}</span>*/}
          <main>{children}</main>
        </div>
      </div>
      <div className={styles.footer_wrapper}>
        <Footer />
      </div>            
    </Fragment>
  );
};
