import React from 'react';
import { components } from 'react-select';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';

import styles from './Placeholder.module.css';

export function Placeholder<T = AP.SelectOption>(
  props: PlaceholderProps<T, boolean>
): JSX.Element {
  return <components.Placeholder {...props} className={styles.container} />;
}
