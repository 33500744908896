import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import cs from 'classnames';

import styles from './Tab.module.css';

interface Props {
  children: string | JSX.Element;
  href: string;
  tabRoute: string;
  
  highlightActive?: boolean;
  className?: string;
  isSidePanel?: boolean;
}

export const Tab: React.FC<Props> = ({
  href,
  highlightActive,
  children,
  className,
  tabRoute,
  isSidePanel
}) => {
  const location = useLocation();

  return (
    <Link
      className={
        location.pathname.includes(tabRoute) && highlightActive
          ? cs(styles.tab, isSidePanel ? styles.tabActiveSidePanel : styles.tabActive, className)
          : cs(styles.tab, className)
      }
      to={href}
    >
      {children}
    </Link>
  );
};
