import { errorException } from 'shared/utils/AppInsights';

export const asyncActionPending = (actionName: string): string =>
  `${actionName}_PENDING`;

export const asyncActionRejected = (actionName: string): string =>
  `${actionName}_REJECTED`;

export const asyncActionFulfilled = (actionName: string): string =>
  `${actionName}_FULFILLED`;

export const smartFetch = async <T>(
  url: string,
  options: RequestInit = {}
): Promise<T | void> => {
  const response = await fetch(url, options);

  if (response.status >= 400) {
    throw response;
  }

  if (response.status === 204) {
    return undefined;
  }

  return response.json();
};


export const fetchData = async <T>(path: string, headers: any): Promise<T | void> => {
  try{
    const resp = await fetch(path, { headers });
    const data = await resp.json();
    return data;
  } catch(error){
    return errorException(`${error}`);
  }
};
