import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';

import { getFromLocalStorage, setToLocalStorage } from 'shared/utils/storage.utils';
import { SUBMISSION_PLAN_STATUS } from 'shared/constants/submission.consts';
import { SidePanel } from 'features/SidePanel/SidePanel';
import { reselectUser } from 'store/auth';
import { selectProductCode, selectProductTitle } from 'store/product';
import {
  selectIsLoading,
  setInitValues,
  reselectSubscriptionEmail,
  fetchSubscriptionDetails,
  reselectSubscriptionPlans,
  reselectAction,
  setUserAction,
  setPlans
} from 'store/updateSubscription';
import { Stepper, Spinner, Select, Icon } from 'components';
import { AppUserNav } from 'features/AppLayout';
import { Footer } from 'features/Footer/Footer';
import { APP_PATH } from '../Routes/Routes.helpers';
import styles from './UpdateSubscription.module.css';
import {
  UPDATE_SUB_STEP,
  Step,
  useUpdateSubSteps,
  UPDATE_SUBSCRIPTIONS_ACTIONS
} from './UpdateSubscription.helpers';
import { SelectPlans } from './steps/SelectPlans/SelectPlans';
import { AccountDetails } from './steps/AccountDetails/AccountDetails';
import { LogoutWarningPopup } from '../../components/LogoutWarningPopup/LogoutWarningPopup';
import { CONFIG } from '../../shared/constants/config.consts';
import { reselectAutoLogoutData } from '../../store/auth';
import { UserMenu } from '../AdminPage/UserMenu/UserMenu';
import { UserProductsSelect } from '../AdminPage/UserProductsSelect/UserProductsSelect';
import { USER_ROLES } from '../AdminPage/OnboardingPage/OnboardingFilters/OnboardingFilters';
import { OptionAdminAction } from './steps/SelectPlans/OptionAdminAction/OptionAdminAction';

export const UpdateSubscription: React.FC<{ ProductCode: string }> = ({
  ProductCode
}) => {
  const { t } = useTranslation();
  const plans: any = useSelector(reselectSubscriptionPlans);
  const action = useSelector(reselectAction);
  const user = useSelector(reselectUser);
  const history = useHistory();
  const productTitle = useSelector(selectProductTitle);
  const productCode = useSelector(selectProductCode);
  const isLoading = useSelector(selectIsLoading);
  const email = useSelector(reselectSubscriptionEmail);
  const [isNextDisabled, setDisabled] = React.useState<boolean>(!action);
  const {
    originalLogoutTimeout,
    originalPopupTimeout,
    originalKeepAliveTimeout
  } = useSelector(reselectAutoLogoutData);
  const [step, setStep] = React.useState<UPDATE_SUB_STEP>(
    UPDATE_SUB_STEP.SELECT_PLANS
  );
  // based on this state we show/hide action dropdown
  const [isNextButtonClicked, disableActionDropDown] = React.useState<boolean>(false);
  const {
    [UPDATE_SUB_STEP.SELECT_PLANS]: selectPlansStep,
    [UPDATE_SUB_STEP.ACCOUNT_DETAILS]: accountDetailsStep
  } = useUpdateSubSteps(setStep);

  const actionList = [
    plans.some(
      (plan: any) =>
        (plan?.status &&
          plan?.status === SUBMISSION_PLAN_STATUS.UNSUBSCRIBED) ||
        (plan?.status && plan?.status === SUBMISSION_PLAN_STATUS.REJECTED)
    ) && {
      actionName: t(
        `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.ADD_NEW_PLAN.toLowerCase().replaceAll(
          ' ',
          ''
        )}`
      ),
      icon: 'icon-plus-special align-text-bottom h5 ml-1 mr-1',
      className: '',
      id: '4'
    },
    plans.some(
      (plan: any) =>
        (plan?.status && plan?.status === SUBMISSION_PLAN_STATUS.ACTIVE) ||
        (plan?.status && plan?.status === SUBMISSION_PLAN_STATUS.EXPIRED) ||
        (plan?.status && plan?.status === SUBMISSION_PLAN_STATUS.INACTIVE)
    ) && {
      actionName: t(
        `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.RENEW.toLowerCase()}`
      ),
      icon: 'align-text-bottom h5 icon-stopwatch-special ml-1 mr-1',
      className: '',
      id: '3'
    },
    plans.some(
      (plan: any) =>
        plan?.status && plan?.status === SUBMISSION_PLAN_STATUS.CANCELLED
    ) && {
      actionName: t(
        `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.RE_ACTIVATE.toLowerCase().replaceAll(
          '-',
          ''
        )}`
      ),
      icon: `icon-backup-special align-bottom h4 ${styles.reactivate_icon}`,
      className: '',
      id: '1'
    },
    plans.some(
      (plan: any) =>
        (plan?.status && plan?.status === SUBMISSION_PLAN_STATUS.ACTIVE)
    ) && {
      actionName: t(
        `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.CANCEL.toLowerCase()}`
      ),
      icon: 'align-text-bottom h5 icon-no-entry-special ml-1 mr-1',
      className: styles.cancel_option,
      id: '2'
    }
  ];
  const [steps] = React.useState<Step[]>([
    {
      name: UPDATE_SUB_STEP.SELECT_PLANS,
      label: t('admin.plans.select'),
      children: <SelectPlans isNextButtonDisabled={isNextDisabled} onBack={null} onNext={() => {
        disableActionDropDown(true);
        return accountDetailsStep();
      }} />
    },
    {
      name: UPDATE_SUB_STEP.ACCOUNT_DETAILS,
      label: t('registration.titles.accountDetails'),
      children: <AccountDetails showBackButton={true} onBack={() => {
        disableActionDropDown(false);
        return selectPlansStep();
      }} onNext={null} />
    }
  ]);

  const dispatch = useDispatch();

  const getOptionValue = React.useCallback(
    ({ actionName }): string => actionName,
    []
  );
  const [selectedAction, setSelectedAction] = React.useState(action);
  // on next btn click, we are setting cache variable to true 
  // if any of plan is Active before next bt click else FALSE
  // see SelectPLans.tsx : proceedWithNextClickAction  functions
  const verifyToDisplayCancelAction = () => {
    const displayCancel = getFromLocalStorage('showCancelButton');
    // when update subscription is 1st time loaded then cache will be null (not set)
    return displayCancel === null ? true: displayCancel;
  };
 
  const getActionName = (actionName: string): string => {
    switch (actionName) {
      case t(
        `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.ADD_NEW_PLAN.toLowerCase().replaceAll(
          ' ',
          ''
        )}`
      ): {
        return t('registration.plans.actions.addnewplan');
      }
      case t(
        `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.RENEW.toLowerCase()}`
      ): {
        return t('registration.plans.actions.renew');
      }
      case t(
        `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.RE_ACTIVATE.toLowerCase().replaceAll(
          '-',
          ''
        )}`
      ): {
        return t('registration.plans.actions.reactivate');
      }
      case t(
        `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.CANCEL.toLowerCase()}`
      ): {
        return t('registration.plans.actions.cancel');
      }
      default:
        return t('registration.plans.actions.renew');
    }
  };
  const selectAction = (act: any): any => {
    setSelectedAction(act.actionName);
    dispatch(setUserAction(act.actionName));
    setDisabled(true);
    setToLocalStorage('actionValue', act.actionName);
    if (act.actionName !== action) {
      const updatedPlans = plans.map((plan: any) => ({
        ...plan,
        checked: false
      }));
      dispatch(setPlans(updatedPlans));
    }
  };
  const renderSelectActions = React.useCallback(
    (act: any): JSX.Element => (
      <div className={styles.option}>
        <span>{act.actionName}</span>
        <Icon
          className={styles.selectedOption}
          name='checkbox-tick-special'
          size={24}
        />
      </div>
    ),
    []
  );
  const placeholder =
    action && action === t('registration.plans.actions.addnewplan') ? (
      <div className={styles.placeholder}>
        <span className='mr-1 icon-smallchevron-down' />
        {action}
      </div>
    ) : (
      <div className={styles.placeholder}>
        {action ? `${getActionName(action)}` : t('registration.texts.action')}
      </div>
    );
  useEffect(() => {
    if (!email) {
      const routeUrl = `${APP_PATH.ADMIN_SUBMISSIONS_ROOT}/${ProductCode}`;
      history.replace(routeUrl);
    } else {
      dispatch(fetchSubscriptionDetails(productCode, email));
    }
    return () => {
      dispatch(setInitValues());
    };
  }, [ProductCode, dispatch, email, history, productCode]);

  const additionalThemeProps = {
    borderRadius: 0,
    colors: {
      neutral0: '#FFFFFF',
      primary25: '#EBF8FD',
      primary: '#00A3E0'
    },
    spacing: {
      baseUnit: 4,
      controlHeight: 4,
      menuGutter: 2
    }
  };

  return isLoading ? (
    <Spinner shouldShow={true} />
  ) : (
    <React.Fragment>
      <AppUserNav>
        {user.role !== USER_ROLES.SUPER_ADMIN && <UserProductsSelect />}
        <UserMenu />
      </AppUserNav>
      {CONFIG.ENABLE_AUTO_LOGOUT && (
        <LogoutWarningPopup
          originalKeepAliveTimeout={originalKeepAliveTimeout}
          originalLogoutTimeout={originalLogoutTimeout}
          originalPopupTimeout={originalPopupTimeout}
        />
      )}
      <div className={styles.wrapper}>
        <SidePanel />
        <div className={styles.container}>
          <main style={{ marginBottom: '6rem' }}>
           
            <div
              className={cs(styles.header_container, isNextButtonClicked ? styles.whileBackground: styles.page_padding)}
            >
              <div className={styles.mainTitle}>
                {t('admin.plans.updateSubscription')} — {productTitle}
              </div>
              {!isNextButtonClicked && (
                <div className={t('admin.plans.selectPlanTo').length<=20 && action.length<=20?styles.engDropdownaction :styles.dropdownaction}>
                <div className={styles.actionLabel}>
                  {t('admin.plans.selectPlanTo')}:
                </div>
                <Select<any>
                  additionalThemeProps={additionalThemeProps}
                  className={styles.action}
                  getOptionValue={getOptionValue}
                  isClearable={false}
                  isSearchable={false}
                  OptionComponent={OptionAdminAction}
                  options={actionList.filter(
                    (actionItem: any) => actionItem !== false
                  )}
                  placeholderPrefix={placeholder}
                  renderOption={renderSelectActions}
                  value=''
                  withDropdownIndicator={true}
                  onChange={selectAction}
                />
              </div>
              )}
            </div>
            <div className={`${styles.border_rule} ${styles.m_b_2}`} />
            <div className={styles.page_padding}>
              <Stepper current={step} isUpdateSubscriptionPage={true}>
                {steps.map(({ name, label, children }) => (
                  <Stepper.Step key={name} isUpdateSubscriptionPage={true} label={label} name={name}>
                    {children}
                  </Stepper.Step>
                ))}
              </Stepper>
            </div>
          </main>
        </div>
      </div>
      <Footer className={styles.footer} />
    </React.Fragment>
  );
};
