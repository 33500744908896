import React from 'react';
import kebabCase from 'lodash/kebabCase';
import cs from 'classnames';
import { Form, Col } from 'react-bootstrap';
import { useField } from 'formik';

import { useError } from '../Form.helpers';
import styles from '../Form.module.css';

interface Props {
  disabled?: boolean;
  type: 'text' | 'email';
  name: string;
  span?: number;
  label?: string;
  placeholder?: string;
  validationErrMsg?: (err: string) => string;
  maxLength?: number;
}

export default function Input({
  disabled,
  type,
  label,
  name,
  placeholder,
  span,
  validationErrMsg,
  maxLength
}: Props): JSX.Element {
  const [field, meta] = useField(name);
  const { value, onChange, onBlur } = field;
  const { error, touched } = meta;
  const isInvalid = error && touched;

  return (
    <Form.Group
      as={Col}
      controlId={kebabCase(`form input ${type} ${label}`)}
      lg={span}
    >
      {label && <Form.Label className={styles.label}>{label}</Form.Label>}
      <Form.Control
        className={cs({ [styles.formControlInvalid]: isInvalid })}
        disabled={disabled}
        isInvalid={isInvalid}
        maxLength={maxLength}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value || ''}
        onBlur={onBlur}
        onChange={onChange}
      />
      {useError(error, touched, validationErrMsg)}
    </Form.Group>
  );
}
