import {
  SET_UPDATED_PRIMARY_USER,
  setUpdatedPrimaryUserAction
} from './global.types';

export const setUpdatedPrimaryUser = (
  id: number
): setUpdatedPrimaryUserAction => ({
  type: SET_UPDATED_PRIMARY_USER,
  payload: id
});
