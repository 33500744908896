import React from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './Step.module.css';

interface Props {
  isUpdateSubscriptionPage?: boolean;
  name: string;
  label: string;
  children?: JSX.Element | JSX.Element[];
  passed?: boolean;
  active?: boolean;
  index?: number;
}

export function Step({ label, passed, active, index = 0, isUpdateSubscriptionPage = false }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <li
      className={cs(styles.point, {
        [styles.active]: active,
        [styles.passed]: passed
      })}
    >
      <span className={ isUpdateSubscriptionPage ?
        cs(styles.content, 
          index === 0 && active ? styles.activeStep1: '',
        index === 1 && active ? styles.activeStep2: '') : null
      }>
        {isUpdateSubscriptionPage ? '' : `${t('common.step', { step: index + 1 })}:${' '}`}
        <span className={cs(styles.label, { [styles.activeLabel]: active })}>
          {t(label)}
        </span>
      </span>
    </li>
  );
}
