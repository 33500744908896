import React from 'react';

import { Dropdown } from 'components';
import { UserMenuToggle } from './UserMenuToggle/UserMenuToggle';
import { UserMenuContent } from './UserMenuContent/UserMenuContent';

export function UserMenu(): JSX.Element {
  return (
    <Dropdown>
      <Dropdown.Toggle as={UserMenuToggle} id='user-nav' />
      <Dropdown.Menu alignRight={true} as={UserMenuContent} />
    </Dropdown>
  );
}
