export const registrationEn = {
  registration: 'Registration',
  data: {
    title: {
      ibr: 'Incremental Borrowing Rate',
      mip: 'My Incentive Plan',
      sbp: 'Share Based Payments Calculator',
      registration: 'registrations'
    }
  },
  titles: {
    selectCountry: 'Jurisdiction',
    termsOfUse: 'Terms of Use',
    selectPlan: 'Select Plan',
    accountDetails: 'Account Details',
    submission: 'Submission',
    acceptableUsePolicy: 'Acceptable Use Policy',
    replaceaupdocumentmsg: 'This document will be replaced by the AUP from ',
    replacetoudocumentmsg: 'This document will be replaced by the TOU from ',
  },
  warning: {
    updatedTerms: 'We have recently updated Terms of Use.',
    acceptTerms: 'Please accept new Terms of Use before continuing.',
    updatedAup: 'We have recently updated the Acceptable Use Policy.',
    acceptAup: 'Please accept new Acceptable Use Policy before continuing.'
  },
  cancelRegistration: {
    title: 'Cancel Registration',
    message: 'Are you sure you want to cancel your registration?'
  },
  labels: {
    selectCountry: 'Please select geography in order to view the Terms of Use',
    selectCountryMIP: 'Please select geography in order to view the Acceptable Use Policy',
    selectedCountry: 'Jurisdiction'
  },
  countryPlaceholder: 'Jurisdiction',
  confirmation: {
    title: 'Thank you for your registration submission.',
    questions:
      'For any technical difficulties and/or any other questions, please contact your Deloitte relationship manager'
  },
  productconfirmation:
    'Your registration is currently under review and you can expect an email once your subscription has been activated by your Deloitte representative.',
  plans: {
    names: {
      BlackScholes: 'Black-Scholes',
      MonteCarlo: 'Monte Carlo',
      Binomial: 'Binomial',
      TSRMonitoring: 'TSR Monitoring'
    },
    descriptions: {
      BlackScholes: 'Description for Black-Scholes plan',
      MonteCarlo: 'Description for Monte Carlo plan',
      Binomial: 'Description for Binomial plan',
      TSRMonitoring:
        'This is a one year subscription that will start once approved'
    },
    certificatesNumber: 'Number of valuations',
    availableLicenses: 'Available licenses',
    purchasedLicenses: 'Purchased licenses',
    requestedLicenses: 'Requested Licenses',
    duration: 'Duration',
    actions: {
      addnewplan: 'Add New Plan',
      renew: 'Renew',
      reactivate: 'Re-Activate',
      cancel: 'Cancel'
    },
    renewSubscription: 'Renew Subscription'
  },
  countries: {
    us: 'United States',
    canada: 'Canada',
    ap: 'Deloitte Asia Pacific',
    nse: 'North and South Europe',
    dce: 'Deloitte DCE',
    aus: 'Australia',
    uk: 'United Kingdom',
    china: 'China'
  },
  texts: {
    select: 'SELECT',
    plansTo: 'PLAN(S) TO',
    action: 'Select Action'
  }
};
