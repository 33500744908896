import React, { FC, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { reselectUser, selectIsFirstLogin } from 'store/auth';
import { ConfirmDialog } from 'components/Localization_Confirm/ConfirmDialog';
import { AppUserNav } from 'features/AppLayout';
import { AdminLayout } from 'features/AdminLayout/AdminLayout';
import { AdminPageContent } from './AdminPageContent/AdminPageContent';
import { UserMenu } from './UserMenu/UserMenu';
import { UserProductsSelect } from './UserProductsSelect/UserProductsSelect';


export const confirmDialogConfig = {
  openButtonName: 'OPEN',
  closeButtonName: 'CANCEL',
  okBtnName: 'CONFIRM',
  cancelBtnName: '',
  title: 'CONFIRM LANGUAGE & LOCALIZATION PREFERENCE',
  subTitle: 'Please select your preferred language',
  subTitle2: 'Please select your preferred date format',
  subTitle3: 'Please select your preferred number format',
  default: 'default',
  description:
    'You may change your preferences any time in your user profile once logged into the application.'
};

export const AdminPage: FC = () => {
  const user = useSelector(reselectUser);
  const [showSettings, setShowSettings] = useState(true);
  const isFirstLogin = useSelector(selectIsFirstLogin);
  // const confirmDialogConfig = {
  //   openButtonName: 'OPEN',
  //   closeButtonName: 'CANCEL',
  //   okBtnName: 'CONFIRM',
  //   cancelBtnName: '',
  //   title: 'CONFIRM LANGUAGE & LOCALIZATION PREFERENCE',
  //   subTitle: 'Please select your preferred language',
  //   subTitle2: 'Please select your preferred date format',
  //   subTitle3: 'Please select your preferred number format',
  //   default: 'default',
  //   description:
  //     'You may change your preferences any time in your user profile once logged into the application.'
  // };

  const closeModal = () => {
    setShowSettings(false);
  };

  // if (!user.profileSettings) {
  return (
    <Fragment>
      {isFirstLogin && (
        <ConfirmDialog
          cancelBtnName={confirmDialogConfig.closeButtonName}
          close={closeModal}
          description={confirmDialogConfig.description}
          firstLogin={true}
          okBtnName={confirmDialogConfig.okBtnName}
          showModal={showSettings}
          subTitle={confirmDialogConfig.subTitle}
          subTitle2={confirmDialogConfig.subTitle2}
          subTitle3={confirmDialogConfig.subTitle3}
          title={confirmDialogConfig.title}
        />
      )}


      {user.profileSettings && (
        <Fragment>
          <AppUserNav>
            {/* <UserProductsSelect /> */}
            <UserMenu />
          </AppUserNav>
          <AdminLayout>
            <AdminPageContent />
          </AdminLayout>
        </Fragment>
      )}
    </Fragment>
  );
};
