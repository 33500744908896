import React from 'react';
// import { useTranslation } from 'react-i18next';
import { InjectedFormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { Dialog, Form } from 'components';
import {
  commentLengthLimit,
  cancelSubmissionDialogEnhancer,
  CancelSubmissionDialogProps,
  CancelSubmissionForm
} from './CancelSubmissionDialog.helpers';
import styles from './CancelSubmissionDialog.module.css';

function CancelSubmissionDialogComponent({
  onSubmit,
  onCancel,
  isValid,
  show = true,
  values,
  datas
}: InjectedFormikProps<
  CancelSubmissionDialogProps,
  CancelSubmissionForm
>): JSX.Element {
  const list = datas.map((data: { checked: boolean; title: string }) => {
    return data.checked ? <p>{data.title}</p> : null;
  });
  const { t } = useTranslation();
  const handleOnClose = React.useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleOnSubmit = React.useCallback(() => {
    onSubmit(values.comment);
  }, [onSubmit, values.comment]);

  const renderFooter = React.useCallback(
    () => (
      <div className={styles.symbolsCounter}>
        {values.comment.length}/{commentLengthLimit}
      </div>
    ),
    [values]
  );
  return (
    <React.Fragment>
      <Dialog
        actions={[
          {
            label: t('dialogs.cancelDialogItems.actions.cancel'),
            onClick: handleOnClose,
            variant: 'outline-secondary'
          },
          {
            label: t('dialogs.cancelDialogItems.actions.cancelPlan'),
            variant: 'danger',
            onClick: handleOnSubmit,
            disabled: !isValid || values.comment.length === 0
          }
        ]}
        noCaps={false}
        show={show}
        title={t('dialogs.cancelDialogItems.title')}
        onClose={handleOnClose}
        onClosed={handleOnClose}
      >
        <Form className={styles.container}>
          {datas.map}
          <p>
            {`${t('dialogs.cancelDialogItems.parts.goingToCancel')} ${
              list.filter((val: any) => !!val).length
            } ${t('dialogs.cancelDialogItems.parts.plans')}`}
          </p>
          {list}
          <Form.Row>
            <Form.Textarea
              label={t('dialogs.cancelDialogItems.notes')}
              maxLength={commentLengthLimit}
              name='comment'
              placeholder={t('dialogs.cancelDialogItems.placeholder')}
              renderFooter={renderFooter}
            />
          </Form.Row>
        </Form>
      </Dialog>
    </React.Fragment>
  );
}

export const CancelSubmissionDialog = cancelSubmissionDialogEnhancer(
  CancelSubmissionDialogComponent
);
